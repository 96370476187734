import React, { useState } from "react"
import { useDispatch } from "react-redux"
import Swal from "sweetalert2"
import AsyncSelect from 'react-select/async';
import { pick } from "lodash"
import { sendNotification } from "../../actions"
import { Inputs } from "../../components"
import { history } from "../../config/stores"
import "./styles.scss"
import { API } from "../../config/settings";
import { Headers } from "../../utilities";
import ReactQuill from "react-quill";

const NotificationsSend = () => {
    const dispatch = useDispatch()
    const [data, setData] = useState({
        title: "",
        message: "",
        users: [],
        selected: 'Всички',
        action: false,
        type: '',
        target: '',
    })
    const handleChange = (payload) => setData((data) => ({ ...data, ...payload }))
    const [showRequired, setShowRequired] = useState(false)
    const isValid = () => {
        if (!data.title || !data.message) return false
        if (data.selected === 'Избрани потребители' && !data.users.length) return false
        if (data.action && (!data.type || !data.target.value)) return false
        return true
    }

    const handleSend = async () => {
        if (!isValid()) setShowRequired(true)
        else dispatch(sendNotification({
            payload: {
                ...pick(data, ['title', 'message', 'type']),
                users: data.users?.map(({ value }) => value),
                target: data?.target?.value || "",

            },
            onSuccess: () => {
                Swal.fire({ icon: 'success', title: 'Success!', text: 'Успешно изпратено известие!', confirmButtonColor: '#0099ff', })
                history.goBack()
            }
        }))

    }

    const loadOptionsUsers = (search) => new Promise((resolve, reject) => {
        let searchString = search.slice()
        if (searchString && searchString?.includes('+')) searchString = searchString.replace(/\+/g, '');

        return fetch(`${API}user/list`, {
            method: 'POST',
            headers: Headers.get_auth(),
            body: JSON.stringify({ pageSize: 20, search: searchString })
        }).then((res) => res.json()).then((res) => {
            const options = res.payload.docs.map(({ _id, name, phoneNumber }) => ({ value: _id, label: [name, phoneNumber].filter(Boolean).join(" - ") }))
            return resolve(options)
        }).catch(() => reject())
    })

    const loadOptionsLocations = (search) => new Promise((resolve, reject) => {
        return fetch(`${API}location/list`, {
            method: 'POST',
            headers: Headers.get_auth(),
            body: JSON.stringify({ pageSize: 20, search })
        }).then((res) => res.json()).then((res) => {
            const options = res.payload.docs.map(({ _id, name }) => ({ value: _id, label: name }))
            return resolve(options)
        }).catch(() => reject())
    })

    const defaultEditorStyles = { margin: '10px 0', borderColor: '#2f4858', borderRadius: '6px', padding: '1px' }

    return <div className="screen-notifications-send-container">
        <div className="screen-notifications-send-inner-container col">
            <div className="screen-notifications-send-header row">
                <div className="icon icon-close" onClick={() => history.goBack()} />
                <h2>Добавяне на персонализирано известие</h2>
                <Inputs.Raised text="Запази" onClick={handleSend} />
            </div>
            <div className="screen-notifications-send-content">
                <span>Заглавие:</span>
                <Inputs.Text
                    value={data?.title || ""}
                    onChange={({ target: { value } }) => handleChange({ title: value })}
                    className={showRequired && !data.title && 'invalid'}
                />
                <span>Текст:</span>
                <ReactQuill
                    style={showRequired && !data.message ? { ...defaultEditorStyles, boxShadow: `0 0 3px #e5214e`, borderColor: 'transparent' } : defaultEditorStyles}
                    value={data?.message}
                    onChange={(e) => handleChange({ message: e })}
                    modules={{ toolbar: ['link'] }}
                />
                <span>Получатели:</span>
                <Inputs.RadioButton
                    options={["Всички", "Избрани потребители"]}
                    value={data?.selected}
                    onChange={(selected) => handleChange({ selected, users: [] })}
                />
                {data.selected === "Избрани потребители" && <AsyncSelect
                    styles={{
                        container: (provided) => ({ ...provided, margin: '10px 0' }),
                        control: (provided) => ({
                            ...provided, borderColor: '#2f4858', '&:hover': { borderColor: '#2f4858' },
                            border: `${showRequired && !data.users.length && 'none'}`,
                            boxShadow: `${showRequired && !data.users.length && '0 0 3px #e5214e'}`,
                        }),
                        valueContainer: (provided) => ({ ...provided, fontSize: 13 }),
                        menu: (provided) => ({ ...provided, margin: 0 }),
                        menuList: (provided) => ({ ...provided, fontSize: 13, }),
                    }}
                    isMulti
                    cacheOptions
                    defaultOptions
                    loadOptions={loadOptionsUsers}
                    value={data.users}
                    onChange={(users) => handleChange({ users })}
                />}
                <Inputs.Toggle
                    label="Води до действие"
                    value={data.action}
                    onChange={() => handleChange({ action: !data.action, type: "", target: "", })}
                />
                {data.action && <>
                    <Inputs.RadioButton
                        options={[{ value: 'location', label: 'Локация' },]}
                        value={data?.type}
                        onChange={(type) => handleChange({ type, target: "" })}
                    />
                    {data.type === 'location' && <AsyncSelect
                        styles={{
                            container: (provided) => ({ ...provided, margin: '10px 0' }),
                            control: (provided) => ({
                                ...provided, borderColor: '#2f4858', '&:hover': { borderColor: '#2f4858' },
                                border: `${showRequired && !data.target && 'none'}`,
                                boxShadow: `${showRequired && !data.target && '0 0 3px #e5214e'}`,
                            }),
                            valueContainer: (provided) => ({ ...provided, fontSize: 13 }),
                            menu: (provided) => ({ ...provided, margin: 0 }),
                            menuList: (provided) => ({ ...provided, fontSize: 13, }),
                        }}
                        cacheOptions
                        defaultOptions
                        loadOptions={loadOptionsLocations}
                        value={data.target}
                        onChange={(target) => handleChange({ target })}
                    />}
                </>}
            </div>
        </div>
    </div>
}

export default NotificationsSend