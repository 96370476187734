/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { history } from "../../config/stores";
import { connect } from "react-redux";
import { getAllCars, getAllBrands, getAllModels, getAllTypes } from "../../actions";
import "./styles.scss";
import CarsGrid from "../../components/CarsGrid/CarsGrid";

const Cars = ({
  getAllCars,
  getAllBrands,
  getAllModels,
  getAllTypes,
  cars,
  brands,
  models,
  types,
}) => {
  const [carsCount, setCarsCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [copyCars, setCopyCars] = useState(cars?.slice(0));
  const tableRef = useRef();

  useEffect(() => {
    getAllCars({
      pageNumber: 1,
      pageSize: 20,
      noPagination: false,
      onSuccess: (res) => {
        setCarsCount(res.totalDocs);
        setCopyCars(res.docs);
      },
    });
    getAllBrands({
      noPagination: true,
      onSuccess: () => { },
    });
  }, []);

  const fetchMoreCars = () => {
    if (hasMore) {
      getAllCars({
        pageNumber: currentPage + 1,
        pageSize: 20,
        onSuccess: (response) => {
          setCurrentPage(response.page);
          setHasMore(response.hasNextPage);
          setCopyCars(copyCars.concat(response.docs));
        },
        onError: () =>
          alert("Имаше проблем с изпращането на съобщение,опитайте отново!"),
      });
    } else return;
  };

  return (
    <div className="stations-container">
      <div className="inner-stations-container">
        <div className="upper-stations-container">
          <h3 className="stations">Aвтомобили</h3>

          <div className="right-inner-container">
            <div
              className="button-container smaller"
              onClick={() => {
                history.push("/cars/add");
              }}
            >
              Добави
            </div>
          </div>
        </div>
        <div className="stations-content">
          <CarsGrid
            fetchMoreCars={fetchMoreCars}
            tableRef={tableRef}
            setCurrentPage={setCurrentPage}
            cars={cars}
            copyCars={copyCars}
            setCopyCars={setCopyCars}
            getAllCars={getAllCars}
            setHasMore={setHasMore}
            brands={brands}
            models={models}
            getAllModels={getAllModels}
            types={types}
            getAllTypes={getAllTypes}
          />
        </div>
        <div className="lower-stations-info">
          <div
            className="inner-station-info"
            style={{ width: "150px", height: "100%" }}
          >
            <p style={{ fontSize: "15px", marginLeft: "8%" }}>
              Брой: <b>{carsCount}</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cars: state.cars.cars,
  brands: state.cars.brands,
  models: state.cars.models,
  types: state.cars.types,
});
const mapDispatchToProps = (dispatch) => ({
  getAllCars: (payload) => dispatch(getAllCars(payload)),
  getAllBrands: (payload) => dispatch(getAllBrands(payload)),
  getAllModels: (payload) => dispatch(getAllModels(payload)),
  getAllTypes: (payload) => dispatch(getAllTypes(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cars);
