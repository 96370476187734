import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import { authTypes, setLoadingOff, sessionSocketDisconnect } from '../actions'
import { API } from "../config/settings"
import { Headers, User } from '../utilities'
import { history } from '../config/stores'
import Swal from 'sweetalert2'

export const loginRequestCodeEpic = action$ =>
  action$.pipe(
    ofType(authTypes.LOGIN_REQUEST_CODE),
    switchMap(({ payload: { phoneNumber, onSuccess, onError } }) => {
      return ajax({
        url: `${API}user/login`,
        method: 'POST',
        headers: Headers.get(),
        body: JSON.stringify({ phoneNumber })
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable
            .create(obs => {
              obs.next(setLoadingOff())
              onSuccess()
              obs.complete()
            })
        }),
        catchError(err => {
          return ActionsObservable
            .create(obs => {
              console.error(err)
              if (err.status === 422) {
                // Swal.fire("Не съществува потребител с този телефонен номер.","","error");
                Swal.fire({
                  confirmButtonText: 'OK',
                  text: "Не съществува потребител с този телефонен номер.",
                  icon: "error",
                })
              }
              else if (err.status === 403) {
                if (err.response.message.includes("Try")) Swal.fire("Направени много опити за влизане. Моля опитайте след час.", "", "error")
                else Swal.fire("Този потребител няма позволен достъп до системата", "", "error");
              }
              console.error(err)

              onError()
              obs.complete()
            })
        })
      )
    })
  )


export const checkCode = (action$) =>
  action$.pipe(
    ofType(authTypes.LOGIN_CHECK_CODE),
    switchMap(({ payload: { phoneNumber, code } }) => {
      return ajax({
        url: `${API}code/check`,
        method: 'POST',
        headers: Headers.get(),
        body: JSON.stringify({ phoneNumber, code })
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable
            .create(obs => {
              obs.complete()
            })
        }),
        catchError(err => {
          return ActionsObservable
            .create(obs => {
              console.error(err);
              obs.complete()
            })
        })
      )
    })
  )


export const logIn = (action$) =>
  action$.pipe(
    ofType(authTypes.LOGIN_CODE_USE),
    switchMap(({ payload: { phoneNumber, code, onSuccess, onError } }) => {
      return ajax({
        url: `${API}code/use`,
        method: 'POST',
        headers: Headers.get(),
        body: JSON.stringify({ phoneNumber, code })
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable
            .create(obs => {
              obs.next(setLoadingOff());
              User.authenticate(response.token, { token: response.token, ...response?.user });
              obs.next({ type: authTypes.LOGIN_SUCCESS, payload: response });
              onSuccess()
              obs.complete()
            })
        }),
        catchError(err => {
          return ActionsObservable
            .create(obs => {
              onError();
              obs.complete()
            })
        })
      )
    })
  )

export const checkUser = (action$) =>
  action$.pipe(
    ofType(authTypes.LOGIN_USER_CHECK),
    switchMap(() => {
      return ajax({
        url: `${API}user/check`,
        method: 'POST',
        headers: Headers.get_auth(),
        body: JSON.stringify({ phoneNumber: User.getUser().phoneNumber })
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable
            .create(obs => {
              obs.next({
                type: authTypes.LOGIN_SUCCESS,
                payload: response,
              })

              obs.complete()
            })
        }),
        catchError(err => {
          return ActionsObservable
            .create(obs => {
              if (err.status === 401) {
                User.signout().then(() => {
                  Swal.fire({
                    icon: 'error',
                    title: 'Упс...',
                    text: 'Невалидна сесия! Моля влезте отново.',
                  })
                  history.push('/')
                  obs.complete()
                })
              }
              if (err.status === 422) {
                obs.next({
                  type: authTypes.LOGIN_SUCCESS,
                  payload: User,
                })
                User.setSupportID(User.getUser()?.customerSupportId);
                obs.complete();
              }
              else {
                Swal.fire({
                  icon: 'error',
                  title: 'Упс...',
                  text: err?.response?.error?.message,
                })
                obs.complete()
              }
              obs.complete()
            })
        })
      )
    })
  )


export const setUserSupportId = (action$) =>
  action$.pipe(
    ofType(authTypes.LOGIN_USER_SUPPORT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}user/support`,
        method: 'POST',
        headers: Headers.get_auth(),
        body: JSON.stringify(payload)
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable
            .create(obs => {
              obs.next({
                type: authTypes.LOGIN_USER_SUPPORT_SUCCESS,
                payload: response.payload,
              })

              localStorage.setItem("customerSupportId", payload.customer_id);

              obs.complete()
            })
        }),
        catchError(err => {
          return ActionsObservable
            .create(obs => {
              console.error(err);
              obs.complete()
            })
        })
      )
    })
  )

export const getUsers = (action$) =>
  action$.pipe(
    ofType(authTypes.GET_USERS),
    switchMap(({ payload: { pageNumber, pageSize, sortBy, noPagination, search, onSuccess } }) => {
      return ajax({
        url: `${API}user/list`,
        method: 'POST',
        headers: Headers.get_auth(),
        body: JSON.stringify({ pageNumber, pageSize, sortBy, noPagination, search })
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable
            .create(obs => {
              onSuccess(response);
              obs.next({
                type: authTypes.GET_USERS_SUCCESS,
                payload: response.payload
              })
              obs.complete()
            })
        }),
        catchError(err => {
          return ActionsObservable
            .create(obs => {
              console.error(err);
              obs.complete()
            })
        })
      )
    })
  )
export const getBusinessUsers = (action$) =>
  action$.pipe(
    ofType(authTypes.GET_BUSINESS_USERS),
    switchMap(({ payload: { type, pageNumber, pageSize, sortBy, noPagination, search, onSuccess } }) => {
      return ajax({
        url: `${API}user/list`,
        method: 'POST',
        headers: Headers.get_auth(),
        body: JSON.stringify({ type, pageNumber, pageSize, sortBy, noPagination, search })
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable
            .create(obs => {
              obs.next({
                type: authTypes.GET_BUSINESS_USERS_SUCCESS,
                payload: response.payload
              })
              onSuccess(response);
              obs.complete()
            })
        }),
        catchError(err => {
          return ActionsObservable
            .create(obs => {
              console.error(err);
              obs.complete()
            })
        })
      )
    })
  )

export const createUser = (action$) =>
  action$.pipe(
    ofType(authTypes.CREATE_USER),
    switchMap(({ payload: { payload, onSuccess, onError } }) => {
      return ajax({
        url: `${API}user/create`,
        method: 'POST',
        headers: Headers.get_auth(),
        body: JSON.stringify(payload)
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable
            .create(obs => {
              onSuccess(response)
              obs.next(setLoadingOff())
              obs.complete()
            })
        }),
        catchError(err => {
          return ActionsObservable
            .create(obs => {
              console.error(err)
              onError(err);
              obs.complete()
            })
        })
      )
    })
  )

export const editUser = (action$) =>
  action$.pipe(
    ofType(authTypes.EDIT_USER),
    switchMap(({ payload: { info, _id } }) => {
      return ajax({
        url: `${API}user/${_id}`,
        method: 'PUT',
        headers: Headers.get_auth(),
        body: JSON.stringify(info)
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable
            .create(obs => {
              Swal.fire('Успешно редактиране.', '', 'success')
              history.push("/users")
              obs.next(setLoadingOff())
              obs.complete()
            })
        }),
        catchError(err => {
          return ActionsObservable
            .create(obs => {
              console.error(err);
              Swal.fire('Нямате право да редактирате други потребители.', '', 'еrror')
              obs.complete()
            })
        })
      )
    })
  )

export const deleteUser = (action$) =>
  action$.pipe(
    ofType(authTypes.DELETE_USER),
    switchMap(({ payload: { _id, onSuccess } }) => {
      return ajax({
        url: `${API}user/${_id}`,
        method: 'DELETE',
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable
            .create(obs => {
              onSuccess()
              obs.next(setLoadingOff())
              obs.complete()
            })
        }),
        catchError(err => {
          return ActionsObservable
            .create(obs => {
              Swal.fire('Нямате право да триете други потребители.', '', 'еrror')
              obs.complete()
            })
        })
      )
    })
  )


export const logOut = (action$) =>
  action$.pipe(
    ofType(authTypes.LOG_OUT),
    switchMap(() =>
      ActionsObservable.create((obs) => {
        User.signout();
        obs.next(sessionSocketDisconnect())
        //window.localStorage.clear();
        obs.next({
          type: authTypes.LOG_OUT_SUCCESS,
        })
      })
    )
  )