import React from "react";
import { LocationsGrid } from "../../../components";
import "../styles.scss";
import { useLocation } from "react-router-dom";
import { history } from "../../../config/stores";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLocations } from "../../../actions";

export default function Locations() {
  const { state } = useLocation();
  const locationsData = state?.data || [];
  const payload = state?.payload || {};
  const groupId = state?.groupId || "";
  const [sortBy, setSortBy] = useState("");
  const [sortValue, setSortValue] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [, setOpenPopup] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const tableRef = useRef();
  const optionsReff = useRef();
  const dispatch = useDispatch();
  const [innerLoading, setInnerLoading] = useState(false);
  const locations = useSelector((state) => state.locations.locations);
  // const locations = useSelector(state => state.stations.stations)

  useOutsideAlerter(optionsReff);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (event.target.nodeName === "svg" || event.target.nodeName === "path" || (ref.current && !ref.current.contains(event.target) && !event.target.className.includes("child"))) {
          setOpenPopup(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const sortData = (sortKey, sortValue, page) => {
    setInnerLoading(true);
    let payload = {
      pageNumber: page || 1,
      pageSize: "10",
      sortBy: "",
      noPagination: false,
    };

    if (sortKey && sortValue) {
      payload.sortBy = { [sortKey]: sortValue };
    }

    dispatch(
      getLocations({
        ...payload,
        onSuccess: (response) => {
          setCurrentPage(response.payload.page);
          setHasMore(response.payload.hasNextPage);
          setInnerLoading(false);
          if (response?.payload?.docs) {
            let locations = selectedLocations.slice();
            locations = response.payload.docs.map((location) => selectedLocations.find((el) => el._id === location._id)) || [];

            setSelectedLocations([...selectedLocations, ...locations]);
          }
        },
        onError: () => alert("Имаше проблем с изпращането на съобщение,опитайте отново!"),
      })
    );
  };

  const fetchData = () => {
    if (hasMore) {
      sortData(sortBy, sortValue, currentPage + 1);
    } else return;
  };

  useEffect(() => {
    dispatch(
      getLocations({
        pageNumber: "1",
        pageSize: "20",
        sortBy: "",
        noPagination: false,
        search: "",
        onSuccess: (response) => {
          setCurrentPage(response.payload.page);
          setHasMore(response.payload.hasNextPage);

          if (locationsData?.length && response.payload?.docs?.length) {
            let newSelectedLocations = response.payload.docs
              .filter((el) => {
                const foundLocation = locationsData.find((location) => location?._id === el?._id || location?.location === el?._id || location?.location?._id === el?._id);

                return foundLocation !== undefined;
              })
              .map((el) => {
                // const foundLocation = locationsData.find((location) => location?._id === el?._id || location?.location === el?._id || location?.location?._id === el?._id);
                const foundPayloadLocation = payload?.locations?.find((location) => location?._id === el?._id || location?.location === el?._id || location?.location?._id === el?._id);

                return {
                  ...el,
                  specialPrice: foundPayloadLocation?.specialPrice || null,
                  initialFee: foundPayloadLocation?.initialFee || null,
                  parkingFee: foundPayloadLocation?.parkingFee || null,
                };
              });

            setSelectedLocations(newSelectedLocations);
          }
        },
        onError: () => alert("Имаше проблем със зареждането на останалите локации,опитайте отново!"),
      })
    );
  }, [dispatch, locationsData, payload]);

  return (
    <div className="locations-list-container stations-container">
      <div className="inner-stations-container stations">
        <div className="full-upper-container" id="full-upper">
          <div className="left-inner-header" style={{ justifyContent: "flex-start" }}>
            <div
              className="exit"
              id={"exit"}
              onClick={() => {
                if (groupId) history.push(`/edit/group/${groupId}`, { ...state, tab: "locations", data: locationsData });
                else history.push("/groups/add", { ...state, tab: "locations", data: locationsData });
              }}
            />
            <p>ИЗБОР НА СТАНЦИИ</p>
          </div>
          <div className="right-inner-header">
            <button
              className={`button-container addStation ${!selectedLocations?.length ? "disabled" : ""}`}
              style={{ marginRight: "2%" }}
              disabled={!selectedLocations?.length}
              onClick={() => {
                if (groupId) history.push(`/edit/group/${groupId}`, { ...state, data: selectedLocations?.filter((loc) => loc?._id) });
                else history.push("/groups/add", { ...state, data: selectedLocations });
              }}
            >
              Избери
            </button>
          </div>
        </div>

        <div className="stations-content select" style={{ height: "85%" }}>
          <LocationsGrid
            locations={locations}
            tableRef={tableRef}
            fetchData={() => fetchData()}
            setHasMore={setHasMore}
            sortBy={sortBy}
            setSortBy={(v) => setSortBy(v)}
            sortValue={sortValue}
            setSortValue={(v) => setSortValue(v)}
            selectedLocations={selectedLocations}
            setSelectedLocations={(v) => setSelectedLocations(v)}
            sortData={(sortKey, sortValue, filterType, payment, users) => sortData(sortKey, sortValue, filterType, payment, users)}
            innerLoading={innerLoading}
          />
        </div>
      </div>
    </div>
  );
}
