/* eslint-disable */
import React, { useState, useCallback, useRef, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import "./styles.scss";
import MessageBox from "../MessageBox/MessageBox";
import { setOpenedTicket, supportSocketEmit } from "../../actions";
import { uploadFile } from "../../utilities";
import moment from "moment";

const ChatContainer = ({
  tickets,
  messagesById,
  supportSocketEmit,
  openedTicket,
  buttonClicked,
}) => {
  const [message, setMessage] = useState("");
  const ImageRef = useRef("");
  const [image, setImage] = useState("");
  const [vote, setVote] = useState(false);
  const [selectedVote, setSelectedVote] = useState(0);
  const [selectedVoteText, setSelectedVoteText] = useState("");
  const [pageCounter, SetPageCounter] = useState(2);

  const [defaultChatHistory, setDefaultChatHistory] = useState([
    "Здравейте! 🖐Ние сме на линия за вас от Понеделник до Петък, между 09:00 и 21:00 часа!",
    "В момента сме: Извън линия",
    "Ще се свържем с Вас възможно най-скоро. Междувременно моля първо добавете кратко описание на Вашия казус:",
  ]);
  const [userChatHistory, setUserChatHistory] = useState([]);

  const currentTicket = tickets.find((el) => el?._id === openedTicket);
  const messages = messagesById[openedTicket]?.docs || [];
  const reversedmessages = messages.slice(0).reverse();
  const listInnerRef = useRef();
  var fileInput = useRef();

  useEffect(() => {
    setMessage("");
    setVote(false);
  }, [openedTicket]);

  const onChangeDelayed = useCallback(
    _.debounce(
      (query) =>
        supportSocketEmit({
          action: "nextMessages",
          payload: {
            ticket: openedTicket,
            pageNumber: pageCounter,
            pageSize: 15,
            sortBy: "",
            noPagination: false,
          },
        }),
      300
    )
  );

  const handleScroll = () => {
    onChangeDelayed();
    SetPageCounter(pageCounter + 1);
  };

  const scrollToMyRef = () => {
    const scroll =
      listInnerRef.current.scrollHeight - listInnerRef.current.clientHeight;
    listInnerRef.current.scrollTo(0, scroll);
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop * -1 + clientHeight === scrollHeight - 1) {
        if (messagesById[openedTicket]?.hasNextPage === true) {
          handleScroll();
          listInnerRef.current.scrollTo(0, scrollTop / 1.5);
        }
      }
    }
  };

  return (
    <>
      {openedTicket === null ? 
        buttonClicked ? 
          <div className="chat-container">
            <div className="upper-chat-title">
              <p className="chat-title">Нов чат</p>
            </div>
            <div className="inner-chat-container">
              <div className="chat-fields">
                <div className="left-field">
                  <div className="avatar" />
                </div>
                <div className="right-field">
                  {defaultChatHistory.map((element, index) => {
                    return (
                      <div key={index}>
                        <div className="field" key={index}>
                          <p className="message">{element}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="chat-fields-user" id="user">
                {reversedmessages.map((item, i) => {
                  return <MessageBox mess={item.text} key={i} />;
                })}
              </div>
            </div>

            <div className="characters-left">
              Остават:{" "}
              <b style={{ color: "#00D688" }}>{100 - message.length} символа</b>
            </div>
            <div className="input-message">
              <input
                required
                className="message"
                type="text"
                id="message"
                value={message}
                maxLength="100"
                onChange={(event) => setMessage(event.target.value)}
              />
              <div
                className="button-send"
                onClick={() => {
                  setUserChatHistory((prevArray) => [...prevArray, message]);
                  supportSocketEmit({
                    action: "createTicket",
                    payload: {
                      name: message,
                    },
                  });
                  setMessage("");
                }}
              >
                <div className="arrow-send" />
              </div>
            </div>
          </div>
           : <div className="chat-container"/> 
       : (
        <div className="chat-container">
          <div className="upper-chat-title">
            <p className="chat-title">{currentTicket?.name}</p>
          </div>
          <div
            className="inner-chat-container"
            id={"get"}
            ref={listInnerRef}
            onScroll={() => {
              onScroll();
            }}
          >
            <div className="chat-history" id={"history"}>
              {reversedmessages.map((item, i) => {
                return (
                  <div key={i}>
                    {moment(reversedmessages[i].createdAt)?.format(
                      "DD.MM.YYYY"
                    ) !==
                    moment(reversedmessages[i + 1]?.createdAt).format(
                      "DD.MM.YYYY"
                    ) ? (
                      <>
                        <div className="inner-date">
                          <p className="chat-title">
                            {moment(reversedmessages[i]?.createdAt).format(
                              "DD.MM.YYYY"
                            )}
                          </p>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {item.type === "Customer" ? (
                      <MessageBox
                        mess={item.text}
                        key={i}
                        SentOn={item.createdAt}
                      />
                    ) : (
                      <MessageBox
                        mess={item.text}
                        key={i}
                        left
                        type={item?.variant}
                        setVote={setVote}
                      />
                    )}
                  </div>
                );
              })}
              {currentTicket?.completedAt !== null && (
                <div className="inner-date">
                  <p className="chat-title">
                    Този чат беше означен като “Завършен”. Може да отворите
                    темата отново като изпратите съобщение.
                  </p>
                </div>
              )}
            </div>
            <div className={`chat-fields `}>
              {messagesById[openedTicket]?.hasNextPage === false ? (
                <>
                  <div className="left-field">
                    <div className="avatar" />
                  </div>
                  <div className="right-field">
                    {defaultChatHistory.map((element, index) => {
                      return (
                        <div key={index}>
                          <div className="fieldd" key={index}>
                            <p className="messagee">{element}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="characters-left">
            Остават:{" "}
            <b style={{ color: "#00D688" }}>{100 - message.length} символа</b>
          </div>

          <div className="full-input-message">
            {vote === true ? (
              currentTicket?.rating < 1 ? (
                <div className="input-votes">
                  <p
                    className="pass"
                    onClick={() => {
                      setSelectedVote(4);
                      setVote(false);
                    }}
                  >
                    Пропусни
                  </p>
                  <div
                    className={`button-vote-container green ${
                      selectedVote === 3 && "selected"
                    }`}
                    onClick={() => {
                      setSelectedVote(3);
                      setSelectedVoteText("Отлично обслужване!");
                    }}
                  >
                    Отлично обслужване!
                  </div>
                  <div
                    className={`button-vote-container gray ${
                      selectedVote === 2 && "selected"
                    }`}
                    onClick={() => {
                      setSelectedVote(2);
                      setSelectedVoteText("Средна работа, може още!");
                    }}
                  >
                    Средна работа, може още!
                  </div>
                  <div
                    className={`button-vote-container pink ${
                      selectedVote === 1 && "selected"
                    }`}
                    onClick={() => {
                      setSelectedVote(1);
                      setSelectedVoteText("Под всякаква критика!");
                    }}
                  >
                    Под всякаква критика!
                  </div>
                  <div
                    className="button-vote-container smaller dark"
                    onClick={() => {
                      if (selectedVote > 0 && selectedVote < 4) {
                        supportSocketEmit({
                          action: "voteTicket",
                          payload: {
                            ticket: openedTicket,
                            message:reversedmessages[reversedmessages.length - 1]?._id,
                            rating: selectedVote,
                          },
                        });
                        supportSocketEmit({
                          action: "sendMessage",
                          payload: {
                            ticket: openedTicket,
                            text: selectedVoteText,
                            variant: "text",
                          },
                        });
                        setSelectedVote(0);
                        setSelectedVoteText("");
                        setVote(false); 
                        
                      }
                    }}
                  >
                    Изпрати
                  </div>
                </div>
              ) : (
                <div className="input-message">
                  <textarea
                    required
                    disabled= {ImageRef.current.length > 0 && true}
                    className="message"
                    type="text"
                    id="message"
                    value={message || ""}
                    maxLength="100"
                    rows={Math.min(4, message?.split("\n")?.length || 0 + 1)}
                    onChange={(event) => {
                      setMessage(event.target.value);
                      event.preventDefault();
                    }}
                    onKeyPress={(event) => {
                      if (event.key === "Enter" && message.length < 100) {
                        event.preventDefault();
                        setMessage(message + "\n");
                      }
                    }}
                  />
                  <div className="button-image" id={"imageId"}>
                    <p style={{ marginTop: "-10px", whiteSpace: "nowrap" }}>
                      {image.slice(36, image.length)}
                    </p>
                    <div
                      className="close-image"
                      onClick={() => {
                        setImage("");
                        document.getElementById("imageId").style.display =
                          "none";
                        ImageRef.current = "";
                      }}
                    />
                  </div>

                  <div
                    className={`add-file ${message.length > 1 && "hide"}`}
                    onClick={() => {
                      if (fileInput) {
                        fileInput.click();
                        document.getElementById("imageId").style.display =
                          "flex";
                          setMessage(ImageRef.current)
                      }
                    }}
                  >
                    <div className="add-icon" />
                  </div>
                  <div
                    className="button-send"
                    onClick={() => {
                      if (message.length > 0) {
                        setUserChatHistory((prevArray) => [
                          ...prevArray,
                          message,
                        ]);
                        setMessage("");
                        if (ImageRef.current.length > 1) {
                          supportSocketEmit({
                            action: "sendMessage",
                            payload: {
                              ticket: openedTicket,
                              text: ImageRef.current,
                              variant: "photo",
                            },
                          });
                          setImage("");
                          ImageRef.current = "";
                          document.getElementById("imageId").style.display =
                            "none";
                        } else{
                          supportSocketEmit({
                            action: "sendMessage",
                            payload: {
                              ticket: openedTicket,
                              text: message,
                              variant: "text",
                            },
                          });
                          scrollToMyRef();
                          setMessage("")
                        }
                         
                      }
                    }}
                  >
                    <div className="arrow-send" />
                  </div>
                </div>
              )
            ) : (
              <div className="input-message">
                <textarea
                  required
                  disabled= {ImageRef.current.length > 0 && true}
                  className={`message ${ImageRef.current.length > 0 && "disabled"}`}
                  type="text"
                  id="message"
                  value={message}
                  maxLength="100"
                  rows={Math.min(4, message?.split("\n")?.length || 0 + 1)}
                  onChange={(event) => {
                    setMessage(event.target.value);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter" && message.length < 100) {
                      event.preventDefault();
                      setMessage(message + "\n");
                    }
                  }}
                />
                <div className="button-image" id={"imageId"}>
                  <p style={{ marginTop: "-10px", whiteSpace: "nowrap" }}>
                    {image.slice(36, image.length)}
                  </p>
                  <div
                    className="close-image"
                    onClick={() => {
                      setImage("");
                      ImageRef.current = "";
                      document.getElementById("imageId").style.display = "none";
                    }}
                  />
                </div>

                <div
                   className={`add-file ${message.length > 1 && "hide"}`}
                  onClick={() => {
                    if (fileInput) {
                      fileInput.click();
                      document.getElementById("imageId").style.display = "flex";
                    }
                  }}
                >
                  <div className="add-icon" />
                </div>
                <div
                  className="button-send"
                  onClick={() => {
                    if (ImageRef.current.length > 1) {
                      supportSocketEmit({
                        action: "sendMessage",
                        payload: {
                          ticket: openedTicket,
                          text: ImageRef.current,
                          variant: "photo",
                        },
                      });
                      setImage("");
                      ImageRef.current = "";
                      document.getElementById("imageId").style.display = "none";
                    } else if (message.length > 0) {
                      supportSocketEmit({
                        action: "sendMessage",
                        payload: {
                          ticket: openedTicket,
                          text: message,
                          variant: "text",
                        },
                      });
                    }
                    setMessage("");
                    scrollToMyRef();
                  }}
                >
                  <div className="arrow-send" />
                </div>
              </div>
            )}
          </div>

          <input
            type="file"
            ref={(ref) => {
              fileInput = ref;
            }}
            style={{ display: "none" }}
            id="openFile"
            onChange={(event) => {
              event.persist();
              uploadFile(event.target.files[0])
                .then((data) => {
                  ImageRef.current = data.location;
                  setImage(data.location);
                  document.getElementById("message").focus();
                  document.getElementById("imageId").style.display = "flex";
                })
                .catch((err) => {});
            }}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  openedTicket: state.support.openedTicket,
  messagesById: state.support.messagesById,
});
const mapDispatchToProps = (dispatch) => ({
  setOpenedTicket: (payload) => dispatch(setOpenedTicket(payload)),
  supportSocketEmit: (payload) => dispatch(supportSocketEmit(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatContainer);
