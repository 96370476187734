import React from "react"
import "./styles.scss"

const RadioButton = ({ className = '', invalid, col, options, value, reversed, disabled, onChange }) => {
  return <div className={`input-radio-button-container ${className} ${col ? 'col' : 'row'} ${invalid ? "invalid" : ""}`}>
    {options?.map((opt, i) => <div
      key={`single-radio-button-${i}`}
      className={`single-radio-button row ${value === (opt?.value ?? opt) && 'selected'} ${reversed && 'reversed'} ${disabled && 'disabled'}`}
      onClick={() => {
        if (disabled || !onChange || value === (opt?.value || opt)) return
        onChange(opt?.value || opt)
      }}
    >
      <div className="radio-button-outer" style={{ borderColor: opt?.color }} >
        <div className={`radio-button-inner `} style={{ backgroundColor: value === opt.value && opt?.color }} />
      </div>
      <div className="label-container row">
        {opt?.icon && <div className="button-image" style={{ backgroundImage: `url(${opt?.icon})` }} />}
        <p>{typeof opt === "string" ? opt : opt?.label || opt?.value}</p>
      </div>
    </div>)}
  </div >
}

export default RadioButton