/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { history } from "../../config/stores";
import { GroupsGrid } from "../../components";
import "./styles.scss";
import { connect } from "react-redux";
import { getGroups } from "../../actions";
import { User } from "../../utilities";

const Groups = ({ getGroups, groups }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [copyGroups, setCopyGroups] = useState();
  const [sortBy, setSortBy] = useState("");
  const [sortValue, setSortValue] = useState(0);
  const [type, setType] = useState([]);
  const tableRef = useRef();

  useEffect(() => {
    getGroups({
      pageNumber: 1,
      pageSize: "10",
      sort: "",
      noPagination: false,
      search: "",
      onSuccess: (response) => {
        setCurrentPage(response.payload.page);
        setHasMore(response.payload.hasNextPage);
        setCopyGroups(response.payload.docs)
      },
      onError: () =>
        alert("Имаше проблем със зареждането на информацията,опитайте отново!"),
    });
  }, []);

  const fetchMoreGroups = () => {
    if (hasMore) {
      let payload = {
        pageNumber: currentPage + 1,
        pageSize: "10",
        sort: "",
        noPagination: false,
        search: ""
      }
        
      if (sortBy) {
        payload.sort = { [sortBy]: sortValue };
      }

      getGroups({
        ...payload,
        onSuccess: (response) => {
          setCurrentPage(response.payload.page);
          setHasMore(response.payload.hasNextPage);
          setCopyGroups([...copyGroups, ...response.payload.docs])
        },
        onError: () =>
          alert(
            "Имаше проблем със зареждането на информацията,опитайте отново!"
          ),
      });
    } else return;
  };

  const sortData = (sortKey, sortValue, filterType) => {
      let payload = {
        pageNumber: 1,
        pageSize: "10",
        sort: "",
        noPagination: false,
        search: "",
      };
  
      if (sortKey && sortValue) {
        payload.sort = { [sortKey]: sortValue };
      }
      if (filterType?.length) {
        payload.type = [...filterType];
      }

      getGroups({
        ...payload,
        onSuccess: (response) => {
          setCurrentPage(response.payload.page);
          setHasMore(response.payload.hasNextPage);
          setCopyGroups([...response.payload.docs])
        },
        onError: () =>
          alert("Имаше проблем с изпращането на съобщение,опитайте отново!"),
      });

  };

  return (
    <div className="stations-container">
      <div className="inner-stations-container">
      {/* <div className="inner-stations-container loading"> */}
        {/* <h3>Страницата е в процес на обновяване</h3> */}
        <div className="upper-stations-container">
          <h3 className="stations text">Бизнес групи</h3>
          {User.isAdmin && (
            <div className="right-inner-container">
              <div
                className="button-container smaller"
                onClick={() => {
                  history.push("/groups/add");
                }}
              >
                Добави
              </div>
            </div>
          )}
        </div>
        <div className="stations-content groups">
          <GroupsGrid
            groups={groups}
            fetchMoreGroups={() => fetchMoreGroups()}
            tableRef={tableRef}
            setHasMore={setHasMore}
            copyGroups={copyGroups}
            setCopyGroups={setCopyGroups}
            getGroups={getGroups}
            sortBy={sortBy}
            setSortBy={(v) => setSortBy(v)}
            sortValue={sortValue}
            setSortValue={(v) => setSortValue(v)}
            type={type}
            setType={(v) => setType(v)}
            sortData={(sortKey, sortValue, filterType) =>
              sortData(sortKey, sortValue, filterType)
            }
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  groups: state.groups.groups,
});
const mapDispatchToProps = (dispatch) => ({
  getGroups: (payload) => dispatch(getGroups(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Groups);
