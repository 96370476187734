import React, { forwardRef } from "react"
import "./styles.scss"

const Text = forwardRef(({ label, invalid, className = "", inputClassName = "", icon, ...props }, ref) => {
    return <div className={`input-text-container row ${className} ${invalid ? 'invalid' : ''} ${label ? 'with-label' : ''} ${icon ? 'with-icon' : ''}`}>
        <input ref={ref} className={`input-text ${inputClassName}`} {...props} />
        {label && <div className="label-container row"><p>{label}</p></div>}
        {icon}
    </div>
})

export default Text