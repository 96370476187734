import { default as Active } from './Active'
import { default as Downloads } from "./Downloads"
import { default as Sessions } from "./Sessions"
import { default as Top } from "./Top"
import { default as Month } from "./Month"
import { default as Cars } from "./Cars"
import { default as Notifications } from "./Notifications"
import { default as LoaderInline } from "./LoaderInline"
import { default as Horizontal } from './Horizontal'
import { default as Pie } from './Pie'
import { default as BarChart } from './BarChart'
import { default as SelectPeriod } from './SelectPeriod'
import { default as ExportOptions } from './ExportOptions'

const Statistics = {
    Active,
    Downloads,
    Sessions,
    Top,
    Month,
    Cars,
    Notifications,
    LoaderInline,
    Horizontal,
    Pie,
    BarChart,
    SelectPeriod,
    ExportOptions
}

export default Statistics