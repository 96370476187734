/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { InnerHeader } from "../../../components";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../styles.scss";

const AddUser = ({}) => {
  const [users, setUsers] = useState([
    {
      phoneNumber: "",
      name: "",
      role: "",
    },
  ]);

  return (
    <div className="full-add-container scroll-width-thin">
      <InnerHeader
        link={"/users"}
        first="ДОБАВЯНЕ НА ПОТРЕБИТЕЛИ"
        AddUserPage={1}
        payload={users}
      />
      {users.map((user, i) => {
        return (
          <div className={`section-container ${i > 0 && "top"}`} id={i}>
            <div className="header-exit">
              <h2>Потребител {i + 1}</h2>
              {users.length > 1 && (
                <div
                  className="delete-user"
                  onClick={() => {
                    const newUsers = users.slice(0);
                    newUsers.splice(i, 1);
                    setUsers(newUsers);
                  }}
                />
              )}
            </div>
            <div className="inner-section-container">
              <div className="first-input">
                <span className="input-title">Име и Фамилия</span>
                <input
                  required
                  className="input-field"
                  type="text"
                  id="voucher-name"
                  value={user.name}
                  onChange={(event) => {
                    const newUsers = users.slice(0);
                    newUsers[i].name = event.target.value;
                    setUsers(newUsers);
                  }}
                />
              </div>
              <div className="first-input" style={{ marginLeft: "1%" }}>
                <span className="input-title" style={{ marginBottom: "3%" }}>
                  Телефонен номер
                </span>
                <div className="partial-container">
                  <PhoneInput
                    value={user.phoneNumber}
                    inputClass="partial"
                    onChange={(phone) => {
                      const newUsers = users.slice(0);
                      newUsers[i].phoneNumber = phone;
                      setUsers(newUsers);
                    }}
                  />
                </div>
              </div>
              <div className="last-input">
                <span
                  className="input-title"
                  style={{ marginTop: "-2%", marginBottom: "2%" }}
                >
                  Роля
                </span>
                <div className="all-radio padded">
                  <div
                    className="radio"
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      marginRight: "4%",
                    }}
                  >
                    <input
                      type="radio"
                      value={`admin${i}`}
                      name={`admin${i}`}
                      className="radio"
                      id={`admin${i}`}
                      onClick={() => {
                        const newUsers = users.slice(0);
                        newUsers[i].role = "admin";
                        setUsers(newUsers);
                      }}
                      checked={user.role === "admin"}
                    />
                    {user.role === "admin" ? <b>Aдмин</b> : "Aдмин"}
                  </div>

                  <div
                    className="radio"
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      marginRight: "4%",
                    }}
                  >
                    <input
                      type="radio"
                      value={`support${i}`}
                      name={`support${i}`}
                      className="radio"
                      id={`support${i}`}
                      onClick={() => {
                        const newUsers = users.slice(0);
                        newUsers[i].role = "support";
                        setUsers(newUsers);
                      }}
                      checked={user.role === "support"}
                    />
                    {user.role === "support" ? <b>Поддръжка</b> : "Поддръжка"}
                  </div>

                  <div
                    className="radio"
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      marginRight: "4%",
                    }}
                  >
                    <input
                      type="radio"
                      value={`accountant${i}`}
                      name={`accountant${i}`}
                      className="radio"
                      id={`accountant${i}`}
                      onClick={() => {
                        const newUsers = users.slice(0);
                        newUsers[i].role = "accountant";
                        setUsers(newUsers);
                      }}
                      checked={user.role === "accountant"}
                    />
                    {user.role === "accountant" ? (
                      <b>Счетоводство</b>
                    ) : (
                      "Счетоводство"
                    )}
                  </div>
                </div>
              </div>
            </div>
            {i + 1 === users.length ? (
              <div
                className="button-container add-section"
                style={{ marginTop: "-2%" }}
                onClick={() => {
                  const newUsers = users.slice(0);
                  newUsers.push({
                    phoneNumber: "",
                    name: "",
                    role: "",
                  });
                  setUsers(newUsers);
                }}
              >
                Добави
              </div>
            ) : (
              <div className="end-line" />
            )}
          </div>
        );
      })}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(null, mapDispatchToProps)(AddUser);
