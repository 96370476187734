/* eslint-disable */
import React, { useEffect, useState } from "react";
import "./styles.scss";
import "moment/locale/bg";
import "react-dropdown/style.css";
import { connect } from "react-redux";
import { getStationByID, getUserByPhoneNumber } from "../../../actions";
import { InnerHeader } from "../../../components";
import { toast } from "react-toastify";
const StartCharging = ({ getStationByID, station, getUserByPhoneNumber }) => {
  const station_id = window.location.href.split("/")[6];
  const outlet_id = window.location.href.split("/")[7];
  const [payload, setPayload] = useState({
    outlet: outlet_id,
    user: "",
    car: "",
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [user, setUser] = useState();
  useEffect(() => {
    getStationByID({
      station: station_id,
    });
  }, []);
  return (
    <div className="stations-add-container">
      <InnerHeader
        link={`/locations/settings/${station?._id}`}
        first={"СТАРТИРАЙ ЗАРЕЖДАНЕ"}
        startCharge
        payload={payload}
      />
      <div className="lower-start-container ">
        <h3 className="start-title">
          <b>Информация за потребителя</b>
        </h3>
        <div className="start-fields">
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <div>
              <span style={{ fontSize: "14px" }}>Телефонен номер</span>
              <div className="partial-container">
                <div className="suffix">+359</div>
                <input
                  required
                  className="partial"
                  type="number"
                  value={phoneNumber}
                  id="partial"
                  onChange={({ target: { value } }) => {
                    if (!/^\d{0,9}$/.test(value)) return;
                    setPhoneNumber(value);
                  }}
                />
              </div>
            </div>
            <div
              className="button-container"
              style={{
                margin: "0%",
                width: "30%",
                marginTop: "2%",
                marginLeft: "-7%",
                height: "40px",
                fontSize: "16px",
              }}
              onClick={() => {
                getUserByPhoneNumber({
                  phoneNumber: "+359" + phoneNumber,
                  onSuccess: (res) => {
                    setUser(res.payload);
                    setPayload({
                      ...payload,
                      ["user"]: res.payload._id,
                    });
                  },
                  onError: (err) => {
                    toast.error(err.response.message, { autoClose: 3000 });
                    setUser({
                      name: "",
                    });
                  },
                });
              }}
            >
              Въведи
            </div>
          </div>
          <div style={{ marginLeft: "3%" }}>
            <span style={{ fontSize: "14px" }}>Име и фамилия</span>
            <input
              required
              disabled
              className={`station-name`}
              type="text"
              id="name"
              style={{ marginBottom: "0%", marginTop: "3%" }}
              value={user?.name}
              onChange={() => {}}
            />
          </div>
          <div style={{ marginLeft: "3%" }}>
            Изберете автомобил {"(oпционално)"}
            <div
              className="all-radio"
              style={{ marginTop: "1%", marginBottom: "2%" }}
            >
              {user?.cars.length < 1 || user?.cars == undefined ? "---" : user?.cars?.map((car) => {
                return (
                  <div className="radio" style={{ marginLeft: "-2%" }}>
                    <input
                      type="radio"
                      value="addre"
                      name="place"
                      className="radio"
                      id="addre"
                      checked={payload.car.includes(car._id) && true}
                      onClick={() => {
                        setPayload({
                            ...payload,
                            ["car"]:car._id
                        })
                      }}
                    />
                    {car.fullName}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  station: state.stations.station,
});
const mapDispatchToProps = (dispatch) => ({
  getStationByID: (payload) => dispatch(getStationByID(payload)),
  getUserByPhoneNumber: (payload) => dispatch(getUserByPhoneNumber(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StartCharging);