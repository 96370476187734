/* eslint-disable */
import React, { useEffect, useState } from "react";
import "./styles.scss";
import { ic_keyboard_arrow_down } from "react-icons-kit/md/ic_keyboard_arrow_down";
import { Icon } from "react-icons-kit";
import HeaderFilterRow from "../HeaderFilterRow/HeaderFilterRow";
import Scrollbars from "react-custom-scrollbars";
import { Checkbox, Statistics } from "../../components";

const LocationFields = ["name", "_stations", "_outlets", "active", "reserved", "businessGroup"];

const LocationsGrid = ({ tableRef, locations, fetchData, setHasMore, sortBy, setSortBy, sortValue, setSortValue, sortData, selectedLocations, setSelectedLocations, innerLoading }) => {
  // const [sortBy, setSortBy] = useState("");
  const [coordinates, setCoordinates] = useState({
    display: "none",
    x: "0%",
    y: "0%",
  });
  const [show, setShow] = useState(false);
  const [container, setContainer] = useState({});
  const [checkedList, setCheckedList] = useState([]); // list of _id-s
  const [indexx, setIndex] = useState(0);
  const [originalOrder, setOriginalOrder] = useState([]);
  const [selectedHeader, setSelectedHeader] = useState({});

  let headerElements = [
    {
      name: "Локация име",
      type: "name",
      size: "medium first",
      query: { options: [] },
    },
    {
      name: "Станции",
      type: "_stations",
      size: "small",
      query: { options: [] },
    },
    {
      name: "Изходи",
      type: "_outlets",
      size: "small",
      query: { options: [] },
    },
    {
      name: "Активни",
      type: "active",
      size: "small",
      query: { options: [] },
    },
    {
      name: "Резервирани",
      type: "reserved",
      size: "small",
      query: { options: [] },
    },
    {
      name: "Към група",
      type: "businessGroup",
      size: "last",
      query: { options: [] },
    },
  ];

  function set_Popup(elem) {
    let rect = elem.getBoundingClientRect();

    setCoordinates({
      display: "flex",
      x: `${rect.x + "20"}px`,
      y: `50px`,
      smaller: true,
    });
  }

  const handleOutlets = (location, type) => {
    let outlets = 0;
    let activeStations = 0;
    let reservedStations = 0;

    if (!location?._stations?.length) return 0;

    location?._stations.map((station, i) => {
      outlets += station?._outlets.length;

      //ask if it should be active stations or outlets
      if (type === "active" && station?.status === "active") activeStations++;
      else if (type === "reserved" && station?.status === "reserved") reservedStations++;
    });

    if (type === "active") return activeStations;
    else if (type === "reserved") return reservedStations;

    return outlets;
  };

  const displayGroup = (businessGroups) => {
    if (!businessGroups?.length) return "Не";
    else {
      let names = businessGroups.map((group) => group?.name);
      return names.join(", ");
    }
  };
  const onScroll = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const t = scrollHeight - clientHeight - scrollTop <= 1 && scrollTop !== 0;
    if (t) fetchData();
  };

  useEffect(() => {
    if (selectedLocations?.length) {
      let locationsId = selectedLocations.slice().map((el) => el?._id);
      setCheckedList(locationsId);
    }
  }, [selectedLocations]);

  return (
    <>
      <Scrollbars
        style={{
          height: "100%",
          width: "100%",
          marginTop: "1%",
          overflowX: "hidden !important",
        }}
        onUpdate={(e) => onScroll(e)}
        ref={tableRef}
        id={"scrollbar"}
      >
        <table className="first-grid expense" style={{ width: "99%" }}>
          <thead>
            <tr className="header-of-table">
              {headerElements?.map((element, index) => {
                return (
                  <th className={`header ${element?.size}`} key={index}>
                    <span className="inner-text">{element?.name}</span>
                    <div className={`icon-container ${sortBy === element?.name && "rotate"}`}>
                      <Icon
                        size={"80%"}
                        icon={ic_keyboard_arrow_down}
                        className={`arrow-down`}
                        id={index}
                        onClick={() => {
                          var All_tabs = document.querySelectorAll(".fulll-inner-tab");
                          All_tabs = Array.prototype.slice.call(All_tabs, 0);
                          setOriginalOrder(All_tabs);
                          setContainer(element);
                          setShow(true);
                          setIndex(index);
                          let elem = document.getElementById(`${index}`);
                          set_Popup(elem);
                          setHasMore(false);
                          setSelectedHeader(element);
                        }}
                      />
                    </div>
                  </th>
                );
              })}
            </tr>
            {show ? (
              <HeaderFilterRow
                coordinates={coordinates}
                element={container}
                index={indexx}
                type={"locations-stations"}
                sortValue={sortValue}
                setSortValue={setSortValue}
                selectedHeader={selectedHeader}
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortData={sortData}
                onClose={() => {
                  setShow(false);
                }}
              />
            ) : null}
          </thead>
          <tbody className="inner-full-income-container" id="all-stations" style={{ overflowY: "hidden" }}>
            {locations?.map((location, i) => {
              return (
                <tr className={`fulll-inner-tab ${checkedList?.includes(location?._id) ? "checked" : ""}`} key={i} id={i}>
                  {LocationFields?.map((field, j) => {
                    return (
                      <td className={`inner-part ${headerElements?.[j]?.size}`} id={field} key={j}>
                        {field === "name" ? (
                          <span className={`inner-text`}>{location?.[field] || "--"}</span>
                        ) : field === "_stations" ? (
                          <span className={`inner-text`}>{location?.[field]?.length || "--"}</span>
                        ) : field === "_outlets" ? (
                          <span className={`inner-text`}>{handleOutlets(location) || "0"}</span>
                        ) : field === "active" ? (
                          <span className={`inner-text`}>{handleOutlets(location, "active") || "0"}</span>
                        ) : field === "reserved" ? (
                          <span className={`inner-text`}>{handleOutlets(location, "reserved") || "0"}</span>
                        ) : field === "businessGroup" ? (
                          <div className="flex wide overflow">
                            <span className={`inner-text`}>{displayGroup(location?._businessGroups)}</span>

                            <Checkbox checkedList={checkedList} setCheckedList={(_id) => setCheckedList(_id)} type={location?._id} location={location} setSelectedLocations={(v) => setSelectedLocations(v)} selectedLocations={selectedLocations} label={""} />
                          </div>
                        ) : (
                          <></>
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {innerLoading && <Statistics.LoaderInline styles={{ display: "flex" }} />}
        </table>
      </Scrollbars>
    </>
  );
};

export default LocationsGrid;
