import React from "react";
import "../styles.scss";
import "rc-slider/assets/index.css";
import "moment/locale/bg";

const LeftPart = ({location,groups,setSelectedTab,setSelectedImage,setCurrentGroup}) => {
  return (
    <div className="left-station-container">
      <div className="second-section">
        <h2 style={{fontSize:"22px",marginLeft:"3%",marginBottom:"2%"}}>
          <b>Основна информация</b>
        </h2>
        <div className="line-and-text" style={{marginTop:"1%", marginLeft:"3%"}}>
          <div className="left-line" />
          <div className="text-and-arrow">
            <p className="bold-tab-text" style={{fontSize: window.innerWidth < 1400 ? "16px" : "18px" }}>
              Aдрес: <b>{location?.address}</b>
            </p>
          </div>
        </div>
      </div>
      <div className="fourth-section">
        <div className="line-and-text">
          <div className="left-line" />
          <div className="text-and-arrow" style={{fontSize: window.innerWidth < 1400 ? "16px" : "18px" }}>
            <p className="bold-tab-text">
              Към група:
              <b>
                {location?.businessGroup === null ||
                location?.businessGroup === undefined ? (
                  " Няма"
                ) : (
                  <u
                    style={{ color: "#3C5276", cursor: "pointer" }}
                    onClick={() => {
                      document.getElementById("blurred").style.display =
                        "block";
                      setSelectedTab("group");
                      setCurrentGroup(
                        groups.filter(
                          (x) => x._id === location?.businessGroup
                        )[0]
                      );
                    }}
                  >
                    {" "}
                    {
                      groups.filter((x) => x._id === location?.businessGroup)[0]
                        ?.name
                    }
                  </u>
                )}
              </b>
            </p>
          </div>
        </div>
        <div className="line-and-text">
          <div className="left-line" />
          <p className="bold-tab-text">
            Споделяне на приходи:{" "}
            <b>
              {location.sharedProfit === false ||
              location.sharedProfit === undefined
                ? "0%"
                : `${location?.partOfProfit}%`}
            </b>
          </p>
        </div>
        <div className="line-and-text">
          <div className="left-line" />
          <div className="text-and-arrow" style={{fontSize: window.innerWidth < 1400 ? "16px" : "18px" }}>
            <p className="bold-tab-text">
              Специални цени:{" "}
              <b>
                {location.specialPrices === false ||
                location.specialPrices === undefined ? (
                  "Не"
                ) : (
                  <u
                    style={{ color: "#3C5276", cursor: "pointer" }}
                    onClick={() => {
                      document.getElementById("blurred").style.display =
                        "block";
                      setSelectedTab("price");
                    }}
                  >
                    Да
                  </u>
                )}
              </b>
            </p>
          </div>
        </div>
        <div className="line-and-text">
          <div className="left-line" />
          <p className="bold-tab-text">
            Видима за всички:{" "}
            <b>
              {location?.visible === true ? (
                "Да"
              ) : (
                <b style={{ color: "#3C5276" }}>Не</b>
              )}
            </b>
          </p>
        </div>
        <div className="line-and-text">
          <div className="left-line" />
          <p className="bold-tab-text">
            Тип станция:{" "}
            <b>
              {location?.stationType === "outside"
                ? "Външна"
                : "Вътрешна/подземен паркинг"}
            </b>
          </p>
        </div>
        <div className="line-and-text">
          <div className="left-line" />
          <p className="bold-tab-text">
            Допълнителни насоки: <b>{location?.locationInfo}</b>
          </p>
        </div>
        <div className="line-and-text">
          <div className="left-line" />
          <p className="bold-tab-text">
            Работно време:{" "}
            <b>
              {location?.workingTime === "fixed"
                ? `Фиксирано | ${location?.fromHour} - ${location?.toHour}`
                : "24/7"}
            </b>
          </p>
        </div>
        <div className="line-and-text">
          <div className="left-line" />
          <p className="bold-tab-text">Снимки:</p>
        </div>
      </div>
      <div className="photos">
        {location?.files?.map((file, i) => {
          return (
            <div
              className="photo"
              style={{
                backgroundImage: `url(${file})`,
                cursor: "pointer",
                backgroundRepeat: "no-repeat",
              }}
              key={i}
              onClick={() => {
                document.getElementById("blurred").style.display = "block";
                setSelectedTab("image");
                setSelectedImage(file);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
export default LeftPart;
