import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getPeriod } from "../../../utilities/helperFunctions"
import { getStatisticsSuccess, getStatisticsTop } from "../../../actions"
import "./styles.scss"
import { periods } from "../../../config/constants"
import Popup from "reactjs-popup"
import { Statistics } from "../.."

const Top = () => {
    const dispatch = useDispatch()
    const { loading, period, page, docs } = useSelector(({ statistics }) => statistics.top)

    const dates = useMemo(() => getPeriod(period), [period])
    useEffect(() => {
        dispatch(getStatisticsSuccess({ type: "top", loading: true }))
        dispatch(getStatisticsTop({ payload: { ...dates } }))
    }, [dispatch, dates])

    const [modal, setModal] = useState(null)

    return <div className="statistics-top-container col">
        <div className="statistics-top-header row">
            <h2>Топ 100 най-активни потребители</h2>
            <div className="col"><div className="row">
                <span>{periods.find(({ value }) => value === period).label}</span>
                <Popup
                    className='popup-header-options-container'
                    trigger={<div className="icon icon-settings" />}
                    position="left"
                >
                    {close => <Statistics.SelectPeriod value={period} onChange={(period) => {
                        dispatch(getStatisticsSuccess({ type: 'top', period }))
                        close()
                    }} />}
                </Popup>
            </div></div>
        </div>
        <div className="statistics-top-content">
            {loading && !page
                ? <Statistics.LoaderInline />
                : docs?.map((user) => <div key={user._id} className="statistics-top-single-user row">
                    <div className="col">
                        <h3>{user.userName || user?.phoneNumber}</h3>
                        <span className="row">{user.cities.length > 1 ? "Повече градове" : user.cities[0].city}
                            {user.cities.length > 1 && <span className="icon icon-info" style={{ display: 'block' }} onClick={() => setModal(user)} />}
                        </span>
                    </div>
                    <p><span>{user.totalSessions}</span> Сесии</p>
                </div>)}
        </div>
        {<Popup modal open={!!modal} onClose={() => setModal(null)}  >
            <div className="modal-statistics-top-container">
                <div className="modal-statistics-top-header row">
                    <h2>Информация за потребител</h2>
                    <div className="icon icon-close" onClick={() => setModal(null)} />
                </div>
                <div className="modal-statistics-top-content row">
                    <h3>Потребител: <span>{modal?.userName || modal?.phoneNumber}</span></h3>
                    <h3>Общо сесии: <span>{modal?.totalSessions}</span></h3>
                </div>
                <div className="modal-statistics-top-table">
                    <div className="row row-header">
                        <span>Град</span>
                        <span>Сесии</span>
                    </div>
                    <div className="scroll-container">
                        {modal?.cities?.map(({ city, count }) => <div key={city} className="row row-content">
                            <span>{city}</span>
                            <span>{count}</span>
                        </div>)}
                    </div>
                </div>
            </div>
        </Popup>}
    </div>
}

export default Top