import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { ajax } from "rxjs/ajax";
import { websiteTypes } from "../actions";
import { API } from "../config/settings";
import { Headers } from "../utilities";

export const getWebsiteArticles = (action$) => action$.pipe(
    ofType(websiteTypes.GET_WEBSITE_ARTICLES),
    switchMap(({ payload, onSuccess }) => ajax({
        url: `${API}admin/article/list`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
    }).pipe(
        switchMap(({ response }) => ActionsObservable.create((obs) => {
            obs.next({ type: websiteTypes.GET_WEBSITE_SUCCESS, payload: { loading: false, ...response.payload } })
            if (onSuccess) onSuccess(response)
            obs.complete()
        })),
        catchError((err) => ActionsObservable.create((obs) => {
            console.error(err)
            obs.complete()
        }))
    ))
)

export const getWebsiteArticle = (action$) => action$.pipe(
    ofType(websiteTypes.GET_WEBSITE_ARTICLE),
    switchMap(({ payload, onSuccess }) => ajax({
        url: `${API}admin/article/${payload}`,
        method: "GET",
        headers: Headers.get_auth(),
    }).pipe(
        switchMap(({ response }) => ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response.payload)
            obs.complete()
        })),
        catchError((err) => ActionsObservable.create((obs) => {
            console.error(err)
            obs.complete()
        }))
    ))
)

export const createWebsiteArticle = (action$) => action$.pipe(
    ofType(websiteTypes.CREATE_WEBSITE_ARTICLE),
    switchMap(({ payload, onSuccess }) => ajax({
        url: `${API}admin/article/create`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
    }).pipe(
        switchMap(({ response }) => ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response)
            obs.complete()
        })),
        catchError((err) => ActionsObservable.create((obs) => {
            console.error(err)
            obs.complete()
        }))
    ))
)

export const updateWebsiteArticle = (action$) => action$.pipe(
    ofType(websiteTypes.UPDATE_WEBSITE_ARTICLE),
    switchMap(({ payload: { _id, ...payload }, onSuccess }) => ajax({
        url: `${API}admin/article/${_id}`,
        method: "PUT",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
    }).pipe(
        switchMap(({ response }) => ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response)
            obs.complete()
        })),
        catchError((err) => ActionsObservable.create((obs) => {
            console.error(err)
            obs.complete()
        }))
    ))
)

export const deleteWebsiteArticle = (action$) => action$.pipe(
    ofType(websiteTypes.DELETE_WEBSITE_ARTICLE),
    switchMap(({ payload, onSuccess }) => ajax({
        url: `${API}admin/article/${payload}`,
        method: "DELETE",
        headers: Headers.get_auth(),
    }).pipe(
        switchMap(({ response }) => ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response)
            obs.complete()
        })),
        catchError((err) => ActionsObservable.create((obs) => {
            console.error(err)
            obs.complete()
        }))
    ))
)