import React from "react";
import "./styles.scss";
import moment from "moment";
import { useState } from "react";
import { ic_keyboard_arrow_down } from "react-icons-kit/md/ic_keyboard_arrow_down";
import { Icon } from "react-icons-kit";
import Scrollbars from "react-custom-scrollbars";
import HeaderFilterRow from "../HeaderFilterRow";
import Statistics from "../Statistics";
import Popup from "reactjs-popup";

export default function ClientHistory({ historyData, loadOptionsLocations, tableRef, fetchData, setHasMore, sortBy, setSortBy, sortValue, setSortValue, sortData, type, setType, payment, setPayment, locations, filteredLocations, setFilteredLocations, innerLoading }) {
  const [selectedHeader, setSelectedHeader] = useState("");
  const [container, setContainer] = useState({});
  const [show, setShow] = useState(false);
  const [indexx, setIndex] = useState(0);
  const [coordinates, setCoordinates] = useState({ display: "none", x: "0%", y: "0%" });

  const fieldMap = {
    charging: "Зареждане",
    reservation: "Резервация",
    balance: "Баланс",
    card: "Карта",
    apple_pay: "Apple Pay",
    google_pay: "Google Pay",
    funding: "Баланс",
    manual: 'Редакция'
  };

  const headerElements = [
    {
      name: "Дата",
      type: "paymentDate",
      icon: true,
      size: "medium first",
      query: { filter: "", options: [] },
    },
    {
      name: "Час",
      type: "hour",
      size: "smallest",
      query: { filter: "", options: [] },
    },
    {
      name: "Вид",
      type: "paymentFor",
      filter: true,
      size: "medium",
      query: {
        filter: "",
        options: [
          { label: "Зареждане", type: "charging" },
          { label: "Резервация", type: "reservation" },
          { label: "Баланс", type: "funding" },
        ],
      },
    },
    {
      name: "Локация",
      type: "location",
      typePath: "location.name",
      filter: true,
      search: true,
      size: "large",
      containerType: "high",
      query: {
        filter: "",
        options: locations || [],
      },
    },
    {
      name: "К-во",
      type: "totalValue",
      size: "small",
      query: { filter: "", options: [] },
    },
    {
      name: "Обща стойност",
      type: "totalPrice",
      size: "small",
      query: { filter: "", options: [] },
    },
    {
      name: "Плащане с",
      type: "paymentMethod",
      filter: true,
      size: "medium",
      containerType: "medium",
      query: {
        filter: "",
        options: [
          { label: "Карта", type: "card" },
          { label: "Баланс", type: "balance" },
          { label: "Apple Pay", type: "apple_pay" },
          { label: "Google Pay", type: "google_pay" },
        ],
      },
    },
    {
      name: "Фактури",
      type: "invoice",
      size: "small last",
      query: [],
    },
  ];

  function set_Popup(elem) {
    let rect = elem.getBoundingClientRect();

    if (Number(elem.id) === 0) {
      setCoordinates({
        display: "flex",
        x: `10px`,
        y: `25px`,
        smaller: true,
      });
    } else if (Number(elem.id) === 7) {
      setCoordinates({
        display: "flex",
        x: `${rect.x - 860}px`,
        y: `25px`,
        smaller: true,
      });
    } else if (Number(elem.id) > 4) {
      setCoordinates({
        display: "flex",
        x: `${rect.x - 800}px`,
        y: `25px`,
        smaller: true,
      });
    } else {
      setCoordinates({
        display: "flex",
        x: `${rect.x - 700}px`,
        y: `25px`,
        smaller: true,
      });
    }
  }

  const onScroll = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const t = scrollHeight - clientHeight - scrollTop <= 1 && scrollTop !== 0;
    if (t) {
      fetchData();
    }
  };

  return (
    <div className="client-history-container">
      <div className="flex margin">
        <h2 className="bold">История баланс</h2>
      </div>
      <Scrollbars
        style={{ height: "85%", marginTop: "0", }}
        renderView={(props) => <div {...props} style={{ ...props.style }} />}
        onUpdate={(e) => onScroll(e)}
        ref={tableRef}
        id={"scrollbar"}
      >
        <table className="lower-history-table" ref={tableRef}>
          <thead>
            <tr className="history-theader">
              {headerElements?.map((element, index) => {
                return (
                  <th className={`header ${element?.size}`} key={index}>
                    <span className="inner-text">{element?.name}</span>
                    <div className={`icon-container ${sortBy === element?.name && "rotate"}`}>
                      <Icon
                        size={"80%"}
                        icon={ic_keyboard_arrow_down}
                        className={`arrow-down`}
                        id={index}
                        onClick={() => {
                          // var All_tabs = document.querySelectorAll(".fulll-inner-tab");
                          // All_tabs = Array.prototype.slice.call(All_tabs, 0);
                          setContainer(element);
                          setShow(true);
                          setIndex(index);
                          let elem = document.getElementById(`${index}`);
                          set_Popup(elem);
                          setSelectedHeader(element);
                          if (sortBy === element.name) setHasMore(true);
                          else if (sortBy !== element.name) setHasMore(false);
                        }}
                      />
                    </div>
                  </th>
                );
              })}
            </tr>

            {show ? (
              <HeaderFilterRow
                coordinates={coordinates}
                element={container}
                index={indexx}
                type={"expenses"}
                containerType={"small"}
                sortValue={sortValue}
                setSortValue={setSortValue}
                selectedHeader={selectedHeader}
                filterType={type}
                setFilterType={setType}
                payment={payment}
                setPayment={setPayment}
                filteredFinanceLocations={filteredLocations}
                setFilteredFinanceLocations={setFilteredLocations}
                allLocations={locations}
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortData={sortData}
                loadOptionsLocations={loadOptionsLocations}
                onClose={() => {
                  setShow(false);
                }}
              />
            ) : null}
          </thead>
          <tbody className="inner-full-income-container">
            {historyData?.map((el, i) => {
              return (
                <tr className={`fulll-inner-tab${el.type === 'manual' ? (el.manualData.action === 'add' ? ' positive' : ' negative') : ''}`} key={i}>
                  <td className="inner-part medium first">
                    {el.type !== 'manual' ? <div className="invoice-icon" onClick={() => window.open(el?.receiptUrl)} /> : <div className="invoice-icon-placeholder" />}
                    <span className="inner-text first">{moment(el?.paymentDate)?.format("DD.MM.YYYY") || "--"}</span>
                  </td>
                  <td className="inner-part smallest">
                    <span className="inner-text">{el?.hour || "--"}</span>
                  </td>
                  <td className="inner-part medium">
                    <span className="inner-text">{fieldMap?.[el?.type] || "--"}</span>
                  </td>
                  <td className="inner-part large">
                    <span className="inner-text">{el?.location?.name || "--"}</span>
                  </td>
                  <td className="inner-part small">
                    <span className="inner-text">{el.type === 'manual' ? '--' : `${el?.totalValue || 0} kW`}</span>
                  </td>
                  <td className="inner-part small">
                    {el.type === 'manual'
                      ? <span className="inner-text inner-text-manual-diff">
                        {el.manualData.action === 'add' && '+'}
                        {el.manualData.diff} лв.
                        {el.manualData.comment && <Popup contentStyle={{ width: 'auto', maxWidth: 400 }} trigger={<span className="icon-info" />} on={['hover']}>{el.manualData.comment}</Popup>}

                      </span>
                      : <span className="inner-text">{el?.totalPrice?.toFixed(2) || 0} лв.</span>
                    }
                  </td>
                  <td className="inner-part medium">
                    <span className="inner-text">{el.type === 'manual' ? '--' : `${fieldMap?.[el?.paymentMethod] || "--"}`}</span>
                  </td>
                  <td className="inner-part small last">
                    <span className="inner-text">{el.type === 'manual' ? '--' : `${el?.invoice ? "Да" : "Не"}`}</span>
                  </td>
                </tr>
              );
            })}
          </tbody>
          {innerLoading && <Statistics.LoaderInline styles={{ display: "flex", alignItems: "center" }} />}
        </table>
      </Scrollbars>
    </div>
  );
}
