/* eslint-disable */
import { LocationTypes } from '../actions'
import { authTypes } from '../actions'


const initialState = {
  locations: [],
  allLocations:[],
  location: {},
  statistics:{},
  activity:[],
  hasMore: false,
  currentPage: 1
}

const handleNewLocations = (state,payload,noPagination) => {
  
  const newState = {...state}
  let notFound = true;
  if(newState.locations.length > 0) {

    if(payload.page === 1){
      newState.locations = payload?.docs;
      return newState.locations;
    }
    
    newState.locations.map(oldLocation => {
      if(oldLocation?._id?.includes(payload?.docs[0]?._id)) notFound = false
    })

    if(!notFound) return newState.locations
    if(notFound){
      newState.locations = [...newState.locations, ...payload?.docs]
      return newState.locations
    }

  }
  if(payload.totalDocs === payload.limit){
    return payload.docs;
  }
  else {
    newState.locations = [...newState.locations, ...payload?.docs]
    return newState.locations
  }
}



const locations = (state = initialState, {type,payload,noPagination}) => {
  switch (type) {
    case LocationTypes.GET_LOCATIONS_SUCCESS:
        return { 
          ...state, 
          locations: handleNewLocations(state,payload,noPagination),
          hasMore: payload?.hasNextPage,
          currentPage: payload?.page
        }
    
    case LocationTypes.SET_LOCATIONS_DETAILS:
        return { 
          ...state, 
          hasMore: payload?.hasNextPage,
          currentPage: payload?.page
        }
    
    case LocationTypes.GET_LOCATION_BY_ID_SUCCESS:
        return{
          ...state,
          location:payload.payload
        }
    case LocationTypes.GET_ALL_LOCATIONS_SUCCESS:
      return{
        ...state,
        allLocations:payload?.docs
      }
    case LocationTypes.GET_ACTIVITY_SUCCESS:
        return{
          ...state,
          activity:payload.docs
        }
    case authTypes.LOG_OUT_SUCCESS:
        return initialState
    default:
      return state
  }
}

export default locations
