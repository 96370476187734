import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { FinanceTypes, setLoadingOff } from "../actions";
import { API } from "../config/settings";
import { Headers } from "../utilities";

export const getFinanceIncome = (action$) =>
  action$.pipe(
    ofType(FinanceTypes.GET_FINANCE_INCOME),
    switchMap(
      ({
        payload: { pageNumber, pageSize, sort, type, paymentMethod, user, business, location, station, invoice, noPagination, onSuccess },
      }) => {
        return ajax({
          url: `${API}finance/income`,
          method: "POST",
          headers: Headers.get_auth(),
          body: JSON.stringify({ pageNumber, pageSize, sort, type, paymentMethod, user, business, location, station, invoice, noPagination }),
        }).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              obs.next({
                type: FinanceTypes.GET_FINANCE_INCOME_SUCCESS,
                payload: response.payload,
              });
              onSuccess(response);
              obs.next(setLoadingOff());
              obs.complete();
            });
          }),
          catchError((err) => {
            return ActionsObservable.create((obs) => {
              console.error(err);
              obs.complete();
            });
          })
        );
      }
    )
  );

export const getFinanceDeposits = (action$) =>
  action$.pipe(
    ofType(FinanceTypes.GET_FINANCE_DEPOSITS),
    switchMap(
      ({
        payload: { pageNumber, pageSize, sort, user, paymentMethod, noPagination, onSuccess },
      }) => {
        return ajax({
          url: `${API}finance/deposits`,
          method: "POST",
          headers: Headers.get_auth(),
          body: JSON.stringify({ pageNumber, pageSize, sort, user, paymentMethod, noPagination }),
        }).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              obs.next({
                type: FinanceTypes.GET_FINANCE_DEPOSITS_SUCCESS,
                payload: response.payload,
              });
              onSuccess(response);
              obs.next(setLoadingOff());
              obs.complete();
            });
          }),
          catchError((err) => {
            return ActionsObservable.create((obs) => {
              console.error(err);
              obs.complete();
            });
          })
        );
      }
    )
  );

export const getFinanceDepositsClients = (action$) =>
  action$.pipe(
    ofType(FinanceTypes.GET_FINANCE_DEPOSITS_CLIENTS),
    switchMap(
      ({
        payload: { pageNumber, pageSize, sort, user, business, noPagination, onSuccess },
      }) => {
        return ajax({
          url: `${API}finance/clients`,
          method: "POST",
          headers: Headers.get_auth(),
          body: JSON.stringify({ pageNumber, pageSize, sort, user, business, noPagination }),
        }).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              obs.next({
                type: FinanceTypes.GET_FINANCE_DEPOSITS_CLIENTS_SUCCESS,
                payload: response.payload,
              });
              onSuccess(response);
              obs.next(setLoadingOff());
              obs.complete();
            });
          }),
          catchError((err) => {
            return ActionsObservable.create((obs) => {
              console.error(err);
              obs.complete();
            });
          })
        );
      }
    )
  );

export const getClientStatistics = (action$) =>
  action$.pipe(
    ofType(FinanceTypes.GET_CLIENT_STATISTICS),
    switchMap(({ payload }) => {
        return ajax({
          url: `${API}finance/clients/${payload?._id}`,
          method: "GET",
          headers: Headers.get_auth(),
          body: JSON.stringify(payload),
        }).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              obs.next({
                type: FinanceTypes.GET_CLIENT_STATISTICS_SUCCESS,
                payload: response.payload,
              });
              obs.next(setLoadingOff());
              obs.complete();
            });
          }),
          catchError((err) => {
            return ActionsObservable.create((obs) => {
              console.error(err);
              obs.complete();
            });
          })
        );
      }
    )
  );

export const getClientDeposits = (action$) =>
  action$.pipe(
    ofType(FinanceTypes.GET_CLIENT_DEPOSITS),
    switchMap(({
      payload: { pageNumber, pageSize, sort, location, user, type, paymentMethod, noPagination, onSuccess },
    }) => {
        return ajax({
          url: `${API}finance/clients/user`,
          method: "POST",
          headers: Headers.get_auth(),
          body: JSON.stringify({ pageNumber, pageSize, sort, location, user, type, paymentMethod, noPagination }),
        }).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              obs.next({
                type: FinanceTypes.GET_CLIENT_DEPOSITS_SUCCESS,
                payload: response.payload,
              });
              if (onSuccess) onSuccess(response)
              obs.next(setLoadingOff());
              obs.complete();
            });
          }),
          catchError((err) => {
            return ActionsObservable.create((obs) => {
              console.error(err);
              obs.complete();
            });
          })
        );
      }
    )
  );

export const getIncomeStatistics = (action$) =>
  action$.pipe(
    ofType(FinanceTypes.GET_INCOME_STATISTICS),
    switchMap(() => {
      return ajax({
        url: `${API}finance/income/stat`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: FinanceTypes.GET_INCOME_STATISTICS_SUCCESS,
              payload: response.payload,
            });
            obs.next(setLoadingOff());
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.error(err);
            obs.complete();
          });
        })
      );
    })
  );

export const getDepositsStatistics = (action$) =>
  action$.pipe(
    ofType(FinanceTypes.GET_DEPOSITS_STATISTICS),
    switchMap(() => {
      return ajax({
        url: `${API}finance/deposits/stat`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: FinanceTypes.GET_DEPOSITS_STATISTICS_SUCCESS,
              payload: response.payload,
            });
            obs.next(setLoadingOff());
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.error(err);
            obs.complete();
          });
        })
      );
    })
  );

export const getFinanceExcel = (action$) => {
  return action$.pipe(
    ofType(FinanceTypes.EXPORT_TO_XLSX),
    switchMap(({ payload: { startDate, endDate, onSuccess } }) => {
      return ajax({
        url: `${API}/finance/income/export`,
        method: "POST",
        headers: Headers.auth(),
        body: JSON.stringify({
          startDate: startDate,
          endDate: endDate,
        }),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            onSuccess(response.blob());
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            obs.complete();
            console.error(err);
          });
        })
      );
    })
  );
};
