import React from "react"
import { Inputs } from "../../"
import { periods } from "../../../config/constants"
import "./styles.scss"

const SelectPeriod = ({ value, onChange }) => {
    return <div className="popups-select-period-container">
        <Inputs.RadioButton col value={value} onChange={onChange} options={periods} />
    </div>
}

export default SelectPeriod