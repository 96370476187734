/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";

const ModalComponent = ({ children, open }) => {
  return (
    <div className={`modal-component ${open && "show"} `}>
      {children}
    </div>
  );
};
export default ModalComponent;
