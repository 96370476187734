/* eslint-disable */
import React, { useEffect, useState } from "react";
import "./styles.scss";
import "moment/locale/bg";
import "react-dropdown/style.css";

import { connect } from "react-redux";
import LeftSettings from "./leftSettings.js";
import MiddleSettings from "./middleSettings.js";
import { getSessions, getStationByID, makeDiagonstics, sendCommand, lastCharge, getConfiguration, setConfiguration, unlockConnector, stopCharging, sessionJoinRoom, getLogs } from "../../../actions";
import RightSettings from "./rightSettings.js";
import TabSettings from "./tabSettings.js";

const stationSettings = ({ getSessions, sessions, getStationByID, station, makeDiagonstics, sendCommand, lastCharge, getConfiguration, unlockConnector, stopCharging, sessionJoinRoom, commandHistory, getLogs }) => {
  const station_id = window.location.href.split("/")[5];
  const [chargePointID, setChargePointID] = useState("2");

  useEffect(() => {
    getStationByID({
      station: station_id,
      onSuccess: (res) => {
        setChargePointID(res.payload.chargePointId);
      },
    });
    getSessions({
      station: station_id,
      noPagination: true,
    });
    sessionJoinRoom({});
  }, []);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        padding: "2vw",
        display: "flex",
      }}
    >
      <div className="container"></div>

      <TabSettings
        leftSettings={<LeftSettings sessions={sessions} station={station} chargePointID={chargePointID} makeDiagnostics={makeDiagonstics} sendCommand={sendCommand} getConfiguration={getConfiguration} getLogs={getLogs} size={`wide`} />}
        middleSettings={<MiddleSettings station={station} lastCharge={lastCharge} unlockConnector={unlockConnector} chargePointID={chargePointID} sendCommand={sendCommand} stopCharging={stopCharging} size={`wide`} />}
      />
      {/* <LeftSettings
          sessions={sessions}
          station={station}
          chargePointID={chargePointID}
          makeDiagnostics={makeDiagonstics}
          sendCommand={sendCommand}
          getConfiguration={getConfiguration}
          getLogs={getLogs}
        />
        <MiddleSettings
          station={station}
          lastCharge={lastCharge}
          unlockConnector={unlockConnector}
          chargePointID={chargePointID}
          sendCommand={sendCommand}
          stopCharging={stopCharging}
        /> */}
      <RightSettings serialID={station?.chargePointId} />
    </div>
  );
};
const mapStateToProps = (state) => ({
  sessions: state.adminSettings.sessions,
  station: state.stations.station,
});
const mapDispatchToProps = (dispatch) => ({
  getSessions: (payload) => dispatch(getSessions(payload)),
  getLogs: (payload) => dispatch(getLogs(payload)),
  getStationByID: (payload) => dispatch(getStationByID(payload)),
  makeDiagonstics: (payload) => dispatch(makeDiagonstics(payload)),
  sendCommand: (payload) => dispatch(sendCommand(payload)),
  lastCharge: (payload) => dispatch(lastCharge(payload)),
  getConfiguration: (payload) => dispatch(getConfiguration(payload)),
  setConfiguration: (payload) => dispatch(setConfiguration(payload)),
  unlockConnector: (payload) => dispatch(unlockConnector(payload)),
  stopCharging: (payload) => dispatch(stopCharging(payload)),
  sessionJoinRoom: (payload) => dispatch(sessionJoinRoom(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(stationSettings);
