/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { history } from "../../config/stores";
import _ from "lodash";
import { connect, useDispatch } from "react-redux";
import { getStations, getStatistics, getLocations, getAllLocations } from "../../actions";
import "./styles.scss";
import NewGrid from "../../components/Grid/NewGrid";

const Stations = ({ statistics, getStatistics, locations, getStations, getAllLocations, allLocations }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const role = localStorage.getItem("role");

  const fetch = useCallback(
    (payload) => {
      dispatch(getLocations(payload));
    },
    [dispatch]
  );

  useEffect(() => {
    getAllLocations({
      noPagination: true,
      onSuccess: (res) => {},
      onError: () => {},
    });
    getStations({
      noPagination: true,
    });
    getStatistics({});
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="stations-container">
      <div className="inner-stations-container">
        <div className="upper-stations-container">
          <h3 className="stations text">Станции за зареждане</h3>
          {role === "admin" && (
            <div className="right-inner-container">
              <div className="button-container smaller" onClick={() => history.push("/locations/add")}>
                Добави
              </div>
            </div>
          )}
        </div>
        <div className="stations-content">
          <NewGrid docs={locations} current={currentPage} setCurrent={setCurrentPage} fetch={fetch} setHasMore={setHasMore} hasMore={hasMore} />
        </div>
        <div className="lower-stations-info">
          <div className="inner-station-info" style={{ width: "150px", height: "100%" }}>
            <p style={{ fontSize: "15px", marginLeft: "8%" }}>
              Локации: <b>{statistics.allLocations}</b>
            </p>
          </div>
          <div className="inner-station-info" style={{ width: "210px" }}>
            <p style={{ fontSize: "15px", marginLeft: "10%" }}>
              Активни станции: <b>{statistics?.activeStations}</b>
            </p>
          </div>
          <div className="inner-station-info" style={{ width: "250px" }}>
            <p style={{ fontSize: "15px", marginLeft: "10%" }}>
              Резервирани изходи: <b>{statistics?.activeReservations}</b>
            </p>
          </div>
          <div className="inner-station-info" style={{ width: "250px" }}>
            <p style={{ fontSize: "15px", marginLeft: "10%" }}>
              Станции в поддръжка: <b>{statistics?.supportStations}</b>
            </p>
          </div>
          <div className="inner-station-info" style={{ width: "280px" }}>
            <p style={{ fontSize: "15px", marginLeft: "10%" }}>
              Станции с проблем: <b>{statistics?.problemStations}</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  locations: state.locations.locations,
  stations: state.stations.stations,
  statistics: state.stations.statistics,
  allLocations: state.locations.allLocations,
});
const mapDispatchToProps = (dispatch) => ({
  getStations: (payload) => dispatch(getStations(payload)),
  getStatistics: (payload) => dispatch(getStatistics(payload)),
  getAllLocations: (payload) => dispatch(getAllLocations(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Stations);
