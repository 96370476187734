import React, { useEffect, useMemo, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { handleUpdate } from "../../utilities/helperFunctions";
import moment from "moment";
import Popup from "reactjs-popup";
import FiltersPopup from "./FiltersPopup";
import { sortBy } from "lodash";
import { useQuery } from "../../utilities/useQuery";
import "./styles.scss";
import { history } from "../../config/stores";
import { Statistics } from "..";

const headerElements = [
  {
    name: "Локация име",
    query: { key: "name", type: "regex" },
  },
  {
    name: "Станции",
    query: { key: "totalStations", type: "sort" },
  },
  {
    name: "Изходи",
    query: { key: "totalOutlets", type: "sort" },
  },
  {
    name: "Активни",
    query: { key: "freeOutlets", type: "sort" },
  },
  {
    name: "Резервирани",
    query: { key: "activeReservations", type: "sort" },
  },
  {
    name: "За всички",
    query: {
      key: "visible",
      type: "checkboxes",
      options: [
        { label: "Да", value: "yes" },
        { label: "Не", value: "no" },
      ],
    },
  },
  {
    name: "Споделен приход",
  },
  {
    name: "Град",
    query: { key: "address", type: "regex" },
  },
  {
    name: "Работно време",
    query: {
      key: "workingTime",
      type: "checkboxes",
      options: [
        { label: "24/7", value: "full" },
        { label: "Фиксирано", value: "fixed" },
      ],
    },
  },
  {
    name: "Последно зареждане",
    query: { key: "lastCharge.endAt", type: "sort" },
  },
  {
    name: "Сума",
    query: { key: "amountForPay", type: "sort" },
  },
];

const NewGrid = ({ docs, current, setCurrent, fetch, hasMore, setHasMore }) => {
  const tableRef = useRef();
  const [innerLoading, setInnerLoading] = useState(false);

  const { filter: filterProp = `{}`, handleUrlChange } = useQuery();
  const filter = useMemo(() => JSON.parse(filterProp), [filterProp]);

  const mappedFilter = useMemo(() => {
    const { name, totalStations, totalOutlets, freeOutlets, activeReservations, visible, address, workingTime, amountForPay, ...payload } = filter;
    if (name) payload.name = name?.pattern;
    if (address) payload.address = address?.pattern;
    if (totalStations) payload.sortBy = { ...sortBy, totalStations: totalStations };
    if (totalOutlets) payload.sortBy = { ...sortBy, totalOutlets: totalOutlets };
    if (visible) {
      if (visible?.length > 1) payload.visible = null;
      else payload.visible = visible?.[0] === "yes" ? true : false;
    }
    if (workingTime) payload.workingTime = workingTime;
    return payload;
  }, [filter]);

  useEffect(() => {
    fetch({
      ...mappedFilter,
      pageSize: 20,
      //   sortBy: { lastCharge: { amountForPay: 1 } },
      onSuccess: (res) => {
        setCurrent(2);
        setHasMore(res?.payload?.hasNextPage);
      },
      onError: () => alert("Имаше проблем със зареждането на останалите локации,опитайте отново!"),
    });
  }, [fetch, mappedFilter, setCurrent, setHasMore]);

  const timerRef = useRef(null);

  useEffect(() => {
    const timer = timerRef.current;
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  const handleUpdateDebounced = (...payload) => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => handleUpdate(...payload), 100);
  };

  const renderThumbHorizontal = ({ style, ...props }) => {
    const thumbStyle = {
      //   backgroundColor: "#c1c1c1", // Customize the thumb color
      backgroundColor: "#000000",
      opacity: "0.2",
      borderRadius: "6px",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };
  const renderTrackHorizontal = ({ style, ...props }) => {
    const trackStyle = {
      backgroundColor: "#000000",
      opacity: "0.2",
      height: "3px",
      borderRadius: "6px",
    };
    return <div style={{ ...style, ...trackStyle }} {...props} />;
  };

  return (
    <>
      <Scrollbars
        onUpdate={(values) => {
          handleUpdateDebounced(values, current, setCurrent, innerLoading, setInnerLoading, fetch, hasMore, {
            ...mappedFilter,
            pageNumber: current,
            pageSize: 20,
            onSuccess: (res) => {
              setHasMore(res?.payload?.hasNextPage);
              setInnerLoading(false);
            },
          });
        }}
        ref={tableRef}
        id={"articlesScrollbar-wider"}
        renderThumbHorizontal={renderThumbHorizontal}
        renderTrackHorizontal={renderTrackHorizontal}
      >
        <div style={{ display: "flex", width: "150%" }}>
          {headerElements?.map((header, i) => {
            return (
              <div className="new-header-element" key={header.name} style={{ paddingLeft: i === 0 && "12px", width: i === 0 ? "20%" : i === 7 ? "15%" : "7%", ...(filter[header?.query?.key] && { color: "#15DD95" }) }}>
                <div className="head">{header.name}</div>
                {header.query && (
                  <Popup trigger={<div className="page-arrow-icon" />} position={i === 0 ? "bottom left" : i === header.length - 2 ? "bottom right" : "bottom center"} className="anvil" contentStyle={{ width: 300, padding: 0 }}>
                    {(close) => <FiltersPopup close={close} className="anvil" filterOptions={header.query} filter={filter} handleSubmit={(filter) => handleUrlChange({ filter: JSON.stringify(filter) })} />}
                  </Popup>
                )}
              </div>
            );
          })}
        </div>
        {docs?.map((el) => {
          const city = el?.address;
          return (
            <div className="table-body-row cursor-pointer h-[4.2rem]" key={el?._id} onClick={() => history.push({ pathname: `/location/${el?._id}` })}>
              <div className="row-data" style={{ width: "20%" }}>
                <div className="signal" />
                {el?.name || "---"}
              </div>
              <div className="row-data" style={{ width: "7%" }}>
                {el?.totalStations}
              </div>
              <div className="row-data" style={{ width: "7%" }}>
                {el?.totalOutlets}
              </div>
              <div className="row-data" style={{ width: "7%" }}>
                {el?.freeOutlets}/{el?.totalOutlets}
              </div>
              <div className="row-data" style={{ width: "7%" }}>
                {el?.activeReservations}/{el?.totalOutlets}
              </div>
              <div className="row-data" style={{ width: "7%" }}>
                {el?.visible ? "Да" : "Не"}
              </div>
              <div className="row-data" style={{ width: "7%" }}>
                {el?.sharedProfit ? "Да" : "Не"}
              </div>
              <div className="row-data" style={{ width: "15.5%" }}>
                {city?.length > 40 ? city?.slice(0, 40) + "..." : city}
              </div>
              <div className="row-data" style={{ width: "7%" }}>
                {el?.workingTime === "full" ? "24/7" : `Фиксирано`}
              </div>
              <div className="row-data" style={{ width: "7%" }}>
                {el?.lastCharge ? moment(el?.lastCharge?.endAt).format("DD.MM.YYYY | HH:mm") : "Няма"}
              </div>
              <div className="row-data" style={{ width: "7%" }}>
                {el?.lastCharge ? el?.lastCharge?.amountForPay?.toFixed(2) : "---"} лв.
              </div>
            </div>
          );
        })}
        {innerLoading && (
          <div className="loader-component">
            <Statistics.LoaderInline />
          </div>
        )}
      </Scrollbars>
    </>
  );
};

export default NewGrid;
