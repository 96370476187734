/* eslint-disable */
import React, { useState } from "react";
import { InnerHeader } from "../../../components";
import { createStation } from "../../../actions";
import { connect } from "react-redux";
import InfoComponent from "./InfoComponent";
import AddStationsToLocation from "./AddStationsToLocation";
import "../styles.scss";

const AddStations = () => {
  const [selectedTab, setSelectedTab] = useState("info");
  const [openHour, setOpenHour] = useState(false);

  const [locationID, setLocationID] = useState("");
  const [fromWorkTime, setFromWorkTime] = useState({
    hour: "",
    minutes: "",
  });
  const [toWorkTime, setToWorkTime] = useState({
    hour: "",
    minutes: "",
  });

  const [payload, setPayload] = useState({
    name: "",
    address: "",
    location: {
      type: "Point",
      coordinates: [23.319941, 42.698334],
    },
    stationType: "",
    locationInfo: "",
    workingTime: "",
    fromHour: ``,
    toHour: ``,
    files: [],
  });

  const [secondStep, setSecondStep] = useState(false);
  const [selectedStation, setSelectedStation] = useState(0);
  const [stations, setStations] = useState([
    {
      name: "",
      location: locationID,
      power: null,
      voltage: null,
      price: null,
      status: null, //active or support
      chargePointId: null, //simple number > 0
      serialFirst: "", // DDDD-DDD-DDDD
      serialSecond: "", // DDDD-DDD-DDDD
      outlets: [
        {
          name: "",
          type: [],
          connectorId: 1,
        },
      ],
    },
  ]);
  return (
    <div className="stations-add-container">
      <InnerHeader
        link={"/"}
        first={"Добавяне на локация"}
        addStation={selectedTab === "info" ? 0 : 1}
        payload={payload}
        stations={stations}
        setStations={setStations}
        setLocationID={setLocationID}
      />

      <div className="lower-container">
        <div className="left-container">
          <div
            className={`first-left-container ${
              selectedTab === "info" && "selected"
            }`}
            onClick={() => {
              setSelectedTab("info");
            }}
          >
            <h3
              className={`add-station  ${selectedTab === "info" && "selected"}`}
            >
              Основна информация
            </h3>
            <div className="arrow-container">
              <div
                className={`arrow-right  ${
                  selectedTab === "info" && "selected"
                }`}
              />
            </div>
          </div>
          <div
            className={`full-stations-left-container ${
              selectedTab === "stations" && "selected"
            } ${secondStep && "opened"}`}
          >
            <div
              className={`stations-tab-container ${
                selectedTab === "stations" && "selected"
              }`}
              onClick={() => {
                setSelectedTab("stations");
              }}
            >
              <h3
                className={`station-name-tab  ${
                  selectedTab === "stations" && "selected"
                }`}
              >
                Станции към локация
              </h3>
              <div className="arrow-container" style={{ marginLeft: "2%" }}>
                <div
                  className={`arrow-right  ${
                    selectedTab === "stations" && "selected"
                  }`}
                />
              </div>
            </div>

            {selectedTab === "stations" && (
              <>
                {stations.map((station, i) => {
                  return (
                    <div
                      className={`inner-station-container ${
                        selectedStation === i && "selected"
                      }`}
                      key={i}
                      onClick={() => {
                        setSelectedStation(i);
                      }}
                    >
                      <h3
                        className={`station-name-tab  ${
                          selectedStation === i && "selected"
                        }`}
                      >
                        Станция {i + 1}
                      </h3>
                      {stations.length > 1 && (
                        <div
                          className="delete-station"
                          onClick={() => {
                            const newStations = stations.slice(0);
                            newStations.splice(i, 1);
                            setStations(newStations);
                          }}
                        />
                      )}
                      <div className="arrow-container wider">
                        <div className={`arrow-right`} />
                      </div>
                    </div>
                  );
                })}
                <div className="add-station-container">
                  <div
                    className={`inner-station-add-container`}
                    onClick={() => {
                      setStations((prevState) => [
                        ...prevState,
                        {
                          name: "",
                          location: locationID,
                          power: null,
                          voltage: null,
                          price: null,
                          status: null, //active or support
                          chargePointId: null, //simple number > 0
                          serialFirst: "", // DDDD-DDD-DDDD
                          serialSecond: "", // DDDD-DDD-DDDD
                          outlets: [
                            {
                              name: "",
                              type: [],
                              connectorId: 1,
                            },
                          ],
                        },
                      ]);
                      setSelectedStation(stations.length);
                    }}
                  >
                    <h3 className={`station-name-tab`}>Добави станция</h3>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {selectedTab === "info" ? (
          <InfoComponent
            payload={payload}
            setPayload={setPayload}
            setSelectedTab={setSelectedTab}
            openHour={openHour}
            setOpenHour={setOpenHour}
            fromWorkTime={fromWorkTime}
            setFromWorkTime={setFromWorkTime}
            toWorkTime={toWorkTime}
            setToWorkTime={setToWorkTime}
            setLocationID={setLocationID}
            setSecondStep={setSecondStep}
          />
        ) : (
          <AddStationsToLocation
            stations={stations}
            setStations={setStations}
            selectedStation={selectedStation}
          />
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createStation: (payload) => dispatch(createStation(payload)),
});

export default connect(null, mapDispatchToProps)(AddStations);
