export const websiteTypes = {
    GET_WEBSITE_SUCCESS: 'website/GET_WEBSITE_SUCCESS',

    GET_WEBSITE_ARTICLES: 'website/GET_WEBSITE_ARTICLES',
    GET_WEBSITE_ARTICLE: 'website/GET_WEBSITE_ARTICLE',
    CREATE_WEBSITE_ARTICLE: 'website/CREATE_WEBSITE_ARTICLE',
    UPDATE_WEBSITE_ARTICLE: 'website/UPDATE_WEBSITE_ARTICLE',
    DELETE_WEBSITE_ARTICLE: 'website/DELETE_WEBSITE_ARTICLE',
}

export const getWebsiteArticles = (payload) => ({
    type: websiteTypes.GET_WEBSITE_ARTICLES,
    ...payload
})

export const getWebsiteArticle = (payload) => ({
    type: websiteTypes.GET_WEBSITE_ARTICLE,
    ...payload
})

export const createWebsiteArticle = (payload) => ({
    type: websiteTypes.CREATE_WEBSITE_ARTICLE,
    ...payload
})

export const updateWebsiteArticle = (payload) => ({
    type: websiteTypes.UPDATE_WEBSITE_ARTICLE,
    ...payload
})

export const deleteWebsiteArticle = (payload) => ({
    type: websiteTypes.DELETE_WEBSITE_ARTICLE,
    ...payload
})