/* eslint-disable */
import {
    authTypes
  } from '../actions'
  
  const initialState = {
    token: null,
    user: {},
    phoneNumber:"",
    allUsers:[],
    businessUsers:[],
    hasMore: false,
    currentPage: 1
  }

  const handleNewUsers = (state,payload) => {
    const newState = {...state}
    let notFound = true;
    if(newState.allUsers.length > 0) {
      newState.allUsers.map(oldUser => {
        if(oldUser?._id?.includes(payload?.docs[0]?._id)) notFound = false
      })

      if(!notFound) return newState.allUsers
      if(notFound){
        newState.allUsers = [...newState.allUsers, ...payload?.docs]
        return newState.allUsers
      }

    }
    else{
      newState.allUsers = [...newState.allUsers, ...payload?.docs]
      return newState.allUsers
    }
  }
  const handleNewBusinessUsers = (state,payload) => {
    const newState = {...state}
      newState.businessUsers = [...newState.businessUsers, ...payload?.docs]
      return newState.businessUsers
  }
  
const login = (state = initialState, { type, payload }) => {
    switch (type) {
      case authTypes.LOGIN_SUCCESS:
        return { ...state, user: payload.user, token:payload.token }

      case authTypes.LOGIN_USER_SUPPORT_SUCCESS:
        return {...state,user:payload}

      case authTypes.LOGIN_SUCCESS_LOCAL_STORAGE:
        {
          return {...state, user:payload, token:payload?.token}
        }

      case authTypes.LOAD_CREDENTIALS:
        return {
          ...state,
          ...payload
        }
      case authTypes.GET_USERS_SUCCESS:
        return{
          ...state,
          allUsers:handleNewUsers(state,payload)
        }
      case authTypes.SET_USERS_DETAILS:
        return{
          ...state,
          hasMore: payload?.hasNextPage,
          currentPage: payload?.page
        }
        case authTypes.GET_BUSINESS_USERS_SUCCESS:
        return{
          ...state,
          businessUsers:handleNewBusinessUsers(state,payload)
        }
      case authTypes.LOG_OUT_SUCCESS:
        return initialState
      default:
        return state
    }
  }
  
  export default login
  
