/* eslint-disable */
import React, { useEffect, useState } from "react";
import "../styles.scss";
import { ic_keyboard_arrow_down } from "react-icons-kit/md/ic_keyboard_arrow_down";
import { Icon } from "react-icons-kit";
import { InnerHeader } from "../../../components";
import { connect } from "react-redux";
import {
  getStationByID,
  getLocationById,
  getGroupByID,
  getGroups,
  getActivity,
} from "../../../actions";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "moment/locale/bg";
import LeftPart from "./LeftPart";
import MiddlePart from "./MiddlePart";
import RightPart from "./RightPart";
import AdditionalModals from "./AdditionalModals";

const orderBy = ["Най-нови", "Най-стари", "Най-висока сума", "Най-ниска сума"];
const orderByType = ["Зареждане", "Резервация"];
const lookTime = ["От началото", "Дата/период"];

const Station = ({
  location,
  getLocationById,
  getGroupByID,
  groups,
  getGroups,
  activity,
  getActivity,
}) => {
  moment.locale("bg");
  const [selectedTab, setSelectedTab] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [currentGroup, setCurrentGroup] = useState({});
  const location_id = window.location.href.split("/")[4];
  const [openCalendar, setOpenCalendar] = useState(false);
  const [dates, setDates] = useState([null, null]);
  const [filterOptions, setFilterOptions] = useState({
    order: "",
    orderType: [],
    look: "",
    sum: [0, 100],
    selectedStation: [],
    selectedType: [],
    cars: [],
  });
  useEffect(() => {
    getLocationById(location_id);
    getGroups({
      sortBy: "",
      noPagination: true,
      search: "",
      onSuccess: (response) => {},
      onError: () =>
        alert("Имаше проблем с изпращането на съобщение,опитайте отново!"),
    }),
      getActivity({
        location: location_id,
        noPagination: true,
        onSuccess: () => {},
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const outletType = [
    ...new Set(activity.map((x) => x?.outlet?.type[0] && x?.outlet?.type[0])),
  ];

  const carsType = [
    ...new Set(activity.map((x) => x?.car?.fullName && x?.car)),
  ];

  const UniqueCars = [
    ...new Map(carsType.map((v) => [JSON.stringify(v), v])).values(),
  ].filter((x) => x !== undefined && x);

  const handleDateChange = (date) => {
    if (!dates[0] && !dates[1]) {
      const newDates = dates.slice(0);
      newDates[0] = date;
      setDates(newDates);
    } else if (dates[0] && !dates[1]) {
      const newDates = dates.slice(0);
      newDates[1] = date;
      setDates(newDates);
    }
    if (dates[0] && dates[1]) {
      const newDates = dates.slice(0);
      newDates[0] = date;
      newDates[1] = null;
      setDates(newDates);
    }
  };

  const handleDuration = (time) => {
    const splitted = time?.split(":");
    return splitted ? `${Number(splitted[0])}ч | ${Number(splitted[1])}м` : '';
  };

  const handlePayload = (type) => {
    switch (type) {
      case "order":
        return filterOptions.order.includes("Най-нови") ? (
          { "dateNew": 1 }
        ) : filterOptions.order.includes("Най-стари") ? (
          { "dateOld": 1 }
        ) : filterOptions.order.includes("Най-висока сума") ? (
          { "amountHigh": 1 }
        ) : filterOptions.order.includes("Най-ниска сума") ? (
          { "amountLow": 1 }
        ) : (
          <></>
        );
      default:
        return;
    }
  };

  return (
    <>
      <AdditionalModals
        currentGroup={currentGroup}
        getActivity={getActivity}
        activity={activity}
        location_id={location_id}
        dates={dates}
        filterOptions={filterOptions}
        location={location}
        selectedTab={selectedTab}
        orderBy={orderBy}
        orderByType={orderByType}
        lookTime={lookTime}
        openCalendar={openCalendar}
        setOpenCalendar={setOpenCalendar}
        outletType={outletType}
        UniqueCars={UniqueCars}
        setFilterOptions={setFilterOptions}
        selectedImage={selectedImage}
        handlePayload={handlePayload}
        handleDateChange={handleDateChange}
        setDates={setDates}
      />
      <div className="not-blurred-container">
        <InnerHeader
          link={"/"}
          first={`${location?.name}`}
          edit={true}
          location={location}
        />
        <div className="full-station-container" id="full">
          <LeftPart
            location={location}
            groups={groups}
            setSelectedImage={setSelectedImage}
            setSelectedTab={setSelectedTab}
            setCurrentGroup={setCurrentGroup}
          />
          <MiddlePart location={location} />
          <RightPart
            location={location}
            handleDuration={handleDuration}
            activity={activity}
            setSelectedTab={setSelectedTab}
            getActivity={getActivity}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  station: state.stations.station,
  location: state.locations.location,
  group: state.groups.group,
  groups: state.groups.groups,
  activity: state.locations.activity,
});
const mapDispatchToProps = (dispatch) => ({
  getStationByID: (payload) => dispatch(getStationByID(payload)),
  getLocationById: (payload) => dispatch(getLocationById(payload)),
  getGroupByID: (payload) => dispatch(getGroupByID(payload)),
  getGroups: (payload) => dispatch(getGroups(payload)),
  getActivity: (payload) => dispatch(getActivity(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Station);
