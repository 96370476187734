/* eslint-disable */
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import "../styles.scss";
import { getUsers, editGroupUsers } from "../../../actions";
import { connect } from "react-redux";

const EditBusinessUsers = ({ payload, setPayload, allUsers, getUsers, setSelectedTab, inputUsers, setInputUsers, editGroupUsers, findVisibleForUsers }) => {
  useEffect(() => {
    getUsers({
      pageSize: "200",
      sortBy: "",
      noPagination: true,
      onSuccess: () => {},
    });
  }, []);

  return (
    <>
      <div className="right-users-container">
        <div className="right-inner-users-container">
          {inputUsers.length > 0 ? (
            inputUsers.map(function (x, i) {
              return (
                <div className="inner-users-container" key={i} style={{height:"unset"}}>
                  <div className="inner-span-exit" style={{ display: "flex",alignItems:"center" }}>
                    <p style={{ fontSize: "18px" }}>
                      <b>{`Потребител ${i + 1}`}</b>
                    </p>
                    {inputUsers.length > 1 ? (
                      <div
                        className="exit"
                        id={"exit"}
                        onClick={() => {
                          const newUsers = inputUsers.slice(0);
                          newUsers.splice(i, 1);
                          setInputUsers(newUsers);

                          //find _id of selected el in allUsers and delete from payload
                          let userIds = [];
                          newUsers.map((inputUser) => {
                            let userId = allUsers.find((user) => user.phoneNumber === "+359".concat(inputUser.phoneNumber))?._id || "";
                            if (userId) userIds.push(userId);
                          });

                          let newPayload = { ...payload };
                          newPayload._users = userIds;
                          setPayload(newPayload);
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="all-fields">
                    <div className="header-input">
                      <span className="input-title">Име и фамилия</span>
                      <input required className="input-field readOnly" type="text" id={`group-name${i}`} value={x?.name} disabled />
                    </div>
                    <div className="header-input">
                      <span className="input-title" style={{ marginBottom: window.innerWidth < 1600 && "3%" }}>
                        Телефонен номер
                      </span>
                      <div className="prefix-input">
                        <div className="prefixx">+359</div>
                        <input
                          required
                          className="inputt-field"
                          type="number"
                          id={`group-name${i}`}
                          value={x?.phoneNumber}
                          onChange={({ target: { value } }) => {
                            if (!/^\d{0,9}$/.test(value)) return;
                            const newUsers = inputUsers.slice(0);
                            let userName = allUsers.find((user) => user.phoneNumber === "+359" + value)?.name || "";
                            newUsers[i].phoneNumber = value;
                            userName?.length ? (newUsers[i].name = userName) : (newUsers[i].name = "");
                            setInputUsers(newUsers);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {i + 1 === inputUsers.length ? (
                    <div
                      className="button-container add-section"
                      style={{ width: "100px" }}
                      onClick={() => {
                        setInputUsers((prevState) => [
                          ...prevState,
                          {
                            name: "",
                            phoneNumber: "+359",
                          },
                        ]);
                      }}
                    >
                      Добави
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            })
          ) : (
            <>
              <div
                className="button-container add-section"
                style={{ width: "100px" }}
                onClick={() => {
                  setInputUsers((prevState) => [
                    ...prevState,
                    {
                      name: "",
                      phoneNumber: "+359",
                    },
                  ]);
                }}
              >
                Добави
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  allUsers: state.login.allUsers,
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: (payload) => dispatch(getUsers(payload)),
  editGroupUsers: (payload) => dispatch(editGroupUsers(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditBusinessUsers);
