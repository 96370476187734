/* eslint-disable */
import React, { useState } from "react";
import "./styles.scss";
import * as helperFunctions from "../../utilities/helperFunctions";

const InnerSpecialPriceTabs = [
  "name",
  "_stations",
  "_outlets", // here must be outlets count
  "status", // here must be how much actives are
  "businessGroup", //here must be if is connected to businessGroup
  "visible", //here must be if is visible to anyone
  "specialPrices",
  "sharedProfit",
  "address",
  "workingTime",
  "lastCharge",
  "lastCharge_price",
];

const InnerLocations = ({ group, groups }) => {
  const InnerLocationsTabs = [
    {
      name: "Локация име",
      query: {
        filter: "",
        options: groups.map((group) =>
          group?._locations.map((location) => {
            return location?.name;
          })
        ),
      },
    },
    {
      name: "Станции",
      query: {
        filter: "",
        options: groups.map((group) =>
          group?._locations.map((location) => {
            return location?._stations.length;
          })
        ),
      },
    },
    {
      name: "Изходи",
      query: { filter: "", options: [] },
    },
    {
      name: "Активни",
      query: { filter: "", options: [] },
    },
    {
      name: "За всички",
      query: { filter: "", options: ["Да", "Не", "---"] },
    },
    {
      name: "Специални цени",
      query: { filter: "", options: ["Да", "Не", "---"] },
    },
    {
      name: "Споделен приход",
      query: {
        filter: "",
        options: () => {
          var array = group?._locations.map((location) => {
            if (location.sharedProfit) return `${location.partOfProfit}%`;
            else return "Не";
          });
          return [...new Set(array)];
        },
      },
    },
    {
      name: "Град",
      query: {
        filter: "",
        options: () => {
          var array = group?._locations.map((location) => {
            return location.address.split(",")[1];
          });
          return [...new Set(array)];
        },
      },
    },
    {
      name: "Работно време",
      query: { filter: "", options: ["24/7", "Фиксирано"] },
    },
    {
      name: "Последно зареждане",
      query: { filter: "", options: [] },
    },
    {
      name: "Сума",
      query: { filter: "", options: [] },
    },
  ];

  return (
    <table className="inner-table scroll-width-thin">
      <tr className="inner-header-tabs" style={{ marginBottom: "2%" }}>
        {InnerLocationsTabs.map((header) => {
          return <th className="inner-header">{header.name}</th>;
        })}
      </tr>
      {group._locations.map((location) => {
        return (
          <tr className="inner-header-tabs" style={{ marginBottom: "2%" }}>
            {InnerSpecialPriceTabs.map((field, j) => {
              return (
                <React.Fragment key={j}>
                  {j === 0 && <div className="signal" />}
                  {field === "_stations" ? (
                    <td className="location-data">{location[field].length}</td>
                  ) : field === "_outlets" ? (
                    helperFunctions.handleOutlets(location, "count")
                  ) : field === "status" ? (
                    helperFunctions.handleOutlets(location, "status")
                  ) : field === "businessGroup" ? (
                    helperFunctions.handleGroupsProperties(location, "group", groups, false)
                  ) : field === "visible" ? (
                    helperFunctions.handleGroupsProperties(location, "visible", groups, false)
                  ) : field === "specialPrices" ? (
                    helperFunctions.handleGroupsProperties(location, "special", groups, false)
                  ) : field === "sharedProfit" ? (
                    helperFunctions.handleGroupsProperties(location, "shared", groups, false)
                  ) : field === "address" ? (
                    <td className="location-data">{location[field].split(",")[1]}</td>
                  ) : field === "workingTime" ? (
                    <td className="location-data">{location[field] === "full" ? "24/7" : "Фиксирано"}</td>
                  ) : (
                    <td className="location-data bigger">{location[field]?.length > 14 ? location[field].slice(0, 14) + "..." : location[field]}</td>
                  )}
                </React.Fragment>
              );
            })}
          </tr>
        );
      })}
    </table>
  );
};

export default InnerLocations;
