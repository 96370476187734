/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import "./styles.scss";
import { connect } from "react-redux";
import { getUsers, getBusinessUsers, getLocations, getGroups, setUsersDetails, setLocationDetails, setGroupsDetails } from "../../actions";
import * as sortFunctions from "../../utilities/sortFunctions";
import Scrollbars from "react-custom-scrollbars";

const HeaderFilterRow = ({
  coordinates,
  element,
  index,
  onClose,
  type,
  filterType,
  copyLocations,
  selectedHeader,
  setCopyLocations,
  setSortBy,
  getLocations,
  getGroups,
  getUsers,
  groups,
  setHasMore,
  copyGroups,
  copyCars,
  setCopyCars,
  brands,
  getAllCars,
  getAllModels,
  models,
  getAllTypes,
  types,
  sortValue,
  setSortValue,
  sortData,
  sortBy,
  setFilterType,
  payment,
  setPayment,
  loadOptionsUsers,
  loadOptionsLocations,
  loadOptionsGroups,
  loadOptionsStations,
  filterOptionsStations,
  filteredUsers,
  setFilteredUsers,
  setUsersDetails,
  filteredBusinessGroups,
  setFilteredBusinessGroups,
  filteredFinanceLocations,
  setFilteredFinanceLocations,
  filteredStations,
  setFilteredStations,
  invoice,
  setInvoice,
  hasMoreLocations,
  hasMoreGroups,
  hasMoreUsers,
  currentPageGroups,
  currentPageLocations,
  currentPageUsers,
  setStations,
}) => {
  // eslint-disable-next-line
  const [changeDisplay, setChangeDisplay] = useState(false);
  const [options, setOptions] = useState(typeof selectedHeader?.query.options === "function" ? selectedHeader?.query?.options()?.slice(0) : selectedHeader?.query?.options?.slice(0));
  const wrapperReff = useRef(null);
  const scrollRef = useRef(null);

  //by num
  const [filteredLocations, setFilteredLocations] = useState(copyLocations?.slice(0));
  const [filteredGroups, setFilteredGroups] = useState(copyGroups?.slice(0));
  const [filteredCars, setFilteredCars] = useState(copyCars?.slice(0));
  const [reorder, setReorder] = useState("");
  const [checked, setChecked] = useState("");
  const [locationFilter, setLocationFilter] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [ans, setAns] = useState();
  const [filteredIncomeType, setFilteredIncomeType] = useState([]);
  const [filteredIncomePayment, setFilteredIncomePayment] = useState([]);
  // const [filteredUsers, setFilteredUsers] = useState([]);

  //by word
  const [word, setWord] = useState("");
  const [filterWords, setFilterWords] = useState([]);

  useOutsideAlerter(wrapperReff);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      // Alert if clicked on outside of element
      function handleClickOutside(event) {
        if (event.target.nodeName === "svg" || event.target.nodeName === "path" || (ref.current && !ref.current.contains(event.target) && !event.target.className.includes("child"))) {
          if (onClose) onClose();
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  function filterByWord(All_tabs) {
    var All_sorted_tabs = new Array(); //default collection
    var parent_div = document.getElementById("all-stations"); //parent of all stations

    var array = Array.from(parent_div.children).filter((child) => child.className === "info-inner-tab");

    if (type === "stations" || type === "users" || type === "incomes" || type === "expenses") parent_div.innerHTML = "";
    else if (type === "groups") {
      parent_div.innerHTML = "";
      array.map((el) => parent_div.appendChild(el));
    }
    let foundWords = Array();
    if (type === "stations") {
      All_sorted_tabs = All_tabs.map((x) => {
        let innerText = x.children[index + 1].innerText;
        filterWords.map((word) => {
          let innerTextCleared = innerText;
          if (innerText.indexOf("...") !== -1) innerTextCleared = innerTextCleared.substring(0, innerText.length - 3);
          if (word.indexOf(innerTextCleared) !== -1) foundWords.push(x);
        });
      });
      foundWords.map((tab) => {
        tab.nodeType === Node.ELEMENT_NODE && parent_div.appendChild(tab);
      });
    } else if (type === "groups") {
      All_sorted_tabs = All_tabs.map((x) => {
        let innerText = x.children[index].innerText;
        filterWords?.map((word) => {
          let innerTextCleared = innerText;
          if (innerText.indexOf("...") !== -1) innerTextCleared = innerTextCleared.substring(0, innerText.length - 3);
          if (word.indexOf(innerTextCleared) !== -1) foundWords.push(x);
        });
      });
      foundWords?.map((tab) => {
        tab.nodeType === Node.ELEMENT_NODE && parent_div.appendChild(tab);
      });
    } else if (type === "users") {
      All_sorted_tabs = All_tabs.map((x) => {
        let innerText = x.children[index].innerText;
        filterWords?.map((word) => {
          let innerTextCleared = innerText;
          if (innerTextCleared.length > 0) {
            if (innerText.indexOf("...") !== -1) innerTextCleared = innerTextCleared.substring(0, innerText.length - 3);
            if (word.length === innerTextCleared.length) foundWords.push(x);
          }
        });
      });
      foundWords?.map((tab) => {
        tab.nodeType === Node.ELEMENT_NODE && parent_div.appendChild(tab);
      });
    } else if (type === "incomes") {
      All_sorted_tabs = All_tabs.map((x) => {
        let innerText = x.children[index].innerText;
        filterWords?.map((word) => {
          let innerTextCleared = innerText;
          if (innerTextCleared.length > 0) {
            if (innerText.indexOf("...") !== -1) innerTextCleared = innerTextCleared.substring(0, innerText.length - 3);
            if (word.indexOf(innerTextCleared) !== -1) foundWords.push(x);
            if (innerTextCleared.includes(word)) foundWords.push(x);
          }
        });
      });
      foundWords?.map((tab) => {
        tab.nodeType === Node.ELEMENT_NODE && parent_div.appendChild(tab);
      });
    }
  }

  const onScroll = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const t = scrollHeight - clientHeight - scrollTop <= 1 && scrollTop !== 0;

    if (t) {
      if (selectedHeader?.type === "user") fetchMoreUsers(word || "");
      if (selectedHeader?.type === "location") fetchMoreLocations(word || "");
      else if (selectedHeader?.type === "businessGroup") fetchMoreGroups(word || "");
    }
  };

  const fetchMoreUsers = (search) => {
    if (hasMoreUsers) {
      let payload = {
        pageNumber: currentPageUsers + 1,
        pageSize: "20",
        search: search || "",
      };

      getUsers({
        ...payload,
        onSuccess: (res) => {
          if (!res?.payload?.docs?.length) return;
          const fetchedOptions = res.payload.docs.map(({ _id, name, phoneNumber }) => ({
            type: _id,
            label: [name, phoneNumber].filter(Boolean).join(" - "),
          }));
          setUsersDetails({ hasNextPage: res?.payload?.hasNextPage, page: res?.payload?.page });
          setOptions([...options, ...fetchedOptions]);
        },
      });
    } else return;
  };

  const fetchMoreLocations = (search) => {
    if (hasMoreLocations) {
      let payload = {
        pageNumber: currentPageLocations + 1,
        pageSize: "20",
        search: search || "",
      };

      getLocations({
        ...payload,
        onSuccess: (res) => {
          if (!res?.payload?.docs?.length) return;
          const fetchedOptions = res.payload.docs.map(({ _id, name }) => ({ type: _id, label: name }));
          let stations = [];
          res.payload.docs.forEach((location) => {
            location._stations.forEach((station) => {
              stations.push({ type: station._id, label: station.name, locationId: location._id });
            });
          });
          setStations([...stations]);
          setLocationDetails({ hasNextPage: res?.payload?.hasNextPage, page: res?.payload?.page });
          setOptions([...options, ...fetchedOptions]);
        },
      });
    } else return;
  };

  const fetchMoreGroups = (search) => {
    if (hasMoreGroups) {
      let payload = {
        pageNumber: currentPageGroups + 1,
        pageSize: "20",
        search: search || "",
      };

      getGroups({
        ...payload,
        onSuccess: (res) => {
          if (!res?.payload?.docs?.length) return;
          const fetchedOptions = res.payload.docs.map(({ _id, name }) => ({ type: _id, label: name }));
          if (onSuccess) onSuccess([...options]);
          setGroupsDetails({ hasNextPage: res?.payload?.hasNextPage, page: res?.payload?.page });
          setOptions([...options, ...fetchedOptions]);
        },
      });
    } else return;
  };

  useEffect(() => {
    if (type == "stations") {
      if (reorder === "ascending") {
        switch (selectedHeader.query.filter) {
          case "_stations":
            filteredLocations.sort((a, b) => {
              return a?._stations.length - b?._stations.length;
            });
            break;
          case "_outlets":
            filteredLocations.sort((a, b) => {
              return sortFunctions.compareInnerArray(a, b, "_stations", "_outlets");
            });
            break;
          case "status":
            filteredLocations.sort((a, b) => {
              return sortFunctions.compareInnerArrayKey(a, b, "_stations", "_outlets", "status");
            });
            break;
          case "_lastCharge":
            filteredLocations?.sort((a, b) => {
              sortFunctions.compareInnerDates(a, b, "_lastCharge", "endAt");
            });
            break;
          case "sum":
            filteredLocations?.sort((a, b) => {
              const aSum = a?._lastCharge?.[0]?.amountForPay;
              const bSum = b?._lastCharge?.[0]?.amountForPay;
              return aSum > bSum;
            });
            break;
          case "about":
            filteredLocations.sort((a, b) => {
              return Number(a?.visible) - Number(b.visible);
            });
            break;
          case "special":
            filteredLocations.sort((a, b) => {
              return Number(a?.specialPrices) - Number(b.specialPrices);
            });
            break;
          default: {
            break;
          }
        }
      } else if (reorder === "descending") {
        switch (selectedHeader.query.filter) {
          case "_stations":
            filteredLocations?.sort((a, b) => sortFunctions.compare(b, a, "_stations"));
            break;
          case "_outlets":
            filteredLocations?.sort((a, b) => {
              return sortFunctions.compareInnerArray(b, a, "_stations", "_outlets");
            });
            break;
          case "status":
            filteredLocations?.sort((a, b) => {
              return sortFunctions.compareInnerArrayKey(b, a, "_stations", "_outlets", "status");
            });
            break;
          case "_lastCharge":
            filteredLocations?.sort((a, b) => sortFunctions.compareInnerDates(b, a, "_lastCharge", "endAt"));
            break;
          case "sum":
            filteredLocations?.sort((a, b) => {
              const aSum = b?._lastCharge?.[0]?.amountForPay;
              const bSum = a?._lastCharge?.[0]?.amountForPay;
              return aSum > bSum ? 1 : aSum < bSum ? -1 : 0;
            });
            break;
          case "about":
            filteredLocations?.sort((a, b) => {
              return Number(b?.visible) - Number(a.visible);
            });
            break;
          case "special":
            filteredLocations?.sort((a, b) => {
              return Number(b?.specialPrices) - Number(a?.specialPrices);
            });
            break;
          default:
            break;
        }
      }
    } else if (type == "groups") {
      if (reorder == "ascending") {
        switch (selectedHeader?.query?.filter) {
          case "_locations":
            filteredGroups.sort((a, b) => {
              let aSum = 0;
              let bSum = 0;
              a?._locations?.map((loc) => (aSum = aSum + loc?._stations.length));
              b?._locations?.map((loc) => (bSum = bSum + loc?._stations.length));
              return aSum > bSum ? 1 : aSum < bSum ? -1 : 0;
            });
            break;
          case "onlyFor":
            filteredGroups.sort((a, b) => {
              const aSum = a?._locations.length;
              const bSum = b?._locations.length;
              return aSum > bSum ? 1 : aSum < bSum ? -1 : 0;
            });
            break;
          case "_users":
            filteredGroups.sort((a, b) => {
              const aSum = a?._users.length;
              const bSum = b?._users.length;
              return aSum > bSum ? 1 : aSum < bSum ? -1 : 0;
            });
            break;
          default:
            break;
        }
      } else if (reorder == "descending") {
        switch (selectedHeader.query.filter) {
          case "_locations":
            filteredGroups.sort((a, b) => {
              let aSum = 0;
              let bSum = 0;
              b?._locations?.map((loc) => (aSum = aSum + loc?._stations.length));
              a?._locations?.map((loc) => (bSum = bSum + loc?._stations.length));
              return aSum > bSum ? 1 : aSum < bSum ? -1 : 0;
            });
            break;
          case "onlyFor":
            filteredGroups.sort((a, b) => {
              const aSum = b?._locations.length;
              const bSum = a?._locations.length;
              return aSum > bSum ? 1 : aSum < bSum ? -1 : 0;
            });
            break;
          case "_users":
            filteredGroups.sort((a, b) => {
              const aSum = b?._users.length;
              const bSum = a?._users.length;
              return aSum > bSum ? 1 : aSum < bSum ? -1 : 0;
            });
            break;
          default:
            break;
        }
      }
    } else if (type == "cars") {
      if (reorder === "ascending") {
        switch (selectedHeader.query.filter) {
          case "capacity":
            filteredCars.sort((a, b) => {
              return a?.batteryPower - b?.batteryPower;
            });
            break;
          case "cable":
            filteredCars.sort((a, b) => {
              return a?.cable - b?.cable;
            });
            break;
          case "distance":
            filteredCars.sort((a, b) => {
              const first = a?.mileage != undefined ? Number(a?.mileage) : 0;
              const second = b?.mileage != undefined ? Number(b?.mileage) : 0;
              return first - second;
            });
            break;
          case "average":
            filteredCars.sort((a, b) => {
              const first = a?.consumption != undefined ? Number(a?.consumption) : 0;
              const second = b?.consumption != undefined ? Number(b?.consumption) : 0;
              return first - second;
            });
            break;
          case "power":
            filteredCars.sort((a, b) => {
              const first = a?.power != undefined ? a?.power : 0;
              const second = b?.power != undefined ? b?.power : 0;
              return first - second;
            });
            break;
        }
      } else if (reorder === "descending") {
        switch (selectedHeader.query.filter) {
          case "capacity":
            filteredCars.sort((a, b) => {
              return b?.batteryPower - a?.batteryPower;
            });
          case "cable":
            filteredCars.sort((a, b) => {
              return b?.cable - a?.cable;
            });
            break;
          case "distance":
            filteredCars.sort((a, b) => {
              const first = a?.mileage != undefined ? Number(a?.mileage) : 0;
              const second = b?.mileage != undefined ? Number(b?.mileage) : 0;
              return second - first;
            });
            break;
          case "average":
            filteredCars.sort((a, b) => {
              const first = a?.consumption != undefined ? Number(a?.consumption) : 0;
              const second = b?.consumption != undefined ? Number(b?.consumption) : 0;
              return second - first;
            });
            break;
          case "power":
            filteredCars.sort((a, b) => {
              const first = a.power != undefined ? a.power : 0;
              const second = b.power != undefined ? b.power : 0;
              return second - first;
            });
            break;
        }
      }
    }
    if (type === "incomes" || type === "expenses") {
      if (filterType?.length) setFilteredIncomeType(filterType);
      if (payment?.length) setFilteredIncomePayment(payment);
      if (filteredFinanceLocations?.length) setLocationFilter(true);
      if (selectedHeader?.type === "user") {
        setCheckedList(filteredUsers);
      } else if (selectedHeader?.type === "paymentMethod") {
        setCheckedList(payment);
      } else if (selectedHeader?.type === "paymentFor") {
        setCheckedList(filterType);
      } else if (selectedHeader?.type === "location") {
        setCheckedList(filteredFinanceLocations);
      } else if (selectedHeader?.type === "businessGroup") {
        setCheckedList(filteredBusinessGroups);
      } else if (selectedHeader?.type === "station") {
        setCheckedList(filteredStations);
      }
    }
  }, [reorder]);

  useEffect(() => {
    if (type === "incomes" || type === "expenses" || type === "groups") {
      if (sortValue === 1 && sortBy) setReorder("ascending");
      else if (sortValue === -1 && sortBy) setReorder("descending");

      if (sortBy !== selectedHeader?.type) setReorder("");
    }
  }, []);

  const getGroupsByName = () => {
    const newGroups = groups.filter((group) => filterWords.includes(group?.name));
    return newGroups;
  };

  return (
    <>
      {type === "stations" ? (
        <div
          className="sort-container"
          id="sort-container"
          ref={wrapperReff}
          style={{
            display: `${changeDisplay === false ? coordinates.display : "none"}`,
            top: `${coordinates.y}`,
            left: `${coordinates.x}`,
            height: `${coordinates.smaller && "200px"}`,
          }}
        >
          {filterType === "value" ? (
            <div className="first-section-inner child">
              <span className="child">
                <b>Подреди по:</b>
              </span>
              <form className="child" style={{ marginBottom: "6%" }}>
                <div
                  className="radio child"
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    marginTop: "5%",
                  }}
                >
                  <input
                    type="radio"
                    value="ascending"
                    name="asc"
                    className="radio child"
                    id="ascending"
                    checked={reorder === "ascending" && true}
                    onClick={() => {
                      setReorder("ascending");
                    }}
                  />
                  Възходящи
                </div>
                <div
                  className="radio child"
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    marginTop: "3%",
                  }}
                >
                  <input
                    type="radio"
                    value="descending"
                    name="desc"
                    className="radio child"
                    id="descending"
                    checked={reorder === "descending" && true}
                    onClick={() => {
                      setReorder("descending");
                    }}
                  />
                  Низходящи
                </div>
              </form>
            </div>
          ) : (
            <>
              <span className="child">
                <b>Филтрирай по:</b>
              </span>
              <div className="prefix-input child high">
                <input
                  required
                  className="inputt-field child"
                  type="text"
                  id="group-name"
                  value={word}
                  placeholder="Търси"
                  onChange={(event) => setWord(event.target.value)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter")
                      setOptions(
                        selectedHeader.query.options?.map((option) => {
                          return option.toLowerCase().includes(word.toLowerCase()) === true ? option : "";
                        })
                      );
                  }}
                />
                <div
                  className="prefix child"
                  onClick={() => {
                    setOptions(
                      selectedHeader.query.options?.map((option) => {
                        return option.toLowerCase().includes(word.toLowerCase()) === true ? option : "";
                      })
                    );
                  }}
                />
              </div>
              <div className="all-checkboxes child">
                {options?.map((el, i) => {
                  return (
                    <>
                      {el === "" ? (
                        ""
                      ) : (
                        <div className="checkboxes child" key={i}>
                          <input
                            type="checkbox"
                            id="scales"
                            name="scales"
                            className="child"
                            checked={filterWords.includes(el) && true}
                            onClick={() => {
                              const newFilterWords = filterWords.slice(0);
                              if (newFilterWords.includes(el)) {
                                const index = newFilterWords.indexOf(el);
                                newFilterWords.splice(index, 1);
                              } else newFilterWords.push(el);
                              setFilterWords(newFilterWords);
                            }}
                          />
                          <label htmlFor="scales" className="child" style={{ marginTop: "2%", marginLeft: "1%" }}>
                            {el}
                          </label>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </>
          )}
          <div className="footer child">
            <span
              style={{ fontSize: "12px", cursor: "pointer" }}
              className="child"
              onClick={() => {
                setReorder("");
                setWord("");
                setFilterWords([]);
                getLocations({
                  noPagination: true,
                  onSuccess: (res) => {
                    setCopyLocations(res.payload.docs);
                  },
                });
                setOptions(selectedHeader.query.options);
              }}
            >
              Изчисти филтри
            </span>
            <div
              className="small-button-container child"
              style={{ color: "white" }}
              onClick={() => {
                if (filterType != "value") {
                  if (selectedHeader.query.filter.includes("group")) {
                    const groupIDs = getGroupsByName().map((group) => {
                      return group.id;
                    });
                    getLocations({
                      noPagination: true,
                      businessGroup: groupIDs,
                      onSuccess: (res) => {
                        setCopyLocations(res.payload.docs);
                        onClose(false);
                        setHasMore(false);
                      },
                    });
                  } else {
                    const newLocations = filteredLocations.slice(0).filter((loc) => filterWords.includes(selectedHeader.query.filter.includes("name") ? loc.name : selectedHeader.query.filter.includes("profit") ? loc.partOfProfit : selectedHeader.query.filter.includes("address") ? loc.address.split(",")[1] : loc.workingTime == "full" ? "24/7" : "Фиксирано"));
                    setCopyLocations(newLocations);
                    setHasMore(false);
                    onClose(false);
                  }
                } else {
                  setCopyLocations(filteredLocations);
                  setHasMore(false);
                  onClose(false);
                }
              }}
            >
              Задай
            </div>
          </div>
        </div>
      ) : type === "groups" ? (
        <div
          className={`sort-container ${element?.containerType ? element?.containerType : ""}`}
          id="sort-container"
          ref={wrapperReff}
          style={{
            display: `${changeDisplay === false ? coordinates.display : "none"}`,
            top: `${coordinates.y}`,
            left: `${coordinates.x}`,
            height: `${coordinates.smaller && "200px"}`,
          }}
        >
          {filterType == "value" ? (
            <div className="first-section-inner child">
              <span className="child">
                <b>Подреди по:</b>
              </span>
              <form className="child" style={{ marginBottom: "6%" }}>
                <div
                  className="radio child"
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    marginTop: "5%",
                  }}
                >
                  <input
                    type="radio"
                    value="ascending"
                    name="asc"
                    className="radio child"
                    id="ascending"
                    checked={reorder === "ascending" && true}
                    onClick={() => {
                      setReorder("ascending");
                    }}
                  />
                  Възходящи
                </div>
                <div
                  className="radio child"
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    marginTop: "3%",
                  }}
                >
                  <input
                    type="radio"
                    value="descending"
                    name="desc"
                    className="radio child"
                    id="descending"
                    checked={reorder === "descending" && true}
                    onClick={() => {
                      setReorder("descending");
                    }}
                  />
                  Низходящи
                </div>
              </form>
            </div>
          ) : filterType == "word" ? (
            <>
              <span className="child">
                <b>Филтрирай по:</b>
              </span>
              <div className="prefix-input child">
                <input
                  required
                  className="inputt-field child"
                  type="text"
                  id="group-name"
                  value={word}
                  placeholder="Търси"
                  onChange={(event) => setWord(event.target.value)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter")
                      setOptions(
                        selectedHeader?.query?.options?.map((option) => {
                          return option.toLowerCase().includes(word.toLowerCase()) === true ? option : "";
                        })
                      );
                  }}
                />
                <div
                  className="prefix child"
                  onClick={() => {
                    setOptions(
                      selectedHeader.query.options?.map((option) => {
                        return option.toLowerCase().includes(word.toLowerCase()) === true ? option : "";
                      })
                    );
                  }}
                />
              </div>
              <div className="all-checkboxes child">
                {options?.map((el, i) => {
                  return (
                    <>
                      {el === "" ? (
                        ""
                      ) : (
                        <div className="checkboxes child" key={i}>
                          <input
                            type="checkbox"
                            id="scales"
                            name="scales"
                            className="child"
                            checked={filterWords.includes(el) && true}
                            onClick={() => {
                              const newFilterWords = filterWords.slice(0);
                              if (newFilterWords.includes(el)) {
                                const index = newFilterWords.indexOf(el);
                                newFilterWords.splice(index, 1);
                              } else newFilterWords.push(el);
                              setFilterWords(newFilterWords);
                            }}
                          />
                          <label htmlFor="scales" className="child">
                            {el}
                          </label>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </>
          ) : (
            <div className="first-section-inner child">
              <span className="child">
                <b>Филтрирай по:</b>
              </span>
              <form className="child" style={{ marginBottom: "6%" }}>
                <div
                  className="radio child"
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    marginTop: "5%",
                  }}
                >
                  <input
                    type="radio"
                    value="ascending"
                    name="asc"
                    className="radio child"
                    id="ascending"
                    defaultChecked={ans == true && true}
                    onClick={() => {
                      setAns(true);
                    }}
                  />
                  Да
                </div>
                <div
                  className="radio child"
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    marginTop: "3%",
                  }}
                >
                  <input
                    type="radio"
                    value="descending"
                    name="desc"
                    className="radio child"
                    id="descending"
                    checked={ans == false && true}
                    onClick={() => {
                      setAns(false);
                    }}
                  />
                  Не
                </div>
              </form>
            </div>
          )}

          <div className="footer child">
            <span
              style={{ fontSize: "12px", cursor: "pointer" }}
              className="child"
              onClick={() => {
                setReorder("");
                setSortBy("");
                setSortValue(0);
                setWord("");
                setFilterWords([]);
                setAns(undefined);
                setOptions(selectedHeader.query.options);
                sortData("");
              }}
            >
              Изчисти филтри
            </span>
            <div
              className="small-button-container child"
              style={{ color: "white" }}
              onClick={() => {
                if (reorder === "ascending") {
                  setSortBy(selectedHeader?.type);
                  setSortValue(1);
                  sortData(selectedHeader?.type, 1);
                } else if (reorder === "descending") {
                  setSortBy(selectedHeader?.type);
                  setSortValue(-1);
                  sortData(selectedHeader?.type, -1);
                }
                onClose();
              }}
            >
              Задай
            </div>
          </div>
        </div>
      ) : type === "users" ? (
        <div
          className="sort-container"
          id="sort-container"
          ref={wrapperReff}
          style={{
            display: `${changeDisplay === false ? coordinates.display : "none"}`,
            top: `${coordinates.y}`,
            left: `${coordinates.x}`,
          }}
        >
          {index === 4 ? (
            <div className="first-section-inner child">
              <span className="child">
                <b>Подреди по:</b>
              </span>
              <form className="child" style={{ marginBottom: "6%" }}>
                <div
                  className="radio child"
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    marginTop: "5%",
                  }}
                >
                  <input
                    type="radio"
                    value="ascending"
                    name="asc"
                    className="radio child"
                    id="ascending"
                    checked={reorder === "ascending" && true}
                    onClick={() => {
                      setReorder("ascending");
                    }}
                  />
                  Възходящи
                </div>
                <div
                  className="radio child"
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    marginTop: "3%",
                  }}
                >
                  <input
                    type="radio"
                    value="descending"
                    name="desc"
                    className="radio child"
                    id="descending"
                    checked={reorder === "descending" && true}
                    onClick={() => {
                      setReorder("descending");
                    }}
                  />
                  Низходящи
                </div>
              </form>
            </div>
          ) : (
            <>
              <span className="child">
                <b>Филтрирай по:</b>
              </span>
              <div className="prefix-input child">
                <input
                  required
                  className="inputt-field child"
                  type="text"
                  id="group-name"
                  value={word}
                  placeholder="Търси"
                  onChange={(event) => setWord(event.target.value)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter")
                      setOptions(
                        element.query.options?.map((option) => {
                          return option.toLowerCase().includes(word.toLowerCase()) === true ? option : "";
                        })
                      );
                  }}
                />
                <div
                  className="prefix child"
                  onClick={() => {
                    if (typeof element.query.options === "function") {
                      setOptions(
                        element.query.options()?.map((option) => {
                          return option.toLowerCase().includes(word.toLowerCase()) === true ? option : "";
                        })
                      );
                    } else
                      setOptions(
                        element.query.options?.map((option) => {
                          return option.toLowerCase().includes(word.toLowerCase()) === true ? option : "";
                        })
                      );
                  }}
                />
              </div>
              <div className="all-checkboxes child">
                {options?.map((el, i) => {
                  return (
                    <>
                      {el === "" ? (
                        ""
                      ) : (
                        <div className="checkboxes child" key={i}>
                          <input
                            type="checkbox"
                            id="scales"
                            name="scales"
                            className="child"
                            checked={filterWords.includes(el) && true}
                            onClick={() => {
                              const newFilterWords = filterWords.slice(0);
                              if (newFilterWords.includes(el)) {
                                const index = newFilterWords.indexOf(el);
                                newFilterWords.splice(index, 1);
                              } else newFilterWords.push(el);
                              setFilterWords(newFilterWords);
                            }}
                          />
                          <label htmlFor="scales" className="child" style={{ marginTop: "2%", marginLeft: "1%" }}>
                            {el}
                          </label>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </>
          )}

          <div className="footer child">
            <span
              style={{ fontSize: "12px", cursor: "pointer" }}
              className="child"
              onClick={() => {
                setReorder("");
                setWord("");
                setFilterWords([]);
                setOptions(element.query.options);
              }}
            >
              Изчисти филтри
            </span>
            <div
              className="small-button-container child"
              style={{ color: "white" }}
              onClick={() => {
                var parentName = `.location-roww`;
                var All_tabs = document.querySelectorAll(parentName);
                All_tabs = Array.prototype.slice.call(All_tabs, 0); // make all tabs object into array so we can loop through it

                if (filterWords.length > 0) filterByWord(All_tabs);
                if (reorder === "ascending") sortFunctions.sortBy_date(index, All_tabs, "all-stations", 1, type);
                else if (reorder === "descending") sortFunctions.sortBy_date(index, All_tabs, "all-stations", 0, type);
                onClose(false);
              }}
            >
              Задай
            </div>
          </div>
        </div>
      ) : type === "incomes" ? (
        <div
          className={`sort-container ${element?.containerType ? element?.containerType : ""} ${selectedHeader?.type === "station" && !locationFilter ? "flex-start" : ""}`}
          id="sort-container"
          ref={wrapperReff}
          style={{
            display: `${changeDisplay === false ? coordinates.display : "none"}`,
            top: `${coordinates.y}`,
            left: `${coordinates.x}`,
            height: `${coordinates?.smaller && "200px"}`,
          }}
        >
          {!element?.filter ? (
            <div className="first-section-inner child">
              <span className="child">
                <b>Подреди по:</b>
              </span>
              <form className="child" style={{ marginBottom: "6%" }}>
                <div className="radio child" style={{ display: "flex", alignItems: "flex-end", marginTop: "5%" }}>
                  <input
                    type="radio"
                    value="ascending"
                    name="asc"
                    className="radio child"
                    id="ascending"
                    checked={reorder === "ascending" && true}
                    onClick={() => {
                      setReorder("ascending");
                    }}
                  />
                  Възходящи
                </div>
                <div
                  className="radio child"
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    marginTop: "3%",
                  }}
                >
                  <input
                    type="radio"
                    value="descending"
                    name="desc"
                    className="radio child"
                    id="descending"
                    checked={reorder === "descending" && true}
                    onClick={() => {
                      setReorder("descending");
                    }}
                  />
                  Низходящи
                </div>
              </form>
            </div>
          ) : (
            <>
              <span className="child">
                <b>Филтрирай по:</b>
              </span>
              {selectedHeader?.type === "station" && !locationFilter ? (
                <div className="red">Моля, филтрирайте първо по локация</div>
              ) : (
                <>
                  {element?.search && (
                    <div className="prefix-input child margin">
                      <img src={require("../../assets/icons/search-icon.svg")} className="search-icon" />
                      <input
                        required
                        className="inputt-field child"
                        type="text"
                        id="group-name"
                        value={word}
                        placeholder="Търси"
                        onChange={(event) => {
                          if (element.type === "user") {
                            setWord(event.target.value);
                            loadOptionsUsers(event.target.value, (res) => setOptions(res));
                          } else if (element.type === "location") {
                            setWord(event.target.value);
                            loadOptionsLocations(event.target.value, (res) => setOptions(res));
                          } else if (element.type === "businessGroup") {
                            setWord(event.target.value);
                            loadOptionsGroups(event.target.value, (res) => setOptions(res));
                          } else if (element.type === "station") {
                            setWord(event.target.value);
                            loadOptionsStations(event.target.value, (res) => setOptions(res));
                          }
                        }}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            if (element.type === "user") loadOptionsUsers(event.target.value, (res) => setOptions(res));
                            else if (element.type === "location") loadOptionsLocations(event.target.value, (res) => setOptions(res));
                            else if (element.type === "businessGroup") {
                              loadOptionsGroups(event.target.value, (res) => {
                                let groups = res.payload.docs.map(({ _id, name }) => ({ type: _id, label: name }));
                                setOptions([...groups]);
                              });
                            } else if (element.type === "station") {
                              setWord(event.target.value);
                              loadOptionsStations(event.target.value, (res) => setOptions(res));
                            }
                          }
                        }}
                      />
                    </div>
                  )}
                  <Scrollbars style={{ height: "85%", width: "100%", overflowX: "hidden" }} ref={scrollRef} onUpdate={(v) => onScroll(v)} id={"scrollbar"} renderView={(props) => <div {...props} style={{ ...props.style, overflowX: "hidden" }} />}>
                    <div className="all-checkboxes child high custom-scroll" ref={scrollRef}>
                      {options?.map((el, i) => {
                        return (
                          <>
                            {el === "" ? (
                              ""
                            ) : (
                              <div className="checkboxes child" key={i}>
                                <input
                                  type="checkbox"
                                  id={`scales-${i}`}
                                  name="scales"
                                  className="child"
                                  checked={(checkedList?.includes(el?.type) || (selectedHeader?.type === "invoice" && invoice === el?.value)) && true}
                                  onClick={() => {
                                    let list = [];

                                    if (!checkedList?.includes(el?.type)) {
                                      list = [...checkedList, el?.type];
                                      setCheckedList([...checkedList, el?.type]);
                                    } else if (checkedList?.includes(el?.type)) {
                                      list = [...checkedList];
                                      list.splice(list.indexOf(el?.type), 1);
                                      setCheckedList(list);
                                    }

                                    if (selectedHeader?.type === "paymentFor") {
                                      // let arrFilter = [...filterType, el?.typePath || el?.type]
                                      let arrFilter = [...list];
                                      setFilterType(arrFilter);
                                      setFilteredIncomeType(arrFilter);
                                    } else if (selectedHeader?.type === "paymentMethod") {
                                      let arrFilter = [...list];
                                      setPayment(arrFilter);
                                      setFilteredIncomePayment(arrFilter);
                                    } else if (selectedHeader?.type === "user") {
                                      setFilteredUsers(list);
                                    } else if (selectedHeader?.type === "location") {
                                      setFilteredFinanceLocations(list);
                                    } else if (selectedHeader?.type === "station") {
                                      setFilteredStations(list);
                                    } else if (selectedHeader?.type === "businessGroup") {
                                      setFilteredBusinessGroups(list);
                                    } else if (selectedHeader?.type === "invoice") {
                                      if (el?.type === "yes") setInvoice(true);
                                      else if (el?.type === "no") setInvoice(false);
                                    }
                                  }}
                                />
                                <label
                                  htmlFor="scales"
                                  className="child"
                                  style={{
                                    marginTop: "2%",
                                    marginLeft: "1%",
                                    width: "80%",
                                  }}
                                >
                                  {el?.label}
                                </label>
                              </div>
                            )}
                          </>
                        );
                      })}
                    </div>
                  </Scrollbars>
                </>
              )}
            </>
          )}

          {(selectedHeader?.type !== "station" || (selectedHeader?.type === "station" && locationFilter)) && (
            <div className="footer child">
              <span
                style={{ fontSize: "12px", cursor: "pointer" }}
                className="child"
                onClick={() => {
                  setReorder("");
                  setOptions(element.query.options);
                  setSortBy("");
                  setChecked("");
                  setFilterType([]);
                  setFilteredIncomeType([]);
                  setPayment([]);
                  setFilteredIncomePayment([]);
                  if (filteredUsers?.length) setFilteredUsers([]);
                  if (filteredFinanceLocations?.length) {
                    setFilteredFinanceLocations([]);
                    setLocationFilter(false);
                  }
                  if (filteredStations?.length) {
                    setFilteredStations([]);
                  }
                  if (filteredBusinessGroups?.length) setFilteredBusinessGroups([]);
                  setInvoice(null);
                  setSortValue(0);
                  onClose(false);
                  sortData("");
                }}
              >
                Изчисти филтри
              </span>
              <div
                className="small-button-container child"
                style={{ color: "white" }}
                onClick={() => {
                  var parentName = type === "incomes" ? `.fulll-inner-tab` : `.location-row`;
                  var All_tabs = document.querySelectorAll(parentName);
                  All_tabs = Array.prototype.slice.call(All_tabs, 0); // make all tabs object into array so we can loop through it
                  if (reorder === "ascending") {
                    setSortBy(selectedHeader?.type);
                    setSortValue(1);
                    sortData(selectedHeader?.type, 1, filteredIncomeType, filteredIncomePayment, filteredUsers, filteredFinanceLocations, filteredStations, invoice, filteredBusinessGroups);
                  } else if (reorder === "descending") {
                    setSortBy(selectedHeader?.type);
                    setSortValue(-1);
                    sortData(selectedHeader?.type, -1, filteredIncomeType, filteredIncomePayment, filteredUsers, filteredFinanceLocations, filteredStations, invoice, filteredBusinessGroups);
                  } else if (options?.length) {
                    sortData(sortBy, sortValue, filteredIncomeType, filteredIncomePayment, filteredUsers, filteredFinanceLocations, filteredStations, invoice, filteredBusinessGroups);
                  }
                  if (filteredFinanceLocations?.length) filterOptionsStations(filteredFinanceLocations);
                  onClose(false);
                }}
              >
                Задай
              </div>
            </div>
          )}
        </div>
      ) : type === "expenses" ? (
        <div
          className={`sort-container ${element?.containerType ? element?.containerType : ""}`}
          id="sort-container"
          ref={wrapperReff}
          style={{
            display: `${changeDisplay === false ? coordinates.display : "none"}`,
            top: `${coordinates.y}`,
            left: `${coordinates.x}`,
            height: `${coordinates?.smaller && "200px"}`,
          }}
        >
          {!element?.filter ? (
            <div className="first-section-inner child">
              <span className="child">
                <b>Подреди по:</b>
              </span>
              <form className="child" style={{ marginBottom: "6%" }}>
                <div
                  className="radio child"
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    marginTop: "5%",
                  }}
                >
                  <input
                    type="radio"
                    value="ascending"
                    name="asc"
                    className="radio child"
                    id="ascending"
                    checked={reorder === "ascending" ? true : false}
                    onClick={() => {
                      setReorder("ascending");
                    }}
                  />
                  Възходящи
                </div>
                <div
                  className="radio child"
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    marginTop: "3%",
                  }}
                >
                  <input
                    type="radio"
                    value="descending"
                    name="desc"
                    className="radio child"
                    id="descending"
                    checked={reorder === "descending" ? true : false}
                    onClick={() => {
                      setReorder("descending");
                    }}
                  />
                  Низходящи
                </div>
              </form>
            </div>
          ) : (
            <>
              <span className="child">
                <b>Филтрирай по:</b>
              </span>
              {element?.search && (
                <div className="prefix-input child margin">
                  <img src={require("../../assets/icons/search-icon.svg")} className="search-icon" />
                  <input
                    required
                    className="inputt-field child"
                    type="text"
                    id="group-name"
                    value={word}
                    placeholder="Търси"
                    onChange={(event) => {
                      if (element.type === "user") {
                        setWord(event.target.value);
                        loadOptionsUsers(event.target.value, (res) => setOptions(res));
                      } else if (element.type === "location") {
                        setWord(event.target.value);
                        loadOptionsLocations(event.target.value, (res) => setOptions(res));
                      } else if (element.type === "businessGroup") {
                        setWord(event.target.value);
                        loadOptionsGroups(event.target.value, (res) => setOptions(res));
                      } else if (element.type === "station") {
                        setWord(event.target.value);
                        loadOptionsLocations(event.target.value, (res) => setOptions(res));
                      }
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        if (element.type === "user") loadOptionsUsers(event.target.value, (res) => setOptions(res));
                        else if (element.type === "location") loadOptionsLocations(event.target.value, (res) => setOptions(res));
                        else if (element.type === "businessGroup") {
                          loadOptionsGroups(event.target.value, (res) => {
                            let groups = res.payload.docs.map(({ _id, name }) => ({ type: _id, label: name }));
                            setOptions([...groups]);
                          });
                        }
                      }
                    }}
                  />
                </div>
              )}
              <Scrollbars style={{ height: "85%", width: "100%", overflowX: "hidden" }} ref={scrollRef} onUpdate={(v) => onScroll(v)} id={"scrollbar"} renderView={(props) => <div {...props} style={{ ...props.style, overflowX: "hidden" }} />}>
                <div className={`all-checkboxes child ${element?.containerType ? element?.containerType : ""} custom-scroll`}>
                  {options?.map((el, i) => {
                    return (
                      <>
                        {el === "" ? (
                          ""
                        ) : (
                          <div className="checkboxes child" key={i}>
                            <input
                              type="checkbox"
                              id={`scales-${i}`}
                              name="scales"
                              className="child"
                              checked={checkedList?.includes(el?.type) && true}
                              onClick={() => {
                                let list = [];

                                if (!checkedList?.includes(el?.type)) {
                                  list = [...checkedList, el?.type];
                                  setCheckedList([...checkedList, el?.type]);
                                } else if (checkedList?.includes(el?.type)) {
                                  list = [...checkedList];
                                  list.splice(list.indexOf(el?.type), 1);
                                  setCheckedList(list);
                                }

                                if (selectedHeader?.type === "paymentFor" || selectedHeader?.type === "type") {
                                  let arrFilter = [...list];
                                  setFilterType(arrFilter);
                                  setFilteredIncomeType(arrFilter);
                                } else if (selectedHeader?.type === "paymentMethod") {
                                  let arrFilter = [...list];
                                  setPayment(arrFilter);
                                  setFilteredIncomePayment(arrFilter);
                                } else if (selectedHeader?.type === "user") {
                                  setFilteredUsers(list);
                                } else if (selectedHeader?.type === "businessGroup") {
                                  setFilteredBusinessGroups(list);
                                } else if (selectedHeader?.type === "location") {
                                  setFilteredFinanceLocations(list);
                                } else if (selectedHeader?.type === "station") {
                                  setFilteredStations(list);
                                }
                              }}
                            />
                            <label
                              htmlFor="scales"
                              className="child"
                              style={{
                                marginTop: "2%",
                                marginLeft: "1%",
                                width: "80%",
                              }}
                            >
                              {el?.label}
                            </label>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </Scrollbars>
            </>
          )}
          <div className="footer child">
            <span
              style={{ fontSize: "12px", cursor: "pointer" }}
              className="child"
              onClick={() => {
                setReorder("");
                setOptions(element.query.options);
                setSortBy("");
                setChecked("");
                setFilterType([]);
                setFilteredIncomeType([]);
                setPayment([]);
                setFilteredIncomePayment([]);
                if (filteredUsers?.length) setFilteredUsers([]);
                // if (filteredFinanceLocations?.length) {
                //   setFilteredFinanceLocations([])
                //   setLocationFilter(false)
                // }
                if (filteredBusinessGroups?.length) setFilteredBusinessGroups([]);
                setSortValue(0);
                onClose(false);
                // sortData("", 0, "", "", 1)
                sortData("");
              }}
            >
              Изчисти филтри
            </span>
            <div
              className="small-button-container child"
              style={{ color: "white" }}
              onClick={() => {
                var parentName = type === "expenses" ? `.fulll-inner-tab` : `.location-row`;
                var All_tabs = document.querySelectorAll(parentName);
                All_tabs = Array.prototype.slice.call(All_tabs, 0); // make all tabs object into array so we can loop through it

                if (reorder === "ascending") {
                  setSortValue(1);
                  sortData(selectedHeader?.type, 1, filteredIncomeType, filteredIncomePayment, filteredUsers, filteredFinanceLocations, [], null, filteredBusinessGroups);
                  setSortBy(selectedHeader?.type);
                } else if (reorder === "descending") {
                  setSortValue(-1);
                  sortData(selectedHeader?.type, -1, filteredIncomeType, filteredIncomePayment, filteredUsers, filteredFinanceLocations, [], null, filteredBusinessGroups);
                  setSortBy(selectedHeader?.type);
                } else if (options?.length) {
                  sortData(sortBy, sortValue, filteredIncomeType, filteredIncomePayment, filteredUsers, filteredFinanceLocations, [], null, filteredBusinessGroups);

                  // if (selectedHeader?.type === "paymentFor" || selectedHeader?.type === "type") sortData(sortBy, sortValue, filteredIncomeType, filteredIncomePayment, filteredUsers)
                  // else if (selectedHeader?.type === "paymentMethod") sortData(sortBy, sortValue, filteredIncomeType, filteredIncomePayment, filteredUsers)
                  // else if (selectedHeader?.type === "user") sortData(sortBy, sortValue, filteredIncomeType, filteredIncomePayment, filteredUsers)
                }
                onClose(false);
              }}
            >
              Задай
            </div>
          </div>
        </div>
      ) : type === "locations-stations" ? (
        <div
          className={`sort-container ${element?.containerType ? element?.containerType : ""}`}
          id="sort-container"
          ref={wrapperReff}
          style={{
            display: `${changeDisplay === false ? coordinates.display : "none"}`,
            top: `${coordinates.y}`,
            left: `${coordinates.x}`,
            height: `${coordinates?.smaller && "200px"}`,
          }}
        >
          <div className="first-section-inner child">
            <span className="child">
              <b>Подреди по:</b>
            </span>
            <form className="child" style={{ marginBottom: "6%" }}>
              <div
                className="radio child"
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginTop: "5%",
                }}
              >
                <input
                  type="radio"
                  value="ascending"
                  name="asc"
                  className="radio child"
                  id="ascending"
                  checked={reorder === "ascending" ? true : false}
                  onClick={() => {
                    setReorder("ascending");
                  }}
                />
                Възходящи
              </div>
              <div
                className="radio child"
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginTop: "3%",
                }}
              >
                <input
                  type="radio"
                  value="descending"
                  name="desc"
                  className="radio child"
                  id="descending"
                  checked={reorder === "descending" ? true : false}
                  onClick={() => {
                    setReorder("descending");
                  }}
                />
                Низходящи
              </div>
            </form>
          </div>

          <div className="footer child">
            <span
              style={{ fontSize: "12px", cursor: "pointer" }}
              className="child"
              onClick={() => {
                setReorder("");
                setSortBy("");
                setSortValue(0);
                onClose(false);
                // sortData("", 0, "", "", 1)
                sortData("");
              }}
            >
              Изчисти филтри
            </span>
            <div
              className="small-button-container child"
              style={{ color: "white" }}
              onClick={() => {
                var parentName = type === "locations-stations" ? `.fulll-inner-tab` : `.location-row`;
                var All_tabs = document.querySelectorAll(parentName);
                All_tabs = Array.prototype.slice.call(All_tabs, 0); // make all tabs object into array so we can loop through it

                if (reorder === "ascending") {
                  setSortValue(1);
                  sortData(selectedHeader?.type, 1);
                  setSortBy(selectedHeader?.type);
                } else if (reorder === "descending") {
                  setSortValue(-1);
                  sortData(selectedHeader?.type, -1);
                  setSortBy(selectedHeader?.type);
                }
                onClose(false);
              }}
            >
              Задай
            </div>
          </div>
        </div>
      ) : (
        type === "cars" && (
          <div
            className="sort-container"
            id="sort-container"
            ref={wrapperReff}
            style={{
              display: `${changeDisplay === false ? coordinates.display : "none"}`,
              top: `${coordinates.y}`,
              left: `${coordinates.x}`,
              height: `${coordinates?.smaller && "200px"}`,
            }}
          >
            {filterType === "value" ? (
              <div className="first-section-inner child">
                <span className="child">
                  <b>Подреди по:</b>
                </span>
                <form className="child" style={{ marginBottom: "6%" }}>
                  <div
                    className="radio child"
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      marginTop: "5%",
                    }}
                  >
                    <input
                      type="radio"
                      value="ascending"
                      name="asc"
                      className="radio child"
                      id="ascending"
                      checked={reorder === "ascending" && true}
                      onClick={() => {
                        setReorder("ascending");
                      }}
                    />
                    Възходящи
                  </div>
                  <div
                    className="radio child"
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      marginTop: "3%",
                    }}
                  >
                    <input
                      type="radio"
                      value="descending"
                      name="desc"
                      className="radio child"
                      id="descending"
                      checked={reorder === "descending" && true}
                      onClick={() => {
                        setReorder("descending");
                      }}
                    />
                    Низходящи
                  </div>
                </form>
              </div>
            ) : (
              <>
                <span className="child">
                  <b>Филтрирай по:</b>
                </span>
                <div className="prefix-input child">
                  <input
                    required
                    className="inputt-field child"
                    type="text"
                    id="group-name"
                    value={word}
                    placeholder="Търси"
                    onChange={(event) => setWord(event.target.value)}
                    onKeyDown={(event) => {
                      if (event.key === "Enter")
                        setOptions(
                          selectedHeader.query.options?.map((option) => {
                            return option.toLowerCase().includes(word.toLowerCase()) === true ? option : "";
                          })
                        );
                    }}
                  />
                  <div
                    className="prefix child"
                    onClick={() => {
                      setOptions(
                        selectedHeader.query.options?.map((option) => {
                          return option.toLowerCase().includes(word.toLowerCase()) === true ? option : "";
                        })
                      );
                    }}
                  />
                </div>
                <div className="all-checkboxes child">
                  {options?.map((el, i) => {
                    return (
                      <React.Fragment key={i}>
                        {el === "" ? (
                          ""
                        ) : (
                          <div className="checkboxes child" key={i}>
                            <input
                              type="checkbox"
                              id="scales"
                              name="scales"
                              className="child"
                              checked={filterWords.includes(el) && true}
                              onClick={() => {
                                if (filterWords.includes(el)) {
                                  setFilterWords("");
                                } else setFilterWords(el);
                              }}
                            />
                            <label htmlFor="scales" className="child" style={{ marginTop: "2%", marginLeft: "1%" }}>
                              {el}
                            </label>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              </>
            )}
            <div className="footer child">
              <span
                style={{ fontSize: "12px", cursor: "pointer" }}
                className="child"
                onClick={() => {
                  setReorder("");
                  setWord("");
                  setFilterWords("");
                  setOptions(selectedHeader.query.options());
                }}
              >
                Изчисти филтри
              </span>
              <div
                className="small-button-container child"
                style={{ color: "white" }}
                onClick={() => {
                  if (filterType == "value") {
                    setCopyCars(filteredCars);
                  } else {
                    if (selectedHeader.query.filter === "type") {
                      const typeID = types.filter((type) => type?.name === filterWords);
                      getAllCars({
                        noPagination: true,
                        batteryType: typeID,
                        onSuccess: (res) => {
                          setCopyCars(res.docs);
                        },
                      });
                    } else if (selectedHeader.query.filter === "model") {
                      const modelID = models?.filter((model) => model?.name == filterWords)[0]?._id;
                      getAllCars({
                        noPagination: true,
                        model: modelID,
                        onSuccess: (res) => {
                          setCopyCars(res.docs);
                          getAllTypes({
                            noPagination: true,
                            model: modelID,
                          });
                        },
                      });
                    } else {
                      const brandID = brands?.filter((brand) => brand?.name == filterWords)[0]?._id;
                      getAllCars({
                        noPagination: true,
                        brand: brandID,
                        onSuccess: (res) => {
                          getAllModels({
                            brand: brandID,
                            noPagination: true,
                            onSuccess: () => {},
                          });
                          setCopyCars(res.docs);
                        },
                      });
                    }
                  }
                  setHasMore(false);
                  onClose(false);
                }}
              >
                Задай
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  allUsers: state.login.allUsers,
  businessUsers: state.login.businessUsers,
  locations: state.locations.locations,
  hasMoreLocations: state.locations.hasMore,
  currentPageLocations: state.locations.currentPage,
  hasMoreGroups: state.groups.hasMore,
  currentPageGroups: state.groups.currentPage,
  hasMoreUsers: state.login.hasMore,
  currentPageUsers: state.login.currentPage,
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: (payload) => dispatch(getUsers(payload)),
  getBusinessUsers: (payload) => dispatch(getBusinessUsers(payload)),
  getLocations: (payload) => dispatch(getLocations(payload)),
  getGroups: (payload) => dispatch(getGroups(payload)),
  getUsers: (payload) => dispatch(getUsers(payload)),
  setUsersDetails: (payload) => dispatch(setUsersDetails(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderFilterRow);
