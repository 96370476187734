/* eslint-disable */
import React, { useEffect, useState } from "react";
import { ic_keyboard_arrow_down } from "react-icons-kit/md/ic_keyboard_arrow_down";
import { Icon } from "react-icons-kit";
import { history } from "../../config/stores";
import HeaderFilterRow from "../HeaderFilterRow/HeaderFilterRow";
import { toast } from "react-toastify";

const CarsGrid = ({
  cars,
  copyCars,
  fetchMoreCars,
  setCurrentPage,
  tableRef,
  setCopyCars,
  setHasMore,
  getAllCars,
  brands,
  getAllModels,
  models,
  types,
  getAllTypes
}) => {
  const [sortBy, setSortBy] = useState("");
  const [coordinates, setCoordinates] = useState({
    display: "none",
    x: "0%",
    y: "0%",
  });
  const [selectedHeader, setSelectedHeader] = useState();
  const [show, setShow] = useState(false);

  const headerElements = [
    {
      name: "Производител",
      query: {
        filter: "brand",
        options: () => {
          return brands.map((brand) => brand.name);
        },
      },
    },
    {
      name: "Модел",
      query: {
        filter: "model",
        options: () => {
          return models.map((model) => model.name);
        },
      },
    },
    {
      name: "Вид",
      query: {
        filter: "type",
        options: () => {
          return types.map(type => type.name)
        },
      },
    },
    {
      name: "Капацитет на батерията",
      query: {
        filter: "capacity",
        options: [],
      },
    },
    {
      name: "Кабел тип",
      query: { filter: "cable", options: [] },
    },
    {
      name: "Пробег изцяло на ток",
      query: { filter: "distance", options: [] },
    },
    {
      name: "Среден разход",
      query: { filter: "average", options: [] },
    },
    {
      name: "Системна мощност",
      query: { filter: "power", options: [] },
    },
  ];

  const set_Popup = (elem) => {
    let rect = elem.getBoundingClientRect();
    if (elem.id === "7") {
      setCoordinates({
        display: "flex",
        x: `${rect.x - 200}px`,
        y: `${rect.y + 50}px`,
      });
    } else
      setCoordinates({
        display: "flex",
        x: `${rect.x - (window.innerWidth < 1600 ? 10 : 20)}px`,
        y: `${rect.y + (window.innerWidth < 1600 ? 40 : 50)}px`,
        smaller: elem.id > 2 ? true : false,
      });
  };

  const handlePaggination = (scrollTop, height, scrollHeight) => {
    if (scrollTop + height >= scrollHeight - 10) fetchMoreCars()
  }

  return (
    <div
      className="cars-table" ref={tableRef}
      onScroll={({ target }) => handlePaggination(target.scrollTop, target.getBoundingClientRect().height, target.scrollHeight)}
    >
      <div className="cars-tHeader">
        {headerElements.map((head, i) => {
          return (
            <div className="cars-tHeaderData" key={i}>
              {head.name}{" "}
              <div
                className={`icon-container ${sortBy == head.name && "rotate"} `}
              >
                <Icon
                  size={"70%"}
                  icon={ic_keyboard_arrow_down}
                  className={`arrow-down`}
                  id={i}
                  onClick={() => {
                    if (sortBy.length > 0) {
                      if ((head.query.filter === "model" && sortBy !== "Модел") || (head.query.filter === "type" && sortBy !== "Вид")) {
                        setShow(true);
                        let elem = document.getElementById(`${i}`);
                        set_Popup(elem);
                        setSortBy(head.name);
                        setSelectedHeader(headerElements[i]);
                      } else {
                        getAllCars({
                          pageSize: 20,
                          pageNumber: 1,
                          noPagination: false,
                          onSuccess: (res) => {
                            setSortBy("");
                            setHasMore(true);
                            setCurrentPage(1);
                            setCopyCars(res.docs);
                          },
                        });
                      }
                    } else {
                      if (head.query.filter === "model" || head.query.filter === "type") {
                        toast.error(`You need to choose ${head.query.filter === "model" ? "brand" : "model"} first.`, {
                          autoClose: 3000,
                        });
                      } else {
                        setShow(true);
                        let elem = document.getElementById(`${i}`);
                        set_Popup(elem);
                        setSortBy(head.name);
                        setSelectedHeader(headerElements[i]);
                      }
                    }
                  }}
                />
              </div>
            </div>
          );
        })}
        {show && (
          <HeaderFilterRow
            setSortBy={setSortBy}
            onClose={(bool) => setShow(bool)}
            type={"cars"}
            selectedHeader={selectedHeader}
            brands={brands}
            getAllModels={getAllModels}
            models={models}
            getAllCars={getAllCars}
            copyCars={copyCars}
            setCopyCars={setCopyCars}
            setHasMore={setHasMore}
            getAllTypes={getAllTypes}
            types={types}
            filterType={
              typeof selectedHeader.query.options === "function"
                ? "word"
                : "value"
            }
            coordinates={coordinates}
          />
        )}
      </div>
      {copyCars?.map((car, i) => {
        return (
          <div className="cars-tRow" key={i}>
            <div className="cars-tData brand">{car?.brand?.name}<div className="icon icon-open" onClick={() => history.push(`cars/add?_id=${car?._id}`)} /></div>
            <div className="cars-tData">{car?.model?.name}</div>
            <div className="cars-tData ">{car?.batteryType?.name}</div>
            <div className="cars-tData">{car?.batteryPower} kWh</div>
            <div className="cars-tData">
              {car?.cable?.length > 0 ? car?.cable : "---"}
            </div>
            <div className="cars-tData">
              {car?.mileage?.length > 0 ? car?.mileage : "---"} км
            </div>
            <div className="cars-tData">
              {car?.consumption?.length > 0 ? car?.consumption : "---"} км/kWh
            </div>
            <div className="cars-tData">
              {car?.power > 0 ? car?.power : "---"} кс
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CarsGrid;
