/* eslint-disable */
import React, { useEffect, useState } from "react";
import "../styles.scss";
import { ic_keyboard_arrow_down } from "react-icons-kit/md/ic_keyboard_arrow_down";
import { Icon } from "react-icons-kit";

import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "moment/locale/bg";

const AdditionalModals = ({
  getActivity,
  activity,
  location_id,
  currentGroup,
  dates,
  filterOptions,
  location,
  selectedTab,
  orderBy,
  orderByType,
  openCalendar,
  setOpenCalendar,
  lookTime,
  outletType,
  UniqueCars,
  setFilterOptions,
  selectedImage,
  handlePayload,
  handleDateChange,
  setDates
}) => {
  const orderTypeMap = {
    "Резервация": "reservation",
    "Зареждане": "charge",
  }
  return(
    <div className="full-to-group" id="blurred">
        {selectedTab === "group" ? (
          <div className="to-group">
            <div className="to-group-first">
              <p className="header-text">{currentGroup?.name}</p>
              <div
                className="close"
                onClick={() => {
                  document.getElementById("blurred").style.display = "none";
                }}
              />
            </div>
            <div className="to-group-second">
              <p className="smaller-header-text">Видима за потребители</p>
              <table>
                <tr style={{ height: "50px" }}>
                  <th>
                    <p style={{ marginLeft: "2%", fontSize: "15px" }}>Име</p>
                    <div
                      className={`iconn-container`}
                      style={{ height: "42px", marginLeft: "3%" }}
                    >
                      <Icon
                        size={"50%"}
                        icon={ic_keyboard_arrow_down}
                        className={`arroww-down`}
                        id={1}
                      />
                    </div>
                  </th>
                  <th>
                    <p style={{ marginLeft: "2%", fontSize: "15px" }}>
                      Телефон
                    </p>
                    <div
                      className={`iconn-container`}
                      style={{ height: "42px", marginLeft: "3%" }}
                    >
                      <Icon
                        size={"50%"}
                        icon={ic_keyboard_arrow_down}
                        className={`arroww-down`}
                        id={1}
                      />
                    </div>
                  </th>
                </tr>
                {currentGroup._users.map((user) => {
                  return (
                    <tr
                      style={{
                        height: "50px",
                        marginTop: "3%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <td
                        style={{
                          borderRight: "1px solid gray",
                          height: "50px",
                        }}
                      >
                        <p style={{ marginLeft: "2%" }}>{user?.name}</p>
                      </td>
                      <td style={{ height: "45px" }}>
                        <p style={{ marginLeft: "2%" }}>{user?.phoneNumber}</p>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        ) : selectedTab === "price" ? (
          <div className="price">
            <div className="to-group-first">
              <p className="header-text">Специани цени</p>
              <div
                className="close"
                onClick={() => {
                  document.getElementById("blurred").style.display = "none";
                }}
              />
            </div>
            <div className="to-group-second">
              <p className="smaller-header-text">{currentGroup?.name}</p>
              {location?._stations?.map((station, i) => {
                return (
                  <div className="info-full-container">
                    <div className="info-inner-container">
                      <div className="logo" />
                      <p className="info-inner-text" style={{ width: "30%" }}>
                        <b>
                          {station._outlets[0].type.map((t) => t + " ")}
                          {station?.voltage}
                        </b>
                      </p>
                      <p className="price-inner-text" style={{ width: "25%" }}>
                        <b style={{ color: "#00D688" }}>
                          {station?.specialPrice}
                        </b>{" "}
                        лв./kW
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : selectedTab === "image" ? (
          <div className="image-popUp">
            <div
              className="image"
              style={{ backgroundImage: `url(${selectedImage})` }}
            />
            <div className="close-container">
              <div
                className="close"
                onClick={() => {
                  document.getElementById("blurred").style.display = "none";
                }}
              />
            </div>
          </div>
        ) : selectedTab === "filtration" ? (
          <div className="filtration-popUp ">
            <div
              className="inner-filtration-container scroll-width-thin"
              style={{
                height: "93%",
                width: "100%",
                overflowX: "hidden",
                overflowY: "scroll",
                padding: "1vw",
              }}
            >
              <div className="to-group-first">
                <p className="header-text">Филтри</p>
                <div
                  className="close"
                  onClick={() => {
                    document.getElementById("blurred").style.display = "none";
                  }}
                />
              </div>
              <div className="orderBy">
                <h2 className="filter-title">Подреди по</h2>
                <div className="all-radio">
                  {orderBy?.map((element, i) => {
                    return (
                      <div
                        className="radio"
                        style={{ display: "flex", alignItems: "flex-end" }}
                        key={i}
                      >
                        <input
                          type="radio"
                          value="active"
                          name={`active`}
                          className="radio-btn"
                          id={`active${i}`}
                          checked={filterOptions.order === element && true}
                          onClick={() => {
                            setFilterOptions({
                              ...filterOptions,
                              ["order"]: element,
                            });
                          }}
                          onChange={() => {}}
                        />
                        {filterOptions.order === element ? (
                          <b>{element}</b>
                        ) : (
                          element
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              {/* new - filters by type */}
              <div className="orderBy small">
                <h2 className="filter-title">Подреди по вид</h2>
                <div className="all-radio">
                  {orderByType.map((element, i) => {
                    return (
                      <div
                        className="radio"
                        style={{ display: "flex", alignItems: "flex-end" }}
                        key={i}
                      >
                        <input
                          type="radio"
                          value="active"
                          name={`active`}
                          className="radio-btn"
                          id={`active${i}`}
                          checked={filterOptions.orderType === element && true}
                          onClick={() => {
                            setFilterOptions({
                              ...filterOptions,
                              ["orderType"]: element,
                            });
                          }}
                          onChange={() => {}}
                        />
                        {filterOptions.orderType === element ? (<b>{element}</b>) : (element)}
                      </div>
                    );
                  })}
                </div>
              </div>
              {/* new */}
              <div className="fromTime">
                <h2 className="filter-title">Преглед</h2>
                <div className="all-radio">
                  {lookTime.map((elem, i) => {
                    return (
                      <div
                        className="radio"
                        style={{ display: "flex", alignItems: "flex-end" }}
                        key={i}
                      >
                        <input
                          type="radio"
                          value="look"
                          name={`look`}
                          className="radio-btn"
                          id={`look${i}`}
                          checked={filterOptions.look === elem && true}
                          onChange={() => {}}
                          onClick={() => {
                            if (elem === "Дата/период") setOpenCalendar(true);
                            else setOpenCalendar(false);
                            setFilterOptions({
                              ...filterOptions,
                              ["look"]: elem,
                            });
                          }}
                        />
                        {filterOptions.look === elem ? <b>{elem}</b> : elem}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={`datePicker ${openCalendar && "show"}`}>
                <DatePicker
                  onChange={(date) => handleDateChange(date)}
                  selectsStart={true}
                  selected={dates[0]}
                  startDate={dates[0]}
                  endDate={dates[1]}
                  open={openCalendar}
                  customInput={<></>}
                />
              </div>
              <div className="sum">
                <h2 className="filter-title">Сума</h2>
                <div className="sum-inputs">
                  <input
                    required
                    className="from-hour"
                    type="number"
                    id="power"
                    value={filterOptions.sum[0]}
                    maxLength={2}
                    onChange={(event) => {
                      const newValues = filterOptions.sum.slice(0);
                      if (Number(event.target.value) > newValues[1]) {
                        Swal.fire(
                          "Не може първата граница да е по-голяма от втората.",
                          "",
                          "error"
                        );
                        return;
                      }
                      newValues[0] = Number(event.target.value);
                      setFilterOptions({
                        ...filterOptions,
                        ["sum"]: newValues,
                      });
                    }}
                  />
                  <input
                    required
                    className="from-hour"
                    type="number"
                    id="power"
                    value={filterOptions.sum[1]}
                    maxLength={2}
                    onChange={(event) => {
                      const newValues = filterOptions.sum.slice(0);
                      newValues[1] = Number(event.target.value);
                      setFilterOptions({
                        ...filterOptions,
                        ["sum"]: newValues,
                      });
                    }}
                  />
                </div>
                <Slider
                  range
                  allowCross={false}
                  value={filterOptions.sum}
                  onChange={(value) => {
                    setFilterOptions({
                      ...filterOptions,
                      ["sum"]: value,
                    });
                  }}
                  trackStyle={{ backgroundColor: "#00D688", height: 5 }}
                  handleStyle={{ backgroundColor: "#193A4D" }}
                />
              </div>
              <div className="stations-filter">
                <h2 className="filter-title">Станции</h2>
                <div className="all-radio">
                  {location?._stations?.map((station, i) => {
                    return (
                      <div className="radio" key={i}>
                        <input
                          type="checkbox"
                          value="stationName"
                          name="stationName"
                          className="radio-btn"
                          id={`stationName${i}`}
                          checked={filterOptions.selectedStation.includes(
                            station.name
                          )}
                          onChange={() => {}}
                          onClick={() => {
                            const newStations =
                              filterOptions.selectedStation.slice(0);
                            if (!newStations.includes(station.name)) {
                              newStations.push(station.name);
                              setFilterOptions({
                                ...filterOptions,
                                ["selectedStation"]: newStations,
                              });
                            } else {
                              setFilterOptions({
                                ...filterOptions,
                                ["selectedStation"]: newStations.filter(
                                  (statione) => !statione.includes(station.name)
                                ),
                              });
                            }
                          }}
                        />
                        {station.name}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="outlet-filter">
                <h2 className="filter-title">Тип кабел</h2>
                <div className="all-radio">
                  {outletType.map((outlet, i) => {
                    return (
                      <div className="radio" key={i}>
                        <input
                          type="checkbox"
                          value="outeltName"
                          name="outeltName"
                          className="radio-btn"
                          id={`outeltName${i}`}
                          onChange={() => {}}
                          checked={filterOptions.selectedType.includes(outlet)}
                          onClick={() => {
                            const newStations =
                              filterOptions.selectedType.slice(0);
                            if (!newStations.includes(outlet)) {
                              newStations.push(outlet);
                              setFilterOptions({
                                ...filterOptions,
                                ["selectedType"]: newStations,
                              });
                            } else {
                              setFilterOptions({
                                ...filterOptions,
                                ["selectedType"]: newStations.filter(
                                  (statione) => !statione.includes(outlet)
                                ),
                              });
                            }
                          }}
                        />
                        {outlet}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="outlet-filter">
                <h2 className="filter-title">Автомобили</h2>
                <div className="all-radio">
                  {UniqueCars.map((car, i) => {
                    return (
                      <div className="radio" key={i}>
                        <input
                          type="checkbox"
                          value="carName"
                          name="carName"
                          className="radio-btn"
                          id={`carName${i}`}
                          checked={filterOptions.cars?.includes(car?._id)}
                          onChange={() => {}}
                          onClick={() => {
                            const newCars = filterOptions.cars.slice(0);
                            if (!newCars.includes(car?._id)) {
                              newCars.push(car?._id);
                              setFilterOptions({
                                ...filterOptions,
                                ["cars"]: newCars,
                              });
                            } else {
                              const clearDuplicates = newCars.filter(
                                (x) => !x.includes(car?._id) && car?._id
                              );
                              setFilterOptions({
                                ...filterOptions,
                                ["cars"]: clearDuplicates,
                              });
                            }
                          }}
                        />
                        {car?.fullName}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="buttons-container">
              <div
                className="btn"
                onClick={() => {
                  setFilterOptions({
                    order: "",
                    orderType: [],
                    look: "",
                    sum: [0, 100],
                    selectedStation: [],
                    cars: [],
                    selectedType: [],
                  });
                  setOpenCalendar(false);
                  setDates([null, null]);
                  getActivity({
                    location: location_id,
                    noPagination: true,
                    onSuccess: () => {},
                  });
                }}
              >
                Изчисти
              </div>
              <div
                className="small-button-container child"
                onClick={() => {
                  if (filterOptions.sum[0] > filterOptions.sum[1]) {
                    Swal.fire(
                      "Не може втората граница да е по-малка от първата",
                      "",
                      "error"
                    );
                    return;
                  }

                  const type = orderTypeMap[filterOptions?.orderType] ? [orderTypeMap[filterOptions?.orderType]] : []

                  const payload = {
                    location: location_id,
                    minAmount: filterOptions.sum[0],
                    maxAmount: filterOptions.sum[1],
                    stations: [],
                    cars: filterOptions.cars,
                    type: type,
                    noPagination: true,
                    onSuccess: (response) =>
                      (document.getElementById("blurred").style.display =
                        "none"),
                  };

                  if (filterOptions.selectedType.length > 0) {
                    let arr = new Array();
                    filterOptions.selectedType.map((type) => {
                      arr = activity.filter(
                        (act) =>
                          act?.outlet?.type.includes(type) && act?.outlet?._id
                      );
                    });
                    payload.cableTypes = new Array();
                    payload.cableTypes.push(arr[0].outlet._id);
                  }

                  if (filterOptions.order.length > 0)
                    payload.sort = handlePayload("order");
                  if (
                    filterOptions.look.length > 0 &&
                    filterOptions.look.includes("Дата/период")
                  ) {
                    payload.startDate = dates[0];
                    if (dates[1] === null) payload.endDate = dates[0];
                    else payload.endDate = dates[1];
                  }
                  if (filterOptions?.orderType) {
                    payload.type = orderTypeMap[filterOptions?.orderType] ? [orderTypeMap[filterOptions?.orderType]] : []
                  }
                  filterOptions.selectedStation?.map((selectedStationName) => {
                    location?._stations?.map((station) => {
                      if (station.name.includes(selectedStationName)) {
                        payload.stations.push(station._id);
                        return;
                      }
                    });
                  });
                  getActivity(payload);
                }}
              >
                Задай
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
  );
};
export default AdditionalModals;
