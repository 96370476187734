import S3FileUpload from "react-s3"
import { nanoid } from "nanoid"
import { s3Credentials } from "../config/settings"

export const uploadFile = (file) =>
  S3FileUpload.uploadFile(
    new File([file], file?.name || nanoid(), {
      type: file?.type || "",
      lastModified: file?.lastModified || new Date(),
    }),
    s3Credentials
  )

export const uploadFiles = (files) => {
  const promises = []
  Array.from(files).forEach((f) =>
    promises.push(
      S3FileUpload.uploadFile(
        new File([f], f?.name || nanoid(), {
          type: f?.type,
          lastModified: f?.lastModified || new Date(),
        }),
        s3Credentials
      )
    )
  )
  return Promise.all(promises)
}
