/* eslint-disable */
import { FinanceTypes } from '../actions'
import { authTypes } from '../actions'

const initialState = {
    incomes: [],
    expenses: [],
    statistics: {},
    statisticsExpenses: {},
    clientData: {},
    clientDeposits: [],
}

const handleNewFinances = (state,payload) => {
  const newState = {...state}
  let notFound = true;
  if(newState.incomes?.length > 0) {

    newState.incomes.map(oldLocation => {
      if(oldLocation._id.includes(payload?.docs[0]._id)) notFound = false
    })

    if(!notFound) return newState.incomes
    if(notFound){
      newState.incomes = [...newState.incomes, ...payload?.docs]
      return newState.incomes
    }

  }
  else{
    newState.incomes = [...newState.incomes, ...payload?.docs]
    return newState.incomes
  }
}

const finances = (state = initialState, {type,payload}) => {
    switch (type) {
      case FinanceTypes.GET_FINANCE_INCOME_SUCCESS:
          return { 
            ...state, 
            incomes: payload?.page > 1 ? [...state.incomes, ...payload?.docs] : [...payload?.docs]
            // incomes:handleNewFinances(state,payload)
          }
      case FinanceTypes.GET_FINANCE_DEPOSITS_SUCCESS:
          return { 
            ...state, 
            expenses: payload?.page > 1 ? [...state.expenses, ...payload?.docs] : [...payload?.docs]
          }
      case FinanceTypes.GET_FINANCE_DEPOSITS_CLIENTS_SUCCESS:
          return { 
            ...state, 
            expenses: payload?.page > 1 ? [...state.expenses, ...payload?.docs] : [...payload?.docs]
          }
      case FinanceTypes.GET_CLIENT_STATISTICS_SUCCESS:
          return {
            ...state,
            clientData: payload
          }
      case FinanceTypes.GET_CLIENT_DEPOSITS_SUCCESS:
          return {
            ...state,
            clientDeposits: payload?.page > 1 ? [...state.clientDeposits, ...payload?.docs] : [...payload?.docs]
          }
      case FinanceTypes.GET_INCOME_STATISTICS_SUCCESS:
          return{
            ...state,
            statistics:payload
          }
      case FinanceTypes.GET_DEPOSITS_STATISTICS_SUCCESS:
          return{
            ...state,
            statisticsExpenses:payload
          }
      case authTypes.LOG_OUT_SUCCESS:
        return initialState
      default:
        return state
    }
  }
  
  export default finances
  