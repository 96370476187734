/* eslint-disable */
import React, { useRef, useState } from "react";
import "./styles.scss";
import { history } from "../../config/stores";
import {
  createStation,
  createGroup,
  createLocation,
  getLocations,
  deleteLocation,
  editLocation,
  editStations,
  editOutlets,
  createOutlet,
  editUser,
  createUser,
  deleteUser,
  startCharging,
} from "../../actions";
import { connect } from "react-redux";
import * as checkFunctions from "../../utilities/checkInfoOnRequest";
import Swal from "sweetalert2";
import { User } from "../../utilities";
import { toast } from "react-toastify";

const InnerHeader = ({
  first,
  link,
  addStation,
  payload,
  addGroup,
  createGroup,
  createLocation,
  setLocationID,
  stations,
  setStations,
  getLocations,
  createStation,
  location,
  edit,
  editPage,
  deleteLocation,
  editLocation,
  editStations,
  editOutlets,
  createOutlet,
  stationsLength,
  editUserPage,
  AddUserPage,
  editUser,
  user,
  setUser,
  createUser,
  deleteUser,
  group,
  startCharge,
  startCharging,
}) => {
  return (
    <div className="full-upper-container" id="full-upper">
      <div className="left-inner-header">
        <div style={{ display: "flex" }}>
          {/* <div
            className="exit"
            id={"exit"}
            onClick={() => {
              history.push(`${link}`);
            }}
          /> */}
          <p
            style={{ fontSize: "22px", marginTop: "16px",textTransform:"uppercase" }}
          >
            <b style={{ whiteSpace: "nowrap" }}>{first}</b>
          </p>
        </div>
      </div>
      <div className="middle">
        {location?.name?.includes(first) && (
          <div style={{ display: "flex", width: "40%" }}>
            <p
              style={{
                whiteSpace: "nowrap",
                fontSize: "16px",
              }}
            >
              Общ брой зареждания: <b>{location?.statistics?.totalCharges}</b>
            </p>
            <p
              style={{
                marginLeft: "30%",
                whiteSpace: "nowrap",
                fontSize: "16px",
              }}
            >
              Oбщ приход:{" "}
              <b>
                {Math.round(location?.statistics?.totalAmount * 100) / 100} лв.
              </b>
            </p>
          </div>
        )}
      </div>
      <div className="right-inner-header">
        {editPage && (
          <>
            {User.isAdmin === true && (
              <div
                className="button-container deleteStation"
                onClick={() => {
                  Swal.fire({
                    title: "Изтриване на локация",
                    text: "Сигурни ли стe, че искате да изтриете тази локация?",
                    showDenyButton: true,
                    confirmButtonText: "Изтрий локация",
                    confirmButtonColor: "#F54280",
                    denyButtonColor: "#FFFFFF",
                    denyButtonText: `Назад`,
                    denyButtonTextColor: "black",
                    reverseButtons: true,
                    customClass: "mine",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      deleteLocation(window.location.pathname.split("/")[3]);
                      Swal.fire("Успешно изтриване.", "", "success");
                      history.push("/");
                    }
                  });
                }}
              >
                Изтрий
              </div>
            )}

            <div
              className="button-container saveStation"
              onClick={() => {
                if (!User.isAdmin === true) {
                  editLocation({
                    payload: payload,
                    _id: stations[0].location,
                    onSuccess: () => {
                      Swal.fire("Успешно редактиране", "", "success");
                      history.push("/");
                    },
                    onError: () => {
                      Swal.fire(
                        "Някое от полетата не е попълнено",
                        "",
                        "error"
                      );
                    },
                  });
                } else {
                  setStations(checkFunctions.stringToNumber(stations));
                  if (stations.length < 1) {
                    Swal.fire(
                      "Не може да оставите локация без да има станция към нея.",
                      "",
                      "error"
                    );
                    return;
                  }
                  if (
                    checkFunctions.checkStationsOnEdit(stations).indexOf(0) ===
                    -1
                  ) {
                    editLocation({
                      payload: payload,
                      _id: stations[0].location,
                      onSuccess: () => {
                        const newStations = stations.slice(0);
                        if (newStations.length > stationsLength.current) {
                          newStations
                            .slice(stationsLength.current, stations.length)
                            .map((station) => {
                              delete Object.assign(station, {
                                ["outlets"]: station["_outlets"],
                              })["_outlets"];
                            });
                          createStation({
                            payload: newStations.slice(
                              stationsLength.current,
                              stations.length
                            ),
                            onSuccess: (response) => {
                              response.payload.map((res) => stations.push(res));
                              createStation = true;
                            },
                            onError: () => {
                              Swal.fire({
                                icon: "error",
                                title: "Упс...",
                                text: "Някое от полетата не е попълнено правилно.",
                              });
                              newStations
                                .slice(stationsLength.current, stations.length)
                                .map((station) => {
                                  delete Object.assign(station, {
                                    ["_outlets"]: station["outlets"],
                                  })["outlets"];
                                });
                              return;
                            },
                          });
                        }
                        editStations({
                          payload: stations,
                          onSuccess: (response) => {
                            var allOutlets = [];
                            stations.map((station) => {
                              allOutlets = allOutlets.concat(station._outlets);
                            });

                            if (
                              allOutlets.length === 1 &&
                              allOutlets[0] === undefined
                            ) {
                              Swal.fire("Успешно редактиране", "", "success");
                              history.push("/");
                              return;
                            }
                            var outletsToCreate = [];
                            allOutlets.map((outlet, i) => {
                              if (outlet === undefined) allOutlets.splice(i, 1);
                              else {
                                if (outlet?._id === undefined) {
                                  outletsToCreate.push(outlet);
                                  /* */
                                }
                              }
                            });
                            if (outletsToCreate.length > 0) {
                              createOutlet({
                                station: outletsToCreate[0].station,
                                outlets: outletsToCreate,
                                onSuccess: (response) => {
                                  allOutlets.push(response.payload);
                                },
                                onError: () => {},
                              });
                            }
                            editOutlets({
                              payload: allOutlets,
                              onSuccess: () => {
                                Swal.fire(
                                  "Успешно редактиране.",
                                  "",
                                  "success"
                                );
                                history.push("/");
                              },
                            });
                          },
                          onError: (err) => {
                            toast.error(err?.message?.response)
                            return;
                          },
                        });
                      },
                      onError: () => console.error("ne локация"),
                    });
                  } else
                    Swal.fire({
                      icon: "error",
                      title: "Упс...",
                      text: "Някое от полетата в Станции към локация не е попълнено правилно.",
                    });
                }
              }}
            >
              Запази
            </div>
          </>
        )}

        {editUserPage === 1 && (
          <>
            <div
              className="button-container deleteStation"
              onClick={() => {
                Swal.fire({
                  title:
                    "Сигурни ли сте, че искате да изтриете този потребител?",
                  showDenyButton: true,
                  confirmButtonText: "Да, сигурен съм",
                  denyButtonText: `Не`,
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteUser({
                      _id: user._id,
                      onSuccess: () => {
                        Swal.fire("Успешно изтриване.", "", "success");
                        history.push("/users");
                      },
                    });
                  }
                });
              }}
            >
              Изтрий
            </div>
            <div
              className="button-container saveStation"
              onClick={() => {
                if (user.phoneNumber.length !== 9)
                  Swal.fire({
                    icon: "error",
                    title: "Упс...",
                    text: "Телефоният номер не е с правилен размер.",
                  });
                else {
                  setUser({
                    ...user,
                    ["phoneNumber"]: `+359${user.phoneNumber}`,
                    ["role"]:user?.role
                  });
                  editUser({
                    info: {
                      name: user.name,
                      role:user?.role
                    },
                    _id: user.id,
                  });
                }
              }}
            >
              Запази
            </div>
          </>
        )}

        {AddUserPage === 1 && (
          <div
            className="button-container addStation"
            style={{ marginRight: "2%" }}
            onClick={() => {
              let ready = true;
              payload.map((user, i) => {
                if (user.phoneNumber.length !== 9) {
                  Swal.fire({
                    icon: "error",
                    title: "Упс...",
                    text: `Телефоният номер на потребител ${
                      i + 1
                    } не е с правилен размер.`,
                  });
                  ready = false;
                } else {
                  user.phoneNumber = `+${user.phoneNumber}`;
                }
              });
              if (ready) {
                createUser({
                  payload: payload,
                  onSuccess: () => {
                    Swal.fire("Успешно създаване.", "", "success");
                    history.push("/users");
                  },
                  onError: (err) => {
                    if (err.response.message.includes("is not valid")) {
                      Swal.fire(
                        "Въведеният телефонен номер не е валиден.",
                        "",
                        "error"
                      );
                    } else
                      Swal.fire(
                        "Същестува вече потребител със този телефонен номер.",
                        "",
                        "error"
                      );
                  },
                });
              }
            }}
          >
            Запази
          </div>
        )}

        {addStation === 1 ? (
          <div
            className="button-container addStation"
            onClick={() => {
              setStations(checkFunctions.stringToNumber(stations));
              if (checkFunctions.checkStations(stations).indexOf("0") === -1) {
                createLocation({
                  payload: payload,
                  onSuccess: (response) => {
                    setLocationID(response.payload.id);
                    stations.map((station) => {
                      station.location = response.payload.id;
                    });
                    createStation({
                      payload: stations,
                      onSuccess: () => {
                        Swal.fire("Успешно създаване.", "", "success");
                        history.push("/");
                      },
                      onError: () => {
                        Swal.fire({
                          icon: "error",
                          title: "Упс...",
                          text: "Някое от полетата не е попълнено правилно.",
                        });
                      },
                    });
                  },
                  onError: () => {
                    Swal.fire({
                      icon: "error",
                      title: "Упс...",
                      text: "Някое от полетата в Основна информация не е попълнено правилно.",
                    });
                  },
                });
              }
              getLocations({
                pageNumber: "1",
                pageSize: "60",
                sortBy: "",
                noPagination: false,
                search: "",
              });
            }}
          >
            Запази
          </div>
        ) : (
          addGroup === 1 && (
            <div
              className="button-container addStation"
              onClick={() => {
                createGroup(payload);
                history.push("/groups");
              }}
            >
              Добави
            </div>
          )
        )}

        {edit && (
          <div
            className="edit-logo"
            onClick={() => {
              if (window.location.href.split("/")[3] === "group") {
                history.push({
                  pathname: `/edit/group/${window.location.href.split("/")[4]}`,
                  state: group,
                });
              } else
                history.push({
                  pathname: `/edit/location/${location._id}`,
                  state: location,
                });
            }}
          />
        )}
        {startCharge && (
          <div
            className="button-container addStation"
            style={{ width: "19%", fontSize: "14px", marginRight: "5%" }}
            onClick={() => {
              startCharging({
                ...payload,
                onSuccess: () => {
                  toast.success("Charging started successfully",{autoClose:3000});
                  history.push(link);
                },
              });
            }}
          >
            Стартирай
          </div>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createLocation: (payload) => dispatch(createLocation(payload)),
  createStation: (payload) => dispatch(createStation(payload)),
  deleteLocation: (payload) => dispatch(deleteLocation(payload)),
  editLocation: (payload) => dispatch(editLocation(payload)),
  createGroup: (payload) => dispatch(createGroup(payload)),
  getLocations: (payload) => dispatch(getLocations(payload)),
  editStations: (payload) => dispatch(editStations(payload)),
  editOutlets: (payload) => dispatch(editOutlets(payload)),
  createOutlet: (payload) => dispatch(createOutlet(payload)),
  editUser: (payload) => dispatch(editUser(payload)),
  createUser: (payload) => dispatch(createUser(payload)),
  deleteUser: (payload) => dispatch(deleteUser(payload)),
  startCharging: (payload) => dispatch(startCharging(payload)),
});

export default connect(null, mapDispatchToProps)(InnerHeader);
