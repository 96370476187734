/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom'
import { history } from "../../../config/stores";
import { createGroup, getLocations, getUsers } from "../../../actions";
import { connect } from "react-redux";
import GroupInfo from "./GroupInfo";
import Swal from "sweetalert2";
import "../styles.scss";
import BusinessUsers from "./BusinessUsers";
import LocationToGroup from "./LocationToGroup";
import LocationToGroupNew from "./LocationToGroupNew";

const AddGroup = ({ createGroup, getLocations, locations,allUsers,getUsers }) => {
  const { state } = useLocation()
  const locationsData = state?.data || []
  const statePayloadData = state?.payload || {}
  const locationsTab = state?.tab || ''
  const [ selectedTab, setSelectedTab ] = useState("info");
  const [ selectedLocation, setSelectedLocation ] = useState(0);
  const [ selectedLocations, setSelectedLocations ] = useState([]);

  const [ inputUsers, setInputUsers ] = useState([{
    name:"",
    phoneNumber:""
}])

const [visibleForUsers,setVisibleForUsers] = useState([{
  name:"",
  phoneNumber:"",
}])

  const [multipleLocations, setMultipleLocations] = useState(true);
  const [payload, setPayload] = useState({
    name: "",
    firm: "",
    bulstat: "",
    eik: "",
    mol: "",
    iban: "",
    contactPerson: "",
    email: "",
    address: "",
    activeStatus: "",
    description: "",
    users: [],
    forAllLocations: true,
    specialPrice: null,
    initialFee: null,
    parkingFee: null,
    locations: [
      {
        location: "",
        specialPrice: null,
        initialFee: null,
        parkingFee: null
    },
      // {
      //   locationId: "",
      //   locationName:"",
      //   sharedProfit: false,
      //   partOfProfit: 0,
      //   paymentDate: 0,
      //   specialPrices: false,
      //   visible: false,
      //   visibleFor: [],
      //   stations: [
      //   ]
      // }
    ]
  });
  const [eik, setEik] = useState(false);
  // const [eik, setEik] = useState(payload?.eik?.length ? true : false);

  useEffect(() => {
    getLocations({
      pageNumber: "1",
      pageSize: "60",
      sortBy: "",
      noPagination: false,
      search: "",
      onSuccess: () => {},
      onError: () => alert("Имаше проблем с изпращането на съобщение,опитайте отново!")
    })
    getUsers({
      pageSize: "500",
      sortBy: "",
      noPagination: true,
      onSuccess: () => {}
  })

  if (locationsTab === "locations") setSelectedTab("locations")
  }, [])

  useEffect(() => {
    if (Object.values(statePayloadData)?.length) {
      setPayload({...statePayloadData})

      if (statePayloadData?.users?.length && allUsers?.length) {
        const newUsers = statePayloadData.users.map(el => {
          let user = allUsers.find(user => user._id === el)
          return { name: user.name, phoneNumber: user.phoneNumber.slice(4) }
         })
         setInputUsers(newUsers)
      }

      setMultipleLocations(statePayloadData.forAllLocations)
      if (statePayloadData?.bulstat) setEik(false)
      else setEik(true)
    }
    
    if (multipleLocations && payload.forAllLocations && locationsData?.length) {
      let newPayload = Object.values(statePayloadData)?.length ? {...statePayloadData} : {...payload}
      newPayload.locations = locationsData.map(el => {
        return {
                location: el._id,
                name: el?.name,
                specialPrice: el?.specialPrice || 0,
                initialFee: el?.initialFee || 0,
                parkingFee: el?.parkingFee || 0
              }
      })
      newPayload.forAllLocations = false
      setPayload(newPayload)
      setSelectedLocations([...newPayload.locations])
      setSelectedTab("locations")
      setMultipleLocations(false)
    }

  }, [statePayloadData, locationsData, allUsers])


  const checkInfoOnLocations = () => {
   return payload.locations.map(location => {
      if(location.location === ""){
        Swal.fire('Няма избрана локация от списъка.','','error')
        return 0;
      } 
      else if(!location.specialPrice){
        Swal.fire('Невъзмножно е дяла от печалбата да е повече от 100%.','','error')
        return 0;
      } 
      else if(!location.initialFee){
        Swal.fire('Не съществува такава дата.','','error')
        return 0;
      }
      else if(!location.parkingFee){
        Swal.fire('Не съществува такава дата.','','error')
        return 0;
      }
      else return 1;
    }).toString()
  }

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const validateFields = (tab) => {
    if (selectedTab === "info") {
      let ready = true;
      // Object.keys(payload).map((key,i) => {
      //     if(key !== "description" && key !== "bulstat" && key !== "eik"){
      //       if (payload[key] === ""){
      //         Swal.fire({
      //             icon: 'error',
      //             title: 'Упс...',
      //             text: "Някое от полетата не е попълнено.",
      //           })
      //           ready = false; 
      //           return;
      //       }
      //    }
      //    if(eik === true && key === "eik"){
      //     if(payload[key].length !== 9){
      //       Swal.fire({
      //           icon: 'error',
      //           title: 'Упс...',
      //           text: "Въведеният ЕИК не е правилен.",
      //         })
      //         ready = false;
      //     } 
      //    }
      //    if(eik === false && key==="bulstat"){
      //     if(payload[key].length !== 9){
      //       Swal.fire({
      //           icon: 'error',
      //           title: 'Упс...',
      //           text: "Въведеният Булстат не е правилен.",
      //         })
      //         ready = false;
      //     } 
      //    }
        
      //     if (key ==="email" && !validateEmail(payload[key])){
      //         Swal.fire({
      //             icon: 'error',
      //             title: 'Упс...',
      //             text: "Въведеният имейл не е правилен.",
      //           })
      //           ready = false;
      //           return;
      //     }
      // })
      ready && tab && setSelectedTab(tab) 
    }
    else if (selectedTab === "users") {
      let foundUsers = Array();
      let notFoundUsers = Array();
      inputUsers.forEach(inputUser => {
        let valid = false;
        allUsers.forEach(user => {
          if(user.phoneNumber.includes(inputUser.phoneNumber)){
             valid = true;
             foundUsers.push(user._id)
          }
        })
        if(!valid) notFoundUsers.push("+359".concat(inputUser.phoneNumber))
      });
    if (!findVisibleForUsers()) return
      setPayload({
        ...payload,
        ["users"]: foundUsers
      })
      // if(inputUsers?.[0]?.phoneNumber && notFoundUsers?.length > 0) Swal.fire(`Не съществуват потребители с телефонни номера: ${notFoundUsers.map(number => number + " ")}.`,'','error')
      if (tab) setSelectedTab(tab)
    }
    else if (selectedTab === "locations") {
      if (tab) setSelectedTab(tab)
    }
  }

  const hasDupticateLocations = (array) => {
    var values = Object.create(null);
    for (var i = 0; i < array.length; ++i) {
        var value = array[i].locationId;
        if (value in values) {
            return true;
        }
        values[value] = true;
    }
    return false;
}

const displayErrors = (err) => {
  if (!err) return

  if (err?.includes('dup key:')) {
    let error = err.substring(err.indexOf('"') + 1, err.lastIndexOf('"'));
    if (err?.includes('bulstat') || err?.includes('eik')) return 'ЕИК/Булстат вече съществува'
    else return `${error} вече съществува`
  }
  else if (err?.includes("can't be empty")) {
    return 'Моля, попълнете всички полета'
  }
  else if (err?.includes("activeStatus' must be 'boolean' type!")) {
    return 'Моля, изберете статус в "Основна информация"'
  }
}

const findVisibleForUsers = () => {
  let FoundUsersID = Array();
  let notFoundUsers = Array();
  
  inputUsers.map(inputUser => {
    let found = false;
    // allUsers.map(user => {
    //   if(inputUser.phoneNumber.length > 0){
    //     if(user.phoneNumber.includes(inputUser.phoneNumber)){
    //       found = true;
    //       FoundUsersID.push(user._id)
    //     } 
    //   }
    //   else found = true;
    // })
    allUsers.map(user => {
      if(inputUser.phoneNumber.length > 0){
        if(user.phoneNumber=== "+359" + inputUser.phoneNumber){
          found = true;
          FoundUsersID.push(user._id)
        } 
      }
      else found = false;
    })
    if(!found) notFoundUsers.push(inputUser.phoneNumber)
  })
  // if(notFoundUsers.length > 0){ 
  //   Swal.fire(`Не същестуват потребители със следните номера: ${notFoundUsers.map(number => number)}.`)
  //   return 0;
  // }
      setPayload({
        ...payload,
        ["users"]:FoundUsersID
      })
    return 1;
    
}

// +359 893847618
// +359 883376412

  return (
    <>
    <div className="groups-add-container">
      <div className="full-upper-container" id="full-upper">
        <div className="left-inner-header" style={{justifyContent:"flex-start"}}>
          <div
            className="exit"
            id={"exit"}
            onClick={() => {
              history.push("/groups");
            }}
          />
          <p>ДОБАВЯНЕ НА БИЗНЕС ГРУПА</p>
        </div>
        <div className="right-inner-header">
          {/* {selectedTab === "locations" && ( */}
            <div
              className="button-container addStation"
              style={{ marginRight: "2%" }}
              onClick={() => {
                findVisibleForUsers()
                // if(checkInfoOnLocations().indexOf("0") === -1 && findVisibleForUsers() === 1){
                if(findVisibleForUsers() === 1) {
                  let finalPayload = {...payload}

                  if (inputUsers?.length) {
                    let newUsers = []
                    inputUsers.forEach(inputUser => {
                      let foundUser = allUsers.find(user => user.phoneNumber === "+359" + inputUser.phoneNumber)
                      if (foundUser?._id) newUsers.push(foundUser._id)
                    });
                    finalPayload.users = newUsers
                  }

                  if (finalPayload.forAllLocations) finalPayload.locations = []
                  else if (!finalPayload.forAllLocations) {
                    let newLocations = finalPayload.locations.slice()
                    newLocations.map(location => {
                      if (location.specialPrice === 0) location.specialPrice = null
                      if (location.initialFee === 0) location.initialFee = null
                      if (location.parkingFee === 0) location.parkingFee = null
                    })
                  }
                  // if (!finalPayload?.users?.length) return Swal.fire("Моля, попълнете потребители и натиснете 'Продължи'","","error")
                  // else if (!finalPayload.forAllLocations) {
                  //   finalPayload.specialPrice = null
                  //   finalPayload.initialFee = null
                  //   finalPayload.parkingFee = null
                  // }
                  if(!finalPayload?.eik) delete finalPayload.eik
                  if(!finalPayload?.bulstat) delete finalPayload.bulstat

                  createGroup({
                    payload: finalPayload,
                    onSuccess:() => {
                      Swal.fire("Успешно създаване.","","success")
                      history.push("/groups")
                    },
                    onError:(res) => {
                      Swal.fire(displayErrors(res?.response?.message))
                      // Swal.fire("Имаше грешка при създаването на бизнес групата.Моля проверете дали информацията е правилна.","","error")
                    }
                  })
                }
              }}
            >
              Запази
            </div>
        </div>
      </div>

      <div className="lower-container">
        <div className="left-container wide">
          <div
            className={`first-left-container wide ${selectedTab === "info" && "selected"
              }`}
            onClick={() => {
              validateFields("info");
            }}
          >
            <h3
              className={`add-station  ${selectedTab === "info" && "selected"}`}
            >
              Основна информация
            </h3>
            <div className="arrow-container">
              <div
                className={`arrow-right  ${selectedTab === "info" && "selected"
                  }`}
              />
            </div>
          </div>
          <div
            className={`firstt-left-container wide ${selectedTab === "users" && "selected"
              } `}
            onClick={() => {
              validateFields("users");
            }}
          >
            <h3
              className={`add-station  ${selectedTab === "users" && "selected"
                }`}
            >
              Потребители
            </h3>
            <div className="arrow-container" style={{ marginLeft: "17%" }}>
              <div
                className={`arrow-right  ${selectedTab === "users" && "selected"
                  }`}
              />
            </div>
          </div>
          <div className={`location-to-group wide ${selectedTab === "locations" && "selected"}`}>
            <div
              className={`firstt-left-container wide no-margin ${selectedTab === "locations" && "selected"
                } `}
              onClick={() => {
                validateFields("locations");
              }}
            >
              <h3
                className={`add-station  ${selectedTab === "locations" && "selected"
                  }`}
              >
                Локации към групата
              </h3>
              <div className="arrow-container" style={{ marginLeft: "15%" }}>
                <div
                  className={`arrow-right margin ${selectedTab === "locations" && "selected"
                    }`}
                />
              </div>
            </div>

            {selectedTab === "locations" && 
              <div className={`flex-wrapper col ${multipleLocations ? 'green' : ''}`}>
                <div className="section flex-wrapper top">
                  <h3>За всички локации</h3>
                  <div className="checkboxes-and-radios">
                        <label
                          className="switch"
                          onClick={() => {
                            if (document.getElementById("checkbox").checked === true) {
                              setMultipleLocations(false);
                              history.push('/locations/select', { payload: payload })

                              const newPayload = {...payload};
                              newPayload.forAllLocations = false;
                              setPayload({ ...newPayload })
                            } else {
                              const newPayload = {...payload};
                              newPayload.forAllLocations = true;
                              newPayload.locations = []
                              setPayload({ ...newPayload })
                              setMultipleLocations(true);
                            }
                          }}
                        >
                          <input
                            type="checkbox"
                            id="checkbox"
                            // checked={payload.locations[selectedLocation]?.sharedProfit === true}
                            checked={multipleLocations}
                            onClick={() => {
                              if (document.getElementById("checkbox").checked === true) {
                                setMultipleLocations(false);
                                history.push('/locations/select', { payload: payload })
                              } else
                              setMultipleLocations(true);

                              // const newLocations = payload.locations.slice(0);
                              // if(newLocations[selectedLocation].sharedProfit){
                              //   newLocations[selectedLocation].sharedProfit = false;
                              //   newLocations[selectedLocation].partOfProfit = 0;
                              //   newLocations[selectedLocation].paymentDate = 0;
                              // }
                              // else {
                              //   newLocations[selectedLocation].sharedProfit = true;
                              // }
                            }}
                          />
                          <div className="slider round">
                            <span className="on" id="on">
                              <b>Да</b>
                            </span>
                            <span className="off" id="off">
                              <b>Не</b>
                            </span>
                          </div>
                        </label>
                  </div>
                </div>

                {!multipleLocations ?
                <div className="section flex-wrapper border margin-top">
                  <h5 className="text light">Избрани:</h5>

                  <div className="flex-wrapper">
                    <p className="text bold">{`${selectedLocations?.length} ${selectedLocations?.length === 1 ? 'локация' : 'локации'}`}</p>
                    <img 
                    className="settings-icon" 
                    src={require(("../../../assets/icons/settings.svg"))}
                    onClick={() => history.push('/locations/select', { data: locationsData, payload: payload })}
                    />
                  </div>
                </div> : <></>}
              </div>
            }
          </div>
          
          
        </div>
        {selectedTab === "info" ? (
          <GroupInfo payload={payload} setPayload={(v) => setPayload(v)} eik={eik} setEik={(v) => setEik(v)} />

        ) : selectedTab === "users" ? (
          <BusinessUsers payload={payload} setPayload={(v) => setPayload(v)} setSelectedTab={setSelectedTab} inputUsers={inputUsers} setInputUsers={setInputUsers} allUsers={allUsers} findVisibleForUsers={() => findVisibleForUsers()}/>

        ) : selectedTab === "locations" ? (
          // <LocationToGroup payload={payload} setPayload={setPayload} setSelectedTab={setSelectedTab} locations={locations} selectedLocation={selectedLocation} visibleForUsers={visibleForUsers} setVisibleForUsers={setVisibleForUsers}/>
          <LocationToGroupNew payload={payload} setPayload={(v) => setPayload(v)} setSelectedTab={() => setSelectedTab()} locations={locations} selectedLocations={selectedLocations} setSelectedLocations={(v) => setSelectedLocations(v)} multipleLocations={multipleLocations}/>
        ) : (
          ""
        )}
      </div>
    </div>
    </>
  );
};


const mapStateToProps = (state) => ({
  locations: state.locations.locations,
  allUsers:state.login.allUsers
});

const mapDispatchToProps = (dispatch) => ({
  createGroup: (payload) => dispatch(createGroup(payload)),
  getLocations: (payload) => dispatch(getLocations(payload)),
  getUsers: (payload) => dispatch(getUsers(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddGroup);
