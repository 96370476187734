import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getStatisticsActive, getStatisticsSuccess } from "../../../actions"
import { Statistics } from "../../"
import "./styles.scss"
import Popup from "reactjs-popup"

const Active = () => {
    const dispatch = useDispatch()
    const { loading, docs, total } = useSelector(({ statistics }) => statistics.active)
    const data = useMemo(() => docs?.map(({ _id, count, users }) => ({ value: _id || "Unknown", label: count, info: users })), [docs])

    useEffect(() => {
        dispatch(getStatisticsSuccess({ type: "active", loading: true }))
        dispatch(getStatisticsActive())
    }, [dispatch])

    const [modal, setModal] = useState(null)

    return <div className="statistics-active-container col">
        <div className="statistics-active-header stats-header row">
            <h2><span>{total}</span> активни потребители в момента</h2>
        </div>
        <div className="statistics-active-content">
            {loading
                ? <Statistics.LoaderInline />
                : <Statistics.Horizontal data={data} handleInfo={setModal} />}
        </div>
        {<Popup modal open={!!modal} onClose={() => setModal(null)}  >
            <div className="modal-statistics-active-container">
                <div className="modal-statistics-active-header row">
                    <h2>Информация за локацията</h2>
                    <div className="icon icon-close" onClick={() => setModal(null)} />
                </div>
                <div className="modal-statistics-active-content row">
                    <h3>Локация: <span>{modal?.value}</span></h3>
                    <h3>Потребители: <span>{modal?.info?.length}</span></h3>
                </div>
                <div className="modal-statistics-active-table">
                    <div className="row row-header">
                        <span>Име</span>
                        <span>Телефонен номер</span>
                    </div>
                    <div className="scroll-conatiner">
                        {modal?.info?.map(({ _id, name, phoneNumber }) => <div key={_id} className="row row-content">
                            <span>{name}</span>
                            <span>{phoneNumber}</span>
                        </div>)}
                    </div>
                </div>
            </div>
        </Popup>}
    </div>
}

export default Active