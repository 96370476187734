import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import Swal from "sweetalert2"
import { deleteWebsiteArticle, getWebsiteArticle } from "../../actions"
import { history } from "../../config/stores"
import { Inputs } from "../../components"
import "./styles.scss"

const Article = () => {
    const dispatch = useDispatch()
    const { _id } = useParams()

    const [article, setArticle] = useState({})
    useEffect(() => { dispatch(getWebsiteArticle({ payload: _id, onSuccess: (res) => setArticle(res) })) }, [dispatch, _id])

    const handleDelete = () => Swal.fire({
        title: 'Сигурни ли сте?',
        text: "Статията ще бъде изтрита завинаги!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#0099ff',
        cancelButtonText: 'Откажи',
        confirmButtonText: 'Изтрий!',
        reverseButtons: true,
    }).then((result) => {
        if (result.isConfirmed) dispatch(deleteWebsiteArticle({
            payload: _id,
            onSuccess: () => history.goBack()
        }))
    })

    return <div className="screen-article-container">
        <div className="screen-article-inner-container col">
            <div className="screen-article-header row">
                <div className="icon icon-close" onClick={() => history.goBack()} />
                <h2>преглед на статия</h2>
                <div className="row row-buttons">
                    <Inputs.Toggle
                        label="Добави на първо място"
                        value={article?.pinned} />
                    <Inputs.Raised text="Изтрий" reversed onClick={handleDelete} />
                    <Inputs.Raised text="Редактирай" onClick={() => history.push(`/website/form?_id=${_id}`)} />
                </div>
            </div>
            <div className="screen-article-content">
                <p>Заглавие</p>
                <div className="title"><span>{article.title}</span></div>
                <p>Снимка на корица</p>
                <div className="image">{article?.coverPhoto?.name}</div>
                <p>Снимки на отворена статия</p>
                <div className="row row-photos">
                    {article?.photos?.map(({ name }, i) => <div key={i} className="image">{name}</div>)}
                </div>
                <p>Текст</p>
                <div className="text">
                    <div className="text-container" dangerouslySetInnerHTML={{ __html: article?.text }} />
                </div>
            </div>
        </div>
    </div>
}

export default Article