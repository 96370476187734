import React from "react";
import "../styles.scss";
import "rc-slider/assets/index.css";
import "moment/locale/bg";
import moment from "moment";

const RightPart = ({ setSelectedTab, getActivity, activity, handleDuration, location_id }) => {

  return (
    <div className="right-station-container">
      <div className="inner-upper-container">
        <h2
          style={{
            fontSize: "22px",
            marginTop: "5%",
            marginLeft: "2%",
            width: "30%",
          }}
        >
          <b>Активност</b>
        </h2>
        <div
          className="filter-button"
          onClick={() => {
            document.getElementById("blurred").style.display = "block";
            setSelectedTab("filtration");
            getActivity({
              location: location_id,
              noPagination: true,
              onSuccess: () => {},
            });
          }}
        />
      </div>
      <div className="full-lower-container scroll-width-thin">
        {activity?.map((info, i) => {
          return (
            <div className="lower-section" key={i}>
              <div className="flex-line">
                <p className="tab-text" style={{ fontSize: "18px" }}>
                  <b style={{ marginLeft: "1%" }}>{moment(info?.startAt).format("DD MMMM ‘YY")}</b>
                </p>
                {info?.type && <p className={`status ${info?.type === "reservation" ? "orange" : "green"}`}>{info?.type === "reservation" ? "Резервация" : "Зареждане"}</p>}
              </div>
              <div className="second-line">
                <p className="smaller-tab-text" style={{ fontSize: window.innerWidth < 1600 ? "16px" : "18px" }}>
                  Станция: <b style={{ marginLeft: "1%" }}>{info?.station?.name}</b>
                </p>
                <p className="smaller-tab-text" style={{ fontSize: window.innerWidth < 1600 ? "16px" : "18px" }}>
                  Изход: <b style={{ marginLeft: "1%" }}>{info?.outlet?.name}</b>
                </p>
              </div>
              <div className="third-line">
                <p className="smaller-tab-text" style={{ fontSize: window.innerWidth < 1600 ? "16px" : "18px" }}>
                  Начало: <b style={{ marginLeft: "1%" }}>{moment(info?.startAt).format("HH:mm")}</b>
                </p>
                <p className="smaller-tab-text" style={{ fontSize: window.innerWidth < 1600 ? "16px" : "18px" }}>
                  Край: <b style={{ marginLeft: "1%" }}>{moment(info?.endAt).format("HH:mm")}</b>
                </p>
                <p className="smaller-tab-text" style={{ fontSize: window.innerWidth < 1600 ? "16px" : "18px" }}>
                  Време: <b style={{ marginLeft: "1%" }}>{handleDuration(info?.duration)}</b>
                </p>
              </div>
              <div className="fourth-line">
                <p className="smaller-tab-text" style={{ fontSize: window.innerWidth < 1600 ? "16px" : "18px" }}>
                  Сума: <b style={{ marginLeft: "1%", color: "#00D688" }}>{Math.round(info?.amountForPay * 100) / 100}лв.</b>
                </p>
                <p className="smaller-tab-text" style={{ fontSize: window.innerWidth < 1600 ? "16px" : "18px" }}>
                  Потребител: <b style={{ marginLeft: "1%" }}>{info?.user?.phoneNumber}</b>
                </p>
              </div>
              {info?.car?.fullName && (
                <p className="tab-text" style={{ fontSize: window.innerWidth < 1600 ? "16px" : "18px" }}>
                  Автомобил: <b style={{ marginLeft: "1%" }}>{info?.car?.fullName}</b>
                </p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default RightPart;
