import { carsTypes } from "../actions";

const initialState = {
  cars: [],
  brands: [],
  models: [],
  types:[]
};

const handleNewCars = (state, payload, noPagination) => {
  const newState = { ...state };
  let notFound = true;
  if (newState.cars.length > 0) {
    if (payload.page === 1) {
      newState.cars = payload?.docs;
      return newState.cars;
    }

    newState.cars.forEach((oldCar) => {
      if (oldCar._id.includes(payload?.docs[0]._id)) notFound = false;
    });

    if (!notFound) return newState.cars;
    if (notFound) {
      newState.cars = [...newState.cars, ...payload?.docs];
      return newState.cars;
    }
  }
  if (payload.totalDocs === payload.limit) {
    return payload.docs;
  } else {
    newState.cars = [...newState.cars, ...payload?.docs];
    return newState.cars;
  }
};

const cars = (state = initialState, { type, payload, noPagination }) => {
  switch (type) {
    case carsTypes.GET_ALL_CARS_SUCCESS:
      return {
        ...state,
        cars: handleNewCars(state, payload, noPagination),
      };
    case carsTypes.GET_ALL_BRANDS_SUCCESS:
      return {
        ...state,
        brands: payload.docs,
      };
    case carsTypes.GET_ALL_MODELS_SUCCESS:
      return {
        ...state,
        models: payload.docs,
      };
      case carsTypes.GET_ALL_TYPES_SUCCESS:
      return {
        ...state,
        types: payload.docs,
      };
    default:
      return state;
  }
};

export default cars;
