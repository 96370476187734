import React from 'react';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';
import "./styles.scss"

const BarChart = ({ data, month, color }) => {
    if (!month) month = moment().format('YYYY-MM-DD')
    return <div className="graph-bar-chart-container">
        <ReactECharts option={{
            color: ['#00D297', '#AAF4DE',],
            legend: {
                orient: 'vertical',
                left: 'right',
                top: '0',
            },
            xAxis: {
                type: 'category',
                data: [...Array(moment(month, 'YYYY-MM-DD').daysInMonth())].map((x, i) => i + 1)
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    data: [...Array(moment(month, 'YYYY-MM-DD').daysInMonth())].map((x, i) => data[i + 1] || 0),
                    type: 'bar',
                    itemStyle: { color },
                }
            ],
            tooltip: {},
            grid: { left: 24, top: 32, right: 16, bottom: 32 }
        }} />
    </div>
}

export default BarChart