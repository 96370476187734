/* eslint-disable */
import React, { useState } from "react";
import "./styles.scss";
import { ic_keyboard_arrow_down } from "react-icons-kit/md/ic_keyboard_arrow_down";
import { Icon } from "react-icons-kit";
import { history } from "../../config/stores";
import HeaderFilterRow from "../HeaderFilterRow/HeaderFilterRow";
import { connect } from "react-redux";
import { getStationByID } from "../../actions";
import InnerLocations from "./InnerLocations";
import InnerShare from "./InnerShare";
import InnerUsers from "./InnerUsers";

const fieldNames = ["name", "firm", "includedLocations", "specialPrice", "initialFee", "parkingFee", "users"];

const GroupsGrid = ({ groups, fetchMoreGroups, tableRef, sortBy, setSortBy, sortValue, setSortValue, type, setType, sortData, setHasMore, copyGroups, setCopyGroups, getGroups }) => {
  const [innerTabSelected, setInnerTabSelected] = useState("");
  const [currentGroup, setCurrentGroup] = useState({});

  //for filtration
  const [show, setShow] = useState(false);
  const [coordinates, setCoordinates] = useState({
    display: "none",
    x: "0%",
    y: "0%",
  });

  const [selectedHeader, setSelectedHeader] = useState();

  const headerElements = [
    {
      name: "Име",
      type: "name",
      containerType: "small",
      query: { filter: "name", options: groups.map((x) => x?.name) },
    },
    {
      name: "Име на фирма",
      type: "firm",
      containerType: "small",
      query: { filter: "firm", options: groups.map((x) => x?.firm) },
    },
    {
      name: "Включени локации",
      type: "includedLocations",
      containerType: "small",
      query: { filter: "includedLocations", options: [] },
    },
    {
      name: "Специални цени",
      type: "specialPrice",
      containerType: "small",
      query: { filter: "specialPrice", options: ["bool"] },
    },
    {
      name: "Начална такса",
      type: "initialFee",
      containerType: "small",
      query: { filter: "initialFee", options: ["bool"] },
    },
    {
      name: "Такса престой",
      type: "parkingFee",
      containerType: "small",
      query: { filter: "parkingFee", options: ["bool"] },
    },
    {
      name: "Към потребители",
      type: "users",
      containerType: "small",
      query: { filter: "users", options: [] },
    },
  ];

  function set_Popup(elem, x, y) {
    let rect = elem.getBoundingClientRect();
    if (elem.id == 6) {
      setCoordinates({
        display: "flex",
        x: `${rect.x - 200}px`,
        y: `${rect.y + 50}px`,
        smaller: true,
      });
    } else
      setCoordinates({
        display: "flex",
        x: `${rect.x + 50}px`,
        y: `${rect.y + 50}px`,
        smaller: elem.id == 2 || elem.id > 2 ? true : false,
      });
  }

  const onScroll = () => {
    let e = tableRef.current;
    const bottom = e.scrollHeight - e.scrollTop === e.clientHeight; //check if scrolled to bottom
    if (bottom) fetchMoreGroups();
  };

  return (
    <>
      <div className="tabs-groups-container" key="1">
        {headerElements.map((element, index) => {
          return (
            <div className={`group-tab`} key={index}>
              <span className="inner-text">{`${element?.name}`}</span>
              <div className={`icon-container ${sortBy === element.type && "rotate"}`}>
                <Icon
                  size={"80%"}
                  icon={ic_keyboard_arrow_down}
                  className={`arrow-down`}
                  id={index}
                  onClick={() => {
                    setShow(true);
                    let elem = document.getElementById(`${index}`);
                    set_Popup(elem);
                    setSelectedHeader(element);
                  }}
                />
              </div>
              {show && <HeaderFilterRow element={selectedHeader} copyGroups={copyGroups} setCopyGroups={setCopyGroups} onClose={(bool) => setShow(bool)} type={"groups"} selectedHeader={selectedHeader} filterType={"value"} coordinates={coordinates} groups={groups} setHasMore={setHasMore} sortValue={sortValue} setSortValue={setSortValue} sortBy={sortBy} setSortBy={setSortBy} sortData={sortData} />}
            </div>
          );
        })}
      </div>
      <div className="all-groups scroll-width-thin" id={"all-stations"} ref={tableRef} onScroll={() => onScroll()}>
        <div className="scroll-inner-container scroll-width-thin">
          {copyGroups?.map((group, i) => {
            return (
              <React.Fragment key={i}>
                <div
                  className={`full-inner-tab`}
                  key={i}
                  id={"location-row"}
                  onClick={() => {
                    if (group?._id) history.push(`/group/${group?._id}`);
                  }}
                >
                  {fieldNames.map((prop, j) => {
                    return (
                      <div className={`inner-tab`} id={i} key={j}>
                        <span className={`inner-tab-text ${j === 0 && "first"}`}>{headerElements?.[j]?.query?.options?.[0] === "bool" ? (group?.[prop] ? "Да" : "Не") : prop === "includedLocations" && group?.[prop] === "1000_all" ? "Всички" : prop === "includedLocations" || prop === "users" ? group?.[prop] : group?.[prop] || "--"}</span>
                      </div>
                    );
                  })}
                </div>
                <div className={`info-inner-tab`} id={`info${i}`}>
                  {innerTabSelected === "locations" ? <InnerLocations group={currentGroup} groups={groups} /> : innerTabSelected === "share" ? <InnerShare group={currentGroup} groups={groups} /> : innerTabSelected === "users" ? <InnerUsers group={currentGroup} groups={groups} /> : ""}
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getStationByID: (payload) => dispatch(getStationByID(payload)),
});
export default connect(null, mapDispatchToProps)(GroupsGrid);
