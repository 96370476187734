/* eslint-disable */
import moment from "moment";

export function sortBy_(
  columnId,
  all_tabs_unsorted,
  parentName,
  ascending,
  type
) {
  if (type === "incomes") {
    if (ascending === 1) {
      all_tabs_unsorted.sort(function (a, b) {
        if (
          Number.isNaN(Number(a.children[columnId].innerText.split(" ")[0]))
        ) {
          return a.children[columnId].innerText ==
            b.children[columnId].innerText
            ? 0
            : a.children[columnId].innerText > b.children[columnId].innerText
            ? 1
            : -1;
        } else {
          return Number(a.children[columnId].innerText.split(" ")[0]) ==
            Number(b.children[columnId].innerText.split(" ")[0])
            ? 0
            : Number(a.children[columnId].innerText.split(" ")[0]) >
              Number(b.children[columnId].innerText.split(" ")[0])
            ? 1
            : -1;
        }
      });

      var parent_div = document.getElementById(parentName);
      parent_div.innerHTML = "";

      for (var i = 0, l = all_tabs_unsorted.length; i < l; i++) {
        parent_div.appendChild(all_tabs_unsorted[i]);
      }
    } else {
      all_tabs_unsorted.sort(function (a, b) {
        if (
          Number.isNaN(Number(a.children[columnId].innerText.split(" ")[0]))
        ) {
          return a.children[columnId].innerText ==
            b.children[columnId].innerText
            ? 0
            : a.children[columnId].innerText > b.children[columnId].innerText
            ? -1
            : 1;
        } else {
          return Number(a.children[columnId].innerText.split(" ")[0]) ==
            Number(b.children[columnId].innerText.split(" ")[0])
            ? 0
            : Number(a.children[columnId].innerText.split(" ")[0]) >
              Number(b.children[columnId].innerText.split(" ")[0])
            ? -1
            : 1;
        }
      });

      // eslint-disable-next-line
      var parent_div = document.getElementById(parentName);
      parent_div.innerHTML = "";

      // eslint-disable-next-line
      for (var i = 0, l = all_tabs_unsorted.length; i < l; i++) {
        parent_div.appendChild(all_tabs_unsorted[i]);
      }
    }
  } else {
    if (ascending === 1) {
      all_tabs_unsorted.sort(function (a, b) {
        return a.children[columnId].innerText == b.children[columnId].innerText
          ? 0
          : a.children[columnId].innerText > b.children[columnId].innerText
          ? 1
          : -1;
      });

      var parent_div = document.getElementById(parentName);
      parent_div.innerHTML = "";

      for (var i = 0, l = all_tabs_unsorted.length; i < l; i++) {
        parent_div.appendChild(all_tabs_unsorted[i]);
      }
    } else {
      all_tabs_unsorted.sort(function (a, b) {
        // eslint-disable-next-line
        return a.children[columnId].innerText == b.children[columnId].innerText
          ? 0
          : a.children[columnId].innerText > b.children[columnId].innerText
          ? -1
          : 1;
      });

      // eslint-disable-next-line
      var parent_div = document.getElementById(parentName);
      parent_div.innerHTML = "";

      // eslint-disable-next-line
      for (var i = 0, l = all_tabs_unsorted.length; i < l; i++) {
        parent_div.appendChild(all_tabs_unsorted[i]);
      }
    }
  }
}

export function sortBy_date(
  columnId,
  all_tabs_unsorted,
  parentName,
  ascending
) {
  if (ascending === 1) {
    all_tabs_unsorted.sort(function (a, b) {
      a = a.children[columnId].children[0].children[0].innerText
        .split(".")
        .reverse()
        .join("");
      b = b.children[columnId].children[0].children[0].innerText
        .split(".")
        .reverse()
        .join("");
      return a.localeCompare(b);
    });

    var parent_div = document.getElementById(parentName);
    parent_div.innerHTML = "";

    for (var i = 0, l = all_tabs_unsorted.length; i < l; i++) {
      parent_div.appendChild(all_tabs_unsorted[i]);
    }
  } else {
    all_tabs_unsorted.sort(function (a, b) {
      a = a.children[columnId].children[0].children[0].innerText
        .split(".")
        .reverse()
        .join("");
      b = b.children[columnId].children[0].children[0].innerText
        .split(".")
        .reverse()
        .join("");
      return b.localeCompare(a);
    });

    // eslint-disable-next-line
    var parent_div = document.getElementById(parentName);
    parent_div.innerHTML = "";

    // eslint-disable-next-line
    for (var i = 0, l = all_tabs_unsorted.length; i < l; i++) {
      parent_div.appendChild(all_tabs_unsorted[i]);
    }
  }
}

export function reverse_sortBy_(reversedData, parentName) {
  var parent_div = document.getElementById(parentName);
  parent_div.innerHTML = "";

  reversedData.sort(function (a, b) {
    // eslint-disable-next-line
    return a.id == b.id ? 0 : a.id > b.id ? 1 : -1;
  });

  for (var i = 0, l = reversedData.length; i < l; i++) {
    parent_div.appendChild(reversedData[i]);
  }
}

export const compare = (a, b, key) => {
  if (a?.[key] < b?.[key]) {
    return -1;
  }
  if (a?.[key] > b?.[key]) {
    return 1;
  }
  return 0;
};

export const compareInnerArray = (a, b, outerKey, innerKey) => {
  let allA = 0;
  let allB = 0;
  a[outerKey].map((station) => (allA = allA + station[innerKey].length));
  b[outerKey].map((station) => (allB = allB + station[innerKey].length));
  return allA - allB;
};

export const compareInnerArrayKey = (a, b, outerKey, innerKey, searchedKey) => {
  let activeA = 0;
  let activeB = 0;
  a[outerKey].map((station) => {
    station[innerKey].map((outlet) =>
      outlet[searchedKey] == "Available" ? (activeA = activeA + 1) : null
    );
  });
  b[outerKey].map((station) => {
    station[innerKey].map((outlet) =>
      outlet[searchedKey] == "Available" ? (activeB = activeB + 1) : null
    );
  });
  return activeA - activeB;
};
export const compareInnerDates = (a, b, outerKey, innerKey) => {
  const aDate = moment(a[outerKey][0]?.[innerKey]);
  const bDate = moment(b[outerKey][0]?.[innerKey]);
  return aDate.isAfter(bDate) ? 1 : bDate.isAfter(aDate) ? -1 : 0;
};
