export const carsTypes = {
  GET_ALL_CARS: "cars/GET_ALL_CARS",
  GET_ALL_CARS_SUCCESS: "cars/GET_ALL_CARS_SUCCESS",
  GET_CAR: "cars/GET_CAR",
  CREATE_CAR: "cars/CREATE_CAR",
  UPDATE_CAR: "cars/UPDATE_CAR",
  DELETE_CAR: "cars/DELETE_CAR",

  GET_ALL_BRANDS: "cars/GET_ALL_BRANDS",
  GET_ALL_BRANDS_SUCCESS: "cars/GET_ALL_BRANDS_SUCCESS",
  CREATE_CAR_BRAND: 'cars/CREATE_CAR_BRAND',

  GET_ALL_MODELS: "cars/GET_ALL_MODELS",
  GET_ALL_MODELS_SUCCESS: "cars/GET_ALL_MODELS_SUCCESS",
  CREATE_CAR_MODEL: 'cars/CREATE_CAR_MODEL',

  GET_ALL_TYPES: "cars/GET_ALL_TYPES",
  GET_ALL_TYPES_SUCCESS: "cars/GET_ALL_TYPES_SUCCESS",
  CREATE_CAR_TYPE: 'cars/CREATE_CAR_TYPE',

};

export const getAllCars = (payload) => ({
  type: carsTypes.GET_ALL_CARS,
  payload
})

export const getCar = (payload) => ({
  type: carsTypes.GET_CAR,
  ...payload
})

export const createCar = (payload) => ({
  type: carsTypes.CREATE_CAR,
  ...payload
})

export const updateCar = (payload) => ({
  type: carsTypes.UPDATE_CAR,
  ...payload
})

export const deleteCar = (payload) => ({
  type: carsTypes.DELETE_CAR,
  ...payload
})

export const getAllBrands = (payload) => ({
  type: carsTypes.GET_ALL_BRANDS,
  payload
})

export const createCarBrand = (payload) => ({
  type: carsTypes.CREATE_CAR_BRAND,
  ...payload
})

export const getAllModels = (payload) => ({
  type: carsTypes.GET_ALL_MODELS,
  payload
})

export const createCarModel = (payload) => ({
  type: carsTypes.CREATE_CAR_MODEL,
  ...payload
})

export const getAllTypes = (payload) => ({
  type: carsTypes.GET_ALL_TYPES,
  payload
})

export const createCarType = (payload) => ({
  type: carsTypes.CREATE_CAR_TYPE,
  ...payload
})
