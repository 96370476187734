import { debounce, sortBy } from "lodash"
import moment from "moment"
import React, { Fragment, useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getWebsiteArticles } from "../../actions"
import { Statistics, Inputs } from "../../components"
import { history } from "../../config/stores"
import "./styles.scss"

const Website = () => {
    const dispatch = useDispatch()
    const { loading, docs, page, hasNextPage, nextPage } = useSelector(({ website }) => website)

    const fetch = useCallback(() => dispatch(getWebsiteArticles({ payload: {} })), [dispatch])
    const fetchDebounced = debounce(fetch, 300)
    useEffect(() => { fetch() }, [fetch])


    const handlePaggination = (scrollTop, height, scrollHeight) => {
        if (scrollTop + height >= scrollHeight - 10 && hasNextPage && !loading) fetchDebounced({ pageNumber: nextPage })
    }

    return <div className="screen-website-container">
        <div className="screen-website-inner-container col">
            <div className="screen-website-header row">
                <h2>Блог</h2>
                <Inputs.Raised text="Добави" onClick={() => history.push(`/website/form`)} />
            </div>
            <div
                className="screen-website-content"
                onScroll={({ target }) => handlePaggination(target.scrollTop, target.getBoundingClientRect().height, target.scrollHeight)}
            >
                {loading && !page
                    ? <Statistics.LoaderInline />
                    : sortBy(docs, ['pinned', 'createdAt'])?.reverse()?.map(({ _id, title, pinned, coverPhoto, createdAt }) => (
                        <Fragment key={_id}>
                            <div
                                className={`screen-website-item-container ${pinned && 'pinned'} row`}
                                onClick={() => history.push(`/website/${_id}`)}
                            >
                                <div
                                    className="icon icon-cover-photo"
                                    style={{ backgroundImage: `url(${coverPhoto.address})` }}
                                />
                                <h3>{title}</h3>
                                <span>{moment(createdAt).format('DD.MM.YYYY')}</span>
                            </div>
                            {pinned && <div className="divider" />}
                        </Fragment >
                    ))}
            </div>
        </div>
    </div>
}

export default Website