import React from "react";
import "./styles.scss";
import moment from "moment";

export default function ClientMainInfo({ clientData }) {
  return (
    <div className="client-main-info-container">
      <div className="second-section">
        <h2 className="bold">Основна информация</h2>
      </div>
      <div className="wrapper-container">
        <div className="fourth-section">
          <div className="line-and-text">
            <div className="left-line" />
            <p className="text light">
              Име: &nbsp;<b>{clientData?.name}</b>
            </p>
          </div>
          <div className="line-and-text">
            <div className="left-line" />
            <p className="text light">
              Телефон: &nbsp;<b>{clientData?.phoneNumber}</b>
            </p>
          </div>
          <div className="line-and-text">
            <div className="left-line" />
            <p className="text light">
              Към група: &nbsp;<b>{clientData?.businessGroup?.name || "Няма"}</b>
            </p>
          </div>
          <div className="line-and-text">
            <div className="left-line" />
            <p className="text light">
              Средно плаща на месец: &nbsp;<b>{clientData?.avgPaidMonth?.toFixed(2) || 0} лв.</b>
            </p>
          </div>
          <div className="line-and-text">
            <div className="left-line" />
            <p className="text light">
              Средно зарежда на месец: &nbsp;<b>{clientData?.avgChargeMonth?.toFixed(2) || 0} kW</b>
            </p>
          </div>
          <div className="line-and-text">
            <div className="left-line" />
            <p className="text light">
              Най-ползвана станция: &nbsp;<b>{clientData?.mostUsedLocation?.name || "--"}</b>
            </p>
          </div>
          <div className="line-and-text">
            <div className="left-line" />
            <p className="text light">
              Клиент от: &nbsp;<b>{moment(clientData?.clientFrom)?.format('DD.MM.YYYY') || ''}</b>
            </p>
          </div>
          <div className="line-and-text">
            <div className="left-line" />
            <p className="text light">
              Добавени автомобили: &nbsp;<b>{clientData?.cars?.length || "0"}</b>
            </p>
          </div>
        </div>
        {clientData?.cars?.length ?
          <div className="fourth-section small">
            {clientData?.cars?.map((el, index) => {
              return (
                <div className="row" key={`car-line-${index}`}>
                  <img src={el?.brand?.logo} className="logo-icon" alt='logo' />
                  <p className="text bold">{el?.fullName || "--"}</p>
                </div>
              )
            })}
          </div> : <></>}
      </div>
    </div>
  );
}
