/* eslint-disable */
import * as XLSX from "xlsx";
import moment from "moment";
import React from "react";
import { setLoadingOn, setLoadingOff } from "../actions";
import { store } from "../config/stores";
import { Headers } from "./Headers";
import { API } from "../config/settings";

//InnerLocations.js
export const handleOutlets = (location, type) => {
  if (type === "count") {
    let outlets = 0;
    let neshto = location?._stations.map((station, i) => {
      outlets += station?._outlets.length;
    });
    return <td className="location-data">{outlets}</td>;
  } else if (type === "status") {
    let outlets = 0;
    let activeOutlets = 0;
    let neshto = location?._stations.map((station, i) => {
      outlets += station?._outlets.length;
      let neshto2 = station?._outlets.map((outlet, j) => {
        if (outlet?.status === "online") activeOutlets++;
      });
    });
    return <td className="location-data">{`${activeOutlets}/${outlets}`}</td>;
  }
};
export function handleGroupsProperties(location, type, groups, locations) {
  if (locations) {
    switch (type) {
      case "group":
        return location.businessGroup === null || location.businessGroup === undefined ? <td className="location-data">{"Не"}</td> : findGroupById(location.businessGroup, groups);
      case "visible":
        return location.visible === false ? <td className="location-data">Не</td> : location.visible === undefined ? <td className="location-data">---</td> : <td className="location-data">Да</td>;
      case "special":
        return location.specialPrices === false ? <td className="location-data">Не</td> : location.specialPrices === undefined ? <td className="location-data">---</td> : <td className="location-data">Да</td>;
      case "shared":
        return location.sharedProfit === false ? <td className="location-data">Не</td> : location.specialPrices === undefined ? <td className="location-data">---</td> : <td className="location-data">{location?.partOfProfit}%</td>;
      default:
        return;
    }
  } else {
    switch (type) {
      case "visible":
        return location.visible === false ? <td className="location-data">Не</td> : location.visible === undefined ? <td className="location-data">---</td> : <td className="location-data">Да</td>;
      case "special":
        return location.specialPrices === false ? <td className="location-data">Не</td> : location.specialPrices === undefined ? <td className="location-data">---</td> : <td className="location-data">Да</td>;
      case "shared":
        return location.sharedProfit === false ? <td className="location-data">Не</td> : location.specialPrices === undefined ? <td className="location-data">---</td> : <td className="location-data">{location?.partOfProfit}%</td>;
      default:
        return;
    }
  }
}
export function findGroupById(id, groups, headerElements) {
  const newGroups = groups.slice(0);
  var foundGroups = newGroups.filter((x) => x._id === id);
  foundGroups.map((found) => headerElements[4]?.query?.options.push(found.name));
  //if(!headerElements[4]?.query?.options.includes("Не")) headerElements[4]?.query?.options.push("Не")
  return <td className="location-data">{foundGroups[0]?.name}</td>;
}

export const getPeriod = (period) => {
  switch (period) {
    case "day":
      return { startDate: moment().subtract(24, "hours"), endDate: moment() };
    case "week":
      return { startDate: moment().subtract(7, "days"), endDate: moment() };
    case "month":
      return { startDate: moment().subtract(31, "days"), endDate: moment() };
    default:
      return {};
  }
};

export const exportAppStatistics = async (period) => {
  store.dispatch(setLoadingOn());
  const wb = XLSX.utils.book_new();

  // total downloads
  const downloads = new Promise((resolve, reject) => {
    fetch(`${API}admin/stat/download`, {
      method: "POST",
      headers: Headers.get_auth(),
      body: JSON.stringify({ ...getPeriod(period) }),
    })
      .then((response) => response.json())
      .then(({ payload }) => {
        XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet([["Operation System", "Total Downloads"], ...payload.map(({ operationSystem, totalDownloads }) => [operationSystem, totalDownloads])]), "Total Downloads");
        resolve();
      })
      .catch((error) => reject(error));
  });

  try {
    await Promise.all([downloads]);
    XLSX.writeFile(wb, `statistics-${moment().format("DD-MM-YYYY")}.xlsx`);
    store.dispatch(setLoadingOff());
  } catch (error) {
    console.error(error);
  }
};

export const handleUpdate = (values, currentPage, setCurrentPage, innerLoading, setInnerLoading, fetch, hasMore, payload) => {
  const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
  const pad = 0; // how much px from bottom
  const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
  if (t >= 0.997 && hasMore && !innerLoading) {
    setInnerLoading(true);
    fetch(payload);
    setCurrentPage(currentPage + 1);
  }
};
