export const FinanceTypes = {
  GET_FINANCE_INCOME: "finance/GET_FINANCE_INCOME",
  GET_FINANCE_INCOME_SUCCESS: "finance/GET_FINANCE_INCOME_SUCCESS",

  GET_FINANCE_DEPOSITS: "finance/GET_FINANCE_DEPOSITS",
  GET_FINANCE_DEPOSITS_SUCCESS: "finance/GET_FINANCE_DEPOSITS_SUCCESS",

  GET_FINANCE_DEPOSITS_CLIENTS: "finance/GET_FINANCE_DEPOSITS_CLIENTS",
  GET_FINANCE_DEPOSITS_CLIENTS_SUCCESS: "finance/GET_FINANCE_DEPOSITS_CLIENTS_SUCCESS",

  GET_INCOME_STATISTICS: "finance/GET_INCOME_STATISTICS",
  GET_INCOME_STATISTICS_SUCCESS: "finance/GET_INCOME_STATISTICS_SUCCESS",

  GET_CLIENT_STATISTICS: "finance/GET_CLIENT_STATISTICS",
  GET_CLIENT_STATISTICS_SUCCESS: "finance/GET_CLIENT_STATISTICS_SUCCESS",

  GET_CLIENT_DEPOSITS: "finance/GET_CLIENT_DEPOSITS",
  GET_CLIENT_DEPOSITS_SUCCESS: "finance/GET_CLIENT_DEPOSITS_SUCCESS",

  GET_DEPOSITS_STATISTICS: "finance/GET_DEPOSITS_STATISTICS",
  GET_DEPOSITS_STATISTICS_SUCCESS: "finance/GET_DEPOSITS_STATISTICS_SUCCESS",

  EXPORT_TO_XLSX: "finance/EXPORT_TO_XLSX",
};
export const getFinanceIncome = (payload) => ({
  type: FinanceTypes.GET_FINANCE_INCOME,
  payload,
});
export const getFinanceDeposits = (payload) => ({
  type: FinanceTypes.GET_FINANCE_DEPOSITS,
  payload,
});
export const getFinanceDepositsClients = (payload) => ({
  type: FinanceTypes.GET_FINANCE_DEPOSITS_CLIENTS,
  payload,
});
export const getClientStatistics = (payload) => ({
  type: FinanceTypes.GET_CLIENT_STATISTICS,
  payload,
});
export const getClientDeposits = (payload) => ({
  type: FinanceTypes.GET_CLIENT_DEPOSITS,
  payload,
});
export const getFinanceIncomeStatistics = (payload) => ({
  type: FinanceTypes.GET_INCOME_STATISTICS,
  payload,
});
export const getFinanceDepositsStatistics = (payload) => ({
  type: FinanceTypes.GET_DEPOSITS_STATISTICS,
  payload,
});
export const exportToXLSX = (payload) => ({
  type: FinanceTypes.EXPORT_TO_XLSX,
  payload,
});
