/* eslint-disable */
import React, { useEffect } from "react";
import moment from "moment";
import "./styles.scss";

const MessageBox = ({ mess, left, SentOn, type, setVote }) => {
  const time = moment(SentOn).format("HH:mm");

  useEffect(() => {
    // eslint-disable-next-line
    mess?.replace("w", "<br/>");
    if (type === "vote") setVote(true);
  }, []); // eslint-disable-next-line react-hooks/exhaustive-deps

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {left ? (
        <div style={{ display: "flex" }}>
          <div className="left-field">
            <div className="avatar" />
          </div>
          <div className={`right-field-user ${left && "left"}`}>
            {mess?.slice(0, 3) === "htt" ? (
              <>
                <div className={`field`} style={{ width: `450px`, height: `300px`, padding: "0", marginLeft: "5px" }}>
                  <div className="message-image" id={"image"} style={{ backgroundImage: `url(${mess})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "center", height: `100%` }} />
                </div>
              </>
            ) : (
              <div className="field">{type === "vote" ? <p className="message">Ще се радваме ако оцените на полученото обслужване!</p> : <p className="message">{mess}</p>}</div>
            )}
            <p className="time left">{time}</p>
          </div>
        </div>
      ) : (
        <div className={`right-field-user ${left && "left"}`}>
          <p className="time">{time}</p>
          {mess?.slice(0, 3).includes("htt") ? (
            <div className="field">
              <a className="message" style={{ color: "black" }} href={`${mess}`}>
                {mess}
              </a>
            </div>
          ) : (
            <div className="field">{type === "vote" ? "Ще се радваме ако оцените на полученото обслужване!" : <p className="message">{mess}</p>}</div>
          )}
        </div>
      )}
    </div>
  );
};
export default MessageBox;
