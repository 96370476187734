/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
//import "../styles.scss";
import { getLocations } from "../../../actions";
import { connect } from "react-redux";

const LocationToGroup = ({ payload, setPayload, setSelectedTab,locations,getLocations,selectedLocation,visibleForUsers,setVisibleForUsers }) => {

    const [currentLocations,setCurrentLocations] = useState(locations)
    const input = useRef("")
    const [currentLocation,setCurrentLocation] = useState({
      stationType:"",
      workingTime:"",
      files:[],
      businessGroup:null,
      sharedProfit:false,
      specialPrices:false,
      visible:true,
      visibleFor:[],
      name:"",
      address:"",
      locationInfo:"",
      fromHour:":",
      toHour:":",
      _stations:[
        {
          voltage:"",
          name:"",
          power:null,
          price:null,
          _outlets:[]
        }
      
      ]
    });


    const [hiddenFields,setHiddenFields] = useState({
      first: false,
      second: false,
      third: true
    })

  return (
    <>
      <div className="right-stations-add-container">
        <div className="right-inner-stations-add-container">
          <p style={{ fontSize: "20px" }}>
            <b>{`Локации към групата`}</b>
          </p>
          <div className="first-add-section">
            <span className="input-title">Локация</span>
            <div className="dropdown-container">
              <input
                required
                autoComplete="off"
                className="location-name"
                type="text"
                id="location-name"
                value={payload.locations[selectedLocation]?.locationName || ""}
                onFocus={() => {document.getElementById("dropdown").style.display="block"}}
                onChange={({target : {value}}) => {
                  input.current = value;
                  const copyLocations = payload.locations.slice(0);
                  copyLocations[selectedLocation].locationName = value;
                  const newLocations = locations.slice(0);
                  setCurrentLocations(newLocations.filter(location => location?.name.toLowerCase().includes(input.current.toLowerCase())))
                }}
              />
              <div className={`dropdown-menu`} id={"dropdown"}>
                  {currentLocations.map((location,i) => {
                      if(i > 4) return (<React.Fragment key={i}></React.Fragment>)
                       return(
                       <p className="dropdown-item" key={i} onClick={()=>{
                           input.current = location.name;
                           setCurrentLocation(location)
                           document.getElementById("dropdown").style.display = "none";
                           const newLocations = payload.locations.slice(0);
                           newLocations[selectedLocation].locationId = location._id;
                           newLocations[selectedLocation].locationName = location.name;
                           setPayload({
                             ...payload,
                             ["locations"] : newLocations
                           })
                        }}>{location.name}</p>
                       )
                  })}
              </div>
            </div>
          </div>
          <div className="second-add-section">
                  <div className="upper-half">
                    <span
                      style={{
                        fontSize: "15px",
                        marginRight: "2%",
                        marginTop: "0.5%",
                      }}
                    >
                      Споделяне на печалба
                    </span>
                    <div className="checkboxes-and-radios">
                      <label
                        className="switch"
                        onClick={() => {
                          if (document.getElementById("checkbox").checked === true) {
                            setHiddenFields({
                              ...hiddenFields,
                              ["first"] : true
                            });
                          } else
                          setHiddenFields({
                            ...hiddenFields,
                            ["first"] : false
                          });
                        }}
                      >
                        <input
                          type="checkbox"
                          id="checkbox"
                          checked={payload.locations[selectedLocation]?.sharedProfit === true}
                          onClick={() => {
                            const newLocations = payload.locations.slice(0);
                            if(newLocations[selectedLocation].sharedProfit){
                              newLocations[selectedLocation].sharedProfit = false;
                              newLocations[selectedLocation].partOfProfit = 0;
                              newLocations[selectedLocation].paymentDate = 0;
                            }
                            else
                             newLocations[selectedLocation].sharedProfit = true;
                          }}
                        />
                        <div className="slider round">
                          <span className="on" id="on">
                            <b>Да</b>
                          </span>
                          <span className="off" id="off">
                            <b>Не</b>
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className={`lower-half ${payload.locations[selectedLocation]?.sharedProfit === true && "show"}`} id="first-half">
                    <div className="first-lower-half">
                      <span style={{ marginBottom: "2%" }}>Дял от печалба</span>
                      <br />
                      <div className="partial-container">
                        <input
                          required
                          className="partial"
                          type="number"
                          value={payload.locations[selectedLocation]?.partOfProfit || ""}
                          id="partial"
                          tabIndex="-1"
                          onChange={(event) => {
                            const newLocations = payload.locations.slice(0);
                            newLocations[selectedLocation].partOfProfit = Number(event.target.value);
                            setPayload({
                              ...payload,
                              ["locations"]:newLocations
                            });
                          }}
                        />
                        <div className="suffix">%</div>
                      </div>
                    </div>
                    <div className="first-lower-half" style={{marginLeft:window.innerWidth < 1600 && "-25%"}}>
                      <span style={{ marginBottom: "2%" }}>Плащане на</span>
                      <br />
                      <div className="partial-container">
                        <input
                          required
                          className="partial"
                          type="number"
                          id="date"
                          value={payload.locations[selectedLocation]?.paymentDate || ""}
                          onChange={(event) => {
                            const newLocations = payload.locations.slice(0);
                            newLocations[selectedLocation].paymentDate = Number(event.target.value);
                            setPayload({
                              ...payload,
                              ["locations"]:newLocations
                            });
                          }}
                        />
                        <div className="suffix">число</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="second-add-section">
                  <div className="upper-half">
                    <span
                      style={{
                        fontSize: "15px",
                        marginRight: "2%",
                        marginTop: "0.5%",
                      }}
                    >
                      Специални цени
                    </span>
                    <div className="checkboxes-and-radios">
                      <label
                        className="switch"
                        onClick={() => {
                          if (document.getElementById("checkbox1").checked === true) {
                            setHiddenFields({
                              ...hiddenFields,
                              ["second"] : true
                            });
                          } else
                          setHiddenFields({
                            ...hiddenFields,
                            ["second"] : false
                          });
                        }}
                      >
                        <input
                          type="checkbox"
                          id="checkbox1"
                          checked = {payload.locations[selectedLocation]?.specialPrices === true}
                          onClick={() => {
                            const newLocations = payload.locations.slice(0);
                            if(newLocations[selectedLocation].specialPrices){
                              newLocations[selectedLocation].specialPrices = false;
                            }
                            else{
                              newLocations[selectedLocation].specialPrices = true;
                              currentLocation._stations.map(station => newLocations[selectedLocation].stations.push({
                                station: station?._id,
                                specialPrice: 0
                              }))
                            }
                          }}
                        />
                        <div className="slider round">
                          <span className="on" id="on">
                            <b>Да</b>
                          </span>
                          <span className="off" id="off">
                            <b>Не</b>
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className={`lower-half  ${payload.locations[selectedLocation]?.specialPrices === true && "show"}`} id="second-half">
                    <table className="special-prices">
                      <tr className="header-row">
                        <th className="header">Станция</th>
                        <th className="header">Заряд</th>
                        <th className="header">Мощност</th>
                        <th className="header">Изходи</th>
                        <th className="header bigger">Стандартна цена</th>
                        <th className="header bigger">Цена за група</th>
                      </tr>
                      {currentLocation._stations.map((station,i) => {
                        return(<tr className="data-row">
                            <td className="info-box">{station.name}</td>
                            <td className="info-box">{station.voltage}</td>
                            <td className="info-box">{station.power}</td>
                            <td className="info-box">{station._outlets.length}</td>
                            <td className="info-box">{station.price} лв./kW</td>
                            <td className="info-box">
                              <div className="partial-container" style={{width:"100%"}}>
                                  <input
                                    required
                                    className="partial"
                                    type="number"
                                    value={payload.locations[selectedLocation]?.stations[i]?.specialPrice || ""}
                                    id="partial"
                                    tabIndex="-1"
                                    onChange={(event) => {
                                      const newLocations = payload.locations.slice(0);
                                      newLocations[selectedLocation].stations[i].specialPrice = event.target.value
                                      setPayload({
                                        ...payload,
                                        ["locations"]:newLocations
                                      })
                                    }}
                                  />
                                  <div className="suffix">лв.</div>
                              </div>
                            </td>
                        </tr>
                        )
                      })}
                    </table>
                  </div>
                </div>
                <div className="second-add-section">
                  <div className="upper-half">
                    <span
                      style={{
                        fontSize: "15px",
                        marginRight: "2%",
                        marginTop: "0.5%",
                      }}
                    >
                      Видима за потребители
                    </span>
                    <div className="checkboxes-and-radios">
                      <label
                        className="switch"
                        onClick={() => {
                          if (document.getElementById("checkbox2").checked === true) {
                            setHiddenFields({
                              ...hiddenFields,
                              ["third"] : false
                            });
                            const newLocations = payload.locations.slice(0);
                            newLocations[selectedLocation].visible = true;
                            newLocations[selectedLocation].visibleFor = [];
                            setPayload({
                              ...payload,
                              ["locations"]:newLocations
                            })
                          } else{
                            const newLocations = payload.locations.slice(0);
                            newLocations[selectedLocation].visible = false;
                            setPayload({
                              ...payload,
                              ["locations"]:newLocations
                            })
                             setHiddenFields({
                              ...hiddenFields,
                              ["third"] : true
                            });
                          }
                         
                        }}
                      >
                        <input type="checkbox" id="checkbox2" />
                        <div className="slider round">
                          <span className="on" id="on">
                            <b>Да</b>
                          </span>
                          <span className="off" id="off">
                            <b>Не</b>
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className={`lower-half ${hiddenFields.third === true && "show"}`} id="third-half">
                    <div className="right-inner-users-container">
                      {visibleForUsers.map((user,j) => {
                        return(
                          <div className="inner-users-container" key={j}>
                            <div style={{display:"flex"}}>
                              <p style={{ fontSize: "20px" }}>
                                <b>{`Потребител ${j + 1}`}</b>
                              </p>
                              {visibleForUsers.length > 1 && <div className='delete-station' style={{marginTop:"2%"}} onClick={()=>{
                              const newVisibleForUsers = visibleForUsers.slice(0);
                              newVisibleForUsers.splice(j,1);
                              setVisibleForUsers(newVisibleForUsers)
                              }}/>}
                            </div>
                            <div className="all-fields">
                              <div className="header-input">
                                <span className="input-title">
                                  Име и фамилия
                                </span>
                                <input
                                  required
                                  className="input-field"
                                  type="text"
                                  id="group-name"
                                  value={user.name}
                                  onChange={(event) =>{
                                    const newVisibleForUsers = visibleForUsers.slice(0);
                                    newVisibleForUsers[j].name = event.target.value;
                                    setVisibleForUsers(newVisibleForUsers)
                                  }}
                                />
                              </div>
                              <div className="header-input">
                                <span className="input-title" style={{marginBottom:window.innerWidth < 1600 && "3%"}}>
                                  Телефонен номер
                                </span>
                                <div className="prefix-input">
                                  <div className="prefixx">+359</div>
                                  <input
                                    required
                                    className="inputt-field"
                                    type="number"
                                    id="group-name"
                                    value={user.phoneNumber}
                                    onChange={({target : {value}}) =>{
                                      if(!/^\d{0,9}$/.test(value)) return
                                      const newVisibleForUsers = visibleForUsers.slice(0);
                                      newVisibleForUsers[j].phoneNumber = value;
                                      setVisibleForUsers(newVisibleForUsers)
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            {j + 1 === visibleForUsers.length ? (
                              <div
                                className="button-container add-section"
                                style={{width:"120px"}}
                                onClick={() => {
                                  const newVisibleForUsers = visibleForUsers.slice(0);
                                  newVisibleForUsers.push({
                                    name:"",
                                    phoneNumber:""
                                  })
                                  setVisibleForUsers(newVisibleForUsers)
                                }}
                              >
                                Добави
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        )
                      })}
                          
                    </div>
                  </div>
                </div>

        </div>
      </div>
    </>
  );
};


const mapDispatchToProps = (dispatch) => ({
    getLocations: (payload) => dispatch(getLocations(payload)),
  });
  
export default connect(null,mapDispatchToProps)(LocationToGroup);
