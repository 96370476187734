import { useLocation, useHistory } from 'react-router-dom'

export const useQuery = (innitial) => {
    const history = useHistory()
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const handleUrlChange = (key, value) => {
        if (!value) query.delete(key)
        else if (query.has(key)) query.set(key, value)
        else query.append(key, value)
        history.replace({ ...location, search: '?' + query.toString() })
    }
    const handleUrlChangeMultiple = (newValues = {}) =>
        Object.entries(newValues).forEach(([key, value]) => handleUrlChange(key, value))
    const initQuery = (acceptedValues) => {
        Object.keys(acceptedValues).forEach((key) => {
            if (!acceptedValues[key]) return
            const value = query.get(key)
            if (!value || !acceptedValues[key].map((option) => option?.value).includes(value))
                setTimeout(() => handleUrlChange(key, acceptedValues[key][0].value), 0)
        })
    }
    const clear = () => {
        Array.from(query.keys()).forEach((key) => query.delete(key))
        history.replace({ ...location, search: '?' + query.toString() })
    }

    innitial && initQuery(innitial)

    return {
        query,
        ...Array.from(query.keys()).reduce((a, key) => ({ ...a, [key]: query.get(key) }), {}),
        handleUrlChange: handleUrlChangeMultiple,
        initQuery,
        clear,
    }
}
