import React, { useEffect } from "react";
import "./styles.scss";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "100%",
};

function Mapforgoogle({ mobilе, lati, long }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyALipb3WN32r90_zyf2wk-vNrEod_JdR5Q",
  });

  const [position, setPosition] = React.useState({
    lat: lati,
    lng: long,
  });


  useEffect(() => {
    setPosition({ lat: lati, lng: long });
  }, [lati, long]);

  const mapRef = React.useRef(null);

  function handleLoad(map) {
    mapRef.current = map;
  }

  function handleCenter() {
    if (!mapRef.current) return;
    setPosition({ lat: lati, lng: long });
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={lati === 42.698334 ? 13 : 17}
      onLoad={handleLoad}
      onDragEnd={handleCenter}
      center={position}
      id="map"
    >
      {lati === 42.698334 ? (
        ""
      ) : (
          <Marker
            draggable={false}
            position={{
              lat: lati,
              lng: long,
            }}
          />
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(Mapforgoogle);
