export const s3Credentials = {
  bucketName: "dev-fidweb",
  region: "eu-central-1",
  accessKeyId: "AKIA5L7PZ7W5LIVFASGG",
  secretAccessKey: "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",
  successActionStatus: 201,
};

export const API = process.env.REACT_APP_API || "https://wink.fidweb.net/";
// export const API = process.env.REACT_APP_API || "https://wink-dev.fidweb.net/";

export const SOCKET_API = process.env.SOCKET_URL || "https://wink-socket.fidweb.net";
// export const SOCKET_API = process.env.SOCKET_URL || "https://wink-dev-socket.fidweb.net";

export const SUPPORT_API = "https://customer-support-dev.herokuapp.com";
//export const SUPPORT_API = 'https://customer-support-testing.herokuapp.com';
