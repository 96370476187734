/* eslint-disable */
import React, { useState,useEffect, useRef } from 'react'
import {InnerHeader} from "../../../components";
import EditInfoComponent from './EditInfoComponent';
import EditStationToLocation from './EditStationsToLocation';
import {connect} from "react-redux";
import { useLocation } from 'react-router-dom';
import '../styles.scss'
import { deleteStation } from '../../../actions';
import {User} from '../../../utilities';


const Edit = ({location,deleteStation}) => {

  const currentLocation = useLocation().state;

  const [selectedTab,setSelectedTab] = useState("info");
  const [secondStep,setSecondStep] = useState(false);
  const [openHour,setOpenHour] = useState(false);

  const [fromWorkTime,setFromWorkTime] = useState({
    hour:currentLocation.fromHour.split(":")[0],
    minutes:currentLocation.fromHour.split(":")[1]
    })
  const [toWorkTime,setToWorkTime] = useState({
      hour:currentLocation.toHour.split(":")[0],
      minutes:currentLocation.toHour.split(":")[1]
    })

    const [payload,setPayload] = useState({
      name: currentLocation.name,
      address: currentLocation.address, 
      location: currentLocation.location,
      stationType: currentLocation.stationType,
      locationInfo: currentLocation.locationInfo,
      workingTime: currentLocation.workingTime,
      fromHour: currentLocation.fromHour,
      toHour: currentLocation.toHour,
      files: currentLocation.files,
  })
  
  const [stations,setStations] = useState(currentLocation._stations)
  const [selectedStation,setSelectedStation] = useState(0);
  const stationsLength = useRef(stations.length);


    return(
    <div className='stations-add-container'>
          <InnerHeader 
                    link={"/"} 
                    first={"Рeдакция на станция"}
                    editPage = {true} 
                    locationID={currentLocation._id}
                    payload={payload}
                    stations={stations}
                    stationsLength = {stationsLength}
                    setStations = {setStations}
          />
          <div className='lower-container'>
                    <div className='left-container'>
                        <div className={`first-left-container ${selectedTab === "info" && "selected"}`} onClick={() => {setSelectedTab("info")}} >
                            <h3 className={`add-station  ${selectedTab === "info" && "selected"}`}>Основна информация</h3>
                            <div className='arrow-container'>
                                <div className={`arrow-right  ${selectedTab === "info" && "selected"}`}/>
                            </div>
                        </div>
                        <div className={`full-stations-left-container ${selectedTab === "stations" && "selected"} opened`}>
                            <div className={`stations-tab-container ${selectedTab === "stations" && "selected"} ${!User.isAdmin === true && "locked"}`} onClick={() => {
                                setSelectedTab("stations")
                                }}>
                                <h3 className={`station-name-tab  ${selectedTab === "stations" && "selected"}`}>Станции към локация</h3>
                                <div className='arrow-container' style={{marginLeft:"2%"}}>
                                    <div className={`arrow-right  ${selectedTab === "stations" && "selected"}`}/>
                                </div>
                            </div>

                            {selectedTab === "stations" && <>
                                {stations.map((station,i)=>{
                                    return(
                                    <div className={`inner-station-container ${selectedStation === i && "selected"}`} key={i} onClick={() => {setSelectedStation(i)}} >
                                        <h3 className={`station-name-tab  ${selectedStation === i && "selected"}`}>Станция {i+1}</h3>
                                        <div className='arrow-container wider'>
                                            <div className={`arrow-right`}/>
                                        </div>
                                    </div>
                                    )
                                })}
                                <div className='add-station-container'>
                                    <div className={`inner-station-add-container`} onClick={()=>{
                                        setStations(prevState => [...prevState, {
                                            name:"",
                                            location: currentLocation._id,
                                            power: null,
                                            voltage: null,
                                            price: null,
                                            status: null, //active or support
                                            chargePointId: null, //simple number > 0
                                            serialFirst: "", // DDDD-DDD-DDDD
                                            serialSecond: "", // DDDD-DDD-DDDD
                                            _outlets: [
                                                {
                                                    name:"",
                                                    type: [],
                                                    connectorId: 1 
                                                },
                                            ]
                                    }])
                                            setSelectedStation(stations.length)
                                        }}>
                                            <h3 className={`station-name-tab`}>Добави станция</h3>
                                    </div>
                                </div>
                            </>
                            }

                        </div>
                    </div>
                    {selectedTab === "info" ?
                        <EditInfoComponent 
                        payload={payload} 
                        setPayload={setPayload} 
                        setSelectedTab={setSelectedTab} 
                        openHour={openHour} 
                        setOpenHour={setOpenHour}
                        fromWorkTime={fromWorkTime}
                        setFromWorkTime={setFromWorkTime}
                        toWorkTime={toWorkTime}
                        setToWorkTime={setToWorkTime}
                        setSecondStep={setSecondStep}
                        edit = {true}
                    /> : 
                    User.isAdmin ? 
                    <EditStationToLocation
                            stations={stations}
                            setStations={setStations}
                            selectedStation={selectedStation}
                            edit = {true}
                    /> : ""
                    }
                    
            </div>
    </div>
    )


}


const mapDispatchToProps = (dispatch) => ({
  getLocationById: (payload) => dispatch(getLocationById(payload)),
  deleteStation: (payload) => dispatch(deleteStation(payload))
  })

export default connect(null,mapDispatchToProps)(Edit);