import { combineEpics } from "redux-observable";
import * as starter from "./starter";
import * as support from "./support";
import * as socket from "./socket";
import * as login from "./login";
import * as stations from "./stations";
import * as groups from "./groups";
import * as locations from "./locations";
import * as outlets from "./outlets";
import * as finance from "./finance";
import * as adminSettings from "./adminSettings";
import * as cars from "./cars";
import * as website from "./website";
import * as statistics from "./statistics";

export const rootEpic = combineEpics(
  starter.loadingEpic,
  support.supportNewCustomerEpic,
  support.supportNewTicketEpic,
  support.supportNewMessageEpic,
  support.supportInitEpic,
  socket.supportConnectEpic,
  socket.supportListenerEpic,
  socket.supportSenderEpic,
  socket.supportDisconnectEpic,
  socket.sessionConnectEpic,
  socket.sessionListenerEpic,
  socket.sessionSenderEpic,
  socket.sessionDisconnectEpic,
  login.loginRequestCodeEpic,
  login.checkCode,
  login.logIn,
  login.checkUser,
  login.logOut,
  login.setUserSupportId,
  login.getUsers,
  login.editUser,
  login.createUser,
  login.deleteUser,
  login.getBusinessUsers,
  stations.getStationsEpic,
  stations.createStation,
  stations.getStationByID,
  stations.deleteStation,
  stations.getStatistics,
  stations.editStations,
  groups.getGroups,
  groups.getGroupByID,
  groups.createGroup,
  groups.editGroupMainInfo,
  groups.editGroupUsers,
  groups.editGroupLocations,
  groups.deleteGroup,
  groups.getActivity,
  groups.getGroupStatistics,
  locations.createLocation,
  locations.getLocations,
  locations.deleteLocation,
  locations.editLocation,
  locations.getLocationById,
  locations.getActivity,
  locations.getAllLocations,
  outlets.editOutlets,
  outlets.createOutlet,
  outlets.deleteOutlet,
  finance.getFinanceIncome,
  finance.getFinanceDeposits,
  finance.getFinanceDepositsClients,
  finance.getClientStatistics,
  finance.getIncomeStatistics,
  finance.getDepositsStatistics,
  finance.getClientDeposits,
  finance.getFinanceExcel,
  adminSettings.getSessions,
  adminSettings.makeDiagnostic,
  adminSettings.sendCommand,
  adminSettings.lastCharge,
  adminSettings.getConfiguration,
  adminSettings.setConfiguration,
  adminSettings.unlockConnector,
  adminSettings.startCharging,
  adminSettings.stopCharging,
  adminSettings.getUserByPhoneNumber,
  adminSettings.sessionJoinRoomEpic,
  adminSettings.getLogsEpic,
  cars.getAllCars,
  cars.getAllBrands,
  cars.getAllModels,
  cars.getAllTypes,
  cars.getCar,
  cars.createCar,
  cars.updateCar,
  cars.deleteCar,
  cars.createCarBrand,
  cars.createCarModel,
  cars.createCarType,
  website.getWebsiteArticles,
  website.getWebsiteArticle,
  website.createWebsiteArticle,
  website.updateWebsiteArticle,
  website.deleteWebsiteArticle,
  statistics.getStatisticsUsers,
  statistics.getStatisticsActive,
  statistics.getStatisticsSessions,
  statistics.getStatisticsTop,
  statistics.getStatisticsMonth,
  statistics.getStatisticsCars,
  statistics.getStatisticsBrands,
  statistics.getStatisticsNotifications,
  statistics.getStatisticsDownloads,
  statistics.getStatisticsDownloadsMonth,
  statistics.sendNotification
);
