import { adminSettingsTypes, socketTypes } from "../actions";

const initialState = {
  sessions: [],
  settings: [],
  configuration: [],
  commandHistory: [],
  charging: "",
  connected: false,
};

const handleNewCommandHistory = (state, payload) => {
  const newCommandHistory = state.commandHistory.slice(0);
  newCommandHistory.push(payload)
  return newCommandHistory;
};

const adminSettings = (state = initialState, { type, payload }) => {
  switch (type) {
    case adminSettingsTypes.GET_SESSIONS_SUCCESS:
      return {
        ...state,
        sessions: payload.docs,
      };
    case "socket/activeCharge":
      return {
        ...state,
        currentCharging: payload,
      };
    case "socket/stopCharging":
      return {
        ...state,
        currentCharging: {},
      };
    case "socket/insertCommand":
      return{
        ...state,
        commandHistory:handleNewCommandHistory(state,payload)
      }
    case socketTypes.SESSION_CONNECTED:
      return {
        ...state,
        connected: true,
      };
    case adminSettingsTypes.START_CHARGING_SUCCESS:
      return {
        ...state,
        charging: payload,
      };
    case adminSettingsTypes.GET_CONFIGURATION_SUCCESS:
      return {
        ...state,
        configuration: payload.configurationKey,
      };
    default:
      return state;
  }
};

export default adminSettings;
