import React from "react"
import "./styles.scss"

const Toggle = ({ className = '', value, label, labelOn, labelOff, onChange = () => { } }) => {
  return <div className={`input-toggle-container ${className} ${value ? 'toggle-on' : ''}`} onClick={onChange}>
    <span className={`input-toggle-label`}>{label}</span>
    <div className={'input-toggle-inner-container'}>
      <span className={`label-on ${value ? 'fade-in' : 'fade-out'}`}>{labelOn || "Да"}</span>
      <div className='input-toggle-circle' />
      <span className={`label-off ${!value ? 'fade-in' : 'fade-out'}`}>{labelOff || "Не"}</span>
    </div>
  </div>
}

export default Toggle