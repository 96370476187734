import React from "react";
import "../styles.scss";
import "rc-slider/assets/index.css";
import "moment/locale/bg";
import { history } from "../../../config/stores";

const MiddlePart = ({
  location,
  groups,
  setSelectedTab,
  setSelectedImage,
  setCurrentGroup,
}) => {
  return (
    <div className="middle-station-container">
      <h2 style={{ fontSize: "22px", marginTop: "5%", marginLeft: "2%" }}>
        <b>Станции</b>
      </h2>
      <div className="middle-full-section scroll-width-thin">
        {location?._stations?.map((station, i) => {
          return (
            <div className="middle-section" key={i}>
              <div className="header-station-info">
                <div style={{ display: "flex",alignItems:"center" }}>
                  <div className="settings-icon" onClick={() => {
                    history.push(`/locations/settings/${station._id}`);
                  }} />
                  {/* <p style={{ fontSize: window.innerWidth < 1600 && "15px",whiteSpace:"nowrap" }}> */}
                  <p style={{ fontSize: window.innerWidth < 1200 ? "16px" : "18px",whiteSpace:"nowrap" }}>
                    <b>Станция {station?.name}</b>
                  </p>
                </div>

                <p
                  style={{
                    color: station?.status === "active" ? "#00D688" : "#F54280",
                    fontSize: window.innerWidth < 1400 ? "16px" : "18px",
                  }}
                >
                  <b>
                    {station?.status === "active" ? "Активна" : "Неактивна"}
                  </b>
                </p>
              </div>
              <div className="inner-header-station-info">
                <p style={{ fontSize: window.innerWidth < 1400 ? "16px" : "18px" }}>
                  Цена: &nbsp;<b>{station?.price} лв./kW</b>
                </p>
                <p style={{ fontSize: window.innerWidth < 1400 ? "16px" : "18px" }}>
                  Заряд: &nbsp;<b>{station?.voltage}</b>
                </p>
                <p style={{ fontSize: window.innerWidth < 1400 ? "16px" : "18px" }}>
                  Мощност: &nbsp;<b>{station?.power} kW</b>
                </p>
              </div>
              <div className="station-outlets-container">
                <p
                  style={{
                    marginTop: "-1%",
                    fontSize: window.innerWidth < 1400 ? "16px" : "18px",
                  }}
                >
                  Изходи
                </p>
                <div className="outlets-info">
                  {station?._outlets.map((outlet, j) => {
                    return (
                      <div className="outlet-info" key={j}>
                        <p
                          style={{
                            fontSize: window.innerWidth < 1400 ? "16px" : "18px",
                          }}
                        >
                          <b>{outlet?.name}</b> ({" "}
                          {outlet?.type.map((x, k) => {
                            return x === "Type 2" ? "Тип 2 " : `${x} `;
                          })}{" "}
                          )
                        </p>
                        <p
                          style={{
                            whiteSpace: "nowrap",
                            fontSize: window.innerWidth < 1400 ? "16px" : "18px",
                          }}
                        >
                          Статус:&nbsp;
                          {outlet?.status === "offline" ? (
                            <b style={{ color: "#F54280" }}>Неакивен</b>
                          ) : 
                          outlet?.status === "reserved" ? (
                            <b style={{ color: "#FFA51D" }}>Резервиран</b>
                          )
                           : (
                            <b style={{ color: "#00D688" }}>Активен</b>
                          )}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default MiddlePart;
