import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { carsTypes, setLoadingOff } from "../actions";
import { API } from "../config/settings";
import { Headers } from "../utilities";

export const getAllCars = (action$) =>
  action$.pipe(
    ofType(carsTypes.GET_ALL_CARS),
    switchMap(
      ({
        payload: {
          brand,
          model,
          batteryType,
          pageNumber,
          pageSize,
          sortBy,
          noPagination,
          search,
          onSuccess,
        },
      }) => {
        return ajax({
          url: `${API}car/list`,
          method: "POST",
          headers: Headers.get_auth(),
          body: JSON.stringify({
            brand,
            model,
            batteryType,
            pageNumber,
            pageSize,
            sortBy,
            noPagination,
            search,
          }),
        }).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              obs.next({
                type: carsTypes.GET_ALL_CARS_SUCCESS,
                payload: response.payload,
              });
              onSuccess(response.payload);
              obs.next(setLoadingOff());
              obs.complete();
            });
          }),
          catchError((err) => {
            return ActionsObservable.create((obs) => {
              console.error(err);
              obs.complete();
            });
          })
        );
      }
    )
  );

export const getAllBrands = (action$) =>
  action$.pipe(
    ofType(carsTypes.GET_ALL_BRANDS),
    switchMap(
      ({
        payload: {
          pageNumber,
          pageSize,
          sortBy,
          noPagination,
          search,
          onSuccess,
        },
      }) => {
        return ajax({
          url: `${API}car/brand/list`,
          method: "POST",
          headers: Headers.get_auth(),
          body: JSON.stringify({
            pageNumber,
            pageSize,
            sortBy,
            noPagination,
            search,
          }),
        }).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              obs.next({
                type: carsTypes.GET_ALL_BRANDS_SUCCESS,
                payload: response.payload,
              });
              onSuccess(response.payload);
              obs.next(setLoadingOff());
              obs.complete();
            });
          }),
          catchError((err) => {
            return ActionsObservable.create((obs) => {
              console.error(err);
              obs.complete();
            });
          })
        );
      }
    )
  );

export const getAllModels = (action$) =>
  action$.pipe(
    ofType(carsTypes.GET_ALL_MODELS),
    switchMap(
      ({
        payload: {
          brand,
          pageNumber,
          pageSize,
          sortBy,
          noPagination,
          search,
          onSuccess,
        },
      }) => {
        return ajax({
          url: `${API}car/model/list`,
          method: "POST",
          headers: Headers.get_auth(),
          body: JSON.stringify({
            brand,
            pageNumber,
            pageSize,
            sortBy,
            noPagination,
            search,
          }),
        }).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              obs.next({
                type: carsTypes.GET_ALL_MODELS_SUCCESS,
                payload: response.payload,
              });
              onSuccess(response.payload);
              obs.next(setLoadingOff());
              obs.complete();
            });
          }),
          catchError((err) => {
            return ActionsObservable.create((obs) => {
              console.error(err);
              obs.complete();
            });
          })
        );
      }
    )
  );
export const getAllTypes = (action$) =>
  action$.pipe(
    ofType(carsTypes.GET_ALL_TYPES),
    switchMap(
      ({
        payload: {
          model,
          pageNumber,
          pageSize,
          sortBy,
          noPagination,
          search,
          onSuccess,
        },
      }) => {
        return ajax({
          url: `${API}car/battery/list`,
          method: "POST",
          headers: Headers.get_auth(),
          body: JSON.stringify({
            model,
            pageNumber,
            pageSize,
            sortBy,
            noPagination,
            search,
          }),
        }).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              obs.next({
                type: carsTypes.GET_ALL_TYPES_SUCCESS,
                payload: response.payload,
              });
              onSuccess(response.payload);
              obs.next(setLoadingOff());
              obs.complete();
            });
          }),
          catchError((err) => {
            return ActionsObservable.create((obs) => {
              console.error(err);
              obs.complete();
            });
          })
        );
      }
    )
  );

export const getCar = (action$) => action$.pipe(
  ofType(carsTypes.GET_CAR),
  switchMap(({ payload, onSuccess }) => ajax({
    url: `${API}car/${payload}`,
    method: "GET",
    headers: Headers.get_auth()
  }).pipe(
    switchMap(({ response }) => ActionsObservable.create((obs) => {
      if (onSuccess) onSuccess(response.payload)
      obs.complete()
    })),
    catchError((err) => ActionsObservable.create((obs) => {
      console.error(err)
      obs.complete()
    }))
  ))
)


export const createCar = (action$) => action$.pipe(
  ofType(carsTypes.CREATE_CAR),
  switchMap(({ payload, onSuccess }) => ajax({
    url: `${API}car/create`,
    method: "POST",
    headers: Headers.get_auth(),
    body: JSON.stringify(payload)
  }).pipe(
    switchMap(({ response }) => ActionsObservable.create((obs) => {
      if (onSuccess) onSuccess(response)
      obs.complete()
    })),
    catchError((err) => ActionsObservable.create((obs) => {
      console.error(err)
      obs.complete()
    }))
  ))
)

export const updateCar = (action$) => action$.pipe(
  ofType(carsTypes.UPDATE_CAR),
  switchMap(({ payload: { _id, ...payload }, onSuccess }) => ajax({
    url: `${API}car/${_id}`,
    method: "PUT",
    headers: Headers.get_auth(),
    body: JSON.stringify(payload)
  }).pipe(
    switchMap(({ response }) => ActionsObservable.create((obs) => {
      if (onSuccess) onSuccess(response)
      obs.complete()
    })),
    catchError((err) => ActionsObservable.create((obs) => {
      console.error(err)
      obs.complete()
    }))
  ))
)

export const deleteCar = (action$) => action$.pipe(
  ofType(carsTypes.DELETE_CAR),
  switchMap(({ payload, onSuccess }) => ajax({
    url: `${API}car/${payload}`,
    method: "DELETE",
    headers: Headers.get_auth(),
  }).pipe(
    switchMap(({ response }) => ActionsObservable.create((obs) => {
      if (onSuccess) onSuccess(response)
      obs.complete()
    })),
    catchError((err) => ActionsObservable.create((obs) => {
      console.error(err)
      obs.complete()
    }))
  ))
)

export const createCarBrand = (action$) => action$.pipe(
  ofType(carsTypes.CREATE_CAR_BRAND),
  switchMap(({ payload, onSuccess }) => ajax({
    url: `${API}car/brand/create`,
    method: "POST",
    headers: Headers.get_auth(),
    body: JSON.stringify(payload)
  }).pipe(
    switchMap(({ response }) => ActionsObservable.create((obs) => {
      if (onSuccess) onSuccess(response.payload)
      obs.complete()
    })),
    catchError((err) => ActionsObservable.create((obs) => {
      console.error(err)
      obs.complete()
    }))
  ))
)

export const createCarModel = (action$) => action$.pipe(
  ofType(carsTypes.CREATE_CAR_MODEL),
  switchMap(({ payload, onSuccess }) => ajax({
    url: `${API}car/model/create`,
    method: "POST",
    headers: Headers.get_auth(),
    body: JSON.stringify(payload)
  }).pipe(
    switchMap(({ response }) => ActionsObservable.create((obs) => {
      if (onSuccess) onSuccess(response.payload)
      obs.complete()
    })),
    catchError((err) => ActionsObservable.create((obs) => {
      console.error(err)
      obs.complete()
    }))
  ))
)

export const createCarType = (action$) => action$.pipe(
  ofType(carsTypes.CREATE_CAR_TYPE),
  switchMap(({ payload, onSuccess }) => ajax({
    url: `${API}car/battery/create`,
    method: "POST",
    headers: Headers.get_auth(),
    body: JSON.stringify(payload)
  }).pipe(
    switchMap(({ response }) => ActionsObservable.create((obs) => {
      if (onSuccess) onSuccess(response.payload)
      obs.complete()
    })),
    catchError((err) => ActionsObservable.create((obs) => {
      console.error(err)
      obs.complete()
    }))
  ))
)