import { default as Button } from "./Button";
import { default as Checkboxes } from "./Checkboxes";
import { default as TextInput } from "./TextInput";
import { default as RadioButtons } from "./RadioButtons";
const Inputs2 = {
  Button,
  TextInput,
  Checkboxes,
  RadioButtons,
};

export default Inputs2;
