export const outletsTypes = {
    CREATE_OUTLET: 'outlets/CREATE_OUTLET',
    EDIT_OUTLETS: 'outlets/EDIT_OUTLETS', // Body is the same like create, BUT without cables. For cables have different endpoints
    DELETE_OUTLET: 'outlets/DELETE_OUTLET'
}
export const editOutlets = (payload) => ({
  type: outletsTypes.EDIT_OUTLETS,
  payload
})
export const createOutlet = (payload) => ({
  type: outletsTypes.CREATE_OUTLET,
  payload
})
export const deleteOutlet = (payload) => ({
  type: outletsTypes.DELETE_OUTLET,
  payload
})
