export const LocationTypes = {
    CREATE_LOCATION: 'locations/CREATE_LOCATION',

    SET_LOCATIONS_DETAILS: 'locations/SET_LOCATIONS_DETAILS',
    GET_LOCATIONS: 'locations/GET_LOCATIONS',
    GET_ALL_LOCATIONS:'locations/GET_ALL_LOCATIONS',
    GET_LOCATIONS_SUCCESS: 'locations/GET_LOCATIONS_SUCCESS',
    GET_ALL_LOCATIONS_SUCCESS:'locations/GET_ALL_LOCATIONS_SUCCESS',

    GET_LOCATION_BY_ID:'locations/GET_LOCATION_BY_ID',
    GET_LOCATION_BY_ID_SUCCESS: 'locations/GET_LOCATION_BY_ID_SUCCESS',

    DELETE_LOCATION:"locations/DELETE_LOCATION",
    EDIT_LOCATION: "locations/EDIT_LOCATION",
    GET_ACTIVITY:"locations/GET_ACTIVITY",
    GET_ACTIVITY_SUCCESS:"locations/GET_ACTIVITY_SUCCESS"
}

export const setLocationDetails = (payload) => ({
    type: LocationTypes.SET_LOCATIONS_DETAILS,
    payload
  })
export const createLocation = (payload) => ({
    type: LocationTypes.CREATE_LOCATION,
    payload
  })
export const getLocations = (payload) => ({
    type: LocationTypes.GET_LOCATIONS,
    payload
})

export const getAllLocations = (payload) => ({
  type:LocationTypes.GET_ALL_LOCATIONS,
  payload
})

export const deleteLocation = (payload) => ({
  type: LocationTypes.DELETE_LOCATION,
  payload,
})
export const editLocation = (payload) => ({
  type: LocationTypes.EDIT_LOCATION,
  payload,
})
export const getLocationById = (payload) => ({
  type: LocationTypes.GET_LOCATION_BY_ID,
  payload
})
export const getActivity = (payload) => ({
  type:LocationTypes.GET_ACTIVITY,
  payload
})