import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { LocationTypes, setLoadingOff } from "../actions";
import { API } from "../config/settings";
import { Headers } from "../utilities";

export const createLocation = (action$) =>
  action$.pipe(
    ofType(LocationTypes.CREATE_LOCATION),
    switchMap(({ payload: { payload, onSuccess, onError } }) => {
      return ajax({
        url: `${API}location/create`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            onSuccess(response);
            obs.next(setLoadingOff());
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.error(err);
            onError();
            obs.complete();
          });
        })
      );
    })
  );

export const getLocations = (action$) =>
  action$.pipe(
    ofType(LocationTypes.GET_LOCATIONS),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}location/list`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: LocationTypes.GET_LOCATIONS_SUCCESS,
              payload: response.payload,
            });
            if (payload?.onSuccess) payload.onSuccess(response);
            obs.next(setLoadingOff());
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.error(err);
            obs.complete();
          });
        })
      );
    })
  );

export const getAllLocations = (action$) =>
  action$.pipe(
    ofType(LocationTypes.GET_ALL_LOCATIONS),
    switchMap(({ payload: { noPagination, onSuccess, onError, search } }) => {
      return ajax({
        url: `${API}location/list`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify({ noPagination, search }),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            if (noPagination) {
              obs.next({
                type: LocationTypes.GET_ALL_LOCATIONS_SUCCESS,
                payload: response.payload,
              });
            } else return;
            onSuccess(response);
            obs.next(setLoadingOff());
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.error(err);
            obs.complete();
          });
        })
      );
    })
  );

export const deleteLocation = (action$) =>
  action$.pipe(
    ofType(LocationTypes.DELETE_LOCATION),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}location/${payload}`,
        method: "DELETE",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(setLoadingOff());
            getLocations({
              pageNumber: "1",
              pageSize: "20",
              sortBy: "",
              noPagination: false,
              search: "",
              onSuccess: (response) => { },
              onError: () => alert("Имаше проблем със зареждането на останалите локации,опитайте отново!"),
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.error(err);
            obs.complete();
          });
        })
      );
    })
  );

export const editLocation = (action$) =>
  action$.pipe(
    ofType(LocationTypes.EDIT_LOCATION),
    switchMap(({ payload: { payload, _id, onSuccess, onError } }) => {
      return ajax({
        url: `${API}location/${_id}`,
        method: "PUT",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            onSuccess();
            obs.next(setLoadingOff());
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            onError();
            console.error(err);
            obs.complete();
          });
        })
      );
    })
  );

export const getLocationById = (action$) =>
  action$.pipe(
    ofType(LocationTypes.GET_LOCATION_BY_ID),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}location/${payload}`,
        method: "GET",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: LocationTypes.GET_LOCATION_BY_ID_SUCCESS,
              payload: response,
            });
            obs.next(setLoadingOff());
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.error(err);
            obs.complete();
          });
        })
      );
    })
  );

export const getActivity = (action$) =>
  action$.pipe(
    ofType(LocationTypes.GET_ACTIVITY),
    switchMap(({ payload: { location, pageNumber, pageSize, sort, startDate, endDate, minAmount, maxAmount, stations, cars, type, noPagination, onSuccess } }) => {
      return ajax({
        url: `${API}location/activity`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify({ location, pageNumber, pageSize, sort, startDate, endDate, minAmount, maxAmount, stations, cars, type, noPagination }),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: LocationTypes.GET_ACTIVITY_SUCCESS,
              payload: response.payload,
            });
            onSuccess(response);
            obs.next(setLoadingOff());
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.error(err);
            obs.complete();
          });
        })
      );
    })
  );
