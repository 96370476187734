export const socketTypes = {
    SOCKET_INIT: 'socket/init',
    CREATED_TICKET:'socket/createticket',

    SESSION_CONNECT: 'socket/SESSION_CONNECT',
    SESSION_CONNECTED: 'socket/SESSION_CONNECTED',
    SESSION_EMIT_EVENT: 'socket/SESSION_EMIT_EVENT',
    SESSION_DISCONNECT: 'socket/SESSION_DISCONNECT'  
}  


export const setLoadingOn = () => ({
    type: socketTypes.SOCKET_INIT
})

  export const sessionSocketConnect = payload => ({
    type: socketTypes.SESSION_CONNECT,
    payload
  })

  export const sessionSocketConnected = () => ({
    type: socketTypes.SESSION_CONNECTED
  })
  
  export const sessionSocketEmit = payload => ({
    type: socketTypes.SESSION_EMIT_EVENT,
    payload
  })
  
  export const sessionSocketDisconnect = () => ({
    type: socketTypes.SESSION_DISCONNECT
  })
  