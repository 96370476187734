export const StationTypes = {
    GET_STATIONS: 'stations/GET_STATIONS',
    GET_STATIONS_SUCCESS: 'stations/GET_STATIONS_SUCCESS',

    SET_OPENED_STATION: 'stations/OPENED_STATION',
    CREATE_STATION: 'stations/CREATE_STATION',
    CREATE_STATION_SUCCESS: 'stations/CREATE_STATION_SUCCESS',

    EDIT_STATIONS: 'stations/EDIT_STATIONS', // Body is the same like create, BUT without cables. For cables have different endpoints

    GET_STATION_BY_ID:'stations/GET_STATION_BY_ID',
    GET_STATION_BY_ID_SUCCESS: 'stations/GET_STATION_BY_ID_SUCCESS',

    DELETE_STATION: `stations/DELETE_STATION`,

    GET_STATISTICS: `stations/GET_STATISTICS`,
    GET_STATISTICS_READY:`stations/GET_STATISTICS_READY`
  }
  
  export const setOpenedStation = payload => ({
    type: StationTypes.SET_OPENED_STATION,
    payload
  })

  export const getStations = (payload) => ({
    type: StationTypes.GET_STATIONS,
    payload
  })

  export const createStation = (payload) => ({
    type: StationTypes.CREATE_STATION,
    payload
  })

  export const editStations = (payload) => ({
    type: StationTypes.EDIT_STATIONS,
    payload
  })

export const getStationByID = (payload) =>({
  type:StationTypes.GET_STATION_BY_ID,
  payload
})

export const deleteStation = (payload) => ({
  type: StationTypes.DELETE_STATION,
  payload,
})

export const getStatistics = (payload) =>({
  type:StationTypes.GET_STATISTICS,
  payload,
})
  