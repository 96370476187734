import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import starter from './starter'
import stations from './stations'
import support from './support'
import login from './login'
import groups from './groups'
import locations from "./locations"
import finances from './finance'
import adminSettings from './adminSettings'
import cars from './cars'
import statistics from './statistics'
import website from './website'

export default combineReducers({
  starter,
  stations,
  support,
  login,
  groups,
  locations,
  finances,
  adminSettings,
  cars,
  routerReducer,
  statistics,
  website,
})
