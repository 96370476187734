export const statisticsTypes = {
    GET_STATISTICS_SUCCESS: 'statistics/GET_STATISTICS_SUCCESS',

    GET_STATISTICS_USERS: 'statistics/GET_STATISTICS_USERS',
    GET_STATISTICS_ACTIVE: 'statistics/GET_STATISTICS_ACTIVE',
    GET_STATISTICS_SESSIONS: 'statistics/GET_STATISTICS_SESSIONS',
    GET_STATISTICS_TOP: 'statistics/GET_STATISTICS_TOP',
    GET_STATISTICS_MONTH: 'statistics/GET_STATISTICS_MONTH',
    GET_STATISTICS_CARS: 'statistics/GET_STATISTICS_CARS',
    GET_STATISTICS_BRANDS: 'statistics/GET_STATISTICS_BRANDS',
    GET_STATISTICS_NOTIFICATIONS: 'statistics/GET_STATISTICS_NOTIFICATIONS',
    GET_STATISTICS_DOWNLOADS: 'statistics/GET_STATISTICS_DOWNLOADS',
    GET_STATISTICS_DOWNLOADS_MONTH: 'statistics/GET_STATISTICS_DOWNLOADS_MONTH',
    SEND_NOTIFICATION: 'statistics/SEND_NOTIFICATION',
}

export const getStatisticsSuccess = (payload) => ({
    type: statisticsTypes.GET_STATISTICS_SUCCESS,
    payload
})

export const getStatisticsUsers = (payload) => ({
    type: statisticsTypes.GET_STATISTICS_USERS,
    ...payload
})

export const getStatisticsActive = (payload) => ({
    type: statisticsTypes.GET_STATISTICS_ACTIVE,
    ...payload
})

export const getStatisticsSessions = (payload) => ({
    type: statisticsTypes.GET_STATISTICS_SESSIONS,
    ...payload
})

export const getStatisticsTop = (payload) => ({
    type: statisticsTypes.GET_STATISTICS_TOP,
    ...payload
})

export const getStatisticsMonth = (payload) => ({
    type: statisticsTypes.GET_STATISTICS_MONTH,
    ...payload
})

export const getStatisticsCars = (payload) => ({
    type: statisticsTypes.GET_STATISTICS_CARS,
    ...payload
})

export const getStatisticsBrands = (payload) => ({
    type: statisticsTypes.GET_STATISTICS_BRANDS,
    ...payload
})

export const getStatisticsNotifications = (payload) => ({
    type: statisticsTypes.GET_STATISTICS_NOTIFICATIONS,
    ...payload
})

export const getStatisticsDownloads = (payload) => ({
    type: statisticsTypes.GET_STATISTICS_DOWNLOADS,
    ...payload
})

export const getStatisticsDownloadsMonth = (payload) => ({
    type: statisticsTypes.GET_STATISTICS_DOWNLOADS_MONTH,
    ...payload
})

export const sendNotification = (payload) => ({
    type: statisticsTypes.SEND_NOTIFICATION,
    ...payload
})
