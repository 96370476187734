/* eslint-disable */
import React, { useState } from 'react'
import '../styles.scss'

const StationsToLocation = ({stations,setStations,selectedStation,edit}) => {

    return(
<div className="right-full">
    <div className = "right-options-container">
        <div className= "right-inner-options-container" >
                <div style={{display:"flex"}}>
                    <p style={{fontSize:"20px",marginTop:"4%"}}><b>{`Основна информация`}</b></p>
                </div>   
                <div className = "lower-inner-options-container">
                    <div className = "inner-element">
                        <span style={{marginBottom:"3%"}}>Цена</span><br/>
                        <div className = "price-container">
                            <input
                                required
                                className='price'
                                value={stations[selectedStation]?.price || ""}
                                id={`price${selectedStation}`}
                                onChange={(event) => {
                                    if(!/^[0-9]*[\.|\,]{0,1}[0-9]*$/.test(event.target.value)) return
                                    const newStations = stations.slice(0);
                                    newStations[selectedStation].price = event.target.value;
                                    setStations(newStations)
                                }}
                            />
                            <div className='suffix'>лв./kW</div>
                        </div>
                    </div>
            
                    <div className= "inner-element"> 
                        <span>Тип кабел</span><br/>
                        <form>
                            <div className='all-radio' id={selectedStation}>
                                
                                <div className='radio' style={{display:"flex",alignItems:"flex-end",width:"50%"}}><input type="radio" value="AC" name={`AC${selectedStation}`} className='radio-btn' id={`AC${selectedStation}`} 
                                checked={stations[selectedStation]?.voltage !== null && stations[selectedStation]?.voltage === "AC"}
                                onClick={()=>{
                                    const newStations = stations.slice(0);
                                    newStations[selectedStation].voltage = "AC";
                                    setStations(newStations)
                                }}
                                onChange={()=>{}}
                                />{stations[selectedStation]?.voltage === "AC" ? <b>AC (Нискозарядна)</b> : "AC (Нискозарядна)"}</div>

                                <div className='radio' style={{display:"flex",alignItems:"flex-end",width:"50%"}}><input type="radio" value="DC" name={`DC${selectedStation}`} className='radio-btn' id={`DC${selectedStation}`}  
                                checked={stations[selectedStation]?.voltage !== null && stations[selectedStation]?.voltage === "DC"}
                                onClick={()=>{
                                    const newStations = stations.slice(0);
                                    newStations[selectedStation].voltage = "DC";
                                    setStations(newStations)
                                }}
                                onChange={()=>{}}
                                />{stations[selectedStation]?.voltage === "DC" ? <b>DC (Бързозарядна)</b> : "DC (Бързозарядна)"}</div>

                            </div>
                        </form>
                    </div>
                    <div className = "inner-element">
                        <span style={{marginBottom:"3%"}}>Мощност</span><br/>
                        <div className = "power-container">
                            <input
                                required
                                className='power'
                                value={stations[selectedStation]?.power || ""}
                                type="number"
                                id="power"
                                onChange={(event) => {
                                    const newStations = stations.slice(0);
                                    newStations[selectedStation].power = Number(event.target.value);
                                    setStations(newStations)
                                }}
                            />
                            <div className='suffix'>kW</div>
                        </div>
                    </div>
                    <div className = "inner-element">
                        <span style={{marginBottom:"3%"}}>Име на станция</span><br/>
                        <div className = "power-container">
                            <input
                                required
                                className='power'
                                style={{borderRight:"none"}}
                                value={stations[selectedStation]?.name || ""}
                                type="text"
                                id="power"
                                maxLength={7}
                                onChange={(event) => {
                                    const newStations = stations.slice(0);
                                    newStations[selectedStation].name = event.target.value;
                                    setStations(newStations)
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className='second-lower-inner-options-container'>
                        <div className= "inner-element"> 
                            <span style={{marginBottom:"-1"}}>Статус</span><br/>
                            <form>
                                <div className='all-radio' id={selectedStation}> 
                                    <div className='radio' style={{display:"flex",alignItems:"flex-end",width:"50%"}}><input type="radio" value="active" name={`active`} className='radio-btn' id={`active${selectedStation}`} 
                                    checked={stations[selectedStation]?.status !== null && stations[selectedStation]?.status === "active"}
                                    onClick={()=>{
                                        const newStations = stations.slice(0);
                                        newStations[selectedStation].status = "active";
                                        setStations(newStations)
                                    }}
                                    onChange={()=>{}}/>{stations[selectedStation]?.status === "active" ? <b>Активна</b> : "Активна"}</div>

                                    <div className='radio' style={{display:"flex",alignItems:"flex-end",width:"50%"}}><input type="radio" value="support" name={`support${selectedStation}`} className='radio-btn' id={`support${selectedStation}`}  
                                    checked={stations[selectedStation]?.status !== null && stations[selectedStation]?.status === "support"}
                                    onClick={()=>{
                                        const newStations = stations.slice(0);
                                        newStations[selectedStation].status = "support";
                                        setStations(newStations)
                                    }}
                                    onChange={()=>{}}/>{stations[selectedStation]?.status === "support" ? <b>Поддръжка</b> : "Поддръжка"}</div>

                                </div>
                            </form>
                        </div>
                        <div className = "inner-element">
                            <span style={{marginBottom:"3%"}}>ID</span><br/>
                            <div className = "price-container">
                                <input
                                    required
                                    className='price'
                                    type="number"
                                    value={stations[selectedStation]?.chargePointId || ""}
                                    id="serialNumber"
                                    onChange={({target : {value} }) => {
                                        const newStations = stations.slice(0);
                                        newStations[selectedStation].chargePointId = value;
                                        setStations(newStations)
                                    }}
                                />
                            </div>
                        </div>
                        <div className = "inner-element">
                            <span style={{marginBottom:"3%"}}>Сериен Номер</span><br/>
                            <div className = "price-container">
                                <input
                                    required
                                    className='price'
                                    type="number"
                                    value={stations[selectedStation]?.serialFirst.replace(/-/g, "")}
                                    id="serialNumber"
                                    onChange={({target : {value} }) => {
                                        const newStations = stations.slice(0);
                                        newStations[selectedStation].serialFirst = value;
                                        setStations(newStations)
                                    }}
                                />
                            </div>
                        </div>
                        <div className = "inner-element">
                            <span style={{marginBottom:"3%"}}>Сериен Номер 2</span><br/>
                            <div className = "price-container">
                                <input
                                    required
                                    className='price'
                                    type="number"
                                    value={stations[selectedStation]?.serialSecond.replace(/-/g, "")}
                                    id="serialNumber"
                                    onChange={({target : {value}}) => {
                                        const newStations = stations.slice(0);
                                        newStations[selectedStation].serialSecond = event.target.value;
                                        setStations(newStations)
                                    }}
                                />
                            </div>
                        </div>
                </div>                                      
        </div>
        {edit ? 
        <></>
        :<div className='right-down-inner-options-container'>
        {stations[selectedStation]?.outlets.map((outlet,i)=>{
                return(
                <React.Fragment key={i+1}>
                    <div style={{display:"flex",alignItems:"center"}}>
                        <p style={{fontSize:"20px",marginTop:"4%"}}><b>{`Изход към станция ${i+1}`}</b></p>
                        {stations[selectedStation]?.outlets.length > 1 && <div className='delete-station' style={{marginTop:"2%"}} onClick={()=>{
                            const newStations = stations.slice(0);
                            newStations[selectedStation].outlets.splice(i,1);
                            newStations[selectedStation].outlets.map((outlet,j) => {
                                outlet.connectorId = j + 1;
                            })
                            setStations(newStations)
                        }}/>}
                    </div>
                    <div className='outlet-elements'>
                        <div className= "inner-element"> 
                            <span>Тип кабел</span><br/>
                            <form>
                                <div className='all-radio' id={i}>
                                    <div className='radio'><input type="checkbox" value="Type2" name="Type2" className='radio-btn' id={`Type2${i}`}
                                    checked={stations[selectedStation].outlets[i].type && stations[selectedStation].outlets[i].type.includes("Type 2")} 
                                    onClick={()=>{
                                        const newStations = stations.slice(0);
                                        if(newStations[selectedStation].outlets[i].type.includes("Type 2")) newStations[selectedStation].outlets[i].type = newStations[selectedStation].outlets[i].type.filter(x => x!== "Type 2");
                                        else newStations[selectedStation].outlets[i].type.push("Type 2");;
                                        setStations(newStations)
                                    }}
                                    onChange={() => {}} />{stations[selectedStation].outlets[i].type === "Type 2" ? <b>Тип 2</b> : "Тип 2"}</div>

                                    <div className='radio'><input type="checkbox" value="CCS" name="CCS" className='radio-btn' id={`CCS${i}`}  
                                    checked={stations[selectedStation].outlets[i].type && stations[selectedStation].outlets[i].type.includes("CCS")} 
                                    onClick={()=>{
                                        const newStations = stations.slice(0);
                                        if(newStations[selectedStation].outlets[i].type.includes("CCS")) newStations[selectedStation].outlets[i].type = newStations[selectedStation].outlets[i].type.filter(x => x!== "CCS")
                                        else newStations[selectedStation].outlets[i].type.push("CCS");
                                        setStations(newStations)
                                    }}
                                    onChange={() => {}}/>{stations[selectedStation].outlets[i].type === "CCS" ? <b>CCS</b> : "CCS"}</div>

                                    <div className='radio'><input type="checkbox" value="CHAdeMO" name="CHAdeMO" className='radio-btn' id={`CHAdeMO${i}`}
                                    checked={stations[selectedStation].outlets[i].type && stations[selectedStation].outlets[i].type.includes("CHAdeMO")}   
                                    onClick={()=>{
                                        const newStations = stations.slice(0);
                                        if(newStations[selectedStation].outlets[i].type.includes("CHAdeMO")) newStations[selectedStation].outlets[i].type = newStations[selectedStation].outlets[i].type.filter(x => x!== "CHAdeMO");
                                        else newStations[selectedStation].outlets[i].type.push("CHAdeMO");
                                        setStations(newStations)
                                    }}
                                    onChange={() => {}}/>{stations[selectedStation].outlets[i].type === "CHAdeMO" ? <b>CHAdeMO</b> : "CHAdeMO"}</div>
                                </div>
                            </form>
                        </div>
                        <div className = "inner-element">
                            <span style={{marginBottom:"3%"}}>Име на изход</span><br/>
                            <div className = "power-container">
                                <input
                                    required
                                    className='power'
                                    type="text"
                                    value={stations[selectedStation]?.outlets[i]?.name}
                                    id="serialNumber"
                                    onChange={(event) => {
                                        const newStations = stations.slice(0);
                                        newStations[selectedStation].outlets[i].name = event.target.value;
                                        setStations(newStations)
                                    }}
                                />
                            </div>
                        </div>
                        <div className = "inner-element">
                            <span style={{marginBottom:"3%"}}>Идентификатор</span><br/>
                            <div className = "power-container" style={{backgroundColor:"#f0ede6"}}>
                                <input
                                    required
                                    style={{pointerEvents:"none",backgroundColor:"#f0ede6"}}
                                    className='power'
                                    type="number"
                                    value={stations[selectedStation]?.outlets[i].connectorId || ""}
                                    id="serialNumber"
                                    onChange={(event) => {
                                        const newStations = stations.slice(0);
                                        newStations[selectedStation].outlets[i].connectorId = Number(event.target.value);
                                        setStations(newStations)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {i+1 === stations[selectedStation].outlets.length ? <div className='button-container add-section' onClick={()=>{
                        const newStations = stations.slice(0);
                        newStations[selectedStation].outlets.push({
                                name:"",
                                type: [],
                                connectorId: i+2 
                        });
                        setStations(newStations)
                    }}>Добави</div> : <div className="end-line"/>}
                </React.Fragment>)
            })}
        </div>
        }
    </div>          
</div>)
}

export default StationsToLocation;