import moment from "moment";
import React, { useState } from "react";
import { connect } from "react-redux";
import { setOpenedTicket, supportSocketEmit } from "../../actions";
import "./styles.scss";

const TicketsContainer = ({ tickets, openedTicket, supportSocketEmit, setOpenedTicket, setButtonClicked, selectedTab, data }) => {
  const [tabs, setTabs] = useState(0);

  return (
    <div className="tickets-container">
      <div className="first-part">
        <p className="inner-title">
          <b>Отворени теми</b>
        </p>
        <div
          className="button new-chat"
          onClick={() => {
            setTabs(tabs + 1);
            setButtonClicked(true);
            setOpenedTicket(null);
          }}
        >
          <span style={{ fontSize: "14px", whiteSpace: "nowrap" }}>НОВ ЧАТ</span>
          <div className="chat-image" />
        </div>
      </div>
      <div className={`inner-tickets-container ${tickets.length === 0 && "empty-container"}`}>
        {data.length === 0 && <p className="empty">Добре дошли в помощния център! В момента нямате отворени теми в помощния център. Ако искате да се свържете с нас, моля започнете нов чат</p>}
        {data.map(function (item, i) {
          return (
            <div
              className={`ticket ${item._id === openedTicket && "selected"}`}
              id={i}
              key={i}
              onClick={() => {
                setOpenedTicket(item._id);
                supportSocketEmit({
                  action: "nextMessages",
                  payload: {
                    ticket: item._id,
                    pageNumber: 1,
                    pageSize: 15,
                    sortBy: "",
                    noPagination: false,
                  },
                });
              }}
            >
              <div className={`indicator ${item?._unreadCustomer > 0 && "show"}`} id={"indicator"} />
              <div className="text-and-arrow">
                <div className="left-side">
                  <p className={`ticket-title`}>{item.name.length > 15 ? item.name.substring(0, 15) + "..." : item.name}</p>
                  <p className={`date`}>Създаден на: {moment(item.createdAt).format("DD.MM.YYYY | HH:mm")} </p>
                </div>

                <div className="right-side">
                  <div className={`arrow-right  ${selectedTab === item._id && "selected"}`} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  tickets: state.support.tickets,
  openedTicket: state.support.openedTicket,
});
const mapDispatchToProps = (dispatch) => ({
  setOpenedTicket: (payload) => dispatch(setOpenedTicket(payload)),
  supportSocketEmit: (payload) => dispatch(supportSocketEmit(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketsContainer);
