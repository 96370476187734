/* eslint-disable */
import React, { useState } from "react";
import "./styles.scss";
import { ic_keyboard_arrow_down } from "react-icons-kit/md/ic_keyboard_arrow_down";
import { Icon } from "react-icons-kit";
import text from "./example.json";
import * as sortFunctions from "../../utilities/sortFunctions";
import { history } from "../../config/stores";
import HeaderFilterRow from "../HeaderFilterRow/HeaderFilterRow";
const example = text;

const headerElements = [
  {
    name: "Име",
    query: { filter: "", options: ["Ваучер OMV"] },
  },
  {
    name: "Статус",
    query: { filter: "", options: ["Активен"] },
  },
  {
    name: "Стойност",
    query: { filter: "", options: ["100 лв.", "150 лв.", "200 лв."] },
  },
  {
    name: "Брой от серия",
    query: { filter: "", options: ["200", "100", "50"] },
  },
  {
    name: "Използван",
    query: { filter: "", options: ["40 пъти", "10 пъти", "8 пъти"] },
  },
  {
    name: "Валиден до",
    query: { filter: "", options: ["31.12.2022"] },
  },
  {
    name: "Кодове",
    query: { filter: "", options: ["134000-134200", "150000-150100", "140000-140050"] },
  },
  {
    name: "Последно използван",
    query: { filter: "", options: ["10.11.2021 | 12:20"] },
  },
  {
    name: "Потребител",
    query: { filter: "", options: ["+359888999000"] },
  },
];

const GroupsGrid = () => {
  const [sortBy, setSortBy] = useState("");
  const [selectedTab, setSelectedTab] = useState("");

  const [coordinates, setCoordinates] = useState({
    display: "none",
    x: "0%",
    y: "0%",
  });
  const [show, setShow] = useState(false);
  const [container, setContainer] = useState({});
  const [indexx, setIndex] = useState(0);
  const [originalOrder, setOriginalOrder] = useState([]);

  function set_Popup(elem) {
    let rect = elem.getBoundingClientRect();
    if (elem.id === "10") {
      setCoordinates({
        display: "block",
        x: `${rect.x - 200}px`,
        y: `${rect.y + 50}px`,
      });
    } else
      setCoordinates({
        display: "block",
        x: `${rect.x + 50}px`,
        y: `${rect.y + 50}px`,
      });
  }

  return (
    <>
      <div className="full-to-group" id="blurred">
        {selectedTab === "history" ? (
          <div className="to-group">
            <div className="to-group-first">
              <p className="header-text">История за осребряване</p>
              <div
                className="close"
                onClick={() => {
                  document.getElementById("blurred").style.display = "none";
                }}
              />
            </div>
            <div className="to-group-second">
              <div className="inner-to-group">
                <p style={{ fontSize: "17px" }}>
                  <b>10.11.2021 | 12:20</b>
                </p>
                <div className="first">
                  <p style={{ fontSize: "17px", marginTop: "-2px" }}>
                    Потребител: <b style={{ color: "#00D688", fontSize: "17px" }}>+359888999000</b>{" "}
                  </p>
                </div>
                <div className="first">
                  <p style={{ fontSize: "17px", marginTop: "-2px" }}>
                    Автомобил: <b style={{ fontSize: "17px" }}>BMW i3</b>
                  </p>
                </div>
              </div>
              <div className="inner-to-group">
                <p style={{ fontSize: "17px" }}>
                  <b>10.11.2021 | 12:20</b>
                </p>
                <div className="first">
                  <p style={{ fontSize: "17px", marginTop: "-1%" }}>
                    Потребител: <b style={{ color: "#00D688", fontSize: "17px" }}>+359888999000</b>{" "}
                  </p>
                </div>
                <div className="first">
                  <p style={{ fontSize: "17px", marginTop: "-1%" }}>
                    Автомобил: <b style={{ fontSize: "17px" }}>BMW i3</b>
                  </p>
                </div>
              </div>
              <div className="inner-to-group">
                <p style={{ fontSize: "17px" }}>
                  <b>10.11.2021 | 12:20</b>
                </p>
                <div className="first">
                  <p style={{ fontSize: "17px", marginTop: "-1%" }}>
                    Потребител: <b style={{ color: "#00D688", fontSize: "17px" }}>+359888999000</b>{" "}
                  </p>
                </div>
                <div className="first">
                  <p style={{ fontSize: "17px", marginTop: "-1%" }}>
                    Автомобил: <b style={{ fontSize: "17px" }}>BMW i3</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="tabs-groups-container scroll-width-thin">
        {headerElements.map((element, index) => {
          return (
            <div className={`groupp-tab`} key={index}>
              <span className="inner-text">{`${element.name}`}</span>
              <div className={`icon-container ${sortBy === element.name && "rotate"}`}>
                <Icon
                  size={"80%"}
                  icon={ic_keyboard_arrow_down}
                  className={`arrow-down`}
                  id={index}
                  onClick={() => {
                    var All_tabs = document.querySelectorAll(".full-inner-tab");
                    All_tabs = Array.prototype.slice.call(All_tabs, 0);
                    setOriginalOrder(All_tabs);
                    if (sortBy === element.name) {
                      setSortBy("");
                      setCoordinates({ display: "none" });
                      sortFunctions.reverse_sortBy_(originalOrder, "all-stations");
                    } else {
                      setSortBy(element.name);
                      setContainer(element);
                      setShow(true);
                      setIndex(index);
                      let elem = document.getElementById(`${index}`);
                      set_Popup(elem);
                    }
                  }}
                />
              </div>
              {show ? (
                <HeaderFilterRow
                  coordinates={coordinates}
                  element={container}
                  index={indexx}
                  onClose={() => {
                    setShow(false);
                  }}
                />
              ) : (
                ""
              )}
            </div>
          );
        })}
      </div>
      <div className="all-groups" id={"all-stations"}>
        {Object.keys(example).map(function (key, i) {
          return (
            <React.Fragment key={i}>
              <div className={`full-inner-tab`} key={i} id={i}>
                {Object.keys(example[i + 1]).map(function (key, j) {
                  return (
                    <div
                      className={`innerr-tab`}
                      id={key}
                      key={j}
                      onClick={() => {
                        if (j === 1) history.push(`voucher/${i}`);
                        else return;
                      }}
                    >
                      {j === 0 ? (
                        <>
                          <div
                            className="history"
                            onClick={() => {
                              document.getElementById("blurred").style.display = "block";
                              setSelectedTab("history");
                            }}
                          />
                          <span className="inner-tab-text first">{example[i + 1][j + 1]}</span>
                        </>
                      ) : (
                        <span className="inner-tab-text">{example[i + 1][j + 1]}</span>
                      )}
                    </div>
                  );
                })}
                <div className="edit" />
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

export default GroupsGrid;
