import io from "socket.io-client";
import { ofType, ActionsObservable } from "redux-observable";
import { map, mergeMap, catchError, switchMap } from "rxjs/operators";
import {
  socketTypes,
  supportSocketConnected,
  supportTypes,
  sessionSocketConnected,
  // adminSettingsTypes
} from "../actions";
import { fromEvent } from "rxjs";
import { SOCKET_API } from "../config/settings";

let supportSocket;
let sessionSocket;

const SUPPORT_API = "https://customer-support-dev.herokuapp.com";
//const SUPPORT_API = 'https://customer-support-testing.herokuapp.com';
const SOCKET_SUPPORT_EVENT = "socket-message";

// support
export const supportConnectEpic = (action$, state$) =>
  action$.pipe(
    ofType(supportTypes.SUPPORT_SOCKET_CONNECT),
    map(({ payload }) => {
      const query = payload.query;
      if (supportSocket) {
        supportSocket.disconnect();
      }
      supportSocket = io(SUPPORT_API + query);
      return supportSocketConnected();
    })
  );

export const supportListenerEpic = (action$, state$) =>
  action$.pipe(
    ofType(supportTypes.SUPPORT_SOCKET_CONNECTED),
    mergeMap(() =>
      fromEvent(supportSocket, SOCKET_SUPPORT_EVENT).pipe(
        switchMap(({ action, payload }) => {
          return ActionsObservable.create((obs) => {
            obs.next({ type: action, payload });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.complete();
          });
        })
      )
    )
  );

export const supportSenderEpic = (action$) =>
  action$.pipe(
    ofType(supportTypes.SUPPORT_SOCKET_EMIT_EVENT),
    switchMap(({ payload }) =>
      ActionsObservable.create((obs) => {
        supportSocket.emit(SOCKET_SUPPORT_EVENT, payload);
        obs.complete(SOCKET_SUPPORT_EVENT, payload);
      })
    )
  );

export const supportDisconnectEpic = (action$) =>
  action$.pipe(
    ofType(supportTypes.SUPPORT_SOCKET_DISCONNECT),
    switchMap(() =>
      ActionsObservable.create((obs) => {
        if (supportSocket) {
          supportSocket.disconnect();
        }
        obs.complete();
      })
    )
  );

// session
export const sessionConnectEpic = (action$, state$) =>
  action$.pipe(
    ofType(socketTypes.SESSION_CONNECT),
    map(({ payload }) => {
      if (sessionSocket) {
        sessionSocket.disconnect();
      }
      sessionSocket = io(SOCKET_API, { query: payload.sessionQuery });
      if (sessionSocket) {
        sessionSocket.emit(SOCKET_SUPPORT_EVENT, {
          action: "joinCommandRoom",
        });
      }
      return sessionSocketConnected();
    })
  );

export const sessionListenerEpic = (action$, state$) =>
  action$.pipe(
    ofType(socketTypes.SESSION_CONNECTED),
    mergeMap(() => {
      return fromEvent(sessionSocket, SOCKET_SUPPORT_EVENT).pipe(
        map(({ action, payload }) => {
          return { type: action, payload };
        }),
        catchError((err) => {
          console.error(err);
        })
      );
    })
  );

export const sessionSenderEpic = (action$) =>
  action$.pipe(
    ofType(socketTypes.SESSION_EMIT_EVENT),
    switchMap(({ payload }) =>
      ActionsObservable.create((obs) => {
        if (sessionSocket) {
          sessionSocket.emit(SOCKET_SUPPORT_EVENT, payload);
        }
        obs.complete();
      })
    )
  );

export const sessionDisconnectEpic = (action$) =>
  action$.pipe(
    ofType(socketTypes.SESSION_DISCONNECT),
    switchMap(() =>
      ActionsObservable.create((obs) => {
        if (sessionSocket) {
          sessionSocket.disconnect();
        }
        obs.complete();
      })
    )
  );
