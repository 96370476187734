/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { exportToXLSX } from "../../actions";
import { toast } from "react-toastify";
import { API } from "../../config/settings";
import { Headers } from "../../utilities";
import axios from "axios";
import moment from "moment";

const ExportModal = ({ handleClose, type, title }) => {
  const [payload, setPayload] = useState({ startDate: "", endDate: "" });
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <div className={`delete-modal-container`}>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          height: "60px",
        }}
      >
        <h3 className="title" style={{ width: "95%" }}>
          {title || "EXPORT DATA"}
        </h3>
        <div className="close-icon" onClick={() => handleClose()} />
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "40%",
          alignItems: "center",
        }}
      >
        <div className="date-cont">
          <label style={{ width: "100%", textAlign: "left" }}>Начална дата</label>
          <input
            className={`inner-input`}
            style={{
              width: "98%",
              borderRadius: "3px",
              border: "1px solid #80808080",
              marginTop: "2%",
            }}
            type="date"
            min={"1930-01-01"}
            max={new Date().toISOString().split("T")[0]}
            placeholder={"yyyy-mm-dd"}
            value={payload.startDate}
            onChange={(e) => {
              setPayload({
                ...payload,
                startDate: e.target.value,
              });
            }}
          />
        </div>
        <div className="date-cont">
          <label style={{ width: "100%", textAlign: "left" }}>Крайна дата</label>
          <input
            className={`inner-input`}
            style={{
              width: "98%",
              borderRadius: "3px",
              border: "1px solid #80808080",
              marginTop: "2%",
            }}
            type="date"
            min={"1930-01-01"}
            max={new Date().toISOString().split("T")[0]}
            placeholder={"yyyy-mm-dd"}
            value={payload.endDate}
            onChange={(e) => {
              setPayload({
                ...payload,
                endDate: e.target.value,
              });
            }}
          />
        </div>
      </div>
      {errorMessage ? <div className="error-msg">{`Няма намерени ${type === "invoice" ? "фактури" : "данни"} за този период`}</div> : loading ? <div className="loading-msg">Изтегляне...</div> : <></>}
      <div
        className="button-container"
        style={{
          marginTop: "0%",
          marginLeft: "0%",
          height: "21%",
          width: "100%",
          fontSize: "14px",
          marginTop: "1%",
        }}
        onClick={() => {
          if (type === "invoice") {
            if (payload?.startDate?.length < 1) {
              setLoading(true);
              axios({
                url: `${API}finance/export/invoices`,
                method: "POST",
                responseType: "blob",
                headers: Headers.get_auth(),
              })
                .then((response) => {
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", "file.zip"); //or any other extension
                  document.body.appendChild(link);
                  link.click();
                  setErrorMessage(false);
                  setLoading(false);
                })
                .catch(function (error) {
                  setErrorMessage(true);
                  setLoading(false);
                });
            } else {
              setLoading(true);
              axios({
                url: `${API}finance/export/invoices`,
                method: "POST",
                responseType: "blob",
                headers: Headers.get_auth(),
                data: { ...payload, startDate: moment(payload?.startDate).startOf("day").toISOString(), endDate: moment(payload?.endDate).endOf("day").toISOString() },
              })
                .then((response) => {
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", "file.zip"); //or any other extension
                  document.body.appendChild(link);
                  link.click();
                  setErrorMessage(false);
                  setLoading(false);
                })
                .catch(function (error) {
                  setErrorMessage(true);
                  setLoading(false);
                  console.error(error.response);
                });
            }
          } else {
            if (payload.startDate.length < 1) {
              axios({
                url: `${API}finance/income/export`,
                method: "POST",
                responseType: "blob",
                headers: Headers.get_auth(),
              }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "file.xlsx"); //or any other extension
                document.body.appendChild(link);
                link.click();
              });
            } else {
              axios({
                url: `${API}finance/income/export`,
                method: "POST",
                responseType: "blob",
                headers: Headers.get_auth(),
                data: { ...payload },
              }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "file.xlsx"); //or any other extension
                document.body.appendChild(link);
                link.click();
              });
            }
          }
        }}
      >
        ЕКСПОРТ
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  exportToXLSX: (payload) => dispatch(exportToXLSX(payload)),
});
export default connect(null, mapDispatchToProps)(ExportModal);
