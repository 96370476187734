/* eslint-disable */
import React,{ useState,useEffect,useRef} from 'react'
import {connect} from "react-redux"
import { getUsers,getBusinessUsers } from '../../actions'
import BusinessUsersGrid from '../../components/UsersGrid/BusinessUsersGrid'
import UsersGrid from '../../components/UsersGrid/UsersGrid'
import { history } from '../../config/stores'
import './styles.scss'

const fieldNames = ['name',
    'active',
    'role',
    'phoneNumber', 
    'createdAt', 
]
const businessFieldNames = ['name',
    'active',
    'businessGroup',
    'phoneNumber', 
    'createdAt', 
]


const Users = ({getUsers,getBusinessUsers,businessUsers,allUsers}) =>{ 

    const [selectedTab,setSelectedTab] = useState("inside");
    const [currentPage,setCurrentPage] = useState(0);
    const [hasMore,setHasMore] = useState(true);
    const [businessCurrentPage,setBusinessCurrentPage] = useState(0);
    const [businessHasMore,setBusinessHasMore] = useState(true);
    const usersTableRef = useRef();
    const businessUsersTableRef = useRef()

    useEffect(()=>{
        getUsers({
            pageNumber: currentPage,
            pageSize: "10",
            sortBy: "",
            noPagination: false,
            onSuccess: (response) => {
                setCurrentPage(response.payload.page)
                setHasMore(response.payload.hasNextPage)
            },
        })
        
    },[])

    const fetchMoreUsers = (type) => {
        if(type === "business"){
            if(businessHasMore){
                getBusinessUsers({
                    type:"partner",
                    pageNumber: businessCurrentPage + 1,
                    pageSize: "10",
                    sortBy: "",
                    noPagination: false,
                    onSuccess: (response) => {
                        setBusinessCurrentPage(response.payload.page)
                        setBusinessHasMore(response.payload.hasNextPage)
                    },
                })
            }
            else return
        }
        else if(type === "inside"){
            if(hasMore){
                getUsers({
                    pageNumber:currentPage + 1,
                    pageSize:"10",
                    sortBy:"",
                    noPagination: false,
                    search:"",
                    onSuccess: (response) => {
                        setCurrentPage(response.payload.page)
                        setHasMore(response.payload.hasNextPage)
                    },
                })
            }
            else return
        }   
        else return
    }


    return (
    <div className='stations-container'>
        <div className='inner-stations-container'>
                <div className='upperr-stations-container'>
                        <div className='first-containerr' style={{display:"flex",width:"50%"}}>
                            <div className={`button-container first ${selectedTab === "inside" && "selected"}`} style={{width:"23%"}} onClick={() => {
                                setSelectedTab("inside");
                                if(currentPage === 1) return
                                getUsers({
                                    pageNumber:currentPage + 1,
                                    pageSize:"10",
                                    sortBy:"",
                                    noPagination: false,
                                    search:"",
                                    onSuccess: (response) => {
                                        setCurrentPage(response.payload.page)
                                        setHasMore(response.payload.hasNextPage)
                                    }
                                })
                               
                            }}>Вътрешни</div>
                            <div className={`button-container second ${selectedTab === "business" && "selected"}`} style={{width:"23%"}} onClick={() => {
                                setSelectedTab("business");
                                if(businessCurrentPage === 1) return
                                else{
                                    getBusinessUsers({
                                        type:"partners",
                                        pageNumber: businessCurrentPage + 1,
                                        pageSize: "10",
                                        sortBy: "",
                                        noPagination: false,
                                        onSuccess: (response) => {
                                            setBusinessCurrentPage(response.payload.page)
                                            setBusinessHasMore(response.payload.hasNextPage)
                                        },
                                    })
                                }
                            }}>Бизнес</div>
                        </div>
                        <div className='first-containerr' style={{display:"flex",width:"50%",justifyContent:"flex-end"}}>
                           <div className='button-container' style={{height:"40px",width:"23%",marginTop:"1.5%",marginRight:"2%"}} onClick={()=>{history.push("users/add")}}>Добави</div> 
                        </div>
                        
                </div>
                <div className='stations-content'>
                    {selectedTab === "inside" ?
                        <UsersGrid allUsers={allUsers} 
                        selectedTab={selectedTab} 
                        fields={selectedTab === "inside" ? fieldNames : businessFieldNames} 
                        tableRef={usersTableRef} 
                        fetchMoreUsers={fetchMoreUsers}
                        setHasMore={setHasMore}
                        />
                    : <BusinessUsersGrid allUsers={businessUsers} 
                        selectedTab={selectedTab} 
                        fields={selectedTab === "inside" ? fieldNames : businessFieldNames} 
                        tableRef={businessUsersTableRef} 
                        fetchMoreUsers={fetchMoreUsers}
                        setBusinessHasMore={setBusinessHasMore}
                        />
                    }
                </div>
        </div>
            
    </div>
    )
}


const mapStateToProps = (state) =>({
    allUsers:state.login.allUsers,
    businessUsers:state.login.businessUsers
})

const mapDispatchToProps = (dispatch) => ({
  getUsers: (payload) => dispatch(getUsers(payload)),
  getBusinessUsers: (payload) => dispatch(getBusinessUsers(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Users);
