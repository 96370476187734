import React, { useEffect, useRef, useState } from "react";
import Inputs2 from "../Inputs2";
import { isArray, omit, uniqBy } from "lodash";

const FiltersPopup = ({ close, filter: filterProp = {}, filterOptions, handleSubmit }) => {
  const { type, key, options, fetch, transform } = filterOptions;

  const [filter, setFilter] = useState(filterProp);
  const [query, setQuery] = useState("");

  const [dbOptions, setDbOptions] = useState([]);
  const allOptions = uniqBy([...(isArray(filter[key]) ? filter[key] : []), ...dbOptions], "value");
  const firstRender = useRef(true);
  useEffect(() => {
    if (!firstRender.current || type !== "dbDropdown" || !["departments"].includes(key)) return;
    firstRender.current = false;
    fetch()
      .then(({ data: { payload } }) => setDbOptions(transform(payload)))
      .catch((error) => console.error(error));
  }, [dbOptions.length, fetch, key, transform, type]);

  const timerRef = useRef(null);
  useEffect(() => {
    const timer = timerRef.current;
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);
  // const handleUpdateDbOptions = (pattern) => {
  //   if (timerRef.current) clearTimeout(timerRef.current);
  //   timerRef.current = setTimeout(
  //     () =>
  //       fetch({ pattern })
  //         .then(({ data: { payload } }) => setDbOptions(transform(payload)))
  //         .catch((error) => console.error(error)),
  //     300
  //   );
  // };

  const renderContent = () => {
    switch (type) {
      case "checkboxes":
        return (
          <Inputs2.Checkboxes
            style={{ maxHeight: "400px", overflow: "auto" }}
            column
            full
            options={options}
            value={filter[key] || ""}
            onChange={({ value }) => {
              const current = filter[key] ?? [];
              if (current.includes(value)) setFilter({ ...filter, [key]: current.filter((v) => v !== value) });
              else setFilter({ ...filter, [key]: [...current, value] });
            }}
          />
        );
      case "sort":
        return (
          <div className="flex flex-col gap-3">
            <Inputs2.RadioButtons
              options={[
                { label: "Възходящо", value: 1 },
                { label: "Низходящо", value: -1 },
              ]}
              column
              value={filter?.sortBy?.[key]}
              onChange={(opt) => {
                setFilter({ ...filter, sortBy: { [key]: opt } });
              }}
            />
          </div>
        );
      case "regex":
        return (
          <div className="flex flex-col gap-3">
            <Inputs2.TextInput placeholder="Търси" value={filter?.[key]?.pattern} onChange={({ target }) => setFilter({ ...filter, [key]: { ...filter?.[key], pattern: target.value } })} {...(query && { suffix: <div className="close-icon h-3 w-3 mr-2" onClick={() => setQuery("")} /> })} />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="filters-popup-container">
      <div className="filter-popup-header">
        <p>{type === "sort" ? "Сортирай по" : "Филтрирай по"}</p>
        <div className="close-icon w-3 h-3" onClick={close} />
      </div>
      <div className="filters-popup-content px-[10px] py-[10px]">{renderContent()}</div>
      {(type !== "dbDropdown" || filter[key] || allOptions.length > 0) && (
        <div className="filters-popup-footer ">
          <Inputs2.Button
            text="ИЗЧИСТИ"
            className={"border border-[#515867] w-1/2"}
            style={{ marginTop: "0px", marginLeft: "0px" }}
            onClick={() => {
              if (filter?.sortBy && Object.keys(filter?.sortBy).length) handleSubmit(omit(filter, "sortBy"));
              else handleSubmit(omit(filter, [key]));

              close();
            }}
          />
          <Inputs2.Button
            text="ЗАПАЗИ"
            className={"selected w-1/2"}
            style={{ marginTop: "0px", marginLeft: "0px" }}
            // disabled={!filter[key] || !filter?.sortBy[key]}
            onClick={() => {
              handleSubmit(filter);
              close();
            }}
          />
        </div>
      )}
    </div>
  );
};

export default FiltersPopup;
