/* eslint-disable */
import React, { useState } from 'react'
import './styles.scss'
import {InnerHeader} from "../../components"
import Datepicker from '../../components/DatePicker/DatePicker'
import moment from 'moment'


const AddVoucher = () => {
    const [counter,setCounter] = useState(1);
    const [voucherName,setVoucherName] = useState("");
    const [amount,setAmount] = useState("");
    const [vouchersAmount,setVouchersAmount] = useState("");
    const [startCode,setStartCode] = useState("");
    const [endCode,setEndCode] = useState("");
    const [date,setDate] = useState();
    
    return(
    <>
        <div className='full-add-container'>
        <InnerHeader link={"/vouchers"} first="Добавяне на ваучери" addStation={1}/>   

            {Array.apply(0, Array(counter)).map(function (x, i) {
                return(
                    <div className='section-container' id={i+1}>
                <h2>Ваучер {i+1}</h2>
                <div className='inner-section-container'>
                        <div className='first-input'>
                            <span className='input-title'>Име на ваучер</span>
                            <input
                                required
                                className='input-field'
                                type="text"
                                id="voucher-name"
                                value={voucherName}
                                onChange={(event) => setVoucherName(event.target.value)}
                            />
                        </div>
                        <div className='first-input' style={{marginLeft:"2%",width:"30%",marginTop:"-0.1%",marginRight:"2%"}}>
                            <span className='input-title' style={{marginBottom:"3%"}}>Стойност</span>
                            <div className = "sum-container">
                                    <input
                                        required
                                        className='sum'
                                        type="number"
                                        value={amount}
                                        id="partial"
                                        onChange={(event) => {
                                            setAmount(event.target.value)
                                        }}
                                    />
                                    <div className='suffix'>лв.</div>
                            </div>
                            
                        </div>
                        <div className='third-input'>
                            <span className='input-title' style={{marginTop:"-2%",marginBottom:"2%",whiteSpace:"nowrap"}}>Брой от серия</span>
                            <input
                                required
                                className='input-field'
                                type="number"
                                id="count"
                                value={vouchersAmount}
                                onChange={(event) => setVouchersAmount(event.target.value)}
                            />
                        </div>
                        <div className='fourth-input'>
                            <span className='input-title'  style={{marginTop:"-2%",marginBottom:"2%"}}>Начален код</span>
                            <input
                                required
                                className='input-field'
                                type="number"
                                id="start-code"
                                value={startCode}
                                onChange={(event) => setStartCode(event.target.value)}
                            />
                        </div>
                        <div className='fourth-input'>
                            <span className='input-title'  style={{marginTop:"-2%",marginBottom:"2%"}}>Краен код</span>
                            <input
                                required
                                className='input-field'
                                type="number"
                                id="end-code"
                                value={endCode}
                                onChange={(event) => setEndCode(event.target.value)}
                            />
                        </div>
                        <div className='fourth-input'>
                            <span className='input-title'  style={{marginTop:"-2%",marginBottom:"2%"}}>Валидност</span>
                            <Datepicker
                             pickDate={moment(date).toDate()}
                             onChange={(date)=> {setDate(date)}}
                             value={moment(date).format("DD.MM.YYYY")}
                            />
                        </div>
                        <div className='last-input' style={{marginLeft:"1%"}}>
                            <span className='input-title'  style={{marginTop:"-2%",marginBottom:"2%"}}>Статус</span>
                            <div className='all-radio padded'>
                                <div className='radio' style={{display:"flex",alignItems:"flex-end",marginRight:"4%"}}><input type="radio" value="active" name="state" className='radio' id="active"/>Активен</div>
                                <div className='radio' style={{display:"flex",alignItems:"flex-end"}}><input type="radio" value="inactive" name="state" className='radio' id="inactive"/>Неактивен</div>
                            </div>
                        </div>
                </div>
                {i+1 === counter ? <div className='button-container add-section-voucher' onClick={()=>{
                    setCounter(counter+1);
                    document.getElementById(`${i+1}`).style.height="200px";
                }}>Добави</div> : ""}
                </div>)
            })}
            
        </div>
    </>
    )
}
export default AddVoucher;