/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { history } from "../../config/stores";
import { connect } from "react-redux";
import { logOut } from "../../actions";
import "./styles.scss";
import { User } from "../../utilities";
import Popup from "reactjs-popup";
import { Statistics } from "../"
import { exportAppStatistics } from "../../utilities/helperFunctions"

function useOutsideAlerter(ref) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        document.getElementById("drowdown-content").style.display = "none";
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const Header = ({ ref, logOut }) => {
  const location = useLocation().pathname;
  const [showDropdown, setShowDropdown] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  // let groupId = location.split('/').pop()

  return (
    <div className="header-container">
      <div className="left-header-container">
        {location?.includes('/locations/settings/') ? 
        <div className="header-go-back" onClick={() => history.goBack()}>{`<Настройки на станция`}</div> :
        location?.includes('/location/') ? 
        <div className="header-go-back" onClick={() => history.goBack()}>{`<Станции`}</div> :
        (location?.includes('/edit/group/')) ? 
        <div className="header-go-back" onClick={() => history.goBack()}>{`<Детайли за група`}</div> :
        location?.includes('/group/') && !(location.includes('/edit/') && location.includes('/add')) ? 
        <div className="header-go-back" onClick={() => history.push('/groups')}>{`<Бизнес групи`}</div> :
        location?.includes('/client/') ? 
        <div className="header-go-back" onClick={() => history.push('/finances/deposits')}>{`<Финанси`}</div> :
        // <div className="header-go-back" onClick={() => history.push('/finances')}>{`<Финанси`}</div> :
        <div className="header-logo" onClick={() => history.push("/")} />}
      </div>

      <div className="middle-header-container">
        <div
          className={`header-element ${location === "/" && "selected"} 
            ${location === "/locations/add" && "selected"} ${(location.includes(`/location`) && location !== "/locations/select") && "selected"
            }`}
          onClick={() => history.push("/")}
        >
          <div
            className={`header-inner-element ${location === "/" && "selected"} 
                ${location === "/locations/add" && "selected"} ${(location.includes(`/location`) && location !== "/locations/select") && "selected"
              } `}
          >
            Станции
          </div>
        </div>
        <div
          className={`header-element ${(location === "/groups" || location === "/locations/select") && "selected"}
            ${location === "/groups/add" && "selected"} ${location === `/group/${0 - 9}` && "selected"
            }`}
          onClick={() => history.push("/groups")}
        >
          <div
            className={`header-inner-element ${(location === "/groups" || location === "/locations/select") && "selected"
              }
                ${location === "/groups/add" && "selected"} ${location === `/group/${0 - 9}` && "selected"
              }`}
          >
            Бизнес групи
          </div>
        </div>
        {User.isAdmin && (
          <div
            className={`header-element ${location === "/vouchers" && "selected"}
            ${location === "/voucher/add" && "selected"} `}
            onClick={() => history.push("/vouchers")}
          >
            <div
              className={`header-inner-element ${location === "/vouchers" && "selected"
                }
                ${location === "/voucher/add" && "selected"}`}
            >
              Ваучери
            </div>
          </div>
        )}

        <div
          className={`header-element ${(location === "/finances" || location === "/finances/deposits" || location?.includes("/client/")) && "selected"}`}
          onClick={() => history.push("/finances")}
        >
          <div
            className={`header-inner-element ${(location === "/finances" || location === "/finances/deposits" || location?.includes("/client/")) && "selected"}`}
          >
            Финанси
          </div>
        </div>
        <div
          className={`header-element ${location === "/cars" && "selected"}
          ${location === "/cars/add" && "selected"}
          `}
          onClick={() => history.push("/cars")}
        >
          <div
            className={`header-inner-element ${location === "/cars" && "selected"
              } ${location === "/cars/add" && "selected"}`}
          >
            Автомобили
          </div>
        </div>

        <div
          className={`header-element ${location === "/statistics" && "selected"}`}
          onClick={() => history.push("/statistics")}
        >
          <div
            className={`header-inner-element ${location === "/statistics" && "selected"
              }`}
          >
            Приложение
          </div>
        </div>
        <div
          className={`header-element ${location === "/website" && "selected"}`}
          onClick={() => history.push("/website")}
        >
          <div
            className={`header-inner-element ${location === "/website" && "selected"
              }`}
          >
            Сайт
          </div>
        </div>



      </div>

      <div className="right-container">
        {location === "/statistics" && <Popup
          className="popup-export-options"
          position="bottom right"
          trigger={<div className="icon icon-export" />}
        >
          {close => <Statistics.ExportOptions close={close} handleExort={exportAppStatistics} />}
        </Popup>}
        <div
          className={`dropdown ${showDropdown && "show"}`}
          onClick={() => {
            if (showDropdown) setShowDropdown(false);
            else setShowDropdown(true);
          }}
        ></div>
        {showDropdown && (
          <>
            <div
              className="dropdown-content"
              ref={wrapperRef}
              id="drowdown-content"
            >
              <div className="profile">
                <div className="text">Профил и настройки</div>
                <div
                  className="exit"
                  onClick={() => {
                    setShowDropdown(false);
                  }}
                ></div>
              </div>
              <div className="info">
                <div className="name">{User.getUser().name}</div>
                <div className="phone-number">{User.getUser().phoneNumber}</div>
              </div>
              {User.getRole() === "admin" && (
                <div className="settings">
                  <div className="settings-text">Настройки потребители</div>
                  <div
                    className="green-arrow"
                    onClick={() => {
                      setShowDropdown(false);
                      history.push("/users");
                    }}
                  ></div>
                </div>
              )}

              <div className="settings">
                <div className="settings-text">Помощен център</div>
                <div
                  className="green-arrow"
                  onClick={() => {
                    document.getElementById("drowdown-content").style.display =
                      "none";
                    history.push("/support");
                  }}
                ></div>
              </div>
              <div className="log-out">
                <div className="log-out-text">Изход</div>
                <div
                  className="red-arrow"
                  onClick={() => {
                    /*User.signout().then(() => {
                      window.localStorage.clear();
                      history.push("/login");
                    });*/
                    logOut();
                    history.push("/login");
                  }}
                ></div>
              </div>
            </div>
          </>
        )}
      </div>
    </div >
  );
};
const mapStateToProps = (state) => ({
  token: state.login.token,
});
const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch(logOut()),
});
export default connect(null, mapDispatchToProps)(Header);
