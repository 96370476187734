import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { ajax } from "rxjs/ajax";
import { statisticsTypes } from "../actions";
import { API } from "../config/settings";
import { Headers } from "../utilities";

export const getStatisticsUsers = (action$) => action$.pipe(
    ofType(statisticsTypes.GET_STATISTICS_USERS),
    switchMap(({ payload, onSuccess }) => ajax({
        url: `${API}admin/stat/users`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
    }).pipe(
        switchMap(({ response }) => ActionsObservable.create((obs) => {
            obs.next({ type: statisticsTypes.GET_STATISTICS_SUCCESS, payload: { type: 'downloads', loading: false, ...response.payload } })
            if (onSuccess) onSuccess(response)
            obs.complete()
        })),
        catchError((err) => ActionsObservable.create((obs) => {
            console.error(err)
            obs.complete()
        }))
    ))
)

export const getStatisticsActive = (action$) => action$.pipe(
    ofType(statisticsTypes.GET_STATISTICS_ACTIVE),
    switchMap(({ payload, onSuccess }) => ajax({
        url: `${API}admin/stat/active`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
    }).pipe(
        switchMap(({ response }) => ActionsObservable.create((obs) => {
            obs.next({ type: statisticsTypes.GET_STATISTICS_SUCCESS, payload: { type: 'active', loading: false, ...response.payload } })
            if (onSuccess) onSuccess(response)
            obs.complete()
        })),
        catchError((err) => ActionsObservable.create((obs) => {
            console.error(err)
            obs.complete()
        }))
    ))
)

export const getStatisticsSessions = (action$) => action$.pipe(
    ofType(statisticsTypes.GET_STATISTICS_SESSIONS),
    switchMap(({ payload, onSuccess }) => ajax({
        url: `${API}admin/stat/sessions`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
    }).pipe(
        switchMap(({ response }) => ActionsObservable.create((obs) => {
            obs.next({ type: statisticsTypes.GET_STATISTICS_SUCCESS, payload: { type: 'sessions', loading: false, ...response.payload } })
            if (onSuccess) onSuccess(response)
            obs.complete()
        })),
        catchError((err) => ActionsObservable.create((obs) => {
            console.error(err)
            obs.complete()
        }))
    ))
)


export const getStatisticsTop = (action$) => action$.pipe(
    ofType(statisticsTypes.GET_STATISTICS_TOP),
    switchMap(({ payload, onSuccess }) => ajax({
        url: `${API}admin/stat/top`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify({ pageSize: 100, ...payload }),
    }).pipe(
        switchMap(({ response }) => ActionsObservable.create((obs) => {
            obs.next({ type: statisticsTypes.GET_STATISTICS_SUCCESS, payload: { type: 'top', loading: false, ...response.payload } })
            if (onSuccess) onSuccess(response)
            obs.complete()
        })),
        catchError((err) => ActionsObservable.create((obs) => {
            console.error(err)
            obs.complete()
        }))
    ))
)

export const getStatisticsMonth = (action$) => action$.pipe(
    ofType(statisticsTypes.GET_STATISTICS_MONTH),
    switchMap(({ payload, onSuccess }) => ajax({
        url: `${API}admin/stat/month`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
    }).pipe(
        switchMap(({ response }) => ActionsObservable.create((obs) => {
            obs.next({ type: statisticsTypes.GET_STATISTICS_SUCCESS, payload: { type: 'month', loading: false, days: response.payload } })
            if (onSuccess) onSuccess(response)
            obs.complete()
        })),
        catchError((err) => ActionsObservable.create((obs) => {
            console.error(err)
            obs.complete()
        }))
    ))
)

export const getStatisticsCars = (action$) => action$.pipe(
    ofType(statisticsTypes.GET_STATISTICS_CARS),
    switchMap(({ payload, onSuccess }) => ajax({
        url: `${API}admin/stat/cars`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify({ pageSize: 30, ...payload }),
    }).pipe(
        switchMap(({ response }) => ActionsObservable.create((obs) => {
            obs.next({ type: statisticsTypes.GET_STATISTICS_SUCCESS, payload: { type: 'cars', loading: false, ...response.payload } })
            if (onSuccess) onSuccess(response)
            obs.complete()
        })),
        catchError((err) => ActionsObservable.create((obs) => {
            console.error(err)
            obs.complete()
        }))
    ))
)

export const getStatisticsBrands = (action$) => action$.pipe(
    ofType(statisticsTypes.GET_STATISTICS_BRANDS),
    switchMap(({ payload, onSuccess }) => ajax({
        url: `${API}admin/stat/cars/brand`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
    }).pipe(
        switchMap(({ response }) => ActionsObservable.create((obs) => {
            obs.next({ type: statisticsTypes.GET_STATISTICS_SUCCESS, payload: { type: 'brands', loading: false, ...response.payload } })
            if (onSuccess) onSuccess(response.payload)
            obs.complete()
        })),
        catchError((err) => ActionsObservable.create((obs) => {
            console.error(err)
            obs.complete()
        }))
    ))
)

export const getStatisticsNotifications = (action$) => action$.pipe(
    ofType(statisticsTypes.GET_STATISTICS_NOTIFICATIONS),
    switchMap(({ payload, onSuccess }) => ajax({
        url: `${API}admin/stat/notification`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
    }).pipe(
        switchMap(({ response }) => ActionsObservable.create((obs) => {
            obs.next({ type: statisticsTypes.GET_STATISTICS_SUCCESS, payload: { type: 'notifications', loading: false, docs: response.payload } })
            if (onSuccess) onSuccess(response)
            obs.complete()
        })),
        catchError((err) => ActionsObservable.create((obs) => {
            console.error(err)
            obs.complete()
        }))
    ))
)

export const getStatisticsDownloads = (action$) => action$.pipe(
    ofType(statisticsTypes.GET_STATISTICS_DOWNLOADS),
    switchMap(({ payload, onSuccess }) => ajax({
        url: `${API}admin/stat/download`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
    }).pipe(
        switchMap(({ response }) => ActionsObservable.create((obs) => {
            obs.next({ type: statisticsTypes.GET_STATISTICS_USERS, payload, onSuccess })
            obs.next({ type: statisticsTypes.GET_STATISTICS_SUCCESS, payload: { type: 'downloads', docs: response.payload } })
            if (onSuccess) onSuccess(response)
            obs.complete()
        })),
        catchError((err) => ActionsObservable.create((obs) => {
            console.error(err)
            obs.complete()
        }))
    ))
)

export const getStatisticsDownloadsMonth = (action$) => action$.pipe(
    ofType(statisticsTypes.GET_STATISTICS_DOWNLOADS_MONTH),
    switchMap(({ payload, onSuccess }) => ajax({
        url: `${API}admin/stat/download/month`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
    }).pipe(
        switchMap(({ response }) => ActionsObservable.create((obs) => {
            obs.next({ type: statisticsTypes.GET_STATISTICS_SUCCESS, payload: { type: 'downloadsMonth', loading: false, docs: response.payload } })
            if (onSuccess) onSuccess(response)
            obs.complete()
        })),
        catchError((err) => ActionsObservable.create((obs) => {
            console.error(err)
            obs.complete()
        }))
    ))
)


export const sendNotification = (action$) => action$.pipe(
    ofType(statisticsTypes.SEND_NOTIFICATION),
    switchMap(({ payload, onSuccess }) => ajax({
        url: `${API}admin/stat/notification/send`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
    }).pipe(
        switchMap(({ response }) => ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response)
            obs.complete()
        })),
        catchError((err) => ActionsObservable.create((obs) => {
            console.error(err)
            obs.complete()
        }))
    ))
)