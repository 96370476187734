export const generalTypes = {
    LOADING_ON: 'general/LOADING_ON',
    LOADING_OFF: 'general/LOADING_OFF',
}  


export const setLoadingOn = () => ({
    type: generalTypes.LOADING_ON
  })
  
  export const setLoadingOff = () => ({
    type: generalTypes.LOADING_OFF
  })
  