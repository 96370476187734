/* eslint-disable */
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { editGroupMainInfo } from '../../../actions';
import {connect} from "react-redux";
import "../styles.scss";

const EditGroupInfo = ({ payload, setPayload,setSelectedTab,editGroupMainInfo }) => {
  const [eik, setEik] = useState(payload?.eik?.length > 0 ? true : false);

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  return (
    <>
      <div className="right-info-container">
        <div className="right-inner-container">
          <p style={{ fontSize: "16px" }}>
            <b>{`Основна информация`}</b>
          </p>
          <div className="inner-first-section">
            <div className="first-input">
              <span className="input-title">Име на групата</span>
              <input
                required
                className="input-field"
                type="text"
                id="group-name"
                value={payload?.name}
                onChange={(event) => {
                  let updatedValue = { name: event.target.value };
                  setPayload((payload) => ({
                    ...payload,
                    ...updatedValue,
                  }));
                }}
              />
            </div>
            <div className="first-input">
              <span className="input-title">Име на фирмата</span>
              <input
                required
                className="input-field"
                type="text"
                id="firm-name"
                value={payload?.firm}
                onChange={(event) => {
                  let updatedValue = { firm: event.target.value };
                  setPayload((payload) => ({
                    ...payload,
                    ...updatedValue,
                  }));
                }}
              />
            </div>
            <div className="first-input">
              <div className="all-radio">
                <div className="radio" style={{display:"flex",alignItems:"flex-end",marginRight:"4%"}}>
                  <input
                    type="radio"
                    value="EIK"
                    name="type"
                    className="radio"
                    id="EIK"
                    checked={eik === true && true}
                    onChange={()=>{}}
                    onClick={() => {
                      setEik(true);
                      setPayload({
                        ...payload,
                        ["eik"]: "",
                        ["bulstat"]: "",
                      });
                    }}
                  />
                  ЕИК
                </div>
                <div className="radio" style={{display:"flex",alignItems:"flex-end",marginRight:"4%"}}>
                  <input
                    type="radio"
                    value="bulstat"
                    name="type"
                    className="radio"
                    id="bulstat"
                    checked={eik === false && true}
                    onChange={()=>{}}
                    onClick={() => {
                      setEik(false);
                      setPayload({
                        ...payload,
                        ["bulstat"]: "",
                        ["eik"]: "",
                      });
                    }}
                  />
                  Булстат
                </div>
              </div>
              <input
                required
                className="input-field"
                type="text"
                id="code"
                value={eik === false ? payload?.bulstat : payload?.eik}
                onChange={({ target: { value } }) => {
                  if(!/^\d{0,9}$/.test(value)) return
                  if (eik === true) {
                    setPayload((payload) => ({
                      ...payload,
                      ["eik"]: value,
                    }));
                  } else {
                    setPayload((payload) => ({
                      ...payload,
                      ["bulstat"]: value,
                    }));
                  }
                }}
              />
            </div>
          </div>

          <div className="inner-second-section">
            <div className="first-input">
              <span className="input-title">МОЛ</span>
              <input
                required
                className="input-field"
                type="text"
                id="MOL"
                value={payload?.mol}
                onChange={(event) => {
                  let updatedValue = { mol: event.target.value };
                  setPayload((payload) => ({
                    ...payload,
                    ...updatedValue,
                  }));
                }}
              />
            </div>
            <div className="first-input">
              <span className="input-title">IBAN</span>
              <input
                required
                className="input-field"
                type="text"
                id="IBAN"
                value={payload?.iban}
                onChange={(event) => {
                  let updatedValue = { iban: event.target.value };
                  setPayload((payload) => ({
                    ...payload,
                    ...updatedValue,
                  }));
                }}
              />
            </div>
            <div className="first-input">
              <span className="input-title">Лице за контакт</span>
              <input
                required
                className="input-field"
                type="text"
                id="contact"
                value={payload?.contactPerson}
                onChange={(event) => {
                  let updatedValue = { contactPerson: event.target.value };
                  setPayload((payload) => ({
                    ...payload,
                    ...updatedValue,
                  }));
                }}
              />
            </div>
          </div>
          <div className="inner-third-section">
            <div className="first-input">
              <span className="input-title">Имейл</span>
              <input
                required
                className="input-field"
                type="text"
                id="email"
                value={payload?.email}
                onChange={(event) => {
                  let updatedValue = { email: event.target.value };
                  setPayload((payload) => ({
                    ...payload,
                    ...updatedValue,
                  }));
                }}
              />
            </div>
            <div className="first-input">
              <span className="input-title">Адрес</span>
              <input
                required
                className="input-field"
                type="text"
                id="address"
                value={payload?.address}
                onChange={(event) => {
                  let updatedValue = { address: event.target.value };
                  setPayload((payload) => ({
                    ...payload,
                    ...updatedValue,
                  }));
                }}
              />
            </div>
            <div className="first-input">
              <span className="input-title">Статус</span>
              <div className="all-radio padded">
                <div className="radio" style={{display:"flex",alignItems:"flex-end",marginRight:"4%"}}>
                  <input
                    type="radio"
                    value="active"
                    name="state"
                    className="radio"
                    id="active"
                    checked={payload?.activeStatus === true && true}
                    onChange={()=>{}}
                    onClick={() => {
                      let updatedValue = { activeStatus: true };
                      setPayload((payload) => ({
                        ...payload,
                        ...updatedValue,
                      }));
                    }}
                  />
                  Активна
                </div>
                <div className="radio" style={{display:"flex",alignItems:"flex-end",marginRight:"4%"}}>
                  <input
                    type="radio"
                    value="inactive"
                    name="state"
                    className="radio"
                    id="inactive"
                    checked={payload?.activeStatus === false && true}
                    onChange={()=>{}}
                    onClick={() => {
                      let updatedValue = { activeStatus: false };
                      setPayload((payload) => ({
                        ...payload,
                        ...updatedValue,
                      }));
                    }}
                  />
                  Неактивна
                </div>
              </div>
            </div>
          </div>
          <div className="inner-fourth-section">
            <span className="input-title">
              Описание на групата (опционално)
            </span>
            <input
              required
              className="input-field"
              type="text"
              id="description"
              value={payload?.description}
              onChange={(event) => {
                let updatedValue = { description: event.target.value };
                setPayload((payload) => ({
                  ...payload,
                  ...updatedValue,
                }));
              }}
            />
          </div>
          {/* <div className="last-inner-section">
            <div
              className="button-container continue"
              onClick={() => {
                var ready = true;
                Object.keys(payload).map((key,i) => {
                    if(key !== "description" && key !== "bulstat" && key !== "eik"){
                      if (payload[key] === ""){
                        Swal.fire({
                            icon: 'error',
                            title: 'Упс...',
                            text: "Някое от полетата не е попълнено.",
                          })
                          ready = false; 
                          return;
                      }
                   }
                   if(eik === true && key === "eik"){
                    if(payload[key].length !== 9){
                      Swal.fire({
                          icon: 'error',
                          title: 'Упс...',
                          text: "Въведеният ЕИК не е правилен.",
                        })
                        ready = false;
                    } 
                   }
                   if(eik === false && key==="bulstat"){
                    if(payload[key].length !== 9){
                      Swal.fire({
                          icon: 'error',
                          title: 'Упс...',
                          text: "Въведеният Булстат не е правилен.",
                        })
                        ready = false;
                    } 
                   }
                  
                    if (key ==="email" && !validateEmail(payload[key])){
                        Swal.fire({
                            icon: 'error',
                            title: 'Упс...',
                            text: "Въведеният имейл не е правилен.",
                          })
                          ready = false;
                          return;
                    }
                })
                if(ready) {
                    const GroupInfo = {
                        name: payload?.name,
                        firm: payload?.firm,
                        mol: payload?.mol,
                        iban: payload?.iban,
                        contactPerson: payload?.contactPerson,
                        email: payload?.email,
                        address: payload?.address,
                        activeStatus: payload?.activeStatus,
                        description: payload?.description
                    }

                    if(payload?.eik?.length > 0) GroupInfo.eik = payload?.eik;
                    else if(payload?.bulstat.length > 0) GroupInfo.bulstat = payload?.bulstat;

                    editGroupMainInfo({
                        payload:GroupInfo,
                        id:payload._id,
                        onSuccess: () => {setSelectedTab("users")},
                        onError: () => {Swal.fire("Някое поле не е попълнено правилно.","","error")}
                    })

                    
                } 
              }}
            >
              Продължи
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
    editGroupMainInfo: (payload) => dispatch(editGroupMainInfo(payload)),
})

export default connect(null,mapDispatchToProps)(EditGroupInfo);
