/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { FinancesGrid, FinancesExpenseGrid, FinancesExpenseClientsGrid } from "../../components";
import { connect } from "react-redux";
import { getFinanceIncome, getFinanceIncomeStatistics, getFinanceDeposits, getFinanceDepositsClients, getFinanceDepositsStatistics } from "../../actions";
import "./styles.scss";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import ExportModal from "../../components/ModalComponent/ExportModal";
import { Icon } from "react-icons-kit";
import { ic_keyboard_arrow_down } from "react-icons-kit/md/ic_keyboard_arrow_down";
import { history } from "../../config/stores";

const Finances = ({ getFinanceIncome, getFinanceDeposits, getFinanceDepositsClients, getFinanceDepositsStatistics, getFinanceIncomeStatistics, incomes, expenses, statistics, statisticsExpenses }) => {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState("income");
  const [viewType, setViewType] = useState("chronology");
  const [sortBy, setSortBy] = useState("");
  const [sortValue, setSortValue] = useState(0);
  const [type, setType] = useState([]);
  const [payment, setPayment] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [filteredStations, setFilteredStations] = useState([]);
  const [filteredBusinessGroups, setFilteredBusinessGroups] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openModalInvoice, setOpenModalInvoice] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [popup, setPopup] = useState("");
  const [locations, setLocations] = useState([]);
  const [stations, setStations] = useState([]);
  const [businessGroups, setBusinessGroups] = useState([]);
  const [invoice, setInvoice] = useState(null);
  const [innerLoading, setInnerLoading] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const tableRef = useRef();
  const optionsReff = useRef(null);

  const viewTypeMap = {
    chronology: "Хронология",
    client: "Клиент",
  };
  useOutsideAlerter(optionsReff);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      // Alert if clicked on outside of element
      function handleClickOutside(event) {
        if (event.target.nodeName === "svg" || event.target.nodeName === "path" || (ref.current && !ref.current.contains(event.target) && !event.target.className.includes("child"))) {
          setOpenPopup(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    setFiltersLoading(true);
    if (selectedTab === "income") {
      getFinanceIncome({
        pageNumber: 1,
        pageSize: "10",
        sortBy: "",
        type: [],
        paymentMethod: [],
        onSuccess: (response) => {
          setCurrentPage(response.payload.page);
          setHasMore(response.payload.hasNextPage);
          setFiltersLoading(false);
        },
      });
      getFinanceIncomeStatistics({});
    } else if (selectedTab === "expense" && viewType === "chronology") {
      getFinanceDeposits({
        pageNumber: 1,
        pageSize: "10",
        sortBy: "",
        user: [],
        paymentMethod: [],
        onSuccess: (response) => {
          setCurrentPage(response.payload.page);
          setHasMore(response.payload.hasNextPage);
          setFiltersLoading(false);
        },
      });
      getFinanceDepositsStatistics({});
    } else if (selectedTab === "expense" && viewType === "client") {
      getFinanceDepositsClients({
        pageNumber: 1,
        pageSize: "10",
        sort: "",
        onSuccess: (response) => {
          setCurrentPage(response.payload.page);
          setHasMore(response.payload.hasNextPage);
          setFiltersLoading(false);
        },
      });
      getFinanceDepositsStatistics({});
    }

    setSortBy("");
    setType([]);
    setPayment("");
    setFilteredUsers([]);
    setFilteredLocations([]);
    setFilteredStations([]);
    setFilteredBusinessGroups([]);
    setSortValue(0);
    setViewType("chronology");
    setOpenPopup(false);
    setHasMore(true);
  }, [selectedTab]);

  useEffect(() => {
    if (location?.pathname?.includes("/deposits")) setSelectedTab("expense");
  }, []);

  const fetchMoreIncomes = () => {
    if (hasMore) {
      setInnerLoading(true);
      if (selectedTab === "income") {
        let payload = {
          pageNumber: currentPage + 1,
          pageSize: "10",
          sortBy: "",
          type: [],
          paymentMethod: [],
        };
        if (sortBy) payload.sort = { [sortBy]: sortValue };

        if (type?.length) payload.type = [...type];
        // payload.paymentMethod = [] // if the filters are used only separately

        // if the filters are not used together, use else if
        if (payment?.length) payload.paymentMethod = [...payment];
        // payload.type = [] // if the filters are used only separately

        if (filteredUsers?.length) payload.user = [...filteredUsers];
        if (filteredBusinessGroups?.length) payload.business = [...filteredBusinessGroups];
        if (filteredLocations?.length) payload.location = [...filteredLocations];
        if (filteredStations?.length) payload.station = [...filteredStations];
        if (invoice !== null) payload.invoice = invoice;

        getFinanceIncome({
          ...payload,
          onSuccess: (response) => {
            setCurrentPage(response.payload.page);
            setHasMore(response.payload.hasNextPage);
            setInnerLoading(false);
          },
          onError: () => {
            alert("Имаше проблем с изпращането на съобщение,опитайте отново!");
            setInnerLoading(false);
          },
        });
      } else if (selectedTab === "expense" && viewType === "chronology") {
        let payload = {
          pageNumber: currentPage + 1,
          pageSize: "10",
          sort: "",
          noPagination: false,
          user: [],
          paymentMethod: [],
        };
        if (sortBy) payload.sort = { [sortBy]: sortValue };
        if (filteredUsers?.length) payload.user = [...filteredUsers];
        if (payment?.length) payload.paymentMethod = [...payment];

        getFinanceDeposits({
          ...payload,
          onSuccess: (response) => {
            setCurrentPage(response.payload.page);
            setHasMore(response.payload.hasNextPage);
            setInnerLoading(false);
          },
          onError: () => {
            alert("Имаше проблем с изпращането на съобщение,опитайте отново!");
            setInnerLoading(false);
          },
        });
      } else if (selectedTab === "expense" && viewType === "client") {
        let payload = {
          pageNumber: currentPage + 1,
          pageSize: "10",
          sort: "",
          noPagination: false,
          user: [],
          paymentMethod: [],
        };
        if (sortBy) payload.sort = { [sortBy]: sortValue };
        if (filteredUsers?.length) payload.user = [...filteredUsers];
        if (filteredBusinessGroups?.length) payload.business = [...filteredBusinessGroups];

        getFinanceDepositsClients({
          ...payload,
          onSuccess: (response) => {
            setCurrentPage(response.payload.page);
            setHasMore(response.payload.hasNextPage);
            setInnerLoading(false);
          },
          onError: () => {
            alert("Имаше проблем с изпращането на съобщение,опитайте отново!");
            setInnerLoading(false);
          },
        });
      }
    } else return;
  };

  const sortData = (sortKey, sortValue, filterType, payment, filteredUsers, filteredLocations, filteredStations, filterInvoice, filteredBusinessGroups) => {
    setFiltersLoading(true);
    if (selectedTab === "income") {
      let payload = {
        pageNumber: 1,
        pageSize: "10",
        sortBy: "",
        noPagination: false,
        type: [],
        paymentMethod: [],
      };

      if (sortKey && sortValue) payload.sort = { [sortKey]: sortValue };

      if (filterType?.length) payload.type = [...filterType];

      if (filteredLocations?.length) payload.location = [...filteredLocations];

      if (filteredUsers?.length) payload.user = [...filteredUsers];

      if (filteredStations?.length) payload.station = [...filteredStations];

      if (filteredBusinessGroups?.length) payload.business = [...filteredBusinessGroups];

      if (filterInvoice !== null) payload.invoice = invoice;

      if (payment?.length) payload.paymentMethod = [...payment];

      getFinanceIncome({
        ...payload,
        onSuccess: (response) => {
          setCurrentPage(response.payload.page);
          setHasMore(response.payload.hasNextPage);
          setFiltersLoading(false);
        },
        onError: () => {
          setFiltersLoading(false);
          alert("Имаше проблем с изпращането на съобщение,опитайте отново!");
        },
      });
    } else if (selectedTab === "expense" && viewType === "chronology") {
      let payload = {
        pageNumber: 1,
        pageSize: "10",
        sort: "",
        noPagination: false,
        user: [],
        paymentMethod: [],
      };

      if (sortKey && sortValue) {
        payload.sort = { [sortKey]: sortValue };
      }
      if (filteredUsers?.length) {
        payload.user = [...filteredUsers];
      }
      if (payment?.length) {
        payload.paymentMethod = [...payment];
      }

      getFinanceDeposits({
        ...payload,
        onSuccess: (response) => {
          setCurrentPage(response.payload.page);
          setHasMore(response.payload.hasNextPage);
          setFiltersLoading(false);
        },
        onError: () => {
          setFiltersLoading(false);
          alert("Имаше проблем с изпращането на съобщение,опитайте отново!");
        },
      });
    } else if (selectedTab === "expense" && viewType === "client") {
      let payload = {
        pageNumber: 1,
        pageSize: "10",
        sort: "",
        user: [],
        noPagination: false,
      };

      if (sortKey && sortValue) {
        payload.sort = { [sortKey]: sortValue };
      }
      if (filteredUsers?.length) {
        payload.user = [...filteredUsers];
      }
      if (filteredBusinessGroups?.length) {
        payload.business = [...filteredBusinessGroups];
      }

      getFinanceDepositsClients({
        ...payload,
        onSuccess: (response) => {
          setCurrentPage(response.payload.page);
          setHasMore(response.payload.hasNextPage);
          setFiltersLoading(false);
        },
        onError: () => {
          setFiltersLoading(false);
          alert("Имаше проблем с изпращането на съобщение,опитайте отново!");
        },
      });
    }
  };

  return (
    <div className="stations-container">
      <div className="inner-stations-container finances">
        <div className="upper-stations-container" style={{ width: "100%", alignItems: "flex-end" }}>
          <div className="tabs-container">
            <div className="section">
              <div
                className={`option ${selectedTab === "income" ? "selected" : ""}`}
                onClick={() => {
                  if (location?.pathname?.includes("/deposits")) history.push("/finances");
                  setSelectedTab("income");
                }}
              >
                Приходи
              </div>
              <div className={`option ${selectedTab === "expense" ? "selected" : ""}`} onClick={() => setSelectedTab("expense")}>
                Депозити
              </div>
            </div>
          </div>

          {selectedTab === "income" ? (
            <div className="buttons-wrapper">
              <button className="button" onClick={() => setOpenModal(true)}>
                Експорт данни
                <img src={require("../../assets/icons/microsoft-excel.svg")} />
              </button>
              <button className="button green" onClick={() => setOpenModalInvoice(true)}>
                Експорт фактури
                <img src={require("../../assets/icons/invoice-white.svg")} />
              </button>
            </div>
          ) : (
            <div className="section">
              <button className="button" onClick={() => setOpenPopup(!openPopup)}>
                Преглед по: &nbsp;<span className="green">{viewTypeMap?.[viewType]}</span>
                <div className="icon-container custom">
                  <Icon size={"70%"} icon={ic_keyboard_arrow_down} className={`arrow-down`} />
                </div>
              </button>
            </div>
          )}

          {openPopup && (
            <div
              className="sort-container"
              id="sort-container"
              ref={optionsReff}
              style={{
                right: "2%",
                top: "13%",
                height: "150px",
              }}
            >
              <span className="child">
                <b>Подреди по:</b>
              </span>
              <div className="all-checkboxes child high no-border">
                <form className="child" style={{ marginBottom: "6%" }}>
                  <div
                    className={`radio child ${viewType === "chronology" ? "selected" : ""}`}
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      marginTop: "5%",
                    }}
                  >
                    <input
                      type="radio"
                      value="chronology"
                      name="chronology"
                      className="radio child"
                      id="chronology"
                      checked={viewType === "chronology" && true}
                      onChange={() => {
                        setViewType("chronology");
                        setOpenPopup(false);

                        getFinanceDeposits({
                          pageNumber: 1,
                          pageSize: "10",
                          sortBy: "",
                          user: [],
                          paymentMethod: [],
                          onSuccess: (response) => {
                            setCurrentPage(response.payload.page);
                            setHasMore(response.payload.hasNextPage);
                          },
                        });
                      }}
                    />
                    Хронология
                  </div>
                  <div
                    className={`radio child ${viewType === "client" ? "selected" : ""}`}
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      marginTop: "3%",
                    }}
                  >
                    <input
                      type="radio"
                      value="client"
                      name="client"
                      className="radio child"
                      id="client"
                      checked={viewType === "client" && true}
                      onChange={() => {
                        setViewType("client");
                        setOpenPopup(false);

                        getFinanceDepositsClients({
                          pageNumber: 1,
                          pageSize: "10",
                          sort: "",
                          onSuccess: (response) => {
                            setCurrentPage(response.payload.page);
                            setHasMore(response.payload.hasNextPage);
                          },
                        });
                      }}
                    />
                    Клиент
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
        <div className="stations-content">
          {selectedTab === "income" ? (
            <FinancesGrid
              incomes={incomes}
              tableRef={tableRef}
              fetchMoreIncomes={fetchMoreIncomes}
              setHasMore={setHasMore}
              sortBy={sortBy}
              setSortBy={(v) => setSortBy(v)}
              invoice={invoice}
              setInvoice={(v) => setInvoice(v)}
              type={type}
              setType={(v) => setType(v)}
              payment={payment}
              setPayment={(v) => setPayment(v)}
              filteredUsers={filteredUsers}
              setFilteredUsers={(v) => setFilteredUsers(v)}
              filteredLocations={filteredLocations}
              setFilteredLocations={(v) => setFilteredLocations(v)}
              locations={locations}
              setLocations={(v) => setLocations(v)}
              businessGroups={businessGroups}
              setBusinessGroups={(v) => setBusinessGroups(v)}
              stations={stations}
              setStations={(v) => setStations(v)}
              filteredStations={filteredStations}
              setFilteredStations={(v) => setFilteredStations(v)}
              filteredBusinessGroups={filteredBusinessGroups}
              setFilteredBusinessGroups={(v) => setFilteredBusinessGroups(v)}
              sortValue={sortValue}
              setSortValue={(v) => setSortValue(v)}
              sortData={(sortKey, sortValue, filterType, payment, users, locations, stations, invoice, groups) => sortData(sortKey, sortValue, filterType, payment, users, locations, stations, invoice, groups)}
              innerLoading={innerLoading}
              filtersLoading={filtersLoading}
            />
          ) : viewType === "chronology" ? (
            <FinancesExpenseGrid
              expenses={expenses}
              tableRef={tableRef}
              fetchMoreIncomes={fetchMoreIncomes}
              setHasMore={setHasMore}
              sortBy={sortBy}
              setSortBy={(v) => setSortBy(v)}
              type={type}
              setType={(v) => setType(v)}
              payment={payment}
              setPayment={(v) => setPayment(v)}
              filteredUsers={filteredUsers}
              setFilteredUsers={(v) => setFilteredUsers(v)}
              sortValue={sortValue}
              setSortValue={(v) => setSortValue(v)}
              sortData={(sortKey, sortValue, filterType, payment, users) => sortData(sortKey, sortValue, filterType, payment, users)}
              innerLoading={innerLoading}
              filtersLoading={filtersLoading}
            />
          ) : (
            <FinancesExpenseClientsGrid
              expenses={expenses}
              tableRef={tableRef}
              fetchMoreIncomes={fetchMoreIncomes}
              setHasMore={setHasMore}
              sortBy={sortBy}
              setSortBy={(v) => setSortBy(v)}
              type={type}
              setType={(v) => setType(v)}
              payment={payment}
              setPayment={(v) => setPayment(v)}
              filteredUsers={filteredUsers}
              setFilteredUsers={(v) => setFilteredUsers(v)}
              filteredBusinessGroups={filteredBusinessGroups}
              setFilteredBusinessGroups={(v) => setFilteredBusinessGroups(v)}
              businessGroups={businessGroups}
              setBusinessGroups={(v) => setBusinessGroups(v)}
              sortValue={sortValue}
              setSortValue={(v) => setSortValue(v)}
              sortData={(sortKey, sortValue, filterType, payment, users, locations, stations, invoice, groups) => sortData(sortKey, sortValue, filterType, payment, users, locations, stations, invoice, groups)}
              innerLoading={innerLoading}
              filtersLoading={filtersLoading}
            />
          )}
        </div>

        {selectedTab === "income" ? (
          <div className="lower-finance-info">
            <div className="inner-finance-info">
              <p className="lower-price-text">
                Общо: <b style={{ marginRight: "1%" }}> {statistics?.total?.charging?.toFixed(2)} лв. </b>
              </p>
              <img className="info-icon" src={require("../../assets/icons/info.svg")} onClick={() => (popup === "" ? setPopup("total") : setPopup(""))} />
            </div>

            <div className="inner-finance-info">
              <p className="lower-price-text">
                Oбщо нетно: &nbsp;
                <b>{statistics?.totalNet?.charging?.toFixed(2)} лв. </b>
              </p>
              <img className="info-icon" src={require("../../assets/icons/info.svg")} onClick={() => (popup === "" ? setPopup("totalNet") : setPopup(""))} />
            </div>

            <div className="inner-finance-info">
              <p className="lower-price-text">
                Общо този месец: &nbsp;
                <b>{statistics?.totalMonth?.charging?.toFixed(2)} лв. </b>
              </p>
              <img className="info-icon" src={require("../../assets/icons/info.svg")} onClick={() => (popup === "" ? setPopup("totalMonth") : setPopup(""))} />
            </div>

            <div className="inner-finance-info">
              <p className="lower-price-text">
                Общо нетно този месец: &nbsp;
                <b>{statistics?.totalMountNet?.charging?.toFixed(2)} лв.</b>
              </p>
              <img className="info-icon" src={require("../../assets/icons/info.svg")} onClick={() => (popup === "" ? setPopup("totalMountNet") : setPopup(""))} />
            </div>
          </div>
        ) : (
          <div className="lower-finance-info">
            <div className="inner-finance-info">
              <p className="lower-price-text">
                Актуална наличност: &nbsp; <b>{statisticsExpenses?.currentAvailability?.toFixed(2)} лв.</b>
              </p>
            </div>
            <div className="inner-finance-info">
              <p className="lower-price-text">
                {/* Депозити от: &nbsp; <b>{statisticsExpenses?.totalFunding?.toFixed(2)} лв.</b> */}
                Депозити от: <b>{statisticsExpenses?.uniqueClients} клиента</b>
              </p>
            </div>
          </div>
        )}
      </div>
      <ModalComponent open={openModal} children={<ExportModal title={"Експорт данни"} handleClose={() => setOpenModal(false)} />} />
      <ModalComponent open={openModalInvoice} children={<ExportModal title={"Експорт фактури"} type={"invoice"} handleClose={() => setOpenModalInvoice(false)} />} />

      {popup && selectedTab === "income" && (
        <div className={`lower-popup-container ${popup || ""}`}>
          <div className="flex margin">
            <h3 className="text bold">Разбивка по вид</h3>
            <img className="close-icon" src={require("../../assets/icons/close.svg")} onClick={() => setPopup("")} />
          </div>
          <div className="flex">
            <h3 className="text medium">Зареждане:</h3>
            <p className="text bold">{statistics?.[popup]?.charging?.toFixed(2)} лв.</p>
          </div>
          <div className="flex">
            <h3 className="text medium">Резервации:</h3>
            <p className="text bold">{statistics?.[popup]?.reservation?.toFixed(2)} лв.</p>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  incomes: state.finances.incomes,
  expenses: state.finances.expenses,
  statistics: state.finances.statistics,
  statisticsExpenses: state.finances.statisticsExpenses,
});
const mapDispatchToProps = (dispatch) => ({
  getFinanceIncome: (payload) => dispatch(getFinanceIncome(payload)),
  getFinanceDeposits: (payload) => dispatch(getFinanceDeposits(payload)),
  getFinanceDepositsClients: (payload) => dispatch(getFinanceDepositsClients(payload)),
  getFinanceIncomeStatistics: (payload) => dispatch(getFinanceIncomeStatistics(payload)),
  getFinanceDepositsStatistics: (payload) => dispatch(getFinanceDepositsStatistics(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Finances);
