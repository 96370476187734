/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./styles.scss";
import { ic_keyboard_arrow_down } from "react-icons-kit/md/ic_keyboard_arrow_down";
import { Icon } from "react-icons-kit";
import HeaderFilterRow from "../HeaderFilterRow/HeaderFilterRow";
import moment from "moment";
import Scrollbars from "react-custom-scrollbars";
import { API } from "../../config/settings";
import { Headers } from "../../utilities";
import { setLocationDetails, setGroupsDetails, setUsersDetails } from "../../actions";
import { useDispatch } from "react-redux";
import Statistics from "../Statistics";
import { history } from "../../config/stores";

const IncomeFields = ["date", "hour", "paymentFor", "location", "user", "businessGroup", "quantity", "singlePrice", "totalPrice", "fee", "net", "paymentMethod", "duration", "invoice"]; //"station","cableType",

const popupIndexMap = {
  0: "5",
  1: "17",
  2: "25",
  3: "38",
  4: "55",
  5: "68",
  6: "76",
  7: "75",
  8: "80",
  9: "85",
  10: "90",
  11: "100",
  12: "106",
  13: "112",
  14: "118",
  15: "122",
  16: "125",
};

const FinancesGrid = ({
  incomes,
  tableRef,
  fetchMoreIncomes,
  setHasMore,
  invoice,
  setInvoice,
  sortBy,
  setSortBy,
  sortValue,
  setSortValue,
  sortData,
  type,
  setType,
  payment,
  setPayment,
  filteredUsers,
  setFilteredUsers,
  filteredLocations,
  setFilteredLocations,
  locations,
  setLocations,
  filteredStations,
  setFilteredStations,
  stations,
  setStations,
  filteredBusinessGroups,
  setFilteredBusinessGroups,
  businessGroups,
  setBusinessGroups,
  innerLoading,
  filtersLoading,
}) => {
  const dispatch = useDispatch();
  const [coordinates, setCoordinates] = useState({
    display: "none",
    x: "0%",
    y: "0%",
  });
  const [show, setShow] = useState(false);
  const [container, setContainer] = useState({});
  const [users, setUsers] = useState([]);
  const [indexx, setIndex] = useState(0);
  const [originalOrder, setOriginalOrder] = useState([]);
  const [selectedHeader, setSelectedHeader] = useState({});

  let headerElements = [
    {
      name: "Дата",
      type: "date",
      size: "medium first",
      query: { filter: "", options: [] },
    },
    {
      name: "Час",
      type: "hour",
      size: "small",
      query: { filter: "", options: [] },
    },
    {
      name: "Плащане за",
      type: "paymentFor",
      filter: true,
      size: "medium",
      query: {
        filter: "",
        options: [
          { label: "Зареждане", type: "charging" },
          { label: "Резервация", type: "reservation" },
        ],
      },
    },
    {
      name: "Локация",
      type: "location",
      typePath: "location.name",
      filter: true,
      search: true,
      containerType: "high",
      size: "large",
      query: {
        filter: "",
        options: locations || [],
      },
    },
    // {
    //   name: "Станция",
    //   type: "station",
    //   typePath: "station.name",
    //   filter: true,
    //   search: true,
    //   containerType: filteredLocations?.length ? "high" : "small",
    //   size: "large",
    //   query: {
    //     filter: "",
    //     options: stations || [],
    //   },
    // },
    {
      name: "Потребител",
      type: "user",
      typePath: "user.phoneNumber",
      size: "medium",
      filter: true,
      search: true,
      containerType: "high",
      query: {
        filter: "",
        options: users || [],
      },
    },
    {
      name: "Бизнес група",
      type: "businessGroup",
      typePath: "businessGroup.name",
      size: "medium",
      filter: true,
      search: true,
      containerType: "high",
      query: {
        filter: "",
        options: businessGroups || [],
      },
    },
    {
      name: "К-во",
      type: "quantity",
      size: "small",
      query: { filter: "", options: [] },
    },
    // {
    //   name: "Вид",
    //   type: "cableType",
    //   typePath: "cableType",
    //   size: "medium",
    //   query: { filter: "", options: [] },
    // },
    {
      name: "Ед. цена",
      type: "singlePrice",
      size: "small",
      query: { filter: "", options: [] },
    },
    {
      name: "Обща стойност",
      type: "totalPrice",
      size: "small",
      query: { filter: "", options: [] },
    },
    {
      name: "Такси и разходи",
      type: "fee",
      size: "small",
      query: { filter: "", options: [] },
    },
    {
      name: "Нетна стойност",
      type: "net",
      size: "small",
      query: { filter: "", options: [] },
    },
    {
      name: "Плащане с",
      type: "paymentMethod",
      filter: true,
      size: "medium",
      query: {
        filter: "",
        options: [
          { label: "Баланс", type: "balance" },
          { label: "Карта", type: "card" },
        ],
      },
      // query: { filter: "", options: ["Карта", "Баланс"] },
    },
    {
      name: "Времетраене",
      type: "duration",
      size: "medium",
      query: { filter: "", options: [] },
    },
    {
      name: "Фактури",
      type: "invoice",
      typePath: "receiptUrl",
      filter: true,
      size: "small last",
      query: {
        filter: "",
        options: [
          { label: "Да", type: "yes", value: true },
          { label: "Не", type: "no", value: false },
        ],
      },
    },
  ];

  const fieldMap = {
    charging: "Зареждане",
    reservation: "Резервация",
    balance: "Баланс",
    card: "Карта",
    yes: "Да",
    no: "Не",
  };

  function set_Popup(elem) {
    let rect = elem.getBoundingClientRect();

    if (Number(elem.id) < 7) {
      setCoordinates({
        display: "flex",
        x: `${rect.x + 20}px`,
        y: `50px`,
        smaller: true,
      });
    } else
      setCoordinates({
        display: "flex",
        x: `${popupIndexMap[elem?.id]}%`,
        y: `50px`,
        smaller: true,
      });
  }

  const convertDuration = (duration) => {
    if (!duration?.includes(":")) return;

    const [hours, minutes, seconds] = duration.split(":").map(Number);
    const formattedDuration = `${hours} ч | ${minutes} мин`;
    // const formattedDuration = `${hours} ч | ${minutes} мин | ${seconds} сек`;

    return formattedDuration;
  };

  const handleObjectFields = (field, income) => {
    switch (field) {
      case "location":
        return <span className={`inner-text`}>{income?.[field]?.name || "--"}</span>;
      case "station":
        return <span className={`inner-text`}>{income?.[field]?.name || "--"}</span>;
      case "user":
        if (!income?.[field]?.phoneNumber) return <span className={`inner-text`}>--</span>;
        return (
          <>
            <span className={`inner-text`}>{income[field].phoneNumber}</span>
            <img className="info-icon" src={require("../../assets/icons/info.svg")} onClick={() => history.push(`/client/${income[field]._id}`)} />
          </>
        );
      case "businessGroup":
        return <span className={`inner-text`}>{income?.businessGroup?.name?.length > 0 ? income?.businessGroup?.name : "Не"}</span>;
      // case "businessGroup":
      //   return (
      //     <span className={`inner-text`}>
      //       {income?.location?.businessGroup?.name.length > 0
      //         ? income?.location?.businessGroup?.name
      //         : "Не"}
      //     </span>
      //   );
      case "station":
        return (
          <span className={`inner-text`}>
            {income?.cableType || ""} {income?.[field]?.voltage || ""} {income?.[field]?.power || 0}
            kW
          </span>
        );
      default:
        return;
    }
  };

  const onScroll = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const t = scrollHeight - clientHeight - scrollTop <= 1 && scrollTop !== 0;
    if (t && !innerLoading) fetchMoreIncomes();
  };

  const loadOptionsUsers = (search, onSuccess) =>
    new Promise((resolve, reject) => {
      let searchString = search.slice();
      if (searchString && searchString?.includes("+")) searchString = searchString.replace(/\+/g, "");

      return fetch(`${API}user/list`, {
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify({ pageSize: 20, search: searchString }),
      })
        .then((res) => res.json())
        .then((res) => {
          const options = res.payload.docs.map(({ _id, name, phoneNumber }) => ({ type: _id, label: [name, phoneNumber].filter(Boolean).join(" - ") }));
          if (onSuccess) onSuccess([...options]);
          dispatch(setUsersDetails({ hasNextPage: res?.payload?.hasNextPage, page: res?.payload?.page }));
          setUsers([...options]);
          return resolve(options);
        })
        .catch(() => reject());
    });

  const loadOptionsLocations = (search, onSuccess) =>
    new Promise((resolve, reject) => {
      return fetch(`${API}location/list`, {
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify({ pageSize: 20, search }),
      })
        .then((res) => res.json())
        .then((res) => {
          const options = res.payload.docs.map(({ _id, name }) => ({ type: _id, label: name }));
          let stations = [];
          res.payload.docs.forEach((location) => {
            location._stations.forEach((station) => {
              stations.push({ type: station._id, label: station.name, locationId: location._id });
            });
          });
          if (onSuccess) onSuccess([...options]);
          dispatch(setLocationDetails({ hasNextPage: res?.payload?.hasNextPage, page: res?.payload?.page }));
          setLocations([...options]);
          setStations([...stations]);
          return resolve(options);
        })
        .catch(() => reject());
    });

  const loadOptionsStations = (search, func) => {
    let filteredList = [...stations].filter((station) => station?.label?.includes(search)) || [];
    if (func) func([...filteredList]);
    // setFilteredStations([...filteredList])
  };
  const filterOptionsStations = (locations) => {
    if (!locations?.length) return;
    let filteredList = [];
    filteredList = stations.filter((station) => locations.includes(station?.locationId));

    setStations([...filteredList]);
  };

  const loadOptionsGroups = (search, onSuccess) =>
    new Promise((resolve, reject) => {
      return fetch(`${API}businessGroup/list`, {
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify({ pageSize: 20, search }),
      })
        .then((res) => res.json())
        .then((res) => {
          const options = res.payload.docs.map(({ _id, name }) => ({ type: _id, label: name }));
          if (onSuccess) onSuccess([...options]);
          dispatch(setGroupsDetails({ hasNextPage: res?.payload?.hasNextPage, page: res?.payload?.page }));
          setBusinessGroups([...options]);
          return resolve(options);
        })
        .catch(() => reject());
    });

  useEffect(() => {
    loadOptionsUsers("");
    loadOptionsLocations("");
    loadOptionsGroups("");
  }, []);

  return (
    <>
      <Scrollbars
        style={{
          height: "85%",
          width: "100%",
          marginTop: "1%",
          overflowX: "hidden",
        }}
        onUpdate={onScroll}
        ref={tableRef}
        id={"scrollbar"}
      >
        <table className="first-grid" ref={tableRef} style={{ width: "100%" }} onScroll={(e) => onScroll(e)}>
          <thead style={{ display: "block" }}>
            <tr className="header-of-table">
              {headerElements?.map((element, index) => {
                return (
                  <th className={`header ${element?.size}`} key={index}>
                    <span className="inner-text">{element?.name}</span>
                    <div className={`icon-container ${sortBy === element?.name && "rotate"}`}>
                      <Icon
                        size={"80%"}
                        icon={ic_keyboard_arrow_down}
                        className={`arrow-down`}
                        id={index}
                        onClick={() => {
                          var All_tabs = document.querySelectorAll(".fulll-inner-tab");
                          All_tabs = Array.prototype.slice.call(All_tabs, 0);
                          setOriginalOrder(All_tabs);
                          setContainer(element);
                          setShow(true);
                          setIndex(index);
                          let elem = document.getElementById(`${index}`);
                          set_Popup(elem);
                          setHasMore(false);
                          setSelectedHeader(element);
                        }}
                      />
                    </div>
                  </th>
                );
              })}
            </tr>
            {show ? (
              <HeaderFilterRow
                coordinates={coordinates}
                element={container}
                index={indexx}
                type={"incomes"}
                sortValue={sortValue}
                setSortValue={setSortValue}
                selectedHeader={selectedHeader}
                filterType={type}
                setFilterType={setType}
                payment={payment}
                setPayment={setPayment}
                filteredUsers={filteredUsers}
                setFilteredUsers={setFilteredUsers}
                filteredFinanceLocations={filteredLocations}
                setFilteredFinanceLocations={setFilteredLocations}
                filteredStations={filteredStations}
                setFilteredStations={setFilteredStations}
                filteredBusinessGroups={filteredBusinessGroups}
                setFilteredBusinessGroups={setFilteredBusinessGroups}
                allLocations={locations}
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortData={sortData}
                invoice={invoice}
                setInvoice={setInvoice}
                users={users}
                setStations={(v) => setStations(v)}
                loadOptionsUsers={loadOptionsUsers}
                loadOptionsLocations={loadOptionsLocations}
                loadOptionsStations={loadOptionsStations}
                filterOptionsStations={(locations) => filterOptionsStations(locations)}
                loadOptionsGroups={loadOptionsGroups}
                onClose={() => {
                  setShow(false);
                }}
              />
            ) : null}
          </thead>
          {filtersLoading ? (
            <Statistics.LoaderInline styles={{ display: "flex", alignItems: "center" }} />
          ) : (
            <tbody className="inner-full-income-container" id="all-stations" style={{ overflowY: "hidden" }}>
              {incomes?.map((income, i) => {
                return (
                  <tr className={`fulll-inner-tab`} key={i} id={i}>
                    {IncomeFields?.map((field, j) => {
                      return (
                        <td className={`inner-part ${headerElements?.find((el) => el?.type === field)?.size}`} id={field} key={j}>
                          {field === "date" ? (
                            <React.Fragment>
                              <div
                                className="invoice-icon"
                                onClick={() => {
                                  window.open(income?.receiptUrl);
                                }}
                              />
                              <span className={`inner-text ${j === 0 && "first"}`}>{moment(income?.paymentDate)?.format("DD.MM.YYYY") || "--"}</span>
                            </React.Fragment>
                          ) : field === "hour" ? (
                            <span className={`inner-text`}>
                              {/* {income?.hour} */}
                              {moment(income?.paymentDate).format("HH:mm")}
                            </span>
                          ) : field === "cableType" ? (
                            <span className={`inner-text`}>{income?.cableType || "--"}</span>
                          ) : field === "paymentFor" ? (
                            <span className={`inner-text`}>{fieldMap?.[income?.type] || income?.type}</span>
                          ) : field === "paymentMethod" ? (
                            <span className={`inner-text`}>{fieldMap[income?.paymentMethod] || income?.paymentMethod}</span>
                          ) : field === "quantity" ? (
                            <span className={`inner-text`}>{income?.[field] || 0} kW</span>
                          ) : field === "singlePrice" ? (
                            <span className={`inner-text`}>{income?.[field] || 0} лв./kW</span>
                          ) : field === "amountForPay" || field === "fee" || field === "net" ? (
                            <span className={`inner-text`}>{Math.round(income?.[field] * 100) / 100 || 0} лв.</span>
                          ) : field === "totalPrice" ? (
                            <span className={`inner-text`}>{Math.round(income?.[field] * 100) / 100 || income?.[field] || 0} лв.</span>
                          ) : field === "duration" ? (
                            <span className={`inner-text`}>{convertDuration(income?.[field])}</span>
                          ) : field === "invoice" ? (
                            <span className={`inner-text`}>{income?.invoice ? "Да" : "Не"}</span>
                          ) : (
                            handleObjectFields(field, income)
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
          {innerLoading && <Statistics.LoaderInline styles={{ display: "flex", alignItems: "center", height: "90px" }} />}
        </table>
      </Scrollbars>
    </>
  );
};

export default FinancesGrid;
