/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import "./styles.scss";
import "moment/locale/bg";
import "react-dropdown/style.css";
import { connect } from "react-redux";
import moment from "moment";

const RightSettings = ({ commandHistory, serialID }) => {
  const scrollRef = useRef();
  const [finalCommands, setFinalCommands] = useState([]);

  useEffect(() => {
    const foundCommands = commandHistory?.filter((cmd) => cmd.chargePoint === serialID);
    setFinalCommands(foundCommands);
  }, [commandHistory]);

  useEffect(() => {
    if (scrollRef?.current) scrollRef.current.scrollTo(0, scrollRef.current.scrollHeight);
  }, [finalCommands]);

  return (
    <div className="settings-cont" style={{ marginRight: "0%", width: "67%" }}>
      <div className="flex margin">
        <h3 style={{ fontSize: "18px", width: "85%", fontWeight: "bold" }}>Информация от сървъра</h3>
        <div className="button-container red" onClick={() => setFinalCommands([])}>
          Изчисти
        </div>
      </div>
      <div className="lower-history-table" ref={scrollRef}>
        <div className="history-theader">
          <div className="history-theaderdata medium">Дата</div>
          <div className="history-theaderdata small">Час</div>
          <div className="history-theaderdata medium">Статус</div>
          <div className="history-theaderdata medium">Команда</div>
          <div className="history-theaderdata large">Заявка</div>
        </div>
        {finalCommands.map((command, i) => {
          return (
            <div className="history-trow" key={i}>
              <div className="history-tdata medium">{command?.date || "--"}</div>
              <div className="history-tdata small">{command?.hour || "--"}</div>
              <div className="history-tdata medium" style={{ textTransform: "uppercase" }}>
                {command?.method}
              </div>
              <div className="history-tdata medium">{command?.command}</div>
              <div className="history-tdata bigger large">{`${JSON.stringify(command?.request)}`}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  commandHistory: state.adminSettings.commandHistory,
});

export default connect(mapStateToProps, null)(RightSettings);
