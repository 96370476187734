/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./styles.scss";
import { ic_keyboard_arrow_down } from "react-icons-kit/md/ic_keyboard_arrow_down";
import { Icon } from "react-icons-kit";
import HeaderFilterRow from "../HeaderFilterRow/HeaderFilterRow";
import Scrollbars from "react-custom-scrollbars";
import { setUsersDetails } from "../../actions";
import { API } from "../../config/settings";
import { Headers } from "../../utilities";
import { history } from "../../config/stores";
import Statistics from "../Statistics";

const ExpenseFields = ["user", "balance", "totalPaid", "totalEnergy", "totalCharges", "mostUsedLocation", "businessGroup", "cars", "avgEnergyPerMonth"];

const FinancesExpenseClientsGrid = ({ tableRef, expenses, fetchMoreIncomes, setHasMore, sortBy, setSortBy, sortValue, setSortValue, sortData, type, setType, payment, setPayment, filteredUsers, setFilteredUsers, filteredBusinessGroups, setFilteredBusinessGroups, businessGroups, setBusinessGroups, innerLoading, filtersLoading }) => {
  const [coordinates, setCoordinates] = useState({
    display: "none",
    x: "0%",
    y: "0%",
  });
  const [show, setShow] = useState(false);
  const [container, setContainer] = useState({});
  const [indexx, setIndex] = useState(0);
  const [originalOrder, setOriginalOrder] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedHeader, setSelectedHeader] = useState({});

  const headerElements = [
    {
      name: "Потребител",
      type: "user",
      size: "medium first",
      filter: true,
      search: true,
      containerType: "high",
      query: { filter: "", options: users },
    },
    {
      name: "Наличен баланс",
      type: "balance",
      size: "small",
      query: { filter: "", options: [] },
    },
    {
      name: "Общо платени",
      type: "totalPaid",
      size: "small",
      query: { filter: "", options: [] },
    },
    {
      name: "Общо заредени",
      type: "totalEnergy",
      size: "small",
      query: { filter: "", options: [] },
    },
    {
      name: "Зареждания",
      type: "totalCharges",
      size: "small",
      query: { filter: "", options: [] },
    },
    {
      name: "Най-ползвана станция",
      type: "mostUsedLocation",
      size: "large",
      query: { filter: "", options: [] },
    },
    {
      name: "Към група",
      type: "businessGroup",
      filter: true,
      search: true,
      containerType: "high",
      size: "small",
      query: {
        filter: "",
        options: businessGroups || [],
      },
    },
    {
      name: "Добавени автомобили",
      type: "cars",
      size: "medium",
      query: { filter: "", options: [] },
    },
    {
      name: "Средно зареждане на месец",
      type: "avgEnergyPerMonth",
      size: "large last",
      query: { filter: "", options: [] },
    },
  ];
  function set_Popup(elem) {
    let rect = elem.getBoundingClientRect();

    setCoordinates({
      display: "flex",
      x: `${rect.x + "20"}px`,
      y: `50px`,
      smaller: true,
    });
  }

  const onScroll = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const t = scrollHeight - clientHeight - scrollTop <= 1 && scrollTop !== 0;
    if (t) {
      fetchMoreIncomes();
    }
  };

  const loadOptionsUsers = (search, onSuccess) =>
    new Promise((resolve, reject) => {
      let searchString = search.slice();
      if (searchString && searchString?.includes("+")) searchString = searchString.replace(/\+/g, "");

      return fetch(`${API}user/list`, {
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify({ pageSize: 20, search: searchString }),
      })
        .then((res) => res.json())
        .then((res) => {
          const options = res.payload.docs.map(({ _id, name, phoneNumber }) => ({
            type: _id,
            label: [name, phoneNumber].filter(Boolean).join(" - "),
          }));
          setUsers([...options]);
          if (onSuccess) onSuccess([...options]);
          dispatch(setUsersDetails({ hasNextPage: res?.payload?.hasNextPage, page: res?.payload?.page }));
          return resolve(options);
        })
        .catch(() => reject());
    });

  const loadOptionsGroups = (search, onSuccess) =>
    new Promise((resolve, reject) => {
      return fetch(`${API}businessGroup/list`, {
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify({ pageSize: 20, search }),
      })
        .then((res) => res.json())
        .then((res) => {
          const options = res.payload.docs.map(({ _id, name }) => ({ type: _id, label: name }));
          if (onSuccess) onSuccess([...options]);
          dispatch(setGroupsDetails({ hasNextPage: res?.payload?.hasNextPage, page: res?.payload?.page }));
          setBusinessGroups([...options]);
          return resolve(options);
        })
        .catch(() => reject());
    });

  useEffect(() => {
    loadOptionsUsers("");
    loadOptionsGroups("");
  }, []);

  return (
    <>
      <Scrollbars
        style={{
          height: "85%",
          width: "100%",
          marginTop: "1%",
          overflowX: "hidden",
        }}
        onUpdate={onScroll}
        ref={tableRef}
        id={"scrollbar"}
        renderView={(props) => <div {...props} style={{ ...props.style, overflowX: "hidden" }} />}
      >
        <table className="first-grid expense-clients" ref={tableRef} style={{ width: "100%" }} onScroll={(e) => onScroll(e)}>
          <thead>
            <tr className="header-of-table">
              {headerElements?.map((element, index) => {
                return (
                  <th className={`header ${element?.size}`} key={index}>
                    <span className="inner-text">{element?.name}</span>
                    <div className={`icon-container ${sortBy === element?.name && "rotate"}`}>
                      <Icon
                        size={"80%"}
                        icon={ic_keyboard_arrow_down}
                        className={`arrow-down`}
                        id={index}
                        onClick={() => {
                          var All_tabs = document.querySelectorAll(".fulll-inner-tab");
                          All_tabs = Array.prototype.slice.call(All_tabs, 0);
                          if (sortBy === element.name) {
                            setSortBy("");
                            setCoordinates({ display: "none" });
                            var parent = document.getElementById("all-stations");
                            parent.innerHTML = "";
                            originalOrder?.map((x) => parent.appendChild(x));
                            setHasMore(true);
                          } else {
                            setOriginalOrder(All_tabs);
                            // setSortBy(element?.type);
                            setContainer(element);
                            setShow(true);
                            setIndex(index);
                            let elem = document.getElementById(`${index}`);
                            set_Popup(elem);
                            setHasMore(false);
                            setSelectedHeader(element);
                          }
                        }}
                      />
                    </div>
                  </th>
                );
              })}
            </tr>
            {show ? (
              <HeaderFilterRow
                coordinates={coordinates}
                element={container}
                index={indexx}
                type={"expenses"}
                sortValue={sortValue}
                setSortValue={setSortValue}
                selectedHeader={selectedHeader}
                filterType={type}
                setFilterType={setType}
                payment={payment}
                setPayment={setPayment}
                filteredUsers={filteredUsers}
                setFilteredUsers={setFilteredUsers}
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortData={sortData}
                filteredBusinessGroups={filteredBusinessGroups}
                setFilteredBusinessGroups={setFilteredBusinessGroups}
                loadOptionsUsers={loadOptionsUsers}
                loadOptionsGroups={loadOptionsGroups}
                onClose={() => {
                  setShow(false);
                }}
              />
            ) : null}
          </thead>
          {filtersLoading ? (
            <Statistics.LoaderInline styles={{ display: "flex", alignItems: "center" }} />
          ) : (
            <tbody className="inner-full-income-container" id="all-stations" style={{ overflowY: "hidden" }}>
              {expenses?.map((expense, i) => {
                return (
                  <tr
                    className={`fulll-inner-tab pointer`}
                    key={i}
                    id={i}
                    onClick={() => {
                      history.push(`/client/${expense?._id}`);
                    }}
                  >
                    {ExpenseFields?.map((field, j) => {
                      return (
                        <td className={`inner-part ${headerElements?.[j]?.size}`} id={field} key={j}>
                          {field === "user" ? (
                            <React.Fragment>
                              <span className={`inner-text`}>{expense?.phoneNumber || "--"}</span>
                              {expense?.phoneNumber ? (
                                <img
                                  className="info-icon"
                                  src={require("../../assets/icons/info.svg")}
                                  onClick={() => {
                                    history.push(`/client/${expense?._id}`);
                                  }}
                                />
                              ) : (
                                <></>
                              )}
                            </React.Fragment>
                          ) : field === "balance" ? (
                            <span className={`inner-text`}>{expense?.[field]?.toFixed(2) || "0"} лв.</span>
                          ) : field === "totalPaid" ? (
                            <span className={`inner-text`}>{expense?.[field]?.toFixed(2) || "0"} лв.</span>
                          ) : field === "totalCharges" ? (
                            <span className={`inner-text`}>{expense?.[field] || "--"}</span>
                          ) : field === "totalEnergy" ? (
                            <span className={`inner-text`}>{expense?.balance?.toFixed(2) || "0"} kW</span>
                          ) : field === "mostUsedLocation" ? (
                            <span className={`inner-text`}>{expense?.[field]?.name || "--"}</span>
                          ) : field === "businessGroup" ? (
                            <span className={`inner-text`}>
                              {/* {expense?.[field]?.name || "Не"} */}
                              {expense?.[field]?.name || "--"}
                            </span>
                          ) : field === "cars" ? (
                            <span className={`inner-text`}>{expense?.[field] || "0"}</span>
                          ) : field === "avgEnergyPerMonth" ? (
                            <span className={`inner-text`}>{expense?.avgEnergyPerMonth?.toFixed(2) || "0"} kW</span>
                          ) : (
                            <></>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
          {innerLoading && <Statistics.LoaderInline styles={{ display: "flex", alignItems: "center", height: "120px" }} />}
        </table>
      </Scrollbars>
    </>
  );
};

export default FinancesExpenseClientsGrid;
