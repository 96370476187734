/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import { Mapforgoogle } from "../../../components";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { geocodeByLatLng } from "react-google-places-autocomplete";
import { uploadFile, User } from "../../../utilities";
import { connect } from "react-redux";
import { createLocation } from "../../../actions";
import Swal from "sweetalert2";
import "../styles.scss";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditInfoComponent = ({
  payload,
  setPayload,
  setSelectedTab,
  openHour,
  setOpenHour,
  fromWorkTime,
  setFromWorkTime,
  toWorkTime,
  setToWorkTime,
  setSecondStep,
  edit,
}) => {
  const [address, setAddress] = useState({});
  const [useCoordinates, setUseCoordinates] = useState(false);
  const [coordinatesInput, setCoordinatesInput] = useState();
  var fileInput = useRef();

  useEffect(() => {
    setPayload({
      ...payload,
      ["fromHour"]: `${fromWorkTime?.hour}:${fromWorkTime?.minutes}`,
      ["toHour"]: `${toWorkTime?.hour}:${toWorkTime?.minutes}`,
    });
  }, [fromWorkTime, toWorkTime]);

  useEffect(() => {
    const temp = coordinatesInput?.slice(0)?.replace(" ", "");
    const lat = Number(temp?.split(",")[0]);
    const long = Number(temp?.split(",")[1]);
    geocodeByLatLng({ lat: lat, lng: long })
      .then((results) => {
        const newLocationInfo = {
          type: "Point",
          coordinates: [long, lat],
        };
        setPayload({
          ...payload,
          ["address"]: results[0]?.formatted_address,
          ["location"]: newLocationInfo,
        });
      })
      .catch((error) => console.error(error));
  }, [coordinatesInput]);

  const getInfoByAdrress = (address) => {
    geocodeByAddress(address["label"])
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        const newLocationInfo = {
          type: "Point",
          coordinates: [lng, lat],
        };
        setPayload({
          ...payload,
          ["address"]: address.label,
          ["location"]: newLocationInfo,
        });
      });
  };

  const changeColor = (key, error) => {
    if (error) document.getElementById(key).style.borderColor = "red";
    else {
      if (key === "address") {
        if (!useCoordinates) {
          document.getElementsByClassName(
            "css-1pahdxg-control"
          )[0].style.borderColor = "#cccccc";
        } else
          document.getElementById("coordinates").style.borderColor = "#cccccc";
      } else if (key === "stationType" || key === "workingTime")
        document.getElementById(key).style.color = "black";
      else document.getElementById(key).style.borderColor = "#cccccc";
    }
  };

  return (
    <>
      <div className="right-add-container">
        <div className="upper-inner-add-container">
          <div className="left-upper">
            <p style={{ fontSize: "20px" }}>
              <b>Основна информация</b>
            </p>
            <span>Име на локацията *</span>
            <br />
            <input
              required
              className="station-name"
              type="text"
              style={{ marginBottom: "3%" }}
              id="name"
              value={payload?.name || ""}
              onChange={(event) => {
                changeColor("name", false);
                setPayload({
                  ...payload,
                  ["name"]: event.target.value,
                });
              }}
            />
            <span style={{}}>Начин на въвеждане *</span>
            <br />
            <div
              className="all-radio"
              style={{ marginTop: "1%", marginBottom: "2%" }}
            >
              <div className="radio" style={{ marginLeft: "-2%" }}>
                <input
                  type="radio"
                  value="addre"
                  name="place"
                  className="radio"
                  id="addre"
                  defaultChecked={!useCoordinates && true}
                  onClick={() => {
                    setUseCoordinates(false);
                  }}
                />
                {useCoordinates === false && true ? (
                  <b style={{ whiteSpace: "nowrap" }}>Точен адрес</b>
                ) : (
                  "Точен адрес"
                )}
              </div>
              <div className="radio">
                <input
                  type="radio"
                  value="coord"
                  name="place"
                  className="radio"
                  id="coord"
                  defaultChecked={useCoordinates && true}
                  onClick={() => {
                    setUseCoordinates(true);
                    setPayload({
                      ...payload,
                      ["address"]: "",
                    });
                  }}
                />
                {useCoordinates === true && true ? (
                  <b>Координати</b>
                ) : (
                  "Координати"
                )}
              </div>
            </div>
            <span>
              {useCoordinates === true
                ? "Координати на локация *"
                : "Адрес на локация *"}
            </span>
            {useCoordinates === true ? (
              <input
                required
                className="station-name"
                style={{ marginBottom: "0%" }}
                type="text"
                id="coordinates"
                value={coordinatesInput || ""}
                onChange={(event) => {
                  setCoordinatesInput(event.target.value);
                  changeColor("address", false);
                }}
              />
            ) : (
              <GooglePlacesAutocomplete
                apiKey="AIzaSyALipb3WN32r90_zyf2wk-vNrEod_JdR5Q"
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ["bg"],
                  },
                  types: ["address"],
                }}
                selectProps={{
                  defaultInputValue: payload.address,
                  address,
                  onChange: (address) => {
                    setAddress(address), getInfoByAdrress(address);
                  },
                }}
              />
            )}
          </div>
          <div className="right-upper">
            <Mapforgoogle
              lati={payload?.location?.coordinates[1]}
              long={payload?.location?.coordinates[0]}
              center={{
                lat: payload?.location?.coordinates[0],
                lng: payload?.location?.coordinates[1],
              }}
            />
          </div>
        </div>
        <div className="lower-inner-add-container">
          <div className="left-upper">
            <p style={{ fontSize: "20px" }}>
              <b>За локацията</b>
            </p>
            <span id="stationType">Тип станция *</span>
            <br />
            <div className="all-radio" style={{ marginTop: "1%" }}>
              <div className="radio">
                <input
                  type="radio"
                  value="outside"
                  name="place1"
                  className="radio"
                  id="outside"
                  defaultChecked={payload?.stationType === "outside" && true}
                  onClick={() => {
                    changeColor("stationType", false);
                    setPayload({
                      ...payload,
                      ["stationType"]: "outside",
                    });
                  }}
                />
                {payload?.stationType === "outside" ? <b>Външна</b> : "Външна"}
              </div>
              <div className="radio">
                <input
                  type="radio"
                  value="inside"
                  name="place1"
                  className="radio"
                  id="inside"
                  defaultChecked={payload?.stationType === "inside" && true}
                  onClick={() => {
                    changeColor("stationType", false);
                    setPayload({
                      ...payload,
                      ["stationType"]: "inside",
                    });
                  }}
                />
                {payload?.stationType === "inside" ? (
                  <b>Вътрешна/подземен паркинг</b>
                ) : (
                  "Вътрешна/подземен паркинг"
                )}
              </div>
            </div>
            <br />
            <span id="workingTime">Работно време *</span>
            <br />
            <div className="all-radio" style={{ marginTop: "1%" }}>
              <div className="radio">
                <input
                  type="radio"
                  value="non-stop"
                  name="time"
                  className="radio"
                  id="24/7"
                  defaultChecked={payload?.workingTime === "full" && true}
                  onClick={() => {
                    changeColor("workingTime", false);
                    setPayload({
                      ...payload,
                      ["workingTime"]: "full",
                      ["fromHour"]: `00:00`,
                      ["toHour"]: `24:00`,
                    });
                    setOpenHour(false);
                  }}
                />
                {payload?.workingTime === "full" ? <b>24/7</b> : "24/7"}
              </div>
              <div className="radio">
                <input
                  type="radio"
                  value="fixed"
                  name="time"
                  className="radio"
                  id="fixed"
                  defaultChecked={payload?.workingTime === "fixed" && true}
                  onClick={() => {
                    changeColor("workingTime", false);
                    setPayload({
                      ...payload,
                      ["workingTime"]: "fixed",
                    });
                    setOpenHour(true);
                  }}
                />
                {payload?.workingTime === "fixed" ? (
                  <b>Фиксирано</b>
                ) : (
                  "Фиксирано"
                )}
              </div>
            </div>
            <br />
            <span>Снимка на станция</span>
            <div
              className="add-file"
              onClick={() => {
                if (fileInput) {
                  fileInput.click();
                }
              }}
            >
              <div className="add-icon" />
              <p>Добави файл</p>
            </div>
          </div>
          <div className="right-upper">
            <span>Допълнителни насоки</span>
            <br />
            <input
              required
              className="station-name smaller"
              type="text"
              id="additional-info"
              value={payload?.locationInfo}
              onChange={(event) => {
                setPayload({
                  ...payload,
                  ["locationInfo"]: event.target.value,
                });
              }}
            />

            {edit === true ? (
              <div
                className={`fieldsfortime ${
                  payload.workingTime === "fixed" && "opened"
                }`}
              >
                <div>
                  <span>От час:</span>
                  <div className="from-time-container" id={"from-time"}>
                    <input
                      className="from-hour"
                      type="text"
                      id="power"
                      value={fromWorkTime?.hour || ""}
                      maxLength={2}
                      onChange={({ target: { value } }) => {
                        Array.from(
                          document.getElementsByClassName("from-time-container")
                        ).map((el) => (el.style.borderColor = "#cccccc"));
                        if (!/^\d{0,2}$/.test(value)) return;
                        setFromWorkTime({
                          ...fromWorkTime,
                          ["hour"]: value,
                        });
                      }}
                    />
                    <div className="suffix">:</div>
                    <input
                      required
                      className="from-hour"
                      type="number"
                      id="power"
                      value={fromWorkTime?.minutes || ""}
                      maxLength={2}
                      onChange={({ target: { value } }) => {
                        Array.from(
                          document.getElementsByClassName("from-time-container")
                        ).map((el) => (el.style.borderColor = "#cccccc"));
                        if (!/^\d{0,2}$/.test(value)) return;
                        setFromWorkTime({
                          ...fromWorkTime,
                          ["minutes"]: value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div>
                  <span style={{ marginLeft: "10%" }}>До час:</span>
                  <div
                    className="from-time-container"
                    style={{ marginLeft: "10%" }}
                    id={"to-time"}
                  >
                    <input
                      required
                      className="from-hour"
                      type="number"
                      id="power"
                      value={toWorkTime?.hour || ""}
                      maxLength={2}
                      onChange={({ target: { value } }) => {
                        Array.from(
                          document.getElementsByClassName("from-time-container")
                        ).map((el) => (el.style.borderColor = "#cccccc"));
                        if (!/^\d{0,2}$/.test(value)) return;
                        setToWorkTime({
                          ...toWorkTime,
                          ["hour"]: value,
                        });
                      }}
                    />
                    <div className="suffix">:</div>
                    <input
                      required
                      className="from-hour"
                      type="number"
                      id="power"
                      value={toWorkTime?.minutes || ""}
                      maxLength={2}
                      onChange={({ target: { value } }) => {
                        Array.from(
                          document.getElementsByClassName("from-time-container")
                        ).map((el) => (el.style.borderColor = "#cccccc"));
                        if (!/^\d{0,2}$/.test(value)) return;
                        setToWorkTime({
                          ...toWorkTime,
                          ["minutes"]: value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className={`fieldsfortime ${openHour && "opened"}`}>
                <div>
                  <span>От час:</span>
                  <div className="from-time-container" id={"from-time"}>
                    <input
                      className="from-hour"
                      type="text"
                      id="power"
                      value={fromWorkTime?.hour || ""}
                      maxLength={2}
                      onChange={({ target: { value } }) => {
                        if (!/^\d{0,2}$/.test(value)) return;
                        setFromWorkTime({
                          ...fromWorkTime,
                          ["hour"]: value,
                        });
                      }}
                    />
                    <div className="suffix">:</div>
                    <input
                      required
                      className="from-hour"
                      type="number"
                      id="power"
                      value={fromWorkTime?.minutes || ""}
                      maxLength={2}
                      onChange={({ target: { value } }) => {
                        if (!/^\d{0,2}$/.test(value)) return;
                        setFromWorkTime({
                          ...fromWorkTime,
                          ["minutes"]: value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div>
                  <span style={{ marginLeft: "10%" }}>До час:</span>
                  <div
                    className="from-time-container"
                    style={{ marginLeft: "10%" }}
                    id={"to-time"}
                  >
                    <input
                      required
                      className="from-hour"
                      type="number"
                      id="power"
                      value={toWorkTime?.hour || ""}
                      maxLength={2}
                      onChange={({ target: { value } }) => {
                        if (!/^\d{0,2}$/.test(value)) return;
                        setToWorkTime({
                          ...toWorkTime,
                          ["hour"]: value,
                        });
                      }}
                    />
                    <div className="suffix">:</div>
                    <input
                      required
                      className="from-hour"
                      type="number"
                      id="power"
                      value={toWorkTime?.minutes || ""}
                      maxLength={2}
                      onChange={({ target: { value } }) => {
                        if (!/^\d{0,2}$/.test(value)) return;
                        setToWorkTime({
                          ...toWorkTime,
                          ["minutes"]: value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            <div
              className="files"
              style={{
                display: "flex",
                marginTop: "6%",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              {payload?.files.map(function (file, i) {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "3%",
                    }}
                    key={i}
                  >
                    <div
                      className="delete-image"
                      onClick={() => {
                        const newPayload = payload.files.slice(0);
                        newPayload.splice(i, 1);
                        setPayload({
                          ...payload,
                          ["files"]: newPayload,
                        });
                      }}
                    />
                    <p>{file.slice(36)}</p>
                  </div>
                );
              })}
            </div>
          </div>
          {User.isAdmin === true && (
            <>
              <div
                className="button"
                style={{
                  display: "flex",
                  width: "15%",
                  alignItems: "flex-end",
                }}
              >
                <div
                  className="button-container continue"
                  style={{ height: "10.5%" }}
                  onClick={() => {
                    var errorFound = false;
                    Object.keys(payload).map((key) => {
                      if (
                        payload[key].length < 1 &&
                        payload[key].length !== undefined
                      ) {
                        if (key !== "locationInfo" && key !== "files") {
                          toast.error(`Field ${key} is empty. `, {
                            position: "top-right",
                            autoClose: 2500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                          errorFound = true;
                          const availableKeys = [
                            "name",
                            "address",
                            "stationType",
                            "workingTime",
                          ];
                          if (availableKeys.includes(key)) {
                            if (key === "address") {
                              if(!useCoordinates){
                                document.getElementsByClassName(
                                  "css-yk16xz-control"
                                )[0].style.borderColor = "red";
                              }
                              else document.getElementById("coordinates").style.borderColor = "red";
                              
                            } else if (
                              key === "stationType" ||
                              key === "workingTime"
                            )
                              document.getElementById(key).style.color = "red";
                            else
                              document.getElementById(key).style.borderColor =
                                "red";
                          }
                        }
                      }
                    });
    
                    if (!errorFound) {
                      if (payload.workingTime === "fixed") {
                        if (payload.fromHour === ":" || payload.toHour === ":") {
                          errorFound = true;
                          toast.error(
                            `Some of the fields for fixed hours are empty. `,
                            {
                              position: "top-right",
                              autoClose: 2500,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            }
                          );
                          Array.from(
                            document.getElementsByClassName("from-time-container")
                          ).map((el) => (el.style.borderColor = "red"));
                        } else if (
                          moment(payload.fromHour, "HH:mm", true).isValid() ===
                            false ||
                          moment(payload.toHour, "HH:mm", true).isValid() ===
                            false ||
                          Number(payload.fromHour.substring(0, 2)) >
                            Number(payload.toHour.substring(0, 2))
                        ) {
                          errorFound = true;
                          toast.error(`Invalid values for fixed hours. `, {
                            position: "top-right",
                            autoClose: 2500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                          Array.from(
                            document.getElementsByClassName("from-time-container")
                          ).map((el) => (el.style.borderColor = "red"));
                        }
                      }
                    }
                    if (!errorFound) {
                      setSelectedTab("stations");
                      setSecondStep(true);
                    }
                  }}
                >
                  Продължи
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <input
        type="file"
        ref={(ref) => {
          fileInput = ref;
        }}
        style={{ display: "none" }}
        onChange={(event) => {
          event.persist();
          if (payload.files.length >= 6) {
            Swal.fire("Максималния брой е достигнат.", "", "error");
            return;
          }
          if (event.target.files[0] === undefined) return;

          if (event.target.files[0].type.split("/")[0] === "image") {
            uploadFile(event.target.files[0])
              .then((data) => {
                const newFiles = [...payload?.files, data.location];
                setPayload({
                  ...payload,
                  ["files"]: newFiles,
                });
                // setFiles([...files,data.location]);
              })
              .catch((err) => {});
          } else {
            Swal.fire("Може да прикачите само снимки.", "", "error");
          }
        }}
      />
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  createLocation: (payload) => dispatch(createLocation(payload)),
});
export default connect(null, mapDispatchToProps)(EditInfoComponent);
