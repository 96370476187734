export const supportTypes = {
    SET_SUPPORT_FIELDS: 'support/SET_SUPPORT_FIELDS',
    SET_OPENED_TICKET: 'support/SET_OPENED_TICKET',
    MARK_TICKET_AS_UNREAD: 'support/MARK_TICKET_AS_UNREAD',
    SUPPORT_SOCKET_CONNECT: 'socket/SUPPORT_SOCKET_CONNECT',
    SUPPORT_SOCKET_CONNECTED: 'socket/SUPPORT_SOCKET_CONNECTED',
    SUPPORT_SOCKET_EMIT_EVENT: 'socket/SUPPORT_SOCKET_EMIT_EVENT',
    SUPPORT_SOCKET_DISCONNECT: 'socket/SUPPORT_SOCKET_DISCONNECT',
  
    SUPPORT_INIT: 'socket/init',
    SUPPORT_NEW_CUSTOMER: 'socket/customer',
    SUPPORT_CREATE_TICKET: 'socket/createTicket',
    SUPPORT_TICKET_MESSAGES: 'socket/ticketMessages',
    SUPPORT_NEW_MESSAGE: 'socket/message',
    SUPPORT_REFRESH_TICKETS: 'socket/myTickets',
    SUPPORT_ALL_READ: 'socket/allRead',
    SUPPORT_RATING_RECEIVED: 'socket/rating'
  }
  
  export const supportAllRead = () => ({
    type: supportTypes.SUPPORT_ALL_READ
  })
  
  export const markTicketAsUnread = payload => ({
    type: supportTypes.MARK_TICKET_AS_UNREAD,
    payload
  })
  
  export const setOpenedTicket = payload => ({
    type: supportTypes.SET_OPENED_TICKET,
    payload
  })
  
  export const setSupportFields = payload => ({
    type: supportTypes.SET_SUPPORT_FIELDS,
    payload
  })
  
  export const supportSocketConnect = payload => ({
    type: supportTypes.SUPPORT_SOCKET_CONNECT,
    payload
  })
  
  export const supportInit = (payload) => ({
    type: supportTypes.SUPPORT_INIT,
    ...payload
})

  export const supportSocketConnected = () => ({
    type: supportTypes.SUPPORT_SOCKET_CONNECTED
  })
  
  export const supportSocketEmit = payload => ({
    type: supportTypes.SUPPORT_SOCKET_EMIT_EVENT,
    payload
  })
  
  export const supportSocketDisconnect = () => ({
    type: supportTypes.SUPPORT_SOCKET_DISCONNECT
  })