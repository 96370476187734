import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import { outletsTypes, setLoadingOff } from '../actions'
import { API } from "../config/settings"
import { Headers } from '../utilities'

export const editOutlets = (action$) =>
  action$.pipe(
    ofType(outletsTypes.EDIT_OUTLETS),
    switchMap(({ payload: { payload, onSuccess, onError } }) => {
      return ajax({
        url: `${API}outlet`,
        method: 'PUT',
        headers: Headers.get_auth(),
        body: JSON.stringify(payload)
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable
            .create(obs => {
              onSuccess();
              obs.next(setLoadingOff())
              obs.complete()
            })
        }),
        catchError(err => {
          return ActionsObservable
            .create(obs => {
              //onError();
              console.error(err);
              obs.complete()
            })
        })
      )
    })
  )

export const createOutlet = (action$) =>
  action$.pipe(
    ofType(outletsTypes.CREATE_OUTLET),
    switchMap(({ payload: { station, outlets, onSuccess, onError } }) => {
      return ajax({
        url: `${API}outlet/create`,
        method: 'POST',
        headers: Headers.get_auth(),
        body: JSON.stringify({ station, outlets })
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable
            .create(obs => {
              onSuccess(response);
              obs.next(setLoadingOff())
              obs.complete()
            })
        }),
        catchError(err => {
          return ActionsObservable
            .create(obs => {
              onError();
              console.error(err);
              obs.complete()
            })
        })
      )
    })
  )

export const deleteOutlet = (action$) =>
  action$.pipe(
    ofType(outletsTypes.DELETE_OUTLET),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}outlet/${payload}`,
        method: 'DELETE',
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable
            .create(obs => {
              obs.next(setLoadingOff())
              obs.complete()
            })
        }),
        catchError(err => {
          return ActionsObservable
            .create(obs => {
              //onError();
              console.error(err);
              obs.complete()
            })
        })
      )
    })
  )