import { websiteTypes } from '../actions'

const initialState = {
    loading: false,
}

const website = (state = initialState, { type, payload } = {}) => {
    switch (type) {
        case websiteTypes.GET_WEBSITE_SUCCESS:
            if (payload.docs && payload.page && payload.page > 1) return ({ ...state, ...payload, docs: [...(state?.docs || {}), ...payload.docs] })
            else return ({ ...state, ...payload })
        default:
            return state
    }
}

export default website