/* eslint-disable */
import React,{useEffect, useState} from 'react'
import {connect} from "react-redux"
import moment from "moment";
import TicketsContainer from '../../components/TicketsContainer/TicketsContainer';
import ChatContainer from '../../components/ChatContainer';
import { checkUser } from '../../actions';
import './styles.scss'


const Support = ({tickets}) =>{

  const [buttonClicked, setButtonClicked] = useState(false)

  const data = tickets.slice(0).sort((a,b) => moment(b.updatedAt).unix()-moment(a.updatedAt).unix());
  const readData = data.slice(0).sort((a,b)=> a._unreadCustomer === b._unreadCustomer ? 0 : a._unreadCustomer ? -1 : 1 );

  const [selectedTab,setSelectedTab] = useState(data[0]?._id);

    return(
        <div className="support-container">
            <TicketsContainer  tickets={tickets} buttonClicked={buttonClicked} 
            setButtonClicked={setButtonClicked} selectedTab={selectedTab} 
            setSelectedTab={setSelectedTab} data={readData}/>
            
            <ChatContainer tickets={tickets} buttonClicked={buttonClicked} setSelectedTab={setSelectedTab} setButtonClicked={setButtonClicked}/>
        </div>
    )
}

const mapStateToProps = (state) => ({
    tickets:state.support.tickets,
})

const mapDispatchToProps = (dispatch) => ({
    checkUser: (payload) => dispatch(checkUser(payload)),
})

export default connect(mapStateToProps,mapDispatchToProps)(Support);