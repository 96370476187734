export const GroupTypes = {
    GET_GROUPS: 'groups/GET_GROUPS',
    GET_GROUPS_SUCCESS: 'groups/GET_GROUPS_SUCCESS',

    SET_GROUPS_DETAILS: 'groups/GET_GROUPS_DETAILS',

    SET_OPENED_GROUP: 'groups/OPENED_GROUP',
    CREATE_GROUP: 'groups/CREATE_GROUP',

    EDIT_GROUP_MAIN_INFO:"groups/EDIT_GROUP_MAIN_INFO",
    EDIT_GROUP_BUSINESS_USERS:"groups/EDIT_GROUP_BUSINESS_USERS",
    EDIT_GROUP_LOCATIONS:"groups/EDIT_GROUP_LOCATIONS",

    GET_GROUP_BY_ID:'groups/GET_GROUP_BY_ID',
    GET_GROUP_BY_ID_SUCCESS: 'groups/GET_GROUP_BY_ID_SUCCESS',

    GET_GROUP_ACTIVITY:'groups/GET_GROUP_ACTIVITY',
    GET_GROUP_ACTIVITY_SUCCESS:'groups/GET_GROUP_ACTIVITY_SUCCESS',
    
    GET_STATISTICS:'groups/GET_STATISTICS',
    GET_STATISTICS_SUCCESS:'groups/GET_STATISTICS_SUCCESS',

    DELETE_GROUP: `groups/DELETE_GROUP`,
  }
  
  export const setOpenedGroup = payload => ({
    type: GroupTypes.SET_OPENED_GROUP,
    payload
  })

  export const getGroups = (payload) => ({
    type: GroupTypes.GET_GROUPS,
    payload
  })

  export const setGroupsDetails = (payload) => ({
    type: GroupTypes.SET_GROUPS_DETAILS,
    payload
  })

  export const getGroupByID = (payload) =>({
    type:GroupTypes.GET_GROUP_BY_ID,
    payload
  })

  export const createGroup = (payload) => ({
    type: GroupTypes.CREATE_GROUP,
    payload
  })

  export const deleteGroup = (payload) => ({
    type: GroupTypes.DELETE_GROUP,
    payload
  })

  export const editGroupMainInfo = (payload) => ({
    type: GroupTypes.EDIT_GROUP_MAIN_INFO,
    payload
  })

  export const editGroupUsers = (payload) => ({
    type: GroupTypes.EDIT_GROUP_BUSINESS_USERS,
    payload
  })

  export const editGroupLocations = (payload) => ({
    type: GroupTypes.EDIT_GROUP_LOCATIONS,
    payload
  })

  export const getGroupActivity =  (payload) => ({
    type:GroupTypes.GET_GROUP_ACTIVITY,
    payload
  })

  export const getGroupStatistics = (payload) => ({
    type:GroupTypes.GET_STATISTICS,
    payload
  })
  