import React from 'react';
import './styles.scss';

export default function Checkbox({ checkedList, setCheckedList, type, label, location, selectedLocations, setSelectedLocations, className, index }) {
  return (
    <div className={`checkboxes child custom ${className ? className : ''} ${!label ? 'no-label' : ''}`} key={index || 0}>
    <input
      type="checkbox"
      id={`scales-${index || 0}`}
      name="scales"
      className="child"
      checked={checkedList?.includes(type) && true}
      onClick={() => {
        let list = []
        let locationsList = []

        if (!checkedList?.includes(type)) {
          list = [...checkedList, type]
          locationsList = [...selectedLocations, location]
          setCheckedList(list)
          setSelectedLocations(locationsList)
        } 
        else if (checkedList?.includes(type)) {
         list = [...checkedList]
         list.splice(list.indexOf(type), 1)
         setCheckedList(list)

         locationsList = [...selectedLocations]
         locationsList.splice(list.indexOf(type), 1)
         setSelectedLocations(locationsList)
      }
      }}
    />
    {label ? 
    <label
      htmlFor="scales"
      className="child"
      style={{
        marginTop: "2%",
        marginLeft: "1%",
        width: "80%",
      }}
    >
      {label}
    </label> : <></>}
  </div>
  )
}
