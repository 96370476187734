import React, { useMemo } from "react"
import "./styles.scss"

const Horizontal = ({ data, handleInfo }) => {
    const scale = (num, in_min, in_max, out_min, out_max) => ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min

    const { min, max } = useMemo(() => {
        const mapped = data?.map(({ label }) => label) || []
        return { min: Math.min(...mapped), max: Math.max(...mapped) }
    }, [data])

    return <div className="graph-horizontal-container">
        {data?.map(({ value, label, info, logo }, i) => <div
            key={i}
            className="graph-horizontal-row-container row"
        >
            <div className="graph-horizontal-row-indicator-outer">
                <div
                    className="graph-horizontal-row-indicator"
                    style={{ width: label === 0 ? 0 : `${scale(label, min, max, 1, 80)}%` }}
                ><span>.</span>
                </div>
            </div>
            <div className="graph-horizontal-row-text row">
                <p><span>{label}</span> {value}</p>
                {logo && <div className="icon icon-logo" style={{ backgroundImage: `url(${logo})` }} />}
                {info && <div className="icon icon-info" onClick={() => handleInfo({ value, label, info })} />}
            </div>
        </div>)
        }
    </div >
}

export default Horizontal