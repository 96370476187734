import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import { GroupTypes, setLoadingOff } from '../actions'
import { API } from "../config/settings"
import { Headers } from '../utilities'

export const getGroups = action$ =>
  action$.pipe(
    ofType(GroupTypes.GET_GROUPS),
    switchMap(({ payload: { pageNumber, pageSize, noPagination, search, sort, onSuccess, onError } }) => {
      return ajax({
        url: `${API}businessGroup/list`,
        method: 'POST',
        headers: Headers.get_auth(),
        body: JSON.stringify({ pageNumber, pageSize, noPagination, search, sort })
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable
            .create(obs => {
              obs.next({
                type: GroupTypes.GET_GROUPS_SUCCESS,
                payload: response.payload,
              })
              onSuccess(response)
              obs.next(setLoadingOff())
              obs.complete()
            })
        }),
        catchError(err => {
          return ActionsObservable
            .create(obs => {
              console.error(err);
              obs.complete()
            })
        })
      )
    })
  )

export const getGroupByID = action$ =>
  action$.pipe(
    ofType(GroupTypes.GET_GROUP_BY_ID),
    switchMap(({ payload: { id, onSuccess } }) => {
      return ajax({
        url: `${API}businessGroup/${id}`,
        method: 'GET',
        headers: Headers.get_auth(),
        body: JSON.stringify(id)
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable
            .create(obs => {
              obs.next({
                type: GroupTypes.GET_GROUP_BY_ID_SUCCESS,
                payload: response
              })
              onSuccess(response)
              obs.next(setLoadingOff())
              obs.complete()
            })
        }),
        catchError(err => {
          return ActionsObservable
            .create(obs => {
              console.error(err);
              obs.complete()
            })
        })
      )
    })
  )

export const createGroup = (action$) =>
  action$.pipe(
    ofType(GroupTypes.CREATE_GROUP),
    switchMap(({ payload: { payload, onSuccess, onError } }) => {
      return ajax({
        url: `${API}businessGroup/create`,
        method: 'POST',
        headers: Headers.get_auth(),
        body: JSON.stringify(payload)
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable
            .create(obs => {
              onSuccess()
              obs.next(setLoadingOff())
              obs.complete()
            })
        }),
        catchError(err => {
          return ActionsObservable
            .create(obs => {
              onError(err)
              console.error(err);
              obs.complete()
            })
        })
      )
    })
  )

export const editGroupMainInfo = (action$) =>
  action$.pipe(
    ofType(GroupTypes.EDIT_GROUP_MAIN_INFO),
    switchMap(({ payload: { payload, id, onSuccess, onError } }) => {
      return ajax({
        url: `${API}businessGroup/${id}`,
        method: 'PUT',
        headers: Headers.get_auth(),
        body: JSON.stringify(payload)
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable
            .create(obs => {
              onSuccess()
              obs.next(setLoadingOff())
              obs.complete()
            })
        }),
        catchError(err => {
          return ActionsObservable
            .create(obs => {
              onError()
              console.error(err);
              obs.complete()
            })
        })
      )
    })
  )

export const editGroupUsers = (action$) =>
  action$.pipe(
    ofType(GroupTypes.EDIT_GROUP_BUSINESS_USERS),
    switchMap(({ payload: { payload, id, onSuccess, onError } }) => {
      return ajax({
        url: `${API}businessGroup/users/${id}`,
        method: 'PUT',
        headers: Headers.get_auth(),
        body: JSON.stringify(payload)
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable
            .create(obs => {
              onSuccess()
              obs.next(setLoadingOff())
              obs.complete()
            })
        }),
        catchError(err => {
          return ActionsObservable
            .create(obs => {
              onError()
              console.error(err);
              obs.complete()
            })
        })
      )
    })
  )

export const editGroupLocations = (action$) =>
  action$.pipe(
    ofType(GroupTypes.EDIT_GROUP_LOCATIONS),
    switchMap(({ payload: { payload, id, onSuccess, onError } }) => {
      return ajax({
        url: `${API}businessGroup/locations/${id}`,
        method: 'PUT',
        headers: Headers.get_auth(),
        body: JSON.stringify(payload)
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable
            .create(obs => {
              onSuccess()
              obs.next(setLoadingOff())
              obs.complete()
            })
        }),
        catchError(err => {
          return ActionsObservable
            .create(obs => {
              onError()
              console.error(err);
              obs.complete()
            })
        })
      )
    })
  )

export const deleteGroup = (action$) =>
  action$.pipe(
    ofType(GroupTypes.DELETE_GROUP),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}businessGroup/${payload}`,
        method: 'DELETE',
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable
            .create(obs => {
              obs.next(setLoadingOff())
              obs.complete()
            })
        }),
        catchError(err => {
          return ActionsObservable
            .create(obs => {
              console.error(err);
              obs.complete()
            })
        })
      )
    })
  )

export const getActivity = action$ =>
  action$.pipe(
    ofType(GroupTypes.GET_GROUP_ACTIVITY),
    switchMap(({ payload: { businessGroup, locations, sort, startDate, endDate, minAmount, maxAmount, stations, cars, pageNumber, pageSize, sortBy, noPagination, onSuccess } }) => {
      return ajax({
        url: `${API}businessGroup/activity`,
        method: 'POST',
        headers: Headers.get_auth(),
        body: JSON.stringify({ businessGroup, locations, sort, startDate, endDate, minAmount, maxAmount, stations, cars, pageNumber, pageSize, sortBy, noPagination, onSuccess })
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable
            .create(obs => {
              obs.next({
                type: GroupTypes.GET_GROUP_ACTIVITY_SUCCESS,
                payload: response.payload,
              })
              onSuccess(response)
              obs.next(setLoadingOff())
              obs.complete()
            })
        }),
        catchError(err => {
          return ActionsObservable
            .create(obs => {
              console.error(err);
              obs.complete()
            })
        })
      )
    })
  )

export const getGroupStatistics = action$ =>
  action$.pipe(
    ofType(GroupTypes.GET_STATISTICS),
    switchMap(({ payload: { id, onSuccess } }) => {
      return ajax({
        url: `${API}businessGroup/statistic/${id}`,
        method: 'GET',
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable
            .create(obs => {
              obs.next({
                type: GroupTypes.GET_STATISTICS_SUCCESS,
                payload: response.payload,
              })
              obs.next(setLoadingOff())
              obs.complete()
            })
        }),
        catchError(err => {
          return ActionsObservable
            .create(obs => {
              console.error(err);
              obs.complete()
            })
        })
      )
    })
  )