/* eslint-disable */
import React, { useEffect, useState } from "react";
import "./styles.scss";
import { ic_keyboard_arrow_down } from "react-icons-kit/md/ic_keyboard_arrow_down";
import { Icon } from "react-icons-kit";
import HeaderFilterRow from "../HeaderFilterRow/HeaderFilterRow";
import moment from "moment";
import { setUsersDetails } from "../../actions";
import Scrollbars from "react-custom-scrollbars";
import { Statistics } from "../../components";
import { API } from "../../config/settings";
import { Headers } from "../../utilities";
import { history } from "../../config/stores";

const ExpenseFields = ["paymentDate", "hour", "user", "amount", "paymentMethod"];

const fieldMap = {
  google_pay: "Google Pay",
  apple_pay: "Apple Pay",
  card: "Банкова карта",
};

const FinancesExpenseGrid = ({ tableRef, expenses, fetchMoreIncomes, setHasMore, sortBy, setSortBy, sortValue, setSortValue, sortData, type, setType, payment, setPayment, filteredUsers, setFilteredUsers, filtersLoading, innerLoading }) => {
  const [coordinates, setCoordinates] = useState({
    display: "none",
    x: "0%",
    y: "0%",
  });
  const [show, setShow] = useState(false);
  const [currentFile, setCurrentFile] = useState({});
  const [container, setContainer] = useState({});
  const [indexx, setIndex] = useState(0);
  const [originalOrder, setOriginalOrder] = useState([]);
  const [selectedHeader, setSelectedHeader] = useState({});
  const [users, setUsers] = useState([]);

  let headerElements = [
    {
      name: "Дата",
      type: "paymentDate",
      size: "medium first",
      query: { filter: "", options: [] },
    },
    {
      name: "Час",
      type: "hour",
      size: "small",
      query: { filter: "", options: [] },
    },
    {
      name: "Потребител",
      type: "user",
      filter: true,
      search: true,
      size: "medium",
      containerType: "high",
      query: {
        filter: "",
        options: users || [],
      },
    },
    {
      name: "Заредена сума",
      type: "amount",
      size: "small",
      query: {
        filter: "",
        options: [],
      },
    },
    {
      name: "Плащане с",
      type: "paymentMethod",
      size: "last",
      filter: true,
      query: {
        filter: "",
        options: [
          { label: "Банкова карта", type: "card" },
          { label: "Apple Pay", type: "apple_pay" },
          { label: "Google Pay", type: "google_pay" },
        ],
      },
    },
  ];

  function set_Popup(elem) {
    let rect = elem.getBoundingClientRect();

    setCoordinates({
      display: "flex",
      x: `${rect.x + "20"}px`,
      y: `50px`,
      smaller: true,
    });
  }
  const onScroll = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const t = scrollHeight - clientHeight - scrollTop <= 1 && scrollTop !== 0;
    if (t) {
      fetchMoreIncomes();
    }
  };
  const loadOptionsUsers = (search, onSuccess) =>
    new Promise((resolve, reject) => {
      let searchString = search.slice();
      if (searchString && searchString?.includes("+")) searchString = searchString.replace(/\+/g, "");

      return fetch(`${API}user/list`, {
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify({ pageSize: 20, search: searchString }),
      })
        .then((res) => res.json())
        .then((res) => {
          const options = res.payload.docs.map(({ _id, name, phoneNumber }) => ({
            type: _id,
            label: [name, phoneNumber].filter(Boolean).join(" - "),
          }));
          setUsers([...options]);
          if (onSuccess) onSuccess([...options]);
          dispatch(setUsersDetails({ hasNextPage: res?.payload?.hasNextPage, page: res?.payload?.page }));
          return resolve(options);
        })
        .catch(() => reject());
    });

  useEffect(() => {
    loadOptionsUsers("");
  }, []);

  return (
    <>
      <Scrollbars
        style={{
          height: "85%",
          width: "100%",
          marginTop: "1%",
          overflowX: "hidden",
        }}
        onUpdate={onScroll}
        ref={tableRef}
        id={"scrollbar"}
        renderView={(props) => <div {...props} style={{ ...props.style, overflowX: "hidden" }} />}
      >
        <table className="first-grid expense" ref={tableRef} style={{ width: "100%" }} onScroll={(e) => onScroll(e)}>
          <thead>
            <tr className="header-of-table">
              {headerElements?.map((element, index) => {
                return (
                  <th className={`header ${element?.size}`} key={index}>
                    <span className="inner-text">{element?.name}</span>
                    <div className={`icon-container ${sortBy === element?.name && "rotate"}`}>
                      <Icon
                        size={"80%"}
                        icon={ic_keyboard_arrow_down}
                        className={`arrow-down`}
                        id={index}
                        onClick={() => {
                          var All_tabs = document.querySelectorAll(".fulll-inner-tab");
                          All_tabs = Array.prototype.slice.call(All_tabs, 0);

                          // setSortBy(element?.type);
                          setOriginalOrder(All_tabs);
                          setContainer(element);
                          setShow(true);
                          setIndex(index);
                          let elem = document.getElementById(`${index}`);
                          set_Popup(elem);
                          setHasMore(false);
                          setSelectedHeader(element);
                        }}
                      />
                    </div>
                  </th>
                );
              })}
            </tr>
            {show ? (
              <HeaderFilterRow
                coordinates={coordinates}
                element={container}
                index={indexx}
                type={"expenses"}
                sortValue={sortValue}
                setSortValue={setSortValue}
                selectedHeader={selectedHeader}
                filterType={type}
                setFilterType={setType}
                payment={payment}
                setPayment={setPayment}
                filteredUsers={filteredUsers}
                setFilteredUsers={setFilteredUsers}
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortData={sortData}
                loadOptionsUsers={loadOptionsUsers}
                onClose={() => {
                  setShow(false);
                }}
              />
            ) : null}
          </thead>
          {filtersLoading ? (
            <Statistics.LoaderInline styles={{ display: "flex", alignItems: "center" }} />
          ) : (
            <tbody className="inner-full-income-container" id="all-stations" style={{ overflowY: "hidden" }}>
              {expenses?.map((expense, i) => {
                return (
                  <tr
                    className={`fulll-inner-tab pointer`}
                    key={i}
                    id={i}
                    style={{ marginBottom: "25px" }}
                    onClick={() => {
                      history.push(`/client/${expense?.user?._id}`);
                    }}
                  >
                    {ExpenseFields?.map((field, j) => {
                      return (
                        <td className={`inner-part ${headerElements?.[j]?.size}`} id={field} key={j}>
                          {field === "paymentDate" ? (
                            <React.Fragment>
                              <div className="invoice-icon" onClick={() => window.open(expense?.receiptUrl)} />
                              <span className={`inner-text ${j === 0 && "first"}`}>{moment(expense?.paymentDate).format("DD.MM.YYYY")}</span>
                            </React.Fragment>
                          ) : field === "hour" ? (
                            <span className={`inner-text`}>{moment(expense?.paymentDate).format("HH:mm")}</span>
                          ) : field === "amount" ? (
                            <span className={`inner-text`}>{expense?.[field] || "0"} лв.</span>
                          ) : field === "user" ? (
                            <React.Fragment>
                              <span className={`inner-text`}>{expense?.[field]?.phoneNumber || "--"}</span>
                              {expense?.[field]?.phoneNumber ? (
                                <img
                                  className="info-icon"
                                  src={require("../../assets/icons/info.svg")}
                                  onClick={() => {
                                    history.push(`/client/${expense?.user?._id}`);
                                  }}
                                />
                              ) : (
                                <></>
                              )}
                            </React.Fragment>
                          ) : field === "paymentMethod" ? (
                            <span className={`inner-text`}>{fieldMap?.[expense?.[field]] || "--"}</span>
                          ) : typeof expense?.[field] === "string" || typeof expense?.[field] === "number" ? (
                            <span className={`inner-text`}>{expense?.[field] || "--"}</span>
                          ) : (
                            <></>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
          {innerLoading && <Statistics.LoaderInline styles={{ display: "flex", alignItems: "center", height: "120px" }} />}
        </table>
      </Scrollbars>
    </>
  );
};

export default FinancesExpenseGrid;
