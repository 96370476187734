import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import { withRouter, Redirect } from "react-router-dom";
import {
  Home,
  Stations,
  AddStations,
  Groups,
  AddGroup,
  EditGroup,
  Group,
  Vouchers,
  AddVoucher,
  Finances,
  Support,
  Edit,
  Users,
  EditUser,
  stationSettings,
  AddUser,
  StartCharging,
  Cars,
  Statistics,
  Website,
  Article,
  ArticleForm,
  NotificationsSend,
  AddCars,
  Client,
  Locations
} from "../../screens";
import { Header } from "../../components";
import { Station } from "../../screens";
import { User } from "../../utilities";

const AuthRoute = ({ children, ...rest }) => {
  return (
    <Route
      render={({ location }) =>
        !User.isAuthenticated ? (
          <Route {...rest} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: location } }} />
        )
      }
    />
  );
};

const PrivateRoute = ({ children, ...rest }) => {
  return (
    <Route
      render={({ location }) =>
        User.isAuthenticated ? (
          <Route {...rest} />
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  );
};

const AdminRoute = ({ children, ...rest }) => {
  return (
    <Route
      render={({ location }) =>
        User.isAuthenticated ? (
          User.isAdmin ? (
            <Route {...rest} />
          ) : (
            <Redirect
              to={{ pathname: "/advertisers", state: { from: location } }}
            />
          )
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  );
};

class Routes extends Component {
  render() {

    return (
      <div className="dashboard-wrap">
        {User.isAuthenticated ? <Header /> : ""}
        <Switch>
          <AuthRoute exact path="/login" component={Home} />
          <PrivateRoute exact path="/" component={Stations} />
          <PrivateRoute path="/location" component={Station} />
          <PrivateRoute path="/edit/location" component={Edit} />
          <AdminRoute exact path="/locations/add" component={AddStations} />
          <AdminRoute path="/locations/settings" component={stationSettings} />
          <AdminRoute
            path="/locations/charge/start"
            component={StartCharging}
          />
          <PrivateRoute exact path="/groups" component={Groups} />
          <PrivateRoute exat path="/groups/add" component={AddGroup} />
          <PrivateRoute path="/edit/group" component={EditGroup} />
          <PrivateRoute path="/locations/select" component={Locations} />
          <PrivateRoute path="/group" component={Group} />
          <AdminRoute exact path="/vouchers" component={Vouchers} />
          <AdminRoute exact path="/voucher/add" component={AddVoucher} />
          <PrivateRoute exact path="/finances" component={Finances} />
          <PrivateRoute exact path="/finances/deposits" component={Finances} />
          <PrivateRoute exact path="/client/:id" component={Client} />
          <PrivateRoute exact path="/support" component={Support} />
          <AdminRoute exact path="/users" component={Users} />
          <AdminRoute path="/user" component={EditUser} />
          <AdminRoute exact path="/users/add" component={AddUser} />
          <PrivateRoute exact path="/cars" component={Cars} />
          <PrivateRoute exact path="/cars/add" component={AddCars} />
          <PrivateRoute exact path="/statistics" component={Statistics} />
          <PrivateRoute exact path="/website" component={Website} />
          <PrivateRoute exact path="/website/form" component={ArticleForm} />
          <PrivateRoute exact path="/website/:_id" component={Article} />
          <PrivateRoute
            exact
            path="/notifications/send"
            component={NotificationsSend}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { loading: state.starter.loading };
};

export default withRouter(connect(mapStateToProps)(Routes));
