import React from "react";
import "./styles.scss";

const Checkboxes = ({ options, disabled, onChange, value, label, compulsory, column, style, full }) => {
  return (
    <div className="input-container" style={style}>
      {label && (
        <label className={`input-text`}>
          {label} {compulsory && <span className={"pl-2 text-black"}>*</span>}
        </label>
      )}
      <div className={`checkboxes-container ${column && "column"}`}>
        {options?.map((opt, i) => (
          <div
            className={`checkbox-container ${column && "column"}`}
            onClick={() => {
              if (disabled || !onChange) return;
              if (full) onChange(opt);
              else onChange(opt?.value || opt);
            }}
          >
            <div className={`outer-square ${value?.includes(opt.value) && "!border-none"}`}>
              <div className={`tick ${value?.includes(opt.value) && "selected !rounded-none !border-none"}`} />
            </div>
            <label className="text-sm" style={{ paddingLeft: "6px" }}>
              {opt?.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Checkboxes;
