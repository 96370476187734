import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { StationTypes, setLoadingOff } from "../actions";
import { API } from "../config/settings";
import { Headers } from "../utilities";

export const getStationsEpic = (action$) =>
  action$.pipe(
    ofType(StationTypes.GET_STATIONS),
    switchMap(
      ({
        payload: {
          pageNumber,
          pageSize,
          noPagination,
          sortBy,
          search,
          onSuccess,
          onError,
        },
      }) => {
        return ajax({
          url: `${API}station/list`,
          method: "POST",
          headers: Headers.get_auth(),
          body: JSON.stringify({ pageNumber, pageSize, sortBy, noPagination, search }),
        }).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              obs.next({
                type: StationTypes.GET_STATIONS_SUCCESS,
                payload: response.payload,
              });
              if (onSuccess) onSuccess(response)
              obs.next(setLoadingOff());
              obs.complete();
            });
          }),
          catchError((err) => {
            return ActionsObservable.create((obs) => {
              console.error(err);
              obs.complete();
            });
          })
        );
      }
    )
  );

export const createStation = (action$) =>
  action$.pipe(
    ofType(StationTypes.CREATE_STATION),
    switchMap(({ payload: { payload, onSuccess, onError } }) => {
      return ajax({
        url: `${API}station/create`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            onSuccess(response);
            obs.next(setLoadingOff());
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.error(err);
            onError();
            obs.complete();
          });
        })
      );
    })
  );

export const getStationByID = (action$) =>
  action$.pipe(
    ofType(StationTypes.GET_STATION_BY_ID),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}station/${payload.station}`,
        method: "GET",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: StationTypes.GET_STATION_BY_ID_SUCCESS,
              payload: response,
            });
            payload.onSuccess(response);
            obs.next(setLoadingOff());
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.error(err);
            obs.complete();
          });
        })
      );
    })
  );
export const deleteStation = (action$) =>
  action$.pipe(
    ofType(StationTypes.DELETE_STATION),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}station/${payload}`,
        method: "DELETE",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(setLoadingOff());
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.error(err);
            obs.complete();
          });
        })
      );
    })
  );

export const editStations = (action$) =>
  action$.pipe(
    ofType(StationTypes.EDIT_STATIONS),
    switchMap(({ payload: { payload, onSuccess, onError } }) => {
      return ajax({
        url: `${API}station`,
        method: "PUT",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            onSuccess(response);
            obs.next(setLoadingOff());
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            onError(err);
            console.error(err);
            obs.complete();
          });
        })
      );
    })
  );

export const getStatistics = (action$) =>
  action$.pipe(
    ofType(StationTypes.GET_STATISTICS),
    switchMap(() => {
      return ajax({
        url: `${API}statistics/stations`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: StationTypes.GET_STATISTICS_READY,
              payload: response.payload,
            });
            obs.next(setLoadingOff());
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.error(err);
            obs.complete();
          });
        })
      );
    })
  );
