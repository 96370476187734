import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Controller, useForm, useWatch } from "react-hook-form";
import { history } from "../../config/stores"
import { useQuery } from "../../utilities/useQuery";
import "./styles.scss"
import { createWebsiteArticle, getWebsiteArticle, updateWebsiteArticle } from "../../actions";
import { isEmpty, pick } from "lodash";
import { Inputs } from "../../components"
import ReactQuill from "react-quill";

const ArticleForm = () => {
    const dispatch = useDispatch()
    const { control, register, handleSubmit, formState: { errors }, reset } = useForm();
    const photos = useWatch({ control, name: 'photos' }) || []
    const coverPhoto = useWatch({ control, name: 'coverPhoto' }) || {}
    const { _id } = useQuery()
    const [text, setText] = useState()
    useEffect(() => {
        if (_id) dispatch(getWebsiteArticle({
            payload: _id,
            onSuccess: (res) => {
                reset({ ...pick(res, ['_id', 'title', 'text', 'coverPhoto', 'photos', 'pinned']) })
                setText(res.text)
            }
        }))
    }, [_id, dispatch, reset])

    const handleCreate = data => dispatch(createWebsiteArticle({
        payload: data,
        onSuccess: () => history.goBack()
    }))
    const handleEdit = data => dispatch(updateWebsiteArticle({
        payload: data,
        onSuccess: () => history.goBack()
    }))


    return <div className="screen-article-form-container">
        <div className="screen-article-form-inner-container col">
            <div className="screen-article-form-header row">
                <div className="icon icon-close" onClick={() => history.goBack()} />
                <h2>{_id ? "review article" : "Добавяне на статия"}</h2>
                <div className="row row-buttons">
                    <Controller
                        control={control}
                        name={`pinned`}
                        render={({ field: { value, onChange } }) => <Inputs.Toggle
                            label="Добави на първо място"
                            value={value}
                            onChange={() => onChange(!value)} />}
                    />
                    {_id
                        ? <>
                            <Inputs.Raised text="CANCEL" reversed onClick={() => history.goBack()} />
                            <Inputs.Raised text="EDIT" onClick={handleSubmit(handleEdit)} />
                        </>
                        : <Inputs.Raised text="Запази" onClick={handleSubmit(handleCreate)} />
                    }
                </div>
            </div>
            <div className="screen-article-form-content">
                <p className="row">Заглавие {!_id && <span className="icon icon-required no-active" />}</p>
                <Inputs.Text
                    className={errors?.title ? "invalid" : ''}
                    {...register(`title`, { required: true, })}
                />
                <p className="row">Снимка на корица {!_id && <span className="icon icon-required no-active" />}</p>
                <div className="row">
                    <Controller
                        control={control}
                        name={`coverPhoto`}
                        render={({ field: { onChange } }) => <Inputs.UploadButton
                            onChange={([coverPhoto]) => onChange(coverPhoto)}
                            className={`${errors?.coverPhoto ? 'invalid' : ''} ${!isEmpty(coverPhoto) ? 'disabled' : ''}`}

                        />}
                        rules={{ required: true }}
                    />
                    {!isEmpty(coverPhoto) && <Controller
                        control={control}
                        name={`coverPhoto`}
                        render={({ field: { value, onChange } }) => <div className="row image">
                            <div className="icon icon-close" onClick={() => onChange(null)} />
                            <p>{value?.name}</p>
                        </div>}
                        rules={{ required: true }}
                    />}
                </div>
                <p className="row">Снимки на отворена статия {!_id && <span className="icon icon-required no-active" />}</p>
                <div className="row">
                    <Controller
                        control={control}
                        name={`photos`}
                        render={({ field: { value, onChange } }) => <Inputs.UploadButton
                            inputProps={{ multiple: true }}
                            className={errors?.photos ? "invalid" : ''}
                            onChange={(res) => onChange([...(value || []), ...res])}
                        />}
                        rules={{ required: true }}
                    />
                    {!isEmpty(photos) && <Controller
                        control={control}
                        name={`photos`}
                        render={({ field: { onChange } }) => <div className="row">
                            {photos.map(({ name }, i) => <div key={i} className="row image">
                                <div className="icon icon-close" onClick={() => {
                                    const newPhotos = [...(photos || [])]
                                    newPhotos.splice(i, 1)
                                    onChange(newPhotos)
                                }} />
                                <p>{name}</p>
                            </div>)}
                        </div>}
                        rules={{ required: true }}
                    />}
                </div>
                <p className="row">Текст {!_id && <span className="icon icon-required no-active" />}</p>
                {(!_id || text) && <Controller
                    control={control}
                    name={`text`}
                    render={({ field: { onChange } }) => <ReactQuill
                        style={errors?.text ? { boxShadow: `0 0 3px #e5214e`, borderColor: 'transparent' } : {}}
                        value={text}
                        onChange={(e) => onChange(e)}
                    />}
                    rules={{ required: true }}
                />}
            </div>
        </div>
    </div>
}

export default ArticleForm