/* eslint-disable */
import Swal from "sweetalert2";

export function checkStations(stations) {
  return stations
    .map((station, i) => {
      let stationInfoCorrect = Object.values(station).map((value, index) => {
        if (index !== 1) {
          //check if key in station is locationID
          if (value === "" || !value) {
            return 0;
          } else return 1;
        }
      });
      let outletCheck = new Array();

      outletCheck = station.outlets.map((outlet) =>
        outlet.name.length < 1 || outlet.type.length < 1 ? 0 : 1
      );

      if (stationInfoCorrect.includes(0) || outletCheck.includes(0)) {
        Swal.fire({
          icon: "error",
          title: "Упс...",
          text: "Всички полета трябва да са попълнени.",
        });
        return 0;
      }
    })
    .toString();
}

export function stringToNumber(stations) {
  stations.map((station) => {
    if (isNaN(station?.price)) {
      if (station?.price?.indexOf(",") > -1) {
        station.price = Number(station.price.replace(/\,/g, "."));
      } else station.price = Number(station.price);
    }
  });
  return stations;
}

export function checkStationsOnEdit(stations) {
  return stations.map((station, i) => {
    let stationInfoCorrect = Object.keys(station).map((key, index) => {
      if (
        !key.includes("deletedAt") &&
        !key.includes("specialPrice") &&
        !key.includes("_v")
      ) {
        if (station[key].length < 1 || !station[key]) return 0;
        else return 1;
      }
    });
    let outletCheck = new Array();

    outletCheck = station._outlets.map((outlet) =>
      outlet.name.length < 1 || outlet.type.length < 1 ? 0 : 1
    );

    if (stationInfoCorrect.includes(0) || outletCheck.includes(0)) {
      Swal.fire({
        icon: "error",
        title: "Упс...",
        text: "Всички полета трябва да са попълнени.",
      });
      return 0;
    }
  }).toString();
}
