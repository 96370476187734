/* eslint-disable */
import React, { useEffect } from 'react'
import { useDispatch } from "react-redux"
import { history } from './config/stores'
import { Router } from "react-router-dom";
import Routes from './components/routes/Routes'
import { connect } from "react-redux"
import { supportSocketConnect, checkUser, setUserSupportId, loginSuccessFromLocalStorage, sessionSocketConnect } from './actions';
import { SUPPORT_API } from "./config/settings"
import { User } from './utilities';
import { ToastContainer, toast } from "react-toastify";
import './App.scss'
import 'reactjs-popup/dist/index.css';
import 'react-quill/dist/quill.snow.css'

function App({ loginSuccessFromLocalStorage }) {
  const dispatch = useDispatch();

  useEffect(() => {

    User.isAuthenticated && loginSuccessFromLocalStorage(User.getUser(), User.getToken())

    if (User.isAuthenticated) {
      const customerID = User.getUser()?.customerSupportId;
      const userName = User.getUser() === null ? "wink-user" : User.getUser().name;
      let query = "";

      if (customerID) {
        query = `?customerId=${customerID}`
        let sessionQuery = `token=${User.getToken()}`
        dispatch(sessionSocketConnect({ sessionQuery }))
        dispatch(supportSocketConnect({ query }))
      } else {
        window.fetch(`${SUPPORT_API}/system/all`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        }).then((res) => {
          res.json().then(response => {
            const systems = response.payload;
            const system = systems.find(item => item.constant === 'wink')
            query = `?systemId=${system._id}&name=${userName}`;
            dispatch(supportSocketConnect({ query }))
          })
        })
          .catch(err => { console.error(err); })
      }
    }

  }, [User.isAuthenticated, User.token]) // eslint-disable-next-line react-hooks/exhaustive-deps


  return (

    <Router history={history}>
      <ToastContainer
        position="top-right"
        autoClose={2}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes />
    </Router>
  )
}
const mapStateToProps = (state) => ({
  token: state.login.token,

})
const mapDispatchToProps = (dispatch) => ({
  checkUser: (payload) => dispatch(checkUser(payload)),
  setUserSupportId: (payload) => dispatch(setUserSupportId(payload)),
  loginSuccessFromLocalStorage: (payload) => dispatch(loginSuccessFromLocalStorage(payload)),

})
export default connect(mapStateToProps, mapDispatchToProps)(App)
