/* eslint-disable */
import React from "react";
import "./styles.scss";

const InnerSpecialPriceTabs = ["name", "partOfProfit", "paymentDate"];

const InnerShare = ({ group, groups }) => {
  const InnerShareTabs = [
    {
      name: "Локация име",
      query: { filter: "", options: [] },
    },
    {
      name: "Процент",
      query: { filter: "", options: [] },
    },
    {
      name: "Плащане на",
      query: { filter: "", options: [] },
    },
  ];
  return (
    <table className="inner-share-table scroll-width-thin">
      <tr className="inner-header-tabs" style={{ marginBottom: "2%" }}>
        {InnerShareTabs.map((header) => {
          return <th className="inner-header">{header.name}</th>;
        })}
      </tr>
      {group._locations.map((location) => {
        return (
          <tr className="inner-header-tabs" style={{ marginBottom: "2%" }}>
            {InnerSpecialPriceTabs.map((field, j) => {
              return <React.Fragment key={j}>{field === "partOfProfit" ? <td className="location-data">{location[field]}%</td> : field === "paymentDate" ? <td className="location-data">{location[field]}-то число</td> : <td className="location-data">{location[field]}</td>}</React.Fragment>;
            })}
          </tr>
        );
      })}
    </table>
  );
};

export default InnerShare;
