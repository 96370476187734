import { default as RadioButton } from './RadioButton'
import { default as Raised } from './Raised'
import { default as Text } from './Text'
import { default as Toggle } from './Toggle'
import { default as UploadButton } from './UploadButton'

const Inputs = {
    RadioButton,
    Raised,
    Text,
    Toggle,
    UploadButton,
}

export default Inputs