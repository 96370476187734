/* eslint-disable */
import React, { useState } from 'react'
import './styles.scss'
import {ic_keyboard_arrow_down} from 'react-icons-kit/md/ic_keyboard_arrow_down'
import { Icon } from 'react-icons-kit'
import HeaderFilterRow from '../HeaderFilterRow/HeaderFilterRow';
import { history } from "../../config/stores";
import moment from 'moment';

const BusinessUsersGrid = ({allUsers,selectedTab,fields,tableRef,fetchMoreUsers,setBusinessHasMore}) =>{
    
    const [sortBy,setSortBy] = useState("");
    const [coordinates,setCoordinates] = useState({
        "display" : "none",
        "x" : "0%",
        "y" : "0%"
    })
    const [show,setShow] = useState(false);
    const [container,setContainer] = useState({});
    const [indexx,setIndex] = useState(0);
    const [originalOrder,setOriginalOrder] = useState([]);

    const businessHeaderElements = [
        {
            name: "Име",
            query: {filter: "",  
                   options:() => {
                    const foundUsers = allUsers.map(user => {
                        if(user.businessGroup){
                            if(user.name === null || user.name === undefined) return ""
                            else return user.name 
                        }
                     })
                     return [...new Set(foundUsers)]
                 }
                }
        },
        {
            name: "Статус",
            query: {filter: "",  
                    options: ["Aктивен","Неактивен"]
                }
        },
        {
            name: "Бизнес Група",
            query: {filter: "",  
                    options: allUsers.map(user => user.businessGroup && user.businessGroup.name)}
        },
        {
            name: "Телефонен номер",
            query: {filter: "",  
                    options: allUsers.map(user => {
                        if(user.businessGroup){
                            return user.phoneNumber
                        }
                    })}
        },
        {
            name: "Добавен на",
            query: {filter: "",  
                    options: []}
        },
    ]
    
    function set_Popup(elem){
        let rect = elem.getBoundingClientRect();
        if(elem.id === "11"){
            setCoordinates({
                "display" : "flex",
                "x" : `${rect.x - 200}px`,
                "y" : `${rect.y + 50}px`
            })
        }
        else setCoordinates({
            "display" : "flex",
            "x" : `${rect.x + 50}px`,
            "y" : `${rect.y + 50}px`
        })
    }

    const onScroll = () => {
        let e = tableRef.current;
        const bottom = e.scrollHeight - e.scrollTop === e.clientHeight; //check if scrolled to bottom
        if(bottom) fetchMoreUsers("business")
    }


    return(
    <>
        <table className='locations-table scroll-width-thin' ref={tableRef} onScroll={() => onScroll()}>
          <thead style={{display:"block"}}>
          <tr className='header-row'>
            {businessHeaderElements.map((head,i) => {
                return(
                <th className='header-element' key={i}>
                    <p className='head'>{head.name}</p>
                    <div className={`icon-container ${sortBy === head.name && "rotate"}`}>
                            <Icon size={'70%'} icon={ic_keyboard_arrow_down} className={`arrow-down`} id={i} onClick={()=>{
                                var All_tabs = document.querySelectorAll(".location-roww");
                                All_tabs = Array.prototype.slice.call(All_tabs, 0);
                                if(sortBy === head.name) {
                                    setSortBy(""); 
                                    var parent = document.getElementById("all-stations");
                                    parent.innerHTML = "";
                                    originalOrder.map(x => parent.appendChild(x));
                                    setBusinessHasMore(true)
                                }
                                else {
                                    setOriginalOrder(All_tabs);
                                    setSortBy(head.name);
                                    setContainer(head);
                                    setShow(true);
                                    setIndex(i);
                                    let elem = document.getElementById(`${i}`);
                                    set_Popup(elem);
                                    setBusinessHasMore(false)
                                } 
                            }}/>
                    </div>
                </th>
                )
            })
            }
            </tr>
            {show ?
                        <HeaderFilterRow coordinates={coordinates} element={container} index={indexx} onClose={(bool)=>{setShow(bool)}} type={"users"}/>
            : null}
          </thead>

        <tbody className='neshtosi' id={"all-stations"} style={{display:"block"}}>
            {allUsers.map((user,i)=>{
                return ( 
                <React.Fragment key={i}>
                    {<tr className="location-roww" key={i+10}>
                            {fields.map((field,j)=>{
                                    return(
                                        <React.Fragment key={j}>
                                                    <td className={`location-data ${field === "businessGroup" && "bigger"}`}>
                                                        {
                                                            field === "active" ? user[field] === true ? "Активен" : "Неактивен" 
                                                            : field === "businessGroup" ? <div className="business-container">
                                                                    <p className='business-data'>{user[field]?.name.length > 14 ? user[field]?.name.slice(0,14) + "..."  : user[field]?.name }</p>
                                                                    <div className='redirect' onClick={()=>{
                                                                        history.push(`/group/${user[field]?._id}`)
                                                                    }}/>
                                                                </div>
                                                            : j === 4 ? 
                                                                <div className='edit-container'>
                                                                    <p className='location-data' style={{marginLeft:"2%"}}>{moment(user[field]).format("DD.MM.YYYY") }</p>
                                                                </div>
                                                            : field === "role" ? user[field] === "client" ? "Клиент" : "Aдмин" 
                                                            : user[field]?.length > 13 ? user[field].slice(0,13) +"..." : (user[field] || "---")
                                                        }
                                                    </td>
                                              
                                        </React.Fragment>
                                    )
                                })
                            }
                        </tr>}
                    </React.Fragment>
                ) 
                }
                )}
        </tbody>
        </table>
    </>
    )
    
}

export default BusinessUsersGrid;