import React, { useState } from "react"
import { Inputs } from "../.."
import { periods } from "../../../config/constants"
import "./styles.scss"

const ExportOptions = ({ close, handleExort }) => {
    const [selected, setSelected] = useState(periods[0].value)

    return <div className="popup-export-options-container">
        <div className="popup-export-options-header row">
            <h2>Изберете период</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="popup-export-options-content">
            <Inputs.RadioButton
                value={selected}
                onChange={setSelected}
                options={periods.filter(({ value }) => value !== 'all')}
                col
            />
        </div>
        <div className="popup-export-options-footer">
            <Inputs.Raised text="Експорт" onClick={() => handleExort(selected)} />
        </div>
    </div>
}

export default ExportOptions