/* eslint-disable */
import React, { useEffect, useState } from "react";
import "./styles.scss";
import "moment/locale/bg";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import moment from "moment";
import { connect, useStore } from "react-redux";
import { API } from "../../../config/settings";
import { Headers } from "../../../utilities";
import { reject, set } from "lodash";
import { toast } from "react-toastify";
import { User } from "../../../utilities/user";

const options = ["DiagnosticsStatusNotification", "FirmwareStatusNotification", "BootNotification", "Heartbeat"];

const LeftSettings = ({ sessions, chargePointID, makeDiagnostics, sendCommand, getConfiguration, configuration, station, getLogs, commandHistory, size }) => {
  const [selAction, setSelAction] = useState("DiagnosticsStatusNotification");
  const [selTab, setSelTab] = useState("history");
  const [currentSettings, setCurrentSettings] = useState([]);
  const [changedSettings, setChangedSettings] = useState([]);
  const [allowChange, setAllowChange] = useState(false);
  const [allowed, setAllowed] = useState(false);

  const statusMap = {
    support: "поддръжка",
    active: "активна",
  };

  useEffect(() => {
    if (selTab == "settings") {
      getConfiguration({
        station: chargePointID,
        onSuccess: (res) => {
          setCurrentSettings(() => {
            const filteredKeys = res?.configurationKey?.filter((key) => key.readonly == false);
            return filteredKeys?.map((key) => {
              return {
                station: chargePointID,
                key: key.key,
                value: key.value,
              };
            });
          });
        },
      });

      //   setCurrentSettings([
      //     {
      //         "key": "AbortConcurrentTx",
      //         "readonly": false,
      //         "value": "false"
      //     },
      //     {
      //         "key": "BackOffice-Path",
      //         "readonly": false,
      //         "value": "/"
      //     },
      //     {
      //         "key": "ChargepointSerialNumber",
      //         "readonly": true,
      //         "value": "ENOGEN22SB-JB-0001350"
      //     },
      //     {
      //         "key": "ConnectionTimeOut",
      //         "readonly": false,
      //         "value": "120"
      //     },
      //     {
      //         "key": "connector.driver.autotest.evccdriver.6ma.enable",
      //         "readonly": false,
      //         "value": "true"
      //     }
      // ])
    }
  }, [selTab]);

  const updateSettings = () => {
    let available = true;
    // currentSettings.map((setting) => {
    //   let isNum = /^\d+$/.test(setting.value);
    //   if (!isNum) {
    //     toast.error(`Field with name '${setting.key}' has incorrect value`, {
    //       autoClose: 3000,
    //     });
    //     available = false;
    //   }
    // });
    if (available) {
      currentSettings.push({
        station: chargePointID,
        key: "NumberOfConnectors",
        value: "2",
      });
      // const promiseArray = currentSettings.map((setting) => {
      const promiseArray = changedSettings?.map((setting) => {
        return new Promise((resolve, reject) => {
          resolve(
            window.fetch(`${API}admin/configuration`, {
              method: "POST",
              headers: Headers.get_auth(),
              body: JSON.stringify({
                station: chargePointID,
                key: setting.key,
                value: setting.value,
              }),
            })
          );
        });
      });
      Promise.all(promiseArray)
        .then((res) => {
          toast.success("Settings updated successfully", {
            autoClose: 3000,
          });
          setAllowChange(false);
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  useEffect(() => {
    const foundCommands = commandHistory.filter((cmd) => cmd?.request?.status?.includes("Uploaded"));

    if (foundCommands.length > 0 && !allowed) {
      toast.success("File uploaded! You can download the logs file.", {
        autoClose: 3000,
      });
      setAllowed(true);
    }
  }, [commandHistory]);

  return (
    <div className={`settings-cont ${size ? size : ""}`}>
      <div className="upper-settings-cont">
        <div style={{ display: "flex", marginBottom: "2%" }}>
          <h3 style={{ fontSize: "18px", width: "85%" }}>
            <b>Станция {station?.name}</b>
          </h3>
          <p>
            <b
              style={{
                color: station?.status === "active" ? "#00d688" : "#0814295b",
                fontSize: "18px",
                textTransform: "uppercase",
              }}
            >
              {statusMap[station?.status] || station?.status}
            </b>
          </p>
        </div>
        <span style={{ fontSize: "14px" }}>Изпрати команда</span>
        <div style={{ display: "flex", width: "100%", marginTop: "2%" }}>
          <Dropdown
            onChange={(e) => {
              setSelAction(e.value);
            }}
            value={options[0]}
            options={options}
            className="options-dropdown"
            placeholder="Select an option"
          />
          <div
            className="button-container"
            style={{
              margin: "0%",
              width: "30%",
              marginLeft: "2%",
              height: "40px",
              fontSize: "16px",
            }}
            onClick={() => {
              sendCommand({
                station: chargePointID,
                command: selAction,
                connector: 1,
              });
            }}
          >
            Изпрати
          </div>
        </div>
        <div
          className="button-container diagnostics"
          onClick={() => {
            const dateTo = moment().format("YYYY-MM-DD");
            const dateFrom = moment().subtract(7, "d").format("YYYY-MM-DD");
            makeDiagnostics({
              station: chargePointID,
              startDate: dateFrom,
              endDate: dateTo,
            });
          }}
        >
          Направи диагностика
        </div>

        <div
          className={`button-container diagnostics ${!allowed && "notReady"}`}
          onClick={() => {
            const foundCommands = commandHistory.filter((cmd) => cmd?.request?.status?.includes("Uploaded"));

            if (foundCommands.length > 0) {
              fetch("https://wink.fidweb.net/admin/diagnostics/download", {
                method: "POST",
                headers: {
                  Authorization: User.getToken(),
                },
              })
                .then((result) => result.blob())
                .then((blob) => {
                  const data = window.URL.createObjectURL(blob);
                  const text = document.createTextNode("Изтегли");
                  const link = document.createElement("a");
                  link.href = data;
                  link.click();
                  link.download = "fail.csv";
                })
                .catch((e) => console.error(e.message));
            } else {
              toast.error(`You need to start diagnostics and wait for the file to be uploaded!`, {
                autoClose: 3000,
              });
            }
          }}
        >
          Вземи logs
        </div>
      </div>
      <div className="lower-settings-cont" style={{ overflowY: "auto", height: selTab == "history" ? "62%" : "55%" }}>
        <div className="switch-buttons">
          <div className={`switch ${selTab == "history" && "selected"}`} onClick={() => setSelTab("history")}>
            {selTab == "history" ? <b>История на сесиите</b> : "История на сесиите"}
          </div>
          <div className={`switch ${selTab == "settings" && "selected"}`} onClick={() => setSelTab("settings")}>
            {selTab == "settings" ? <b>Настройки</b> : "Настройки"}
          </div>
        </div>
        {selTab === "history" ? (
          <div className="history-table">
            <div className="t-header">
              <p className="header bigger">Статус</p>
              <p className="header">Дата</p>
              <p className="header">Час</p>
            </div>
            <div className="t-body">
              {sessions.map((session, i) => {
                return (
                  <div className="t-row" key={i}>
                    <div className="t-data bigger">
                      <span style={{ marginLeft: "7%" }}>{session?.reason?.includes("Lost") ? "Загубена връзка" : session?.reason?.includes("restart") ? "Рестартиране на сървъра" : session?.reason == undefined && "Свързана"}</span>
                    </div>
                    <div className="t-data">
                      <span style={{ marginLeft: "7%" }}>{moment(session?.updatedAt).format("DD.MM.YYYY")}</span>
                    </div>
                    <div className="t-data" style={{ border: "none" }}>
                      <span style={{ marginLeft: "7%" }}>{moment(session?.updatedAt).format("HH:mm")}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "3%",
              }}
            >
              <h3 style={{ fontSize: "16px" }}>С опция за редакция</h3>
              {allowChange ? (
                <div
                  style={{
                    display: "flex",
                    width: "50%",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="button-container"
                    style={{
                      margin: "0%",
                      width: "50%",
                      marginRight: "2%",
                      border: "1px solid",
                      borderColor: "#F54280",
                      background: "white",
                      color: "black",
                      fontSize: "14px",
                      height: "40px",
                      marginRight: "5%",
                    }}
                    onClick={() => {
                      const filteredKeys = configuration?.filter((key) => key.readonly == false);
                      setCurrentSettings([...filteredKeys]);
                      setAllowChange(false);
                    }}
                  >
                    Откажи
                  </div>
                  <div
                    className="button-container"
                    style={{
                      margin: "0%",
                      width: "45%",
                      marginRight: "2%",
                      fontSize: "14px",
                      height: "40px",
                    }}
                    onClick={() => {
                      updateSettings();
                    }}
                  >
                    Запази
                  </div>
                </div>
              ) : (
                <div
                  className="edit-logo"
                  style={{
                    width: "25px",
                    marginTop: "0%",
                    height: "unset",
                    marginRight: "3%",
                  }}
                  onClick={() => setAllowChange(true)}
                />
              )}
            </div>
            <div className="settings-fields">
              {currentSettings?.map((setting, i) => {
                if (setting.value == "true" || setting.value == "false") {
                  return (
                    <div className="setting" key={i}>
                      <p className="setting-title">{setting.key}</p>
                      <div className="cheboxes-line">
                        <div
                          className={`checkbox ${setting.value == "true" && "selected"}`}
                          onClick={() => {
                            const tempSettings = [...currentSettings];
                            tempSettings[i].value = "true";
                            setCurrentSettings(tempSettings);

                            let newChangedSettings = [...changedSettings];
                            let settingsKeys = newChangedSettings.map((el) => el?.key);
                            if (newChangedSettings?.length && settingsKeys?.includes(tempSettings[i]?.key)) {
                              let indexToRemove = newChangedSettings.findIndex((el) => el?.key === tempSettings[i]?.key);
                              newChangedSettings.splice(indexToRemove, 1);
                            }
                            newChangedSettings.push(tempSettings[i]);
                            setChangedSettings(newChangedSettings);
                          }}
                        >
                          True
                        </div>
                        <div
                          className={`checkbox ${setting.value == "false" && "selected"}`}
                          onClick={() => {
                            const tempSettings = [...currentSettings];
                            tempSettings[i].value = "false";
                            setCurrentSettings(tempSettings);

                            let newChangedSettings = [...changedSettings];
                            let settingsKeys = newChangedSettings.map((el) => el?.key);
                            if (newChangedSettings?.length && settingsKeys?.includes(tempSettings[i]?.key)) {
                              let indexToRemove = newChangedSettings.findIndex((el) => el?.key === tempSettings[i]?.key);
                              newChangedSettings.splice(indexToRemove, 1);
                            }
                            newChangedSettings.push(tempSettings[i]);
                            setChangedSettings(newChangedSettings);
                          }}
                        >
                          False
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="setting" key={i}>
                      <p className="setting-title">{setting.key}</p>
                      <input
                        required
                        disabled={!allowChange && true}
                        className={`station-name ${!allowChange && "opacity"} ${!allowChange && "notRead"}`}
                        type="text"
                        style={{
                          marginTop: "0%",
                          height: "30px",
                          marginBottom: "0%",
                          width: "95%",
                          fontSize: "13px",
                        }}
                        id="name"
                        value={setting.value}
                        onChange={({ target: { value } }) => {
                          const tempSettings = [...currentSettings];
                          tempSettings[i].value = value;
                          setCurrentSettings(tempSettings);

                          let newChangedSettings = [...changedSettings];
                          let settingsKeys = newChangedSettings.map((el) => el?.key);
                          if (newChangedSettings?.length && settingsKeys?.includes(tempSettings[i]?.key)) {
                            let indexToRemove = newChangedSettings.findIndex((el) => el?.key === tempSettings[i]?.key);
                            newChangedSettings.splice(indexToRemove, 1);
                          }
                          newChangedSettings.push(tempSettings[i]);
                          setChangedSettings(newChangedSettings);
                        }}
                      />
                    </div>
                  );
                }
              })}
            </div>
            <h3 style={{ fontSize: "16px" }}>Без опция за редакция</h3>
            <div className="settings-fields notRead">
              {configuration?.map((setting, i) => {
                if (setting.readonly == true) {
                  if (setting.value == "true" || setting.value == "false") {
                    return (
                      <div className="setting" key={i}>
                        <p className="setting-title">{setting.key}</p>
                        <div className="cheboxes-line">
                          <div className={`checkbox notRead ${setting.value == "true" && "selected"}`}>True</div>
                          <div className={`checkbox notRead ${setting.value == "false" && "selected"}`}>False</div>
                        </div>
                      </div>
                    );
                  } else
                    return (
                      <div className="setting" key={i}>
                        <p className="setting-title">{setting.key}</p>
                        <input
                          required
                          disabled
                          className={`station-name opacity notRead`}
                          type="text"
                          style={{
                            marginTop: "0%",
                            height: "30px",
                            marginBottom: "0%",
                            width: "95%",
                            fontSize: "13px",
                          }}
                          id="name"
                          value={setting.value}
                        />
                      </div>
                    );
                }
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  configuration: state.adminSettings.configuration,
  commandHistory: state.adminSettings.commandHistory,
});

export default connect(mapStateToProps)(LeftSettings);
