export const periods = [
    { label: 'Последните 24 часа', value: 'day' },
    { label: 'Последните 7 дни', value: 'week' },
    { label: 'Последните 31 дни', value: 'month' },
    { label: 'Всички', value: 'all' },
]


export const editorConfig = {
    toolbar: [
        { name: 'corrections', items: ['Undo', 'Redo'] },
        { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike'] },
        { name: 'styles', items: ['Format'] },
        { name: 'paragraph', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'NumberedList', 'BulletedList'] },
        { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText'] },
        { name: 'tools', items: ['Maximize'] },
    ],
    enterMode: 'ENTER_BR',
    plugins: [
        'basicstyles',
        'clipboard',
        'emoji',
        'enterkey',
        'entities',
        'floatingspace',
        'indentlist',
        'justify',
        'link',
        'list',
        'toolbar',
        'undo',
        'wysiwygarea',
        "maximize",
        'format',
        'resize',
        "autogrow"
    ],
}

export const editorNotificationsConfig = {
    enterMode: 'ENTER_BR',
    toolbar: [{ name: 'links', items: ['Link', 'Unlink'] },],
    plugins: ['link', 'toolbar', 'undo', 'wysiwygarea',]
}