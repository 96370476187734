/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { history } from "../../config/stores";
import { connect } from "react-redux";
import { loginRequestCode, loginCodeUse } from "../../actions";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./styles.scss";
import Swal from "sweetalert2";

const Home = ({ loginRequestCode, loginCodeUse }) => {
  const [showPhoneCode, setShowPhoneCode] = useState(false);
  const [phone, setPhone] = useState("");
  const [inputCode, setinputCode] = useState(["", "", "", ""]);
  const phoneNumber = useRef();

  useEffect(() => {}, [phone, inputCode]);

  return (
    <div className="full-container">
      <div className="login-container">
        <div className="login-inner-container">
          <div className="logo" />
          <p className="header">
            {showPhoneCode ? "Верификация на телефона" : "Вход в системата"}
          </p>
          <p className="inner-text">
            {showPhoneCode
              ? "Моля въведете четирицифрения код, който Ви изпратихме на телефон:"
              : "Моля въведете телефонния номер, с който сте регистрирани"}
          </p>
          <p className="number"> {showPhoneCode ? phoneNumber.current : ""} </p>

          {!showPhoneCode ? (
            <div className="text-and-input">
              <div className="inner-text-and-input">
                <span className="phone">Телефон</span>
                <div className="field-container">
                  <PhoneInput
                    value={phone}
                    containerStyle={{ width: "100%" }}
                    inputStyle={{ width: "100%" }}
                    onChange={(phone) => {
                      setPhone(phone);
                    }}
                  />
                </div>
              </div>
              <div
                className={`button-container ${
                  phone.length < 4 && "inactive"
                } `}
                onClick={() => {
                  if (phone.length < 4) return;
                  var prefix = "+";

                  if (phone[0] === "0") {
                    prefix += phone.substring(1);
                    phoneNumber.current = prefix;
                    if (
                      phoneNumber.current.length > 4 &&
                      phoneNumber.current.length <= 13
                    ) {
                      loginRequestCode({
                        phoneNumber: phoneNumber.current,
                        onSuccess: () => setShowPhoneCode(1),
                        onError: () => {},
                      });
                    }
                  } else {
                    phoneNumber.current = prefix + phone;
                    if (
                      phoneNumber.current.length > 4 &&
                      phoneNumber.current.length <= 13
                    ) {
                      loginRequestCode({
                        phoneNumber: phoneNumber.current,
                        onSuccess: () => setShowPhoneCode(1),
                        onError: () => {},
                      });
                    }
                  }
                }}
              >
                Продължи
              </div>
            </div>
          ) : (
            <>
              <div className="code-container">
                <div className="code-container-inner">
                  {new Array(4).fill("").map((v, i) => (
                    <input
                      className={`digit-container`}
                      type="text"
                      maxLength="1"
                      size={1}
                      key={`id${i}`}
                      min={0}
                      max={1}
                      pattern={"[0-9]{1}"}
                      id={`${i}`}
                      onFocus={() => {
                        document.getElementById(`${i}`).select();
                      }}
                      onChange={(event) => {
                        var newCode = inputCode;
                        newCode[i] = event.target.value;
                        setinputCode(newCode);
                        if (i + 1 < 4) {
                          document.getElementById(`${i + 1}`).focus();
                        }
                        if (i === 3) {
                          document.getElementById(`${i}`).blur();
                          document.getElementById("log-in").className =
                            "button-container bigger";
                        }
                      }}
                    />
                  ))}
                </div>
              </div>
              <div
                className={`button-container bigger ${
                  inputCode[3] === "" ? "innactive" : ""
                } `}
                id={"log-in"}
                onClick={() => {
                  loginCodeUse({
                    phoneNumber: phoneNumber.current,
                    code: inputCode.join(""),
                    onSuccess: () => {
                      history.push("/");
                    },
                    onError: () => {
                      Swal.fire({
                        icon: "error",
                        title: "Упс...",
                        text: "Грешен код. Моля опитайте отново.",
                      });
                      inputCode.map((digit, i) => {
                        document.getElementById(`${i}`).style.color = "red";
                        document.getElementById(`${i}`).style.borderColor =
                          "red";
                        return 1;
                      });
                    },
                  });
                }}
              >
                Продължи
              </div>
              <div className="move-back">
                <p>
                  Посоченият номер е грешен?{" "}
                  <u
                    className="move-back-button"
                    onClick={() => {
                      setShowPhoneCode(false);
                      phoneNumber.current = "";
                    }}
                  >
                    Въведете нов телефонен номер
                  </u>
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loginRequestCode: (payload) => dispatch(loginRequestCode(payload)),
  loginCodeUse: (payload) => dispatch(loginCodeUse(payload)),
});

export default connect(null, mapDispatchToProps)(Home);
