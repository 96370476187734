/* eslint-disable */
import React, { useState, useEffect } from "react";
import { history } from "../../../config/stores";
import { connect } from "react-redux";
import {
  createGroup,
  getLocations,
  getUsers,
  getGroupByID,
  editGroupLocations,
  editGroupUsers,
  editGroupMainInfo,
  deleteGroup,
} from "../../../actions";
import EditGroupInfo from "./EditGroupInfo";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import "../styles.scss";
import EditBusinessUsers from "./EditBusinessUsers";
import EditLocationToGroup from "./EditLocationToGroup";
import { User } from "../../../utilities";

const EditGroup = ({
  getLocations,
  locations,
  allUsers,
  getUsers,
  editGroupLocations,
  editGroupUsers,
  editGroupMainInfo,
  deleteGroup,
}) => {
  const { state } = useLocation()
  const currentGroup = useLocation().state;
  const locationsData = state?.data || []
  const statePayloadData = state?.payload || {}
  const locationsTab = state?.tab || ''

  const [selectedTab, setSelectedTab] = useState(
    User.isAdmin ? "info" : "users"
  );
  const [selectedLocation, setSelectedLocation] = useState(0);
  const [inputUsers, setInputUsers] = useState(
    currentGroup?._users.map((user) => {
      return {
        name: user.name,
        phoneNumber: user.phoneNumber.replace("+359", ""),
      };
    }) || []);
  const [visibleForUsers, setVisibleForUsers] = useState([]);
  const [payload, setPayload] = useState(currentGroup);
  const [multipleLocations, setMultipleLocations] = useState(true);
  const [locationsToGroup, setLocationsToGroup] = useState([]);

  const checkInfoOnLocations = () => {
    return locationsToGroup
      .map((location) => {
        if (location.locationId === "") {
          Swal.fire("Няма избрана локация от списъка.", "", "error");
          return 0;
        } else if (location.partOfProfit > 100) {
          Swal.fire(
            "Невъзмножно е дяла от печалбата да е повече от 100%.",
            "",
            "error"
          );
          return 0;
        } else if (location.paymentDate > 31) {
          Swal.fire("Не съществува такава дата.", "", "error");
          return 0;
        } 
        // else if (hasDupticateLocations(locationsToGroup)) {
        //   Swal.fire("Има повтарящи се локации.", "", "error");
        //   return 0;
        // } 
        else return 1;
      })
      .toString();
  };

  const hasDupticateLocations = (array) => {
    var values = Object.create(null);
    for (var i = 0; i < array.length; ++i) {
      var value = array[i].id;
      if (value in values) {
        return true;
      }
      values[value] = true;
    }
    return false;
  };

  const findVisibleForUsers = () => {
    let FoundUsersID = Array();
    let notFoundUsers = Array();

    inputUsers.map((inputUser) => {
      let found = false;

      if (inputUser.phoneNumber.includes("+359")) {
        FoundUsersID.push(inputUser._id);
        found = true;
      } else {
        allUsers.map((user) => {
          if (user.phoneNumber === "+359".concat(inputUser.phoneNumber)) {
            found = true;
            FoundUsersID.push(user._id);
          }
        });
      }

      if (!found) notFoundUsers.push(inputUser.phoneNumber);
    });

      setPayload({
        ...payload,
        ["users"]:FoundUsersID
      })
    return 1;
    
  };



  useEffect(() => {
   if (Object.values(currentGroup)?.length) {
    setMultipleLocations(currentGroup.forAllLocations)
    setLocationsToGroup(currentGroup.locations)
    // setPayload(currentGroup)
   }
   if (currentGroup?.forAllLocations) {
    let newPayload = {...payload}
    newPayload.locations = locationsData.map(el => {
      return {
              location: { name: location?.name, _id: location?._id },
              // location: el._id,
              // name: el?.name,
              specialPrice: el?.specialPrice || null,
              initialFee: el?.initialFee || null,
              parkingFee: el?.parkingFee || null
            }
    })

    setPayload(newPayload)
    setLocationsToGroup([...newPayload.locations])
    // setSelectedTab("locations")
   }

   if (locationsTab === "locations") setSelectedTab("locations")
  }, []);

  useEffect(() => {
    if (Object.values(statePayloadData)?.length) {
      setPayload({...statePayloadData})

      if (statePayloadData?.users?.length && allUsers?.length) {
        const newUsers = statePayloadData.users.map(el => {
          let user = allUsers.find(user => user._id === el)
          return { name: user.name, phoneNumber: user.phoneNumber.slice(4) }
         })
         setInputUsers(newUsers)
      }

      setMultipleLocations(payload.forAllLocations)
    }
    
    if (locationsData?.length) {
      let newPayload = Object.values(statePayloadData)?.length ? {...statePayloadData} : {...payload}
      newPayload.locations = locationsData.map(el => {
        return {
                location: el?._id || el?.location?._id,
                name: el?.name || el?.location?.name,
                specialPrice: el?.specialPrice || null,
                initialFee: el?.initialFee || null,
                parkingFee: el?.parkingFee || null
              }
      })
      newPayload.forAllLocations = false
      setPayload(newPayload)
      setLocationsToGroup([...newPayload.locations])
      setSelectedTab("locations")
      if (multipleLocations) setMultipleLocations(false)
    }

  }, [locationsData, statePayloadData])

  return (
    <>
      <div className="groups-add-container">
        <div className="full-upper-container" id="full-upper">
          <div
            className="left-inner-header"
            style={{ justifyContent: "flex-start" }}
          >
            <div
              className="exit"
              id={"exit"}
              onClick={() => {
                history.push(`/group/${payload?._id}`);
              }}
            />
            <p
              style={{
                fontSize: "20px",
                marginLeft: "20px",
                marginTop: "0",
              }}
            >
              <b style={{ whiteSpace: "nowrap" }}>
                РЕДАКТИРАНЕ НА БИЗНЕС ГРУПА
              </b>
            </p>
          </div>
          <div className="right-inner-header">
            {selectedTab === "locations" && (
              <>
                {User.isAdmin === true && (
                  <div
                    className="button-container deleteStation"
                    style={{
                      marginTop: "1%",
                      whiteSpace: "nowrap",
                      width: "120px",
                      padding: "7px 15px",
                      marginRight: "-12%",
                      height: "70%",
                    }}
                    onClick={() => {
                      Swal.fire({
                        title: "Изтриване на бизнес група",
                        text: "Сигурни ли сте, че искате да премахнете тази бизнес група?",
                        showDenyButton: true,
                        confirmButtonText: "Изтрий бизнес група",
                        confirmButtonColor: "#F54280",
                        denyButtonColor: "#FFFFFF",
                        denyButtonText: `Назад`,
                        denyButtonTextColor: "black",
                        reverseButtons: true,
                        customClass: "mine",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          deleteGroup(currentGroup._id);
                          Swal.fire("Успешно изтриване.", "", "success");
                          history.push("/groups");
                        }
                      });
                    }}
                  >
                    Изтрий
                  </div>
                )}

                <div
                  className="button-container addStation"
                  style={{ marginRight: "2%", width: "120px" }}
                  onClick={() => {
                    if ( checkInfoOnLocations().indexOf("0") === -1 ) {
                      let finalPayload = {...payload, locations: locationsToGroup}

                      if (locationsToGroup?.length && !finalPayload.forAllLocations) {
                        finalPayload.specialPrice = null
                        finalPayload.initialFee = null
                        finalPayload.parkingFee = null
                      }

                      let foundUsers = Array();
                      let notFoundUsers = Array();
                      inputUsers.forEach((inputUser,j) => {
                        let valid = false;
                        allUsers.forEach((user) => {
                          if (user.phoneNumber === "+359".concat(inputUser.phoneNumber)) {
                            valid = true;
                            foundUsers.push(user._id);
                          }
                        });
                        if (!valid)
                          notFoundUsers.push(j + 1);
                      });
                      setPayload({
                        ...payload,
                        ["users"]: foundUsers,
                      });
                     
                        if (!findVisibleForUsers()) return

                      if(!finalPayload?.eik) delete finalPayload.eik
                      if(!finalPayload?.bulstat) delete finalPayload.bulstat

                      editGroupMainInfo({
                        payload: finalPayload,
                        id: currentGroup._id,
                        onSuccess: () => {
                          editGroupUsers({
                            payload: foundUsers,
                            id: payload._id,
                            onSuccess: () => {
                              Swal.fire("Успешно редактиране.", "", "success");
                              history.push("/groups");
                            },
                            onError: () => {
                              Swal.fire(
                                "Някое от изискванията не е изпълнено.",
                                "",
                                "error"
                              );
                            },
                          });
                        },
                        onError: () => {
                          Swal.fire(
                            "Имаше грешка при редактирането на бизнес групата.Моля проверете дали информацията е правилна.",
                            "",
                            "error"
                          );
                        },
                      });
                    }
                  }}
                >
                  Запази
                </div>
              </>
            )}
          </div>
        </div>

        <div className="lower-container">
          <div className="left-container wide">
            <div
              className={`first-left-container wide ${
                selectedTab === "info" && "selected"
              } ${selectedTab === "users" && "disabled"}`}
              style={{ pointerEvents: !User.isAdmin && "none" }}
              onClick={() => {
                setSelectedTab("info");
              }}
            >
              <h3
                className={`add-station  ${
                  selectedTab === "info" && "selected"
                }`}
              >
                Основна информация
              </h3>
              <div className="arrow-container">
                <div
                  className={`arrow-right  ${
                    selectedTab === "info" && "selected"
                  } ${selectedTab === "users" && "disabled"}`}
                />
              </div>
            </div>
            <div
              className={`firstt-left-container wide ${
                selectedTab === "users" && "selected"
              } `}
              onClick={() => {
                setSelectedTab("users");
              }}
            >
              <h3
                className={`add-station  ${
                  selectedTab === "users" && "selected"
                }`}
              >
                Потребители
              </h3>
              <div className="arrow-container" style={{ marginLeft: "15%" }}>
                <div
                  className={`arrow-right  ${
                    selectedTab === "users" && "selected"
                  }`}
                />
              </div>
            </div>
            <div
              className={`location-to-group wide ${selectedTab === "locations" && "selected"} ${selectedTab === "users" && "disabled"}`}
            >
              <div
                className={`firstt-left-container wide no-margin ${selectedTab === "locations" && "selected"} ${selectedTab === "users" && "disabled"}`}
                disabled={selectedTab === "users"}
                onClick={() => {
                  setSelectedTab("locations");
                }}
              >
                <h3
                  className={`add-station  ${
                    selectedTab === "locations" && "selected"
                  }`}
                >
                  Локации към групата
                </h3>
                <div className="arrow-container" style={{ marginLeft: "15%" }}>
                  <div
                    className={`arrow-right margin ${
                      selectedTab === "locations" && "selected"
                    }`}
                  />
                </div>
              </div>

              {selectedTab === "locations" && 
              <div className={`flex-wrapper col ${multipleLocations ? 'green' : ''}`}>
                <div className="section flex-wrapper top">
                  <h3>За всички локации</h3>
                  <div className="checkboxes-and-radios">
                        <label
                          className="switch"
                          onClick={() => {
                            if (document.getElementById("checkbox").checked === true) {
                              const newPayload = {...payload};
                              newPayload.forAllLocations = false;
                              newPayload.specialPrice = null;
                              newPayload.initialFee = null;
                              newPayload.parkingFee = null;
                              setPayload({ ...newPayload })
                              setMultipleLocations(false);

                              history.push('/locations/select', {...state, groupId: currentGroup._id, payload: payload })
                            } else {
                              const newPayload = {...payload};
                              newPayload.forAllLocations = true;
                              newPayload.locations = []
                              newPayload.specialPrice = null;
                              newPayload.initialFee = null;
                              newPayload.parkingFee = null;
                              setPayload({ ...newPayload })
                              setLocationsToGroup([])
                              setMultipleLocations(true)
                            }
                          }}
                        >
                          <input
                            type="checkbox"
                            id="checkbox"
                            checked={multipleLocations}
                            onClick={() => {
                              if (document.getElementById("checkbox").checked === true) {
                                setMultipleLocations(false);
                                history.push('/locations/select', {...state, payload: payload, groupId: currentGroup._id })
                              } else {
                                // const newPayload = {...payload};
                                // newPayload.forAllLocations = true;
                                // newPayload.locations = []
                                // setPayload({ ...newPayload })
                                // setLocationsToGroup([])
                                setMultipleLocations(true)
                              }
                            }}
                          />
                          <div className="slider round">
                            <span className="on" id="on">
                              <b>Да</b>
                            </span>
                            <span className="off" id="off">
                              <b>Не</b>
                            </span>
                          </div>
                        </label>
                  </div>
                </div>

                {!multipleLocations ?
                <div className="section flex-wrapper border margin-top">
                  <h5 className="text light">Избрани:</h5>

                  <div className="flex-wrapper">
                    <p className="text bold">{`${locationsToGroup?.length} ${locationsToGroup?.length === 1 ? 'локация' : 'локации'}`}</p>
                    <img 
                    className="settings-icon" 
                    src={require(("../../../assets/icons/settings.svg"))}
                    onClick={() => history.push('/locations/select', {...state, data: locationsToGroup, groupId: currentGroup._id, payload: payload })}
                    />
                  </div>
                </div> : <></>}
              </div>
            }
            </div>
          </div>
          {selectedTab === "info" && User.isAdmin === true ? (
            <EditGroupInfo
              payload={payload}
              setPayload={setPayload}
              setSelectedTab={setSelectedTab}
            />
          ) : selectedTab === "users" ? (
            <EditBusinessUsers
              payload={payload}
              setPayload={(v) => setPayload(v)}
              setSelectedTab={setSelectedTab}
              inputUsers={inputUsers}
              setInputUsers={setInputUsers}
              findVisibleForUsers={() => findVisibleForUsers()}
            />
          ) : selectedTab === "locations" ? (
            <EditLocationToGroup
              locationsToGroup={locationsToGroup}
              allUsers={allUsers}
              setLocationsToGroup={setLocationsToGroup}
              locations={locations}
              selectedLocation={selectedLocation}
              visibleForUsers={visibleForUsers}
              setVisibleForUsers={setVisibleForUsers}
              currentGroupID={currentGroup._id}
              payload={payload}
              setPayload={(v) => setPayload(v)}
              multipleLocations={multipleLocations}
              setMultipleLocations={(v) => setMultipleLocations(v)}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  locations: state.locations.locations,
  allUsers: state.login.allUsers,
  group: state.groups.group,
});

const mapDispatchToProps = (dispatch) => ({
  createGroup: (payload) => dispatch(createGroup(payload)),
  getLocations: (payload) => dispatch(getLocations(payload)),
  getUsers: (payload) => dispatch(getUsers(payload)),
  getGroupByID: (payload) => dispatch(getGroupByID(payload)),
  editGroupLocations: (payload) => dispatch(editGroupLocations(payload)),
  editGroupUsers: (payload) => dispatch(editGroupUsers(payload)),
  editGroupMainInfo: (payload) => dispatch(editGroupMainInfo(payload)),
  deleteGroup: (payload) => dispatch(deleteGroup(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditGroup);
