import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap } from 'rxjs/operators'
import {markTicketAsUnread, setLoadingOff, setOpenedTicket, supportSocketEmit, supportTypes,setUserSupportId } from '../actions'


export const supportInitEpic = (action$, state$) =>
  action$.pipe(
    ofType(supportTypes.SUPPORT_INIT),
    switchMap(({ payload }) =>
      ActionsObservable
        .create(obs => {
          if (state$.value.support.openedTicket) {
            obs.next(supportSocketEmit({
              action: 'nextMessages',
              payload: {
                pageNumber: 1,
                pageSize: 20,
                ticket: state$.value.support.openedTicket
              }
            }))
          }
          obs.complete()
        })
    )
  )

export const supportNewCustomerEpic = (action$, state$) =>
  action$.pipe(
    ofType(supportTypes.SUPPORT_NEW_CUSTOMER),
    switchMap(({ payload }) =>
      ActionsObservable
        .create(obs => {
          //localStorage.setItem("customerSupportID",payload.customer_id)
          obs.next(setUserSupportId({"customerSupportId": payload.customer_id}));
          obs.complete()
        })
    )
  )

export const supportNewTicketEpic = (action$, state$) =>
  action$.pipe(
    ofType(supportTypes.SUPPORT_CREATE_TICKET),
    switchMap(({ payload }) =>
      ActionsObservable
        .create(obs => {
          obs.next(setOpenedTicket(payload._id))
          obs.next(supportSocketEmit({
            action: 'nextMessages',
            payload: {
              ticket: payload._id,
              pageNumber: 1,
              pageSize: 20,
              sortBy: '',
              noPagination: false,
            }
          }))
          obs.next(setLoadingOff())
          obs.complete()
        })
    )
  )

export const supportNewMessageEpic = (action$, state$) =>
  action$.pipe(
    ofType(supportTypes.SUPPORT_NEW_MESSAGE),
    switchMap(({ payload }) =>
      ActionsObservable
        .create(obs => {
          if (state$.value.support.openedTicket === payload.ticket?._id) {
            obs.next(supportSocketEmit({
              action: 'markAllRead',
              payload: {
                ticket: payload.ticket._id
              }
            }))
          } else {
            obs.next(markTicketAsUnread(payload.ticket?._id))
          }
          obs.complete()
        })
    )
  )
