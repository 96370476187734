/* eslint-disable */
import React, { useEffect, useState } from "react";
import "./styles.scss";
import "moment/locale/bg";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import moment from "moment";
import { toast } from "react-toastify";
import { history } from "../../../config/stores";

const MiddleSettings = ({ station, lastCharge, unlockConnector, chargePointID, sendCommand, stopCharging }) => {
  const [lastChargeInfo, setLastChargeInfo] = useState();

  return (
    <>
      <div className="full-to-group" id="blurred">
        <div className="centerModal" style={{ height: window.innerWidth < 1600 && "22%" }}>
          <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
            <h3 style={{ fontSize: "16px", width: "95%", textAlign: "center" }}>
              <b>{lastChargeInfo?.status == "completed" ? "Информация за последен потребител" : "Информация за потребител"}</b>
            </h3>
            <div
              className="close"
              onClick={() => {
                document.getElementById("blurred").style.display = "none";
              }}
            />
          </div>
          <div style={{ width: "100%", display: "flex" }}>
            <p
              style={{
                fontSize: "15px",
                fontWeight: "lighter",
                marginLeft: "6%",
              }}
            >
              Потребител: <b>{lastChargeInfo?.user?.phoneNumber}</b>
            </p>
            <p
              style={{
                fontSize: "15px",
                fontWeight: "lighter",
                marginLeft: "6%",
              }}
            >
              Aвтомобил: <b>{lastChargeInfo?.car?.fullName}</b>
            </p>
          </div>
          <div style={{ width: "100%", display: "flex", marginTop: "3%" }}>
            <p
              style={{
                fontSize: "15px",
                fontWeight: "lighter",
                marginLeft: "6%",
              }}
            >
              Начало: <b>{moment(lastChargeInfo?.startAt).format("HH:mm")}</b>
            </p>
            <p
              style={{
                fontSize: "15px",
                fontWeight: "lighter",
                marginLeft: "6%",
              }}
            >
              Време: <b>{lastChargeInfo?.duration == undefined ? "---" : `${Number(lastChargeInfo?.duration.split(":")[0])}ч. | ${Number(lastChargeInfo?.duration.split(":")[1])}м. | ${Number(lastChargeInfo?.duration.split(":")[2])}с.`}</b>
            </p>
          </div>
        </div>
      </div>
      <div className="settings-cont" style={{ width: "100%" }}>
        <h3 style={{ fontSize: "18px", width: "85%" }}>
          <b>Изходи</b>
        </h3>
        <div className="station-cards-cont">
          {station?._outlets?.map((outlet, i) => {
            return (
              <div className="station-card" key={i}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h3 style={{ fontSize: "16px", fontWeight: "lighter" }}>
                    <b>{outlet?.name}</b> {`(${outlet?.type?.map((type) => type + " ")})`}
                  </h3>
                  <h3
                    style={{
                      fontSize: "16px",
                      color: outlet?.status === "Charging" ? "#F54280" : "#00d688",
                      textTransform: "uppercase",
                    }}
                  >
                    {outlet?.status}{" "}
                    <b
                      style={{
                        color: "black",
                        cursor: "pointer",
                        textTransform: "lowercase",
                      }}
                      onClick={() => {
                        lastCharge({
                          station: station?._id,
                          onSuccess: (res) => {
                            setLastChargeInfo(res.payload);
                          },
                        });
                        document.getElementById("blurred").style.display = "block";
                      }}
                    >
                      i
                    </b>
                  </h3>
                </div>
                {outlet.status.includes("Charging") ? (
                  <div
                    className={`button-container diagnostics charging`}
                    onClick={() => {
                      stopCharging({
                        outlet: outlet._id,
                      });
                    }}
                  >
                    Спри зареждане
                  </div>
                ) : (
                  <div
                    className={`button-container diagnostics ${!outlet.status.includes("Preparing") && "notReady"}`}
                    onClick={() => {
                      if (!outlet.status.includes("Preparing")) {
                        toast.error("Outlet must be in Preparing mode", {
                          autoClose: 3000,
                        });
                      } else {
                        history.push(`/locations/charge/start/${station._id}/${outlet._id}`);
                      }
                    }}
                  >
                    Стартирай зареждане
                  </div>
                )}

                <div
                  className="button-container diagnostics"
                  onClick={() => {
                    unlockConnector({
                      station: chargePointID,
                      connector: outlet.connectorId,
                      onSuccess: () => {
                        toast.success(`Outlet '${outlet.name}' has been unlocked successfully.`, { autoClose: 3000 });
                      },
                    });
                  }}
                >
                  Отключи изход
                </div>
                <div
                  className="button-container diagnostics"
                  onClick={() => {
                    sendCommand({
                      station: chargePointID,
                      command: "StatusNotification",
                      connector: outlet.connectorId,
                    });
                  }}
                >
                  Актуализирай статус
                </div>
                <div
                  className="button-container diagnostics"
                  onClick={() => {
                    sendCommand({
                      station: chargePointID,
                      command: "MeterValues",
                      connector: outlet.connectorId,
                    });
                  }}
                >
                  Изпрати MeterValues
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default MiddleSettings;
