import React from "react";
import "./styles.scss";

const LoaderInline = ({styles}) => {
  return (
    <div className="statistics-loader-inline-container row" style={styles}>
      <div className="lds-facebook">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default LoaderInline;
