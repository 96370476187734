/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import { InnerHeader } from "../../components";
import { Icon } from "react-icons-kit";
import { ic_keyboard_arrow_down } from "react-icons-kit/md/ic_keyboard_arrow_down";
import {
  getGroupByID,
  getStationByID,
  getUsers,
  getLocations,
  getGroupActivity,
  getGroupStatistics,
} from "../../actions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import Slider from "rc-slider";
import moment from "moment";
import "moment/locale/bg";
import "rc-slider/assets/index.css";

const orderBy = ["Най-нови", "Най-стари", "Най-висока сума", "Най-ниска сума"];
const lookTime = ["От началото", "Дата/период"];

const Group = ({
  group,
  getGroupByID,
  getUsers,
  getLocations,
  locations,
  allUsers,
  getGroupActivity,
  activities,
  getGroupStatistics,
  statistics,
}) => {
  moment.locale("bg");
  const [selectedTab, setSelectedTab] = useState("");
  const [selectedLocation, setSelectedLocation] = useState();
  const [visibleForUsers, setVisibleForUsers] = useState([]);
  const group_id = window.location.href.split("/")[4];
  const [filterOptions, setFilterOptions] = useState({
    order: "",
    look: "",
    sum: [0, 100],
    checkedLocation: [],
    selectedType: [],
    selectedCar: [],
  });
  const [openCalendar, setOpenCalendar] = useState(false);
  const [addedUsers,setAddedUsers] = useState([]);
  const [filter,setFilter] = useState("name");
  const [dates, setDates] = useState([null, null]);

  useEffect(() => {
    getGroupByID({
      id: group_id,
      onSuccess: (response) => {
        setAddedUsers(response?.payload?._users);
        getGroupActivity({
          businessGroup: group_id,
          noPagination: true,
          onSuccess: () => {
            getGroupStatistics({
              id: group_id,
              onSuccess: () => {},
            });
          },
        });
      },
    });
    getUsers({
      pageSize: "500",
      sortBy: "",
      noPagination: true,
      onSuccess: () => {},
    });
    getLocations({
      pageNumber: "1",
      pageSize: "60",
      sortBy: "",
      noPagination: false,
      search: "",
      onSuccess: () => {},
      onError: () => alert("Имаше проблем със зареждането на локации,опитайте отново!")
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const outletType = [
    ...new Set(activities.map((x) => x?.outlet?.type[0] && x?.outlet?.type[0])),
  ];
  const carsType = [
    ...new Set(activities.map((x) => x?.car?.fullName && x?.car?.fullName)),
  ].filter((car) => car !== undefined && car);

  const Items = {
    "Име на фирма:": group?.name,
    "Описание:": group?.description || "Няма",
    "Булстат:": group?.bulstat,
    "EИК:": group?.eik,
    "МОЛ:": group?.mol,
    "IBAN:": group?.iban,
    "Лице за контакт:": group?.contactPerson,
    "Имейл:": group?.email,
    "Адрес:": group?.address,
  };

  const handleDuration = (time) => {
    if (!time) return
    const splitted = time.split(":");
    return `${Number(splitted[0])}ч | ${Number(splitted[1])}м`;
  };

  const handlePayload = (type) => {
    switch (type) {
      case "order":
        return filterOptions.order.includes("Най-нови") ? (
          "dateNew"
        ) : filterOptions.order.includes("Най-стари") ? (
          "dateOld"
        ) : filterOptions.order.includes("Най-висока сума") ? (
          "amountHigh"
        ) : filterOptions.order.includes("Най-ниска сума") ? (
          "amountLow"
        ) : (
          <></>
        );
      default:
        return;
    }
  };

  const handleDateChange = (date) => {
    if (!dates[0] && !dates[1]) {
      const newDates = dates.slice(0);
      newDates[0] = date;
      setDates(newDates);
    } else if (dates[0] && !dates[1]) {
      const newDates = dates.slice(0);
      newDates[1] = date;
      setDates(newDates);
    }
    if (dates[0] && dates[1]) {
      const newDates = dates.slice(0);
      newDates[0] = date;
      newDates[1] = null;
      setDates(newDates);
    }
  };

  const usersFilter = (currentFilter) => {
    switch(currentFilter){
      case "name": addedUsers.sort((a,b) => {
        if(!a["name"] && !b["name"]) {
          return 0;
        }
        else if(a["name"] && !b["name"]) {
          return -1;
        }
        else if(!a["name"] && b["name"]) {
          return 1;
        } 
        else {
          const value1 = a["name"].toString().toUpperCase(); // ignore upper and lowercase
          const value2 = b["name"].toString().toUpperCase(); // ignore upper and lowercase
          if (value1 < value2) {
            return -1;
          } else if (value1 > value2) {
            return 1;
          } else {
            return 0;
          }
        }
      })
      break;
      case "reverseName": addedUsers.sort((a,b) => {
        if(!a["name"] && !b["name"]) {
          return 0;
        }
        else if(a["name"] && !b["name"]) {
          return 1;
        }
        else if(!a["name"] && b["name"]) {
          return -1;
        } 
        else {
          const value1 = a["name"].toString().toUpperCase(); // ignore upper and lowercase
          const value2 = b["name"].toString().toUpperCase(); // ignore upper and lowercase
          if (value1 < value2) {
            return 1;
          } else if (value1 > value2) {
            return -1;
          } else {
            return 0;
          }
        }
      })
      break;
      case "phoneNumber": addedUsers.sort((a,b) => {
        if(!a["phoneNumber"] && !b["phoneNumber"]) {
          return 0;
        }
        else if(a["phoneNumber"] && !b["phoneNumber"]) {
          return -1;
        }
        else if(!a["phoneNumber"] && b["phoneNumber"]) {
          return 1;
        } 
        else {
          const value1 = a["phoneNumber"].toString().toUpperCase(); // ignore upper and lowercase
          const value2 = b["phoneNumber"].toString().toUpperCase(); // ignore upper and lowercase
          if (value1 < value2) {
            return -1;
          } else if (value1 > value2) {
            return 1;
          } else {
            return 0;
          }
        }
      })
      break;
      case "reversePhoneNumber": addedUsers.sort((a,b) => {
        if(!a["phoneNumber"] && !b["phoneNumber"]) {
          return 0;
        }
        else if(a["phoneNumber"] && !b["phoneNumber"]) {
          return 1;
        }
        else if(!a["phoneNumber"] && b["phoneNumber"]) {
          return -1;
        } 
        else {
          const value1 = a["phoneNumber"].toString().toUpperCase(); // ignore upper and lowercase
          const value2 = b["phoneNumber"].toString().toUpperCase(); // ignore upper and lowercase
          if (value1 < value2) {
            return 1;
          } else if (value1 > value2) {
            return -1;
          } else {
            return 0;
          }
        }
      })
      break;
      case "status": addedUsers.sort((a,b) => {
        return (a["active"] === b["active"]) ? 0 : a["active"]? -1 : 1;
      })
      break;
      case "reverseStatus": addedUsers.sort((a,b) => {
        return (a["active"] === b["active"]) ? 0 : a["active"]? 1 : -1;
      })
      break;
    }
  }

  return (
    <>
      <div className="full-to-group" id="blurred">
        {selectedTab === "group" ? (
          <div className="to-group">
            <div className="to-group-first">
              <p className="header-text">{group?.name}</p>
              <div
                className="close"
                style={{height:"15px",width:"25px",marginTop:"2%"}}
                onClick={() => {
                  document.getElementById("blurred").style.display = "none";
                }}
              />
            </div>
            <div className="to-group-second">
              <p className="smaller-header-text">Видима за потребители</p>
              <table>
                <tr style={{width:"100%",height:"45px"}}>
                  <th>
                    <p style={{ marginLeft: "2%", fontSize: "13px" }}>Име</p>
                    <div className={`iconn-container`}>
                      <Icon
                        size={"50%"}
                        icon={ic_keyboard_arrow_down}
                        className={`arroww-down`}
                        id={1}
                      />
                    </div>
                  </th>
                  <th>
                    <p style={{ marginLeft: "2%", fontSize: "13px" }}>
                      Телефон
                    </p>
                    <div className={`iconn-container`}>
                      <Icon
                        size={"50%"}
                        icon={ic_keyboard_arrow_down}
                        className={`arroww-down`}
                        id={1}
                      />
                    </div>
                  </th>
                </tr>
                {visibleForUsers.map((user) => {
                  return (
                    <tr style={{height:"55px",marginBottom:"5%"}}>
                      <td
                        style={{
                          borderRight: "1px solid gray",
                          padding: "0.5vw",
                          height:"55px",
                          display:"flex",
                          alignItems:"center"
                        }}
                      >
                        <p style={{ marginLeft: "2%", fontSize: "15px" }}>
                          {user.name}
                        </p>
                      </td>
                      <td style={{ padding: "0.5vw",height:"55px",
                          display:"flex",
                          alignItems:"center" }}>
                        <p style={{ marginLeft: "2%",fontSize:"15px" }}>{user.phoneNumber}</p>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        ) : selectedTab === "price" ? (
          <div className="price">
            <div className="to-group-first">
              <p className="header-text">{group?.name}</p>
              <div
                className="close"
                onClick={() => {
                  document.getElementById("blurred").style.display = "none";
                }}
              />
            </div>
            <div className="to-group-second">
              {selectedLocation?._stations?.map((station, i) => {
                return (
                  <div className="info-full-container" key={i}>
                    <div className="info-inner-container">
                      <p className="info-inner-text">
                        <b>Станция {station?.name}</b>
                      </p>
                      <p className="price-inner-text">
                        <b style={{ color: "#00D688",fontSize:"16px" }}>
                          {station?.specialPrice}
                        </b>{" "}
                        лв./kW
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : selectedTab === "filtration" ? (
          <div className="filtration-popUp ">
            <div
              className="inner-filtration-container scroll-width-thin"
              style={{
                height: "94%",
                width: "100%",
                overflowX: "hidden",
                overflowY: "scroll",
                padding: "1vw",
              }}
            >
              <div className="to-group-first">
                <p className="header-text">Филтри</p>
                <div
                  className="close"
                  onClick={() => {
                    document.getElementById("blurred").style.display = "none";
                  }}
                />
              </div>
              <div className="orderBy">
                <h2 style={{ fontSize: "18px" }}>Подреди по</h2>
                <div className="all-radio">
                  {orderBy.map((element, i) => {
                    return (
                      <div
                        className="radio"
                        style={{ display: "flex", alignItems: "flex-end" }}
                        key={i}
                      >
                        <input
                          type="radio"
                          value="active"
                          name={`active`}
                          className="radio-btn"
                          id={`active${i}`}
                          checked={filterOptions.order === element && true}
                          onClick={() => {
                            setFilterOptions({
                              ...filterOptions,
                              ["order"]: element,
                            });
                          }}
                          onChange={() => {}}
                        />
                        {filterOptions.order === element ? (
                          <b>{element}</b>
                        ) : (
                          element
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="fromTime">
                <h2 style={{ fontSize: "18px" }}>Преглед</h2>
                <div className="all-radio">
                  {lookTime.map((elem, i) => {
                    return (
                      <div
                        className="radio"
                        style={{ display: "flex", alignItems: "flex-end" }}
                        key={i}
                      >
                        <input
                          type="radio"
                          value="look"
                          name={`look`}
                          className="radio-btn"
                          id={`look${i}`}
                          checked={filterOptions.look === elem && true}
                          onChange={() => {}}
                          onClick={() => {
                            if (elem === "Дата/период") setOpenCalendar(true);
                            else setOpenCalendar(false);
                            setFilterOptions({
                              ...filterOptions,
                              ["look"]: elem,
                            });
                          }}
                        />
                        {filterOptions.look === elem ? <b>{elem}</b> : elem}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={`datePicker ${openCalendar && "show"}`}>
                <DatePicker
                  onChange={(date) => handleDateChange(date)}
                  selectsStart={true}
                  selected={dates[0]}
                  startDate={dates[0]}
                  endDate={dates[1]}
                  open={openCalendar}
                  customInput={<></>}
                />
              </div>
              <div className="sum">
                <h2 style={{ fontSize: "18px" }}>Сума</h2>
                <div className="sum-inputs">
                  <input
                    required
                    className="from-hour"
                    type="number"
                    id="power"
                    value={filterOptions.sum[0]}
                    maxLength={2}
                    onChange={(event) => {
                      const newValues = filterOptions.sum.slice(0);
                      if (Number(event.target.value) > newValues[1]) {
                        Swal.fire(
                          "Не може първата граница да е по-голяма от втората.",
                          "",
                          "error"
                        );
                        return;
                      }
                      newValues[0] = Number(event.target.value);
                      setFilterOptions({
                        ...filterOptions,
                        ["sum"]: newValues,
                      });
                    }}
                  />
                  <input
                    required
                    className="from-hour"
                    type="number"
                    id="power"
                    value={filterOptions.sum[1]}
                    maxLength={2}
                    onChange={(event) => {
                      const newValues = filterOptions.sum.slice(0);
                      newValues[1] = Number(event.target.value);
                      setFilterOptions({
                        ...filterOptions,
                        ["sum"]: newValues,
                      });
                    }}
                  />
                </div>
                <Slider
                  range
                  allowCross={false}
                  value={filterOptions.sum}
                  onChange={(value) => {
                    setFilterOptions({
                      ...filterOptions,
                      ["sum"]: value,
                    });
                  }}
                  trackStyle={{ backgroundColor: "#00D688", height: 5 }}
                  handleStyle={{ backgroundColor: "#193A4D" }}
                />
              </div>

              <div className="outlet-filter">
                <h2 style={{ fontSize: "18px" }}>Тип кабел</h2>
                <div className="all-radio">
                  {outletType.map((outlet, i) => {
                    return (
                      <div className="radio" key={i}>
                        <input
                          type="checkbox"
                          value="outeltName"
                          name="outeltName"
                          className="radio-btn"
                          id={`outeltName${i}`}
                          onChange={() => {}}
                          checked={filterOptions.selectedType.includes(outlet)}
                          onClick={() => {
                            const newStations =
                              filterOptions.selectedType.slice(0);
                            if (!newStations.includes(outlet)) {
                              newStations.push(outlet);
                              setFilterOptions({
                                ...filterOptions,
                                ["selectedType"]: newStations,
                              });
                            } else {
                              setFilterOptions({
                                ...filterOptions,
                                ["selectedType"]: newStations.filter(
                                  (statione) => !statione.includes(outlet)
                                ),
                              });
                            }
                          }}
                        />
                        {outlet}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="stations-filter">
                <h2 style={{ fontSize: "18px" }}>Локации</h2>
                <div className="all-radio">
                  {group?._locations?.map((location, i) => {
                    return (
                      <div className="radio" key={i}>
                        <input
                          type="checkbox"
                          value="stationName"
                          name="stationName"
                          className="radio-btn"
                          id={`stationName${i}`}
                          checked={filterOptions.checkedLocation.includes(
                            location.name
                          )}
                          onChange={() => {}}
                          onClick={() => {
                            const newStations =
                              filterOptions.checkedLocation.slice(0);
                            if (!newStations.includes(location.name)) {
                              newStations.push(location.name);
                              setFilterOptions({
                                ...filterOptions,
                                ["checkedLocation"]: newStations,
                              });
                            } else {
                              setFilterOptions({
                                ...filterOptions,
                                ["checkedLocation"]: newStations.filter(
                                  (statione) =>
                                    !statione.includes(location.name)
                                ),
                              });
                            }
                          }}
                        />
                        {location.name}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="outlet-filter">
                <h2 style={{ fontSize: "18px" }}>Автомобили</h2>
                <div className="all-radio">
                  {carsType.map((car, i) => {
                    return (
                      <div className="radio" key={i}>
                        <input
                          type="checkbox"
                          value="carName"
                          name="carName"
                          className="radio-btn"
                          id={`carName${i}`}
                          checked={filterOptions.selectedCar?.includes(car)}
                          onChange={() => {}}
                          onClick={() => {
                            const newCars = filterOptions.selectedCar.slice(0);
                            if (!newCars.includes(car)) {
                              newCars.push(car);
                              setFilterOptions({
                                ...filterOptions,
                                ["selectedCar"]: newCars,
                              });
                            } else {
                              setFilterOptions({
                                ...filterOptions,
                                ["selectedCar"]: newCars.filter(
                                  (care) => !care.includes(car)
                                ),
                              });
                            }
                          }}
                        />
                        {car}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="buttons-container">
              <div
                className="btn"
                onClick={() => {
                  setFilterOptions({
                    order: "",
                    look: "",
                    sum: [0, 100],
                    checkedLocation: [],
                    selectedType: [],
                    selectedCar: [],
                  });
                  setOpenCalendar(false);
                  getGroupActivity({
                    businessGroup: group_id,
                    onSuccess: () => {},
                  });
                }}
              >
                Изчисти
              </div>
              <div
                className="small-button-container child"
                onClick={() => {
                  if (filterOptions.sum[0] > filterOptions.sum[1]) {
                    Swal.fire(
                      "Не може втората граница да е по-малка от първата",
                      "",
                      "error"
                    );
                    return;
                  }
                  const payload = {
                    businessGroup: group_id,
                    minAmount: filterOptions.sum[0],
                    maxAmount: filterOptions.sum[1],
                    locations: [],
                    noPagination: true,
                    onSuccess: (response) =>
                      (document.getElementById("blurred").style.display =
                        "none"),
                  };

                  if (filterOptions.selectedType.length > 0) {
                    let arr = new Array();
                    filterOptions.selectedType.map((type) => {
                      arr = activities.filter(
                        (activity) =>
                          activity?.outlet?.type.includes(type) &&
                          activity?.outlet?._id
                      );
                    });
                    payload.cableTypes = new Array();
                    payload.cableTypes.push(arr[0].outlet._id);
                  }

                  if (filterOptions.selectedCar.length > 0) {
                    let arr = new Array();
                    filterOptions.selectedCar.map((selectedCar) => {
                      arr = activities.filter(
                        (activity) =>
                          activity?.car?.fullName.includes(selectedCar) &&
                          activity?.car?._id
                      );
                    });
                    payload.cars = new Array();
                    payload.cars.push(arr[0].car._id);
                  }

                  if (filterOptions.order.length > 0)
                    payload.sort = handlePayload("order");

                  if (
                    filterOptions.look.length > 0 &&
                    filterOptions.look.includes("Дата/период")
                  ) {
                    payload.startDate = dates[0];
                    if (dates[1] === null) payload.endDate = dates[0];
                    else payload.endDate = dates[1];
                  }

                  filterOptions.checkedLocation.map((checkedLocationName) => {
                    group._locations.map((location) => {
                      if (location.name.includes(checkedLocationName)) {
                        payload.locations.push(location._id);
                        return;
                      }
                    });
                  });

                  getGroupActivity(payload);
                }}
              >
                Задай
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="group-not-blurred-container">
        <InnerHeader
          link={"/groups"}
          first={`${group?.name}`}
          edit={true}
          group={group}
        />
        <div className="full-group-container">
          <div className="left-group-container">
            <h2>
              <b>Основна информация</b>
            </h2>
            <div className="first-part">
              {Object.keys(Items).map((key, i) => {
                return (
                  <div
                    className={`line-and-text ${
                      Items[key] === undefined && "not"
                    }`}
                    key={i}
                  >
                    <div className="left-line" />
                    <p className="bold-tab-text">
                      {key} <b>{Items[key]}</b>
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="splitter">Добавени потребители</div>
            <div className="second-part">
              <table className="second-part-table scroll-width-thin">
                <tbody className="first-row">
                  <tr style={{width:"40%"}}>
                    <th className="first">
                      <p style={{ marginLeft: "2%", fontSize: "14px" }}>Име</p>
                      <div className={`table-icon-container first`} style={{marginTop:"0%",height:"unset",display:"flex",alignItems:"center",marginTop:"3%"}}>
                        <Icon
                          size={"80%"}
                          style={{color:"#00D688",transform:filter === "name" && "rotate(180deg)"}}
                          onClick = {() => {
                            if(filter === "name"){
                              setFilter("reverseName");
                              usersFilter("reverseName")
                            }
                            else{
                              setFilter("name")
                              usersFilter("name")
                            }
                          }}
                          icon={ic_keyboard_arrow_down}
                          className={`arroww-down`}
                          id={1}
                        />
                      </div>
                    </th>
                  </tr>

                <tr style={{width:"33%"}}>
                    <th style={{width:"100%"}}>
                        <p style={{ marginLeft: "2%", fontSize: "14px" }}>
                        Телефон
                        </p>
                        <div className={`table-icon-container`} style={{marginTop:"0%",height:"unset",display:"flex",alignItems:"center",marginTop:"3%"}}>
                        <Icon
                            size={"80%"}
                            style={{color:"#00D688",transform:filter === "phoneNumber" && "rotate(180deg)"}}
                            onClick = {() => {
                              if(filter === "phoneNumber"){
                                setFilter("reversePhoneNumber");
                                usersFilter("reversePhoneNumber")
                              }
                              else{
                                setFilter("phoneNumber")
                                usersFilter("phoneNumber")
                              }
                              
                            }}
                            icon={ic_keyboard_arrow_down}
                            className={`arroww-down`}
                            id={2}
                        />
                        </div>
                    </th>
                </tr>
                 
                 <tr style={{width:"30%"}}>
                    <th style={{width:"100%"}}>
                        <p style={{ marginLeft: "2%", fontSize: "14px" }}>Статус</p>
                        <div className={`table-icon-container`} style={{marginTop:"0%",height:"unset",display:"flex",alignItems:"center",marginTop:"3%"}}>
                        <Icon
                            size={"80%"}
                            style={{color:"#00D688",transform:filter === "status" && "rotate(180deg)"}}
                            onClick = {() => {
                              if(filter === "status"){
                                setFilter("reverseStatus")
                                usersFilter("reverseStatus")
                              }
                              else {
                                setFilter("status")
                                usersFilter("status")
                              }
                              
                            }}
                            icon={ic_keyboard_arrow_down}
                            className={`arroww-down`}
                            id={3}
                        />
                        </div>
                    </th>
                 </tr>
                  
                </tbody>

                {addedUsers?.map((user, i) => {
                  return (
                    <tr className="second-row" key={i}>
                      <td className="data first" style={{width:"40%"}}>{user?.name}</td>
                      <td className="data" style={{width:"35%"}}> {user?.phoneNumber} </td>
                      <td className="data"  style={{width:"30%"}}>
                        {user?.active === false ? (
                          <b style={{color:"#F54280"}}>Неактивен</b>
                        ) : (
                          <b style={{ color: "#00D688" }}>Активен</b>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
          <div className="middle-group-container">
            <h2>
              <b>Локации към група</b>
            </h2>

            <div className="middle-group-sections-wrapper">
              {group?.forAllLocations ?
              <div className="middle-group-section-container" key={'station-for-all-locations'}>
                <div className="inner-header-group-section">
                  <p className="text bold uppercase">Всички станции</p>
                </div>
                <div className="inner-group-section">
                  <div className="small-section overflow">
                    <p className="text">Специална цена зареждане: &nbsp;</p>
                    <p className={`text margin ${(group?.specialPrice || group?.specialPrice === 0) ? 'bold' : 'regular'}`}>
                        {(group?.specialPrice || group?.specialPrice === 0)
                          ? `Дa | ${group?.specialPrice?.toFixed(2)} лв./kW`
                          : "Не"}
                    </p>
                  </div>
                  <div className="small-section overflow">
                    <p className="text">Начална такса: &nbsp;</p>
                    <p className={`text margin ${(group?.initialFee || group?.initialFee === 0) ? 'bold' : 'regular'}`}>
                    {(group?.initialFee || group?.initialFee === 0)
                          ? `Дa | ${group?.initialFee?.toFixed(2)} лв.`
                          : "Не"}
                    </p>
                  </div>
                  <div className="small-section overflow">
                    <p className="text">Такса престой: &nbsp;</p>
                    <p className={`text margin ${(group?.parkingFee || group?.parkingFee === 0) ? 'bold' : 'regular'}`}>
                        {(group?.parkingFee || group?.parkingFee === 0)
                          ? `Дa | ${group?.parkingFee?.toFixed(2)} лв./мин`
                          : "Не"}
                    </p>
                  </div>
                </div>
              </div>
               :
              group?.locations?.map((location, i) => {
                return (
                  <div className="middle-group-section-container" key={i}>
                    <div className="inner-header-group-section">
                      <p className="text bold uppercase">{location?.location?.name || '--'}</p>
                    </div>
                    <div className="inner-group-section">
                      <div className="small-section overflow">
                        <p className="text">Специална цена зареждане: &nbsp;</p>
                        <p className={`text margin ${location?.specialPrice ? 'bold' : 'regular'}`}>
                            {location?.specialPrice
                              ? `Дa | ${location?.specialPrice?.toFixed(2)} лв./kW`
                              : "Не"}
                        </p>
                      </div>
                      <div className="small-section overflow">
                        <p className="text">Начална такса: &nbsp;</p>
                        <p className={`text margin ${location?.initialFee ? 'bold' : 'regular'}`}>
                        {location?.initialFee
                              ? `Дa | ${location?.initialFee?.toFixed(2)} лв.`
                              : "Не"}
                        </p>
                      </div>
                      <div className="small-section overflow">
                        <p className="text">Такса престой: &nbsp;</p>
                        <p className={`text margin ${location?.parkingFee ? 'bold' : 'regular'}`}>
                            {location?.parkingFee
                              ? `Дa | ${location?.parkingFee?.toFixed(2)} лв./мин`
                              : "Не"}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
            }
            </div>

            {/* <div className="full-middle-group-section-container"></div> */}
          </div>
          <div className="right-group-container">
            <div className="inner-upper-container scroll-width-thin">
              <h2
                style={{
                  fontSize: "22px",
                  marginTop: "5%",
                  marginLeft: "2%",
                  width: "30%",
                }}
              >
                <b>Активност</b>
              </h2>
              <div
                className="filter-button"
                onClick={() => {
                  document.getElementById("blurred").style.display = "block";
                  setSelectedTab("filtration");
                  getGroupActivity({
                    businessGroup: group_id,
                    noPagination: true,
                    onSuccess: () => {},
                  });
                }}
              />
            </div>
            <div className="inner-middle-container">
              <p style={{ fontSize: "16px", width: "50%" }}>
                Общо: <b>{statistics?.proceeds !== undefined ? statistics?.proceeds  + "лв." : '0 лв.'}</b>
              </p>
              <p style={{ fontSize: "17px", marginLeft: "10%" }}>
                За период: <b>От началото</b>
              </p>
            </div>
            <div className="full-lower-container scroll-width-thin">
              {activities?.map((activity,i) => {
                return (
                  <div className="lower-section" key={i}>
                    <p className="tab-text">
                      <b style={{ marginLeft: "1%" }}>
                        {moment(activity?.startAt).format("DD MMMM ‘YY")}
                      </b>
                    </p>
                    <p className="tab-text" style={{ marginLeft: "3%" }}>
                      Локация: <b>{activity?.location?.name}</b>
                    </p>
                    <div style={{ display: "flex", marginTop: "-2%" }}>
                      <p
                        className="tab-text"
                        style={{ marginRight: "2%", marginLeft: "3%" }}
                      >
                        Станция: <b>{activity?.station?.name}</b>
                      </p>
                      <p
                        className="tab-text"
                        style={{ marginLeft: "3%", whiteSpace: "nowrap" }}
                      >
                        Кабел:{" "}
                        <b style={{ marginLeft: "1%" }}>
                          {activity?.outlet?.type[0]}
                        </b>
                      </p>
                    </div>

                    <div className="third-line">
                      <p className="smaller-tab-text">
                        Начало:{" "}
                        <b style={{ marginLeft: "1%" }}>
                          {moment(activity?.startAt).format("HH:mm")}
                        </b>
                      </p>
                      <p className="smaller-tab-text">
                        Край:{" "}
                        <b style={{ marginLeft: "1%" }}>
                          {moment(activity?.endAt).format("HH:mm")}
                        </b>
                      </p>
                      <p className="smaller-tab-text">
                        Време:{" "}
                        <b style={{ marginLeft: "1%" }}>
                          {handleDuration(activity?.duration)}
                        </b>
                      </p>
                    </div>
                    <div className="fourth-line">
                      <p className="smaller-tab-text">
                        Сума:{" "}
                        <b style={{ marginLeft: "1%", color: "#00D688" }}>
                          {Math.round(activity?.amountForPay * 100) / 100}лв.
                        </b>
                      </p>
                      <p className="smaller-tab-text">
                        Потребител:{" "}
                        <b style={{ marginLeft: "1%" }}>
                          {activity?.user?.phoneNumber}
                        </b>
                      </p>
                    </div>
                    <p className="tab-text">
                      Автомобил:{" "}
                      <b style={{ marginLeft: "1%" }}>
                        {activity?.car?.fullName}
                      </b>
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  group: state.groups.group,
  station: state.stations.station,
  allUsers: state.login.allUsers,
  activities: state.groups.activities,
  statistics: state.groups.statistics,
  locations: state.locations.locations
});
const mapDispatchToProps = (dispatch) => ({
  getGroupByID: (payload) => dispatch(getGroupByID(payload)),
  getStationByID: (payload) => dispatch(getStationByID(payload)),
  getUsers: (payload) => dispatch(getUsers(payload)),
  getGroupActivity: (payload) => dispatch(getGroupActivity(payload)),
  getLocations: (payload) => dispatch(getLocations(payload)),
  getGroupStatistics: (payload) => dispatch(getGroupStatistics(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Group);
