import { StationTypes } from "../actions";
import { authTypes } from "../actions";

const initialState = {
  stations: [],
  station: {},
  statistics: {},
};

const handleUpdatedLocation = (state, payload) => {
  const foundStation = payload._stations.find((x) => x?._id === state?.station?._id);
  return foundStation || state?.station;
};

const stations = (state = initialState, { type, payload }) => {
  switch (type) {
    case StationTypes.GET_STATIONS_SUCCESS:
      return {
        ...state,
        stations: payload?.page > 1 ? [...state.stations, ...payload?.docs] : [...payload?.docs],
        // stations: payload.docs
      };
    case "socket/updateLocation":
      return { ...state, station: handleUpdatedLocation(state, payload) };
    case StationTypes.GET_STATION_BY_ID_SUCCESS:
      return {
        ...state,
        station: payload.payload,
      };
    case StationTypes.GET_STATISTICS_READY:
      return {
        ...state,
        statistics: payload,
      };
    case authTypes.LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default stations;
