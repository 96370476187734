import { pick } from "lodash";
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom"
import AsyncCreatableSelect from 'react-select/async-creatable';
import Swal from "sweetalert2";
import { createCar, createCarBrand, createCarModel, createCarType, deleteCar, getCar, updateCar } from "../../actions";
import { Inputs } from "../../components"
import { API } from "../../config/settings";
import { useQuery } from "../../hooks"
import { Headers } from "../../utilities";
import "./styles.scss"

const AddCars = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const [data, setData] = useState({})
    const [editable, setEditable] = useState(false)
    const handleChange = (payload) => setData((data) => ({ ...data, ...payload }))

    const { _id } = useQuery()
    useEffect(() => {
        if (_id) dispatch(getCar({
            payload: _id,
            onSuccess: (res) => setData({
                ...pick(res, ["_id", "cable", "batteryPower", "mileage", "consumption", "power"]),
                brand: { value: res?.brand?._id, label: res?.brand?.name },
                model: { value: res?.model?._id, label: res?.model?.name },
                batteryType: { value: res?.batteryType?._id, label: res?.batteryType?.name },
                picture: { address: res?.picture, name: "photo" }
            })
        }))
    }, [dispatch, _id])

    const loadBrandOptions = (search) => new Promise((resolve, reject) => {
        return fetch(`${API}car/brand/list`, {
            method: 'POST',
            headers: Headers.get_auth(),
            body: JSON.stringify({ pageSize: 20, search })
        }).then((res) => res.json()).then((res) => {
            const options = res.payload.docs.map(({ _id, name, }) => ({ value: _id, label: name }))
            return resolve(options)
        }).catch(() => reject())
    })

    const loadModelOptions = (search) => new Promise((resolve, reject) => {
        if (data?.brand?.__isNew__) return resolve([])
        return fetch(`${API}car/model/list`, {
            method: 'POST',
            headers: Headers.get_auth(),
            body: JSON.stringify({ pageSize: 20, search, brand: data?.brand?.value })
        }).then((res) => res.json()).then((res) => {
            const options = res.payload.docs.map(({ _id, name, }) => ({ value: _id, label: name }))
            return resolve(options)
        }).catch(() => reject())
    })

    const loadBateryOptions = (search) => new Promise((resolve, reject) => {
        if (data?.model?.__isNew__) return resolve([])
        return fetch(`${API}car/battery/list`, {
            method: 'POST',
            headers: Headers.get_auth(),
            body: JSON.stringify({ pageSize: 20, search, model: data?.model?.value })
        }).then((res) => res.json()).then((res) => {
            const options = res.payload.docs.map(({ _id, name, }) => ({ value: _id, label: name }))
            return resolve(options)
        }).catch(() => reject())
    })

    const [showRequired, setShowRequired] = useState(false)
    const isValid = (payload) => {
        const requiredFields = ["brand", "model", "batteryType", "cable", "batteryPower", "mileage", "consumption", "power", "picture"]
        if (requiredFields.some((f) => !payload?.[f])) return false
        if (payload?.brand?.__isNew__ && !payload?.logo) return false
        return true
    }

    const handleSubmit = (payload) => {
        if (!isValid(payload)) setShowRequired(true)
        else if (payload?.brand?.__isNew__) dispatch(createCarBrand({
            payload: { name: payload?.brand?.value, logo: payload?.url?.address },
            onSuccess: (res) => handleSubmit({ ...payload, brand: { value: res._id, label: res.name } })
        }))
        else if (payload?.model?.__isNew__) dispatch(createCarModel({
            payload: { brand: payload.brand.value, name: payload.model.value, },
            onSuccess: (res) => handleSubmit({ ...payload, model: { value: res._id, label: res.name } })
        }))
        else if (payload?.batteryType?.__isNew__) dispatch(createCarType({
            payload: { model: payload.model.value, name: payload.batteryType.value, },
            onSuccess: (res) => handleSubmit({ ...payload, batteryType: { value: res._id, label: res.name } })
        }))
        else {
            const func = _id ? updateCar : createCar
            dispatch(func({
                payload: {
                    ...pick(payload, ["_id", "cable", "batteryPower", "mileage", "consumption", "power"]),
                    brand: payload?.brand?.value,
                    model: payload?.model?.value,
                    batteryType: payload?.batteryType?.value,
                    picture: payload?.picture?.address
                },
                onSuccess: () => {
                    Swal.fire(`Успешно ${_id ? 'редактиране' : 'създаване'}.`, "", "success")
                    history.push("/cars")
                }
            }))
        }
    }

    const handleDelete = () => Swal.fire({
        title: 'Сигурни ли сте, че искате да изтриете автомобила?',
        icon: 'warning',
        cancelButtonText: 'Откажи',
        confirmButtonText: 'Изтрий',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#0099ff',
        reverseButtons: true,
    }).then(({ isConfirmed }) => {
        if (isConfirmed) dispatch(deleteCar({
            payload: _id,
            onSuccess: () => {
                Swal.fire(`Успешно изтриване.`, "", "success")
                history.push("/cars")
            }
        }))
    })


    return <div className="screen-add-cars-container">
        <div className="screen-add-cars-inner-container col">
            <div className="screen-add-cars-header row">
                <div className="icon icon-close" onClick={() => history.goBack()} />
                {editable
                    ? <>
                        <h2>Редакция на автомобил</h2>
                        <div className="row row-buttons">
                            <Inputs.Raised text={"Изтрий"} reversed onClick={handleDelete} />
                            <Inputs.Raised text={"Запази"} onClick={() => handleSubmit(data)} />
                        </div>
                    </>
                    : _id
                        ? <>
                            <h2>Преглед на автомобил</h2>
                            <div className="icon icon-edit" onClick={() => setEditable(true)} />

                        </>
                        : <>
                            <h2>Добавяне на автомобил</h2>
                            <Inputs.Raised text={"Запази"} onClick={() => handleSubmit(data)} />
                        </>
                }
            </div>
            <div className="screen-add-cars-content">
                <div className="row">
                    <div className="col">
                        <span>Производител</span>
                        <AsyncCreatableSelect
                            styles={{
                                container: (provided) => ({ ...provided, margin: '10px 0' }),
                                control: (provided) => ({
                                    ...provided, borderColor: '#2f4858', '&:hover': { borderColor: '#2f4858' },
                                    border: `${showRequired && !data.brand && 'none'}`,
                                    boxShadow: `${showRequired && !data.brand && '0 0 3px #e5214e'}`,
                                }),
                                valueContainer: (provided) => ({ ...provided, fontSize: 13 }),
                                menu: (provided) => ({ ...provided, margin: 0 }),
                                menuList: (provided) => ({ ...provided, fontSize: 13, }),
                            }}
                            isClearable
                            cacheOptions
                            defaultOptions
                            loadOptions={loadBrandOptions}
                            value={data?.brand}
                            onChange={(brand) => handleChange({ brand, model: undefined, batteryType: undefined })}
                            placeholder=""
                            isDisabled={_id && !editable}
                        />
                    </div>
                    <div className="col">
                        <span>Модел</span>
                        <AsyncCreatableSelect
                            styles={{
                                container: (provided) => ({ ...provided, margin: '10px 0' }),
                                control: (provided) => ({
                                    ...provided, borderColor: '#2f4858', '&:hover': { borderColor: '#2f4858' },
                                    border: `${showRequired && !data.model && 'none'}`,
                                    boxShadow: `${showRequired && !data.model && '0 0 3px #e5214e'}`,
                                }),
                                valueContainer: (provided) => ({ ...provided, fontSize: 13 }),
                                menu: (provided) => ({ ...provided, margin: 0 }),
                                menuList: (provided) => ({ ...provided, fontSize: 13, }),
                            }}
                            key={data?.brand?.value}
                            isClearable
                            cacheOptions
                            defaultOptions
                            loadOptions={loadModelOptions}
                            value={data?.model}
                            onChange={(model) => handleChange({ model, batteryType: undefined })}
                            placeholder=""
                            isDisabled={_id && !editable}
                        />
                    </div>
                    <div className="col">
                        <span>Вид</span>
                        <AsyncCreatableSelect
                            styles={{
                                container: (provided) => ({ ...provided, margin: '10px 0' }),
                                control: (provided) => ({
                                    ...provided, borderColor: '#2f4858', '&:hover': { borderColor: '#2f4858' },
                                    border: `${showRequired && !data.model && 'none'}`,
                                    boxShadow: `${showRequired && !data.model && '0 0 3px #e5214e'}`,
                                }),
                                valueContainer: (provided) => ({ ...provided, fontSize: 13 }),
                                menu: (provided) => ({ ...provided, margin: 0 }),
                                menuList: (provided) => ({ ...provided, fontSize: 13, }),
                            }}
                            key={data?.model?.value}
                            isClearable
                            cacheOptions
                            defaultOptions
                            loadOptions={loadBateryOptions}
                            value={data?.batteryType}
                            onChange={(batteryType) => handleChange({ batteryType })}
                            placeholder=""
                            isDisabled={_id && !editable}
                        />
                    </div>
                    <div className="col">
                        <span>Капацитет на батерията</span>
                        <Inputs.Text label="kWh"
                            value={data?.batteryPower || ""}
                            onChange={({ target: { value } }) => handleChange({ batteryPower: value })}
                            className={showRequired && !data.batteryPower && 'invalid'}
                            disabled={_id && !editable}
                        />
                    </div>
                    <div className="col">
                        <span>Кабел тип</span>
                        <Inputs.Text
                            value={data?.cable || ""}
                            onChange={({ target: { value } }) => handleChange({ cable: value })}
                            className={showRequired && !data.cable && 'invalid'}
                            disabled={_id && !editable}
                        />
                    </div>
                    <div className="col">
                        <span>Пробег изцяло на ток</span>
                        <Inputs.Text label="км"
                            value={data?.mileage || ""}
                            onChange={({ target: { value } }) => handleChange({ mileage: value })}
                            className={showRequired && !data.mileage && 'invalid'}
                            disabled={_id && !editable}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <span>Среден разход</span>
                        <Inputs.Text label="км/kWh"
                            value={data?.consumption || ""}
                            onChange={({ target: { value } }) => handleChange({ consumption: value })}
                            className={showRequired && !data.consumption && 'invalid'}
                            disabled={_id && !editable}
                        />
                    </div>
                    <div className="col">
                        <span>Системна мощност</span>
                        <Inputs.Text label="кс"
                            value={data?.power || ""}
                            onChange={({ target: { value } }) => handleChange({ power: value })}
                            className={showRequired && !data.power && 'invalid'}
                            disabled={_id && !editable}
                        />
                    </div>
                    {data?.brand?.__isNew__ &&
                        <div className="col col-photo">
                            <span>Лого производител</span>
                            {data?.logo
                                ? <div className="photo-container row">
                                    <span>{data?.logo?.name}</span>
                                    {(!_id || editable) && <div className="icon icon-remove" onClick={() => handleChange({ logo: undefined })} />}
                                </div>
                                : <Inputs.UploadButton
                                    onChange={([logo]) => handleChange({ logo })}
                                    className={showRequired && !data?.logo && "invalid"}
                                    inputProps={{ disabled: _id && !editable }}
                                />}
                        </div>
                    }
                    <div className="col col-photo">
                        <span>Снимка автомобил</span>
                        {data?.picture
                            ? <div className="photo-container row">
                                <span>{data?.picture?.name}</span>
                                {(!_id || editable) && <div className="icon icon-remove" onClick={() => handleChange({ picture: undefined })} />}
                            </div>
                            : <Inputs.UploadButton
                                onChange={([picture]) => handleChange({ picture })}
                                className={showRequired && !data?.picture && "invalid"}
                                inputProps={{ disabled: _id && !editable }}
                            />}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default AddCars

