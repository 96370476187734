/* eslint-disable */
import React, { useState,useEffect, useRef } from 'react'
import {connect} from "react-redux";
import { useLocation } from 'react-router-dom';
import { InnerHeader } from '../../../components';
import '../styles.scss'


const EditUser = ({}) => {
    const user = useLocation().state;
    const [editedUser,setEditedUser] = useState(user)
    useEffect(()=>{
        setEditedUser({
            ...editedUser,
            ["phoneNumber"]: editedUser.phoneNumber.slice(4,editedUser.phoneNumber.length)
        })
    },[])


    return(
        <div className='full-add-container'>
            <InnerHeader
            link={"/users"}
            first="Редактиране на потребител" 
            editUserPage={1}
            user={editedUser}
            setUser = {setEditedUser}
            />
            <div className='section-container' id={1}>
                <h2>Ваучер {1}</h2>
                <div className='inner-section-container'>
                        <div className='first-input'>
                            <span className='input-title'>Име и Фамилия</span>
                            <input
                                required
                                className='input-field'
                                type="text"
                                id="voucher-name"
                                value={editedUser.name}
                                onChange={(event) => {
                                    setEditedUser({
                                        ...editedUser,
                                        ["name"]:event.target.value
                                    })
                                }}
                            />
                        </div>
                        <div className='first-input' style={{marginLeft:"1%"}}>
                            <span className='input-title' style={{marginBottom:"2.5%"}}>Телефонен номер</span>
                            <div className = "partial-container">
                            <div className='suffix'>+359</div>
                                    <input
                                        required
                                        className='partial'
                                        type="number"
                                        value={editedUser?.phoneNumber}
                                        id="partial"
                                        onChange={({target : {value}}) => {
                                            if(!/^\d{0,9}$/.test(value)) return
                                            setEditedUser({
                                                ...editedUser,
                                                ["phoneNumber"]:event.target.value
                                            })
                                        }}
                                    />
                            </div>
                        </div>
                        <div className='last-input'>
                            <span className='input-title'  style={{marginTop:"-2%",marginBottom:"2%"}}>Роля</span>
                            <div className='all-radio padded'>
                                <div className='radio'><input type="radio" value="admin" name="state" className='radio' id="admin" onClick={()=>{
                                    setEditedUser({
                                        ...editedUser,
                                        ["role"] : "admin"
                                    })
                                }}
                                checked={editedUser.role === "admin"}/>{editedUser.role === "admin" ? <b>Aдмин</b> : "Aдмин"}</div>

                                <div className='radio'><input type="radio" value="support" name="state" className='radio' id="support" onClick={()=>{
                                    setEditedUser({
                                        ...editedUser,
                                        ["role"] : "support"
                                    })
                                }}
                                checked={editedUser.role === "support"}/>{editedUser.role === "support" ? <b>Поддръжка</b> : "Поддръжка"}</div>

                                <div className='radio'><input type="radio" value="accountant" name="state" className='radio' id="accountant" onClick={()=>{
                                    setEditedUser({
                                        ...editedUser,
                                        ["role"] : "accountant"
                                    })
                                }}
                                checked={editedUser.role === "accountant"}/>{editedUser.role === "accountant" ? <b>Счетоводство</b> : "Счетоводство"}</div>
                            </div>
                        </div>
                </div>
            </div> 
        </div>  
    )


}


const mapDispatchToProps = (dispatch) => ({
  })

export default connect(null,mapDispatchToProps)(EditUser);