/* eslint-disable */
import { GroupTypes } from '../actions'
import { authTypes } from '../actions'


const initialState = {
  groups: [],
  group: {},
  activities:[],
  statistics:{},
  hasMore: false,
  currentPage: 1
}

const handleNewGroups = (state,payload) => {
  const newState = {...state}
  let notFound = true;
  if(newState.groups.length > 0) {
    newState.groups.map(oldLocation => {
      if(oldLocation?._id?.includes(payload?.docs?.[0]?._id)) notFound = false
    })

    if(!notFound) return newState.groups
    if(notFound){
      newState.groups = [...newState.groups, ...payload?.docs]
      return newState.groups
    }

  }
  else{
    newState.groups = [...newState.groups, ...payload?.docs]
    return newState.groups
  }
}


const groups = (state = initialState, {type,payload}) => {
  switch (type) {
    case GroupTypes.GET_GROUPS_SUCCESS:
        return { 
          ...state, 
          // groups: handleNewGroups(state,payload),
          groups: payload?.page > 1 ? [...state.groups, ...payload?.docs] : [...payload?.docs],
          hasMore: payload?.hasNextPage,
          currentPage: payload?.page
        }
    case GroupTypes.SET_GROUPS_DETAILS:
        return { 
          ...state,
          hasMore: payload?.hasNextPage,
          currentPage: payload?.page
        }
    case GroupTypes.GET_GROUP_BY_ID_SUCCESS:
        return{
          ...state,
          group:payload.payload
        }
    case GroupTypes.GET_GROUP_ACTIVITY_SUCCESS:
      return{
        ...state,
        activities:payload.docs
      }
    case GroupTypes.GET_STATISTICS_SUCCESS:
      return{
        ...state,
        statistics:payload
      }
    case authTypes.LOG_OUT_SUCCESS:
        return initialState
    default:
      return state
  }
}

export default groups
