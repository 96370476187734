import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { adminSettingsTypes, setLoadingOff, sessionSocketEmit } from "../actions";
import { API } from "../config/settings";
import { Headers } from "../utilities";
import { toast } from "react-toastify";
import { User } from "../utilities/user";

export const getSessions = (action$) =>
  action$.pipe(
    ofType(adminSettingsTypes.GET_SESSIONS),
    switchMap(({ payload: { station, pageNumber, pageSizeLimit, noPagination, onError } }) => {
      return ajax({
        url: `${API}admin/sessions`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify({
          station,
          pageNumber,
          pageSizeLimit,
          noPagination,
        }),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: adminSettingsTypes.GET_SESSIONS_SUCCESS,
              payload: response.payload,
            });
            obs.next(setLoadingOff());
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err?.response?.message) {
              toast.error(err?.response?.message, { autoClose: 3000 });
            }
            obs.complete();
          });
        })
      );
    })
  );
export const makeDiagnostic = (action$) =>
  action$.pipe(
    ofType(adminSettingsTypes.DIAGNOSTICS),
    switchMap(({ payload: { station, onError } }) => {
      return ajax({
        url: `${API}admin/diagnostics`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify({
          station,
        }),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            toast.success(`Диагностиката беше успешна {${response.payload.fileName}}`, {
              autoClose: 3000,
            });
            obs.next(setLoadingOff());
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            onError(err);
            if (err?.response?.message) {
              toast.error(err?.response?.message, { autoClose: 3000 });
            }
            obs.complete();
          });
        })
      );
    })
  );
export const sendCommand = (action$) =>
  action$.pipe(
    ofType(adminSettingsTypes.SEND_COMMAND),
    switchMap(({ payload: { station, command, connector, onError } }) => {
      return ajax({
        url: `${API}admin/command`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify({
          station,
          command,
          connector,
        }),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            if (response.payload.status.includes("Accepted")) {
              toast.success(`Командата е изпратена и приета успешно. {${command}:${response.payload.status}}`, { autoClose: 3000 });
            } else {
              toast.error(`Командата е изпратена, но беше отхвърлена. {${command}:${response.payload.status}}`, { autoClose: 3000 });
            }
            obs.next(setLoadingOff());
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err?.response?.message) {
              toast.error(err?.response?.message, { autoClose: 3000 });
            }
            obs.complete();
          });
        })
      );
    })
  );
export const lastCharge = (action$) =>
  action$.pipe(
    ofType(adminSettingsTypes.LAST_CHARGE),
    switchMap(({ payload: { station, onSuccess, onError } }) => {
      return ajax({
        url: `${API}admin/charge/last`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify({
          station,
        }),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            onSuccess(response);
            obs.next(setLoadingOff());
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err?.response?.message) {
              toast.error(err?.response?.message, { autoClose: 3000 });
            }
            obs.complete();
          });
        })
      );
    })
  );

export const getConfiguration = (action$) =>
  action$.pipe(
    ofType(adminSettingsTypes.GET_CONFIGURATION),
    switchMap(({ payload: { station, onSuccess, onError } }) => {
      return ajax({
        url: `${API}admin/configuration/${station}`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            onSuccess(response.payload);
            obs.next({
              type: adminSettingsTypes.GET_CONFIGURATION_SUCCESS,
              payload: response.payload,
            });
            obs.next(setLoadingOff());
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err?.response?.message) {
              toast.error(err?.response?.message, { autoClose: 3000 });
            }
            obs.complete();
          });
        })
      );
    })
  );

export const setConfiguration = (action$) =>
  action$.pipe(
    ofType(adminSettingsTypes.SET_CONFIGURATION),
    switchMap(({ payload: { station, key, value, onSuccess, onError } }) => {
      return ajax({
        url: `${API}admin/configuration`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify({
          station,
          key,
          value,
        }),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            //onSuccess(response.payload);
            obs.next(setLoadingOff());
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err?.response?.message) {
              toast.error(err?.response?.message, { autoClose: 3000 });
            }
            obs.complete();
          });
        })
      );
    })
  );

export const unlockConnector = (action$) =>
  action$.pipe(
    ofType(adminSettingsTypes.UNLOCK_CONNECTOR),
    switchMap(({ payload: { station, connector, onSuccess, onError } }) => {
      return ajax({
        url: `${API}admin/unlock`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify({
          station,
          connector,
        }),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            onSuccess();
            obs.next(setLoadingOff());
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            toast.error(err.response.message, { autoClose: 3000 });
            obs.complete();
          });
        })
      );
    })
  );

export const startCharging = (action$) =>
  action$.pipe(
    ofType(adminSettingsTypes.START_CHARGING),
    switchMap(({ payload: { outlet, user, car, onSuccess } }) => {
      return ajax({
        url: `${API}admin/charge/start`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify({
          outlet,
          user,
          car,
        }),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            if (response.payload.request.status === "Accepted") {
              onSuccess(response);
              obs.next({
                type: adminSettingsTypes.START_CHARGING_SUCCESS,
                payload: response.charge.chargePointID,
              });
            } else {
              toast.error(`Charging not started ${response.payload.request.status}`);
            }
            obs.next(setLoadingOff());
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            toast.error(err.response, { autoClose: 3000 });
            obs.complete();
          });
        })
      );
    })
  );

export const stopCharging = (action$) =>
  action$.pipe(
    ofType(adminSettingsTypes.STOP_CHARGING),
    switchMap(({ payload: { outlet, onSuccess } }) => {
      return ajax({
        url: `${API}admin/charge/stop`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify({
          outlet,
        }),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            if (response.payload.status === "Accepted") {
              toast.success("Charging stopped successfully");
            } else {
              toast.error(`Charging could not be stopped ${response.payload.request.status}`);
            }
            obs.next(setLoadingOff());
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            toast.error(err.response, { autoClose: 3000 });
            obs.complete();
          });
        })
      );
    })
  );

export const getUserByPhoneNumber = (action$) =>
  action$.pipe(
    ofType(adminSettingsTypes.GET_USER_BY_PHONE_NUMBER),
    switchMap(({ payload: { phoneNumber, onSuccess, onError } }) => {
      return ajax({
        url: `${API}admin/user/phone`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify({
          phoneNumber,
        }),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            onSuccess(response);
            obs.next(setLoadingOff());
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            onError(err);
            toast.error(err.response.message, { autoClose: 3000 });
            obs.complete();
          });
        })
      );
    })
  );
export const sessionJoinRoomEpic = (action$, state$) =>
  action$.pipe(
    ofType(adminSettingsTypes.SESSION_JOIN_ROOM),
    switchMap(({ payload }) =>
      ActionsObservable.create((obs) => {
        obs.next(
          sessionSocketEmit({
            action: "joinCommandRoom",
          })
        );
        obs.next(setLoadingOff());
        obs.complete();
      })
    )
  );
export const getLogsEpic = (action$) =>
  action$.pipe(
    ofType(adminSettingsTypes.GET_LOGS),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}admin/diagnostics/download`,
        method: "POST",
        headers: {
          Accept: "text/csv",
          Authorization: User.getToken(),
        },
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "diagnostics.csv");
            document.body.appendChild(link);
            link.click();
            payload.onSuccess(response);
            obs.next(setLoadingOff());
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            payload.onError(err);
            toast.error(err.response.message, { autoClose: 3000 });
            obs.complete();
          });
        })
      );
    })
  );
