/* eslint-disable */
import moment from "moment";
import React from "react";
import "./styles.scss";

const InnerSpecialPriceTabs = ["name", "active", "phoneNumber", "addToGroupAt"];

const InnerUsers = ({ group }) => {
  const InnerUsersTabs = [
    {
      name: "Име",
      query: { filter: "", options: [] },
    },
    {
      name: "Статус",
      query: { filter: "", options: [] },
    },
    {
      name: "Телефонен номер",
      query: { filter: "", options: [] },
    },
    {
      name: "Добавен на",
      query: { filter: "", options: [] },
    },
  ];
  return (
    <div className="outer-div">
      <table className="inner-share-table scroll-width-thin">
        <tr className="inner-header-tabs" style={{ marginBottom: "2%" }}>
          {InnerUsersTabs.map((header) => {
            return <th className="inner-header">{header.name}</th>;
          })}
        </tr>
        {group._users.map((user) => {
          return (
            <tr className="inner-header-tabs" style={{ marginBottom: "2%" }}>
              {InnerSpecialPriceTabs.map((field, j) => {
                return <React.Fragment key={j}>{field === "active" ? user[field] === true ? <td className="location-data">Активен</td> : <td className="location-data">Неактивен</td> : field === "addToGroupAt" ? <td className="location-data">{moment(user[field]).format("DD.MM.YYYY")}</td> : <td className="location-data">{user[field]}</td>}</React.Fragment>;
              })}
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default InnerUsers;
