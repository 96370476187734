import { statisticsTypes } from '../actions'

const initialState = {
    "active": { loading: false },
    "sessions": { loading: false, period: "all" },
    "top": { loading: false, period: "all" },
    "month": { loading: false },
    "cars": { loading: false, period: "all" },
    "brands": { loading: false },
    "notifications": { loading: false, period: "all" },
    "downloads": { loading: false, period: "all" },
    "downloadsMonth": { loading: false },
}

const statistics = (state = initialState, { type, payload } = {}) => {
    switch (type) {
        case statisticsTypes.GET_STATISTICS_SUCCESS:
            if (payload.docs && payload.page && payload.page > 1) return ({ ...state, [payload.type]: { ...(state?.[payload.type] || {}), ...payload, docs: [...(state?.[payload.type]?.docs || {}), ...payload.docs] } })
            else return ({ ...state, [payload.type]: { ...(state?.[payload.type] || {}), ...payload } })
        default:
            return state
    }
}

export default statistics