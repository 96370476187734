import moment from "moment";
import { supportTypes, socketTypes } from "../actions";
import { authTypes } from "../actions";

const initialState = {
  connected: false,
  tickets: [],
  messagesById: {},
  openedTicket: null,
};
const handleRatingReceived = (state, payload) => {
  const newState = state.slice(0);
  const index = newState.findIndex((item) => item._id === payload?.ticket?._id);
  if (index === -1) return newState;
  newState[index].rating = payload?.ticket?.rating || 0;
  return newState;
};
const handleMarkTicketAsUnread = (state, ticketId) => {
  const newState = state.slice(0);

  const index = newState.findIndex((item) => item._id === ticketId);

  if (index === -1) return newState;
  newState[index]._unreadCustomer = state[index]._unreadCustomer + 1;
  newState[index].updatedAt = moment();

  return newState;
};
const handleMarkCurrentTicketAsRead = (state, ticketId) => {
  const newState = state.slice(0);
  if (!ticketId) return newState;
  const index = newState.findIndex((item) => item._id === ticketId);
  if (index === -1) return newState;
  newState[index]._unreadCustomer = 0;
  newState[index].updatedAt = moment();

  return newState;
};
const handleTicketMessages = (state, payload) => {
  const newState = { ...state };
  if (payload?.docs?.length) {
    const ticketId = payload.docs[0].ticket;
    if (payload.page === 1) {
      newState[ticketId] = payload;
    } else {
      const filtered = payload.docs.filter(
        (message) =>
          !newState[ticketId].docs.find(
            (oldMessage) => oldMessage._id === message._id
          )
      );
      newState[ticketId] = {
        ...payload,
        docs: [...state[ticketId].docs, ...filtered],
      };
    }
  }
  return newState;
};
const handleNewMessage = (state, payload) => {
  const newState = { ...state };
  const ticketId = payload.ticket._id;
  const message = { ...payload };
  message.ticket = ticketId;
  if (newState[ticketId]) {
    newState[ticketId].docs = [message, ...newState[ticketId].docs];
  }
  return newState;
};



// eslint-disable-next-line default-param-last
const support = (state = initialState, { type, payload }) => {
  switch (type) {
    case supportTypes.SUPPORT_RATING_RECEIVED:
      return {
        ...state,
        tickets: handleRatingReceived(state.tickets, payload),
      };
    case supportTypes.SUPPORT_ALL_READ:
      return {
        ...state,
        tickets: handleMarkCurrentTicketAsRead(
          state.tickets,
          state.openedTicket
        ),
      };
    case supportTypes.MARK_TICKET_AS_UNREAD:
      return {
        ...state,
        tickets: handleMarkTicketAsUnread(state.tickets, payload),
      };
    case supportTypes.SET_OPENED_TICKET:
      return {
        ...state,
        openedTicket: payload,
      };
    case supportTypes.SUPPORT_REFRESH_TICKETS:
      return {
        ...state,
        tickets: payload.tickets,
      };
    case supportTypes.SUPPORT_NEW_MESSAGE:
      return {
        ...state,
        messagesById: handleNewMessage(state.messagesById, payload),
      };
    case supportTypes.SUPPORT_TICKET_MESSAGES:
      return {
        ...state,
        messagesById: handleTicketMessages(state.messagesById, payload),
      };
    case supportTypes.SUPPORT_CREATE_TICKET:
      return {
        ...state,
        tickets: [...state.tickets, payload],
      };
    case supportTypes.SET_SUPPORT_FIELDS:
      return {
        ...state,
        ...payload,
      };
      case supportTypes.SUPPORT_NEW_CUSTOMER:
      return {
        ...state,
        connected: true,
        customerId:payload.customer_id
      };
    case supportTypes.SUPPORT_INIT:
      return {
        ...state,
        tickets: payload.tickets,
      };
    case supportTypes.SUPPORT_SOCKET_CONNECTED:
      return {
        ...state,
        connected: true,
      };
    case supportTypes.SUPPORT_SOCKET_DISCONNECT:
      return {
        ...state,
        connected: false,
      };
      
    case socketTypes.CREATED_TICKET:
      return {
        ...state,
        tickets: [...payload, ...state.tickets],
      };
    case authTypes.LOG_OUT_SUCCESS:
        return initialState
    default:
      return state;
  }
};

export default support;
