export const authTypes = {
  LOGIN_REQUEST_CODE: 'login/LOGIN_REQUEST_CODE',
  LOGIN_CHECK_CODE:'login/LOGIN_CHECK_CODE',
  LOGIN_CODE_USE:'login/LOGIN_CODE_USE',
  LOGIN_SUCCESS:'login/LOGIN_SUCCESS',
  LOGIN_SUCCESS_LOCAL_STORAGE:'login/LOGIN_SUCCESS_LOCAL_STORAGE',
  LOGIN_USER_CHECK:'login/LOGIN_USER_CHECK',
  LOGIN_USER_SUPPORT:'login/USER_SUPPORT',
  LOGIN_USER_SUPPORT_SUCCESS:"login/LOGIN_USER_SUPPORT_SUCCESS",
  SET_USER:'login/SET_USER',
  LOG_OUT:'login/LOG_OUT',
  LOG_OUT_SUCCESS:'login/LOG_OUT_SUCCESS',
  LOAD_CREDENTIALS:'login/LOAD_CREDENTIALS',
  LOAD_SYSTEM: 'login/LOAD_SYSTEM',

  
  GET_USERS:"login/GET_USERS",
  SET_USERS_DETAILS:"login/SET_USERS_DETAILS",
  GET_BUSINESS_USERS:"login/GET_BUSINESS_USERS",
  GET_USERS_SUCCESS:"login/GET_USERS_SUCCESS",
  GET_BUSINESS_USERS_SUCCESS:"login/GET_BUSINESS_USERS_SUCCESS",
  EDIT_USER:'login/EDIT_USER',
  GET_USER_BY_ID:"login/GET_USER_BY_ID",
  CREATE_USER:"login/CREATE_USER",
  DELETE_USER:"login/DELETE_USER",


}

export const setUsersDetails = (payload) =>({
  type: authTypes.SET_USERS_DETAILS,
  payload,
})

export const loadSystem = (payload) =>({
  type: authTypes.LOAD_SYSTEM,
  payload,
})

export const loginRequestCode = (payload) => ({
  type: authTypes.LOGIN_REQUEST_CODE,
  payload,
})

export const loginCheckCode = (payload) =>({
  type:authTypes.LOGIN_CHECK_CODE,
  payload,
})

export const loginCodeUse = (payload) => ({
  type: authTypes.LOGIN_CODE_USE,
  payload,
})

export const setUser = (payload) => ({
  type: authTypes.SET_USER,
  payload,
})

export const checkUser = (payload) => ({
  type: authTypes.LOGIN_USER_CHECK,
  payload,
})

export const setUserSupportId = (payload) =>({
  type:authTypes.LOGIN_USER_SUPPORT,
  payload,
})

export const loadUserCredentials = payload => ({
  type: authTypes.LOAD_CREDENTIALS,
  payload
})

export const logOut = () =>({
  type: authTypes.LOG_OUT,
})

export const getUsers = (payload) =>({
  type:authTypes.GET_USERS,
  payload,
})

export const getBusinessUsers = (payload) =>({
  type:authTypes.GET_BUSINESS_USERS,
  payload,
})

export const getUserById = (payload) =>({
  type:authTypes.GET_USER_BY_ID,
  payload,
})

export const editUser = (payload) =>({
  type:authTypes.EDIT_USER,
  payload,
})

export const deleteUser = (payload) =>({
  type:authTypes.DELETE_USER,
  payload,
})

export const createUser = (payload) =>({
  type:authTypes.CREATE_USER,
  payload,
})

export const loginSuccessFromLocalStorage = (payload) =>({
  type:authTypes.LOGIN_SUCCESS_LOCAL_STORAGE,
  payload
})


