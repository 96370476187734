import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import Popup from "reactjs-popup"
import { getStatisticsNotifications, getStatisticsSuccess } from "../../../actions"
import { Statistics } from "../.."
import { periods } from "../../../config/constants"
import { history } from "../../../config/stores"
import { getPeriod } from "../../../utilities/helperFunctions"
import "./styles.scss"

const Notifications = () => {
    const dispatch = useDispatch()
    const { loading, period, docs } = useSelector(({ statistics }) => statistics.notifications)


    const dates = useMemo(() => getPeriod(period), [period])
    useEffect(() => {
        dispatch(getStatisticsSuccess({ type: "notifications", loading: true }))
        dispatch(getStatisticsNotifications({ payload: { ...dates } }))
    }, [dispatch, dates])

    return <div className="statistics-notifications-container col">
        <div className="statistics-notifications-header row">
            <h2>Известия</h2>
            <div className="col"><div className="row">
                <span>{periods.find(({ value }) => value === period).label}</span>
                <Popup
                    className='popup-header-options-container'
                    trigger={<div className="icon icon-settings" />}
                    position="left"
                >
                    {close => <Statistics.SelectPeriod value={period} onChange={(period) => {
                        dispatch(getStatisticsSuccess({ type: 'notifications', period }))
                        close()
                    }} />}
                </Popup>
            </div></div>
        </div>
        <div className="statistics-notifications-content">
            {loading
                ? <Statistics.LoaderInline />
                : docs?.map(({ count, title, type }, i) => <div
                    key={i}
                    className="statistics-notifications-item-container row"
                >
                    <div className="col">
                        <h3>{title}</h3>
                        <span>{type}</span>
                    </div>
                    <p><span>{count}</span> Сесии</p>
                </div>)
            }
        </div>
        <div className="statistics-notifications-footer">
            <div className="statistics-notifications-inner-footer row" onClick={() => history.push(`/notifications/send`)}>
                <div className="icon icon-plus" />
                <p>Изпрати персонализирано</p>
            </div>

        </div>
    </div>
}

export default Notifications