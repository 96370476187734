import moment from "moment"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import DatePicker from 'react-datepicker'
import { getStatisticsMonth, getStatisticsDownloadsMonth, getStatisticsSuccess } from "../../../actions"
import { Statistics } from "../.."
import "./styles.scss"

const Month = () => {
    const dispatch = useDispatch()
    const {
        month: { month = moment().format('YYYY-MM-DD'), days, ...downloads },
        downloadsMonth: { docs, ...downloadsMonth }
    } = useSelector(({ statistics }) => statistics)

    useEffect(() => {
        dispatch(getStatisticsSuccess({ type: "month", loading: true }))
        dispatch(getStatisticsSuccess({ type: "downloadsMonth", loading: true }))
        dispatch(getStatisticsMonth({ payload: { date: month } }))
        dispatch(getStatisticsDownloadsMonth({ payload: { date: month ? moment(month, 'YYYY-MM-DD') : moment() } }))
    }, [dispatch, month])


    return <div className="statistics-month-container col">
        <div className="statistics-month-header row">
            <h2>Ръст на приложението</h2>
            <div className="col"><div className="row">
                <span style={{ textTransform: 'uppercase' }}>{moment(month).format('MMMM')}</span>
                <div className="input-datepicker-container month-picker">
                    <DatePicker
                        customInput={<div className="icon icon-settings" />}
                        selected={moment(month, 'YYYY-MM-DD').toDate()}
                        onChange={(value) => dispatch(getStatisticsSuccess({ type: 'month', month: moment(value).format('YYYY-MM-DD') }))}
                        maxDate={new Date()}
                        showMonthYearPicker
                        showFullMonthYearPicker
                        dateFormat={'dd.MM.yyyy'}
                        calendarStartDay={1}
                    />
                </div>
            </div></div>
        </div>
        <div className="statistics-month-content">
            <div className="statistics-month-section">
                <h4>Регистрирани потребители по дни</h4>
                {downloads?.loading
                    ? <Statistics.LoaderInline />
                    : <Statistics.BarChart
                        color="#00D688"
                        month={month}
                        data={Object.fromEntries(days?.map(({ day, count }) => [day, count]) || [])}
                    />}
            </div>
            <div className="statistics-month-section">
                <h4>Тегления по дни</h4>
                {downloadsMonth?.loading
                    ? <Statistics.LoaderInline />
                    : <Statistics.BarChart
                        color="#00D688"
                        month={month}
                        data={Object.fromEntries(docs?.map(({ date, total }) => [moment(date).format('D'), total]) || [])}
                    />}
            </div>
        </div>
    </div>
}

export default Month