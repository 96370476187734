export const adminSettingsTypes = {
    GET_SESSIONS: 'adminSettings/GET_SESSIONS',
    GET_SESSIONS_SUCCESS: 'adminSettings/GET_SESSIONS_SUCCESS',

    DIAGNOSTICS:'adminSettings/DIAGNOSTICS',
    SEND_COMMAND:'adminSettings/SEND_COMMAND',

    LAST_CHARGE:'adminSettings/LAST_CHARGE',
    LAST_CHARGE_SUCCESS:'adminSettings/LAST_CHARGE_SUCCESS',

    GET_CONFIGURATION:'adminSettings/GET_CONFIGURATION',
    GET_CONFIGURATION_SUCCESS:'adminSettings/GET_CONFIGURATION_SUCCESS',

    SET_CONFIGURATION:'adminSettings/SET_CONFIGURATION',

    UNLOCK_CONNECTOR:'adminSettings/UNLOCK_CONNECTOR',

    START_CHARGING:'adminSettings/START_CHARGING',
    START_CHARGING_SUCCESS:'adminSettings/START_CHARGING_SUCCESS',
    STOP_CHARGING:'adminSettings/STOP_CHARGING',
    
    GET_USER_BY_PHONE_NUMBER:'adminSettings/GET_USER_BY_PHONE_NUMBER',

    SESSION_SOCKET_EMIT_EVENT: 'socket/SESSION_SOCKET_EMIT_EVENT',
    SESSION_JOIN_ROOM:"adminSettings/SESSION_JOIN_ROOM",

    GET_LOGS:"adminSettings/GET_LOGS"

}

export const getSessions = (payload) => ({
    type: adminSettingsTypes.GET_SESSIONS,
    payload
})

export const makeDiagonstics = (payload) => ({
    type: adminSettingsTypes.DIAGNOSTICS,
    payload
})

export const sendCommand = (payload) => ({
    type:adminSettingsTypes.SEND_COMMAND,
    payload
})

export const lastCharge = (payload) => ({
    type:adminSettingsTypes.LAST_CHARGE,
    payload
})

export const getConfiguration = (payload) => ({
    type:adminSettingsTypes.GET_CONFIGURATION,
    payload
})

export const setConfiguration = (payload) => ({
    type:adminSettingsTypes.SET_CONFIGURATION,
    payload
})

export const unlockConnector = (payload) => ({
    type:adminSettingsTypes.UNLOCK_CONNECTOR,
    payload
})

export const startCharging = (payload) => ({
    type:adminSettingsTypes.START_CHARGING,
    payload
})
export const stopCharging = (payload) => ({
    type:adminSettingsTypes.STOP_CHARGING,
    payload
})
export const getUserByPhoneNumber = (payload) => ({
    type:adminSettingsTypes.GET_USER_BY_PHONE_NUMBER,
    payload
})
export const sessionJoinRoom = (payload) => ({
    type:adminSettingsTypes.SESSION_JOIN_ROOM,
    payload
})

export const getLogs = (payload) => ({
    type:adminSettingsTypes.GET_LOGS,
    payload
})
