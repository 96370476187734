/* eslint-disable */
import React, { useEffect, useState } from "react";
import "./styles.scss";
import { ic_keyboard_arrow_down } from "react-icons-kit/md/ic_keyboard_arrow_down";
import { Icon } from "react-icons-kit";
import { history } from "../../config/stores";
import HeaderFilterRow from "../HeaderFilterRow/HeaderFilterRow";
import { connect } from "react-redux";
import { getLocationById, deleteLocation, getGroups, editLocation, getGroupByID } from "../../actions";
import moment from "moment";

const fieldNames = ["name", "_stations", "_outlets", "status", "reserved", "businessGroup", "visible", "specialPrices", "sharedProfit", "address", "workingTime", "_lastCharge", "_lastCharge_price"];

const Grid = ({ locations, getGroups, groups, getLocationById, fetchMoreLocations, tableRef, setHasMore, copyLocations, setCopyLocations, getLocations }) => {
  const [sortBy, setSortBy] = useState("");
  const [coordinates, setCoordinates] = useState({
    display: "none",
    x: "0%",
    y: "0%",
  });
  const [selectedHeader, setSelectedHeader] = useState();
  const [show, setShow] = useState(false);

  useEffect(() => {
    getGroups({
      sortBy: "",
      noPagination: true,
      search: "",
      onSuccess: () => {},
      onError: () => alert("Имаше проблем с изпращането на съобщение,опитайте отново!"),
    });
  }, []);

  const headerElements = [
    {
      name: "Локация име",
      query: {
        filter: "name",
        options: copyLocations?.map((location) => {
          return location?.name;
        }),
      },
    },
    {
      name: "Станции",
      query: { filter: "_stations", options: [] },
    },
    {
      name: "Изходи",
      query: { filter: "_outlets", options: [] },
    },
    {
      name: "Активни",
      query: { filter: "status", options: [] },
    },
    {
      name: "Резервирани",
      query: { filter: "reserved", options: [] },
    },
    {
      name: "Към група",
      query: { filter: "group", options: [] },
    },
    {
      name: "За всички",
      query: { filter: "about", options: ["Да", "Не"] },
    },
    {
      name: "Специални цени",
      query: { filter: "special", options: ["Да", "Не"] },
    },
    {
      name: "Споделен приход",
      query: {
        filter: "shared",
        options: copyLocations
          ?.map((loc) => {
            return loc.sharedProfit == true && loc.partOfProfit;
          })
          .filter((loc) => loc != false),
      },
    },
    {
      name: "Град",
      query: {
        filter: "",
        options: copyLocations
          ?.map((location) => {
            return location.address.split(",")[1];
          })
          .filter((loc) => loc != false),
      },
    },
    {
      name: "Работно време",
      query: { filter: "worktime", options: ["24/7", "Фиксирано"] },
    },
    {
      name: "Последно зареждане",
      query: { filter: "_lastCharge", options: [] },
    },
    {
      name: "Сума",
      query: { filter: "sum", options: [] },
    },
  ];

  const set_Popup = (elem) => {
    let rect = elem.getBoundingClientRect();
    if (elem.id === "11") {
      setCoordinates({
        display: "flex",
        x: `${rect.x - 200}px`,
        y: `${rect.y + 50}px`,
      });
    } else
      setCoordinates({
        display: "flex",
        x: `${rect.x - (window.innerWidth < 1600 ? 10 : 20)}px`,
        y: `${rect.y + (window.innerWidth < 1600 ? 20 : 10)}px`,
        smaller: elem.id > 0 && elem.id < 8 && elem.id != 4 && elem.id != 9 ? true : false,
      });
  };

  const handleOutlets = (location, type) => {
    if (type === "count") {
      let outlets = 0;
      location?._stations.map((station, i) => {
        outlets += station?._outlets.length;
      });
      return <td className="location-data">{outlets}</td>;
    } else if (type === "status") {
      let outlets = 0;
      let activeOutlets = 0;
      location?._stations.map((station, i) => {
        outlets += station?._outlets.length;
        station?._outlets.map((outlet, j) => {
          if (outlet?.status === "Available") activeOutlets++;
        });
      });
      return <td className="location-data">{`${activeOutlets}/${outlets}`}</td>;
    } else if (type === "reserved") {
      let outlets = 0;
      let reservedOutlets = 0;
      location?._stations.map((station, i) => {
        outlets += station?._outlets.length;
        station?._outlets.map((outlet, j) => {
          if (outlet?.status === "Reserved" || outlet?.status === "reserved") reservedOutlets++;
        });
      });
      return <td className="location-data">{`${reservedOutlets}/${outlets}`}</td>;
    }
  };

  const handleGroupsProperties = (location, type) => {
    switch (type) {
      case "group":
        return <td className="location-data">{!location?.businessGroup || !location?.businessGroups?.length ? "Не" : "Да"}</td>;
      case "visible":
        return <td className="location-data">{!location?.visible ? "Не" : "Да"}</td>;
      case "special":
        return <td className="location-data">{!location.specialPrices ? "Не" : "Да"}</td>;
      case "shared":
        return <td className="location-data">{!location.sharedProfit ? "Не" : "Да"}</td>;
      default:
        return 0;
    }
  };

  const onScroll = () => {
    let e = tableRef.current;
    const bottom = e.scrollHeight - e.scrollTop === e.clientHeight || e.scrollHeight - e.scrollTop - e.clientHeight === 1; //check if scrolled to bottom
    if (bottom) fetchMoreLocations();
  };

  const handleLastCharge = (location, type) => {
    if (type === "date") {
      if (location.length > 0) {
        return <td className="location-data">{moment(location[0]?.endAt).format("DD.MM.YYYY | HH:MM")}</td>;
      } else return <td className="location-data">Няма</td>;
    } else {
      if (location.length > 0) {
        return <td className="location-data">{Math.round(location?.[0]?.amountForPay * 100) / 100} лв.</td>;
      } else return <td className="location-data">0 лв.</td>;
    }
  };

  return (
    <>
      <table className="locations-table scroll-width-thin" id={"123"} ref={tableRef} style={{ display: "block", overflow: "auto" }} onScroll={() => onScroll()}>
        <thead style={{ display: "block" }}>
          <tr className="header-location-row">
            {headerElements.map((head, i) => {
              return (
                <th className="header-element" style={{ fontWeight: "normal" }} key={i}>
                  <p
                    className="head"
                    style={{
                      fontSize: window.innerWidth < 1500 ? "10px" : "11px",
                    }}
                  >
                    {head.name}
                  </p>
                  <div className={`icon-container ${sortBy === head.name && "rotate"}`}>
                    <Icon
                      size={"70%"}
                      icon={ic_keyboard_arrow_down}
                      className={`arrow-down`}
                      id={i}
                      onClick={() => {
                        if (sortBy.length > 0) {
                          setSortBy("");
                          getLocations({
                            noPagination: true,
                            onSuccess: (res) => setCopyLocations(res.payload.docs),
                          });
                        } else {
                          setShow(true);
                          let elem = document.getElementById(`${i}`);
                          set_Popup(elem);
                          setSortBy(head.name);
                          setSelectedHeader(headerElements[i]);
                        }
                      }}
                    />
                  </div>
                </th>
              );
            })}
          </tr>
          {show && <HeaderFilterRow copyLocations={copyLocations} setSortBy={setSortBy} setCopyLocations={setCopyLocations} onClose={(bool) => setShow(bool)} type={"stations"} selectedHeader={selectedHeader} filterType={selectedHeader.query.options.length > 1 ? "word" : "value"} coordinates={coordinates} getLocations={getLocations} locations={locations} groups={groups} setHasMore={setHasMore} />}
        </thead>
        <tbody className="neshtosi" id={"all-stations"} style={{ display: "block" }}>
          {copyLocations?.map((location, i) => {
            return (
              <tr
                className="location-row"
                key={i + 10}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  getLocationById(location._id);
                  history.push({ pathname: `/location/${location?._id}` });
                }}
              >
                {fieldNames.map((field, j) => {
                  return (
                    <React.Fragment key={j}>
                      {j === 0 && <div className="signal" />}
                      {field === "_stations" ? (
                        <td className={`location-data`}>{location[field].length}</td>
                      ) : field === "_outlets" ? (
                        handleOutlets(location, "count")
                      ) : field === "status" ? (
                        handleOutlets(location, "status")
                      ) : field === "reserved" ? (
                        handleOutlets(location, "reserved")
                      ) : field === "businessGroup" ? (
                        handleGroupsProperties(location, "group")
                      ) : field === "visible" ? (
                        handleGroupsProperties(location, "visible")
                      ) : field === "specialPrices" ? (
                        handleGroupsProperties(location, "special")
                      ) : field === "sharedProfit" ? (
                        handleGroupsProperties(location, "shared")
                      ) : field === "address" ? (
                        <td className="location-data medium">{location[field].split(",")[1]?.length > 30 ? location[field].split(",")[1]?.slice(0, 30) + "..." : location[field].split(",")[1]}</td>
                      ) : field === "workingTime" ? (
                        <td className="location-data small">{location[field] === "full" ? "24/7" : "Фиксирано"}</td>
                      ) : field === "_lastCharge" ? (
                        handleLastCharge(location[field], "date")
                      ) : field === "_lastCharge_price" ? (
                        handleLastCharge(location?._lastCharge, "price")
                      ) : (
                        <td className={`location-data ${(window.innerWidth < 1500 || j === 0) && "wider"}`}>
                          {window.innerWidth >= 1700 ? (location[field]?.length > 60 ? location[field].slice(0, 60) + "..." : location[field]) : window.innerWidth >= 1200 ? (location[field]?.length > 40 ? location[field].slice(0, 40) + "..." : location[field]) : location[field]?.length > 20 ? location[field].slice(0, 20) + "..." : location[field]}
                        </td>
                      )}
                    </React.Fragment>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

const mapStateToProps = (state) => ({
  groups: state.groups.groups,
  group: state.groups.group,
  location: state.locations.location,
});
const mapDispatchToProps = (dispatch) => ({
  getLocationById: (payload) => dispatch(getLocationById(payload)),
  deleteLocation: (payload) => dispatch(deleteLocation(payload)),
  editLocation: (payload) => dispatch(editLocation(payload)),
  getGroups: (payload) => dispatch(getGroups(payload)),
  getGroupByID: (payload) => dispatch(getGroupByID(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Grid);
