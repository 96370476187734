import { debounce } from "lodash"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Popup from "reactjs-popup"
import { getStatisticsCars, getStatisticsSuccess, getStatisticsBrands } from "../../../actions"
import { Statistics } from "../.."
import { periods } from "../../../config/constants"
import { getPeriod } from "../../../utilities/helperFunctions"
import "./styles.scss"

const Cars = () => {
    const dispatch = useDispatch()
    const { loading, period, page, nextPage, hasNextPage, docs } = useSelector(({ statistics }) => statistics.cars)
    const data = useMemo(() => docs?.map(({ name, count, logo, _id }) => ({ value: name || "Unknown", label: count, logo, info: _id })), [docs])
    const total = useMemo(() => docs?.reduce((acc, { count }) => acc + count, 0), [docs])

    const dates = useMemo(() => getPeriod(period), [period])
    const fetch = useCallback((payload) => {
        dispatch(getStatisticsSuccess({ type: "cars", loading: true }))
        dispatch(getStatisticsCars({ payload: { ...dates, ...payload } }))
    }, [dispatch, dates])
    const fetchDebounced = debounce(fetch, 300)
    useEffect(() => { fetch() }, [fetch])

    const handlePaggination = (scrollTop, height, scrollHeight) => {
        if (scrollTop + height >= scrollHeight - 10 && hasNextPage && !loading) fetchDebounced({ pageNumber: nextPage })
    }

    const [modal, setModal] = useState(null)
    const [brandData, setBrandData] = useState({})
    const fetchBrand = useCallback((payload) => dispatch(getStatisticsBrands({
        payload, onSuccess: (payload) => setBrandData((state) => {
            if (payload.docs && payload.page && payload.page > 1) return ({ ...state, ...payload, docs: [...(state?.docs || {}), ...payload.docs] })
            else return ({ ...state, ...payload })
        })
    })), [dispatch])
    const fetchBrandDebounced = debounce(fetchBrand, 300)
    useEffect(() => { modal?.info && fetchBrand({ brand: modal.info, ...dates, }) }, [fetchBrand, modal, dates])

    const handleBrandPaggination = (scrollTop, height, scrollHeight) => {
        if (scrollTop + height >= scrollHeight - 10 && brandData.hasNextPage) fetchBrandDebounced({ brand: modal.info, ...dates, pageNumber: brandData.nextPage })
    }

    return <div className="statistics-cars-container col">
        <div className="statistics-cars-header row">
            <h2>Добавени автомобили</h2>
            <div className="col"><div className="row">
                <span>{periods.find(({ value }) => value === period).label}</span>
                <Popup
                    className='popup-header-options-container'
                    trigger={<div className="icon icon-settings" />}
                    position="left"
                >
                    {close => <Statistics.SelectPeriod value={period} onChange={(period) => {
                        dispatch(getStatisticsSuccess({ type: 'cars', period }))
                        close()
                    }} />}
                </Popup>
            </div></div>
        </div>
        <div className="statistics-cars-secondary-header">
            <h3><span>{total}</span> добавени в приложението</h3>
        </div>
        <div
            className="statistics-cars-content"
            onScroll={({ target }) => handlePaggination(target.scrollTop, target.getBoundingClientRect().height, target.scrollHeight)}
        >
            {loading && !page
                ? <Statistics.LoaderInline />
                : <Statistics.Horizontal data={data} handleInfo={setModal} />}
        </div>
        {<Popup modal open={!!modal} onClose={() => setModal(null)}  >
            <div className="modal-statistics-cars-container">
                <div className="modal-statistics-cars-header row">
                    <h2>Информация за автомобили</h2>
                    <div className="icon icon-close" onClick={() => setModal(null)} />
                </div>
                <div className="modal-statistics-cars-content row">
                    <h3>Марка: <span>{modal?.value}</span></h3>
                    <h3>Брой: <span>{modal?.label}</span></h3>
                </div>
                <div className="modal-statistics-cars-table">
                    <div className="row row-header">
                        <span>Потребител</span>
                        <span>Телефон</span>
                        <span>Модел</span>
                        <span>Вид</span>
                    </div>
                    <div
                        className="scroll-container"
                        onScroll={({ target }) => handleBrandPaggination(target.scrollTop, target.getBoundingClientRect().height, target.scrollHeight)}
                    >
                        {brandData?.docs?.map(({ _id, userName, userPhoneNumber, modelName, batteryName }) => <div key={_id} className="row row-content">
                            <span>{userName}</span>
                            <span>{userPhoneNumber}</span>
                            <span>{modelName}</span>
                            <span>{batteryName}</span>
                        </div>)}
                    </div>
                </div>

            </div>
        </Popup>
        }
    </div >
}

export default Cars