import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import Popup from "reactjs-popup"
import { periods } from "../../../config/constants"
import Statistics from ".."
import { getStatisticsDownloads, getStatisticsSuccess } from "../../../actions"
import { getPeriod } from "../../../utilities/helperFunctions"
import "./styles.scss"

const Downloads = () => {
    const dispatch = useDispatch()
    const { loading, period, docs, total, withPaymentCards, withCars, withCharges } = useSelector(({ statistics }) => statistics.downloads)

    const data = useMemo(() => {
        return docs
            ?.filter(({ operationSystem }) => ['iOS', 'Android'].includes(operationSystem))
            ?.map(({ operationSystem, totalDownloads }) => ({ value: operationSystem, label: totalDownloads }))
    }, [docs])
    const total2 = useMemo(() => data?.reduce((acc, { label }) => acc + Number(label), 0), [data])
    const dates = useMemo(() => getPeriod(period), [period])
    useEffect(() => {
        dispatch(getStatisticsSuccess({ type: "downloads", loading: true }))
        dispatch(getStatisticsDownloads({ payload: { ...dates } }))
    }, [dispatch, dates])

    return <div className="statistics-downloads-container col">
        <div className="statistics-downloads-header row">
            <h2><span>{total2}</span> тегления</h2>
            <div className="col"><div className="row">
                <span>{periods.find(({ value }) => value === period).label}</span>
                <Popup
                    className='popup-header-options-container'
                    trigger={<div className="icon icon-settings" />}
                    position="left"
                >
                    {close => <Statistics.SelectPeriod value={period} onChange={(period) => {
                        dispatch(getStatisticsSuccess({ type: 'downloads', period }))
                        close()
                    }} />}
                </Popup>
            </div></div>
        </div>
        <div className="statistics-downloads-content">
            {loading
                ? <Statistics.LoaderInline />
                : <Statistics.Pie data={data} />}
        </div>
        <div className="statistics-downloads-footer">
            <h3><span>{total}</span> регистрирани потребители</h3>
            <h3><span>{withPaymentCards}</span> с платежни средства</h3>
            <h3><span>{withCars}</span> с автомобили</h3>
            <h3><span>{withCharges}</span> успешни зареждания</h3>
        </div>
    </div>
}

export default Downloads