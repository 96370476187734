import React from "react";
import '../styles.scss';
import Swal from "sweetalert2";
import { useState, useEffect, useRef } from "react";

export default function LocationToGroupNew({
  payload,
  setPayload,
  selectedLocations,
  setSelectedLocations,
  multipleLocations
}) {
  const input = useRef("");
  const [hiddenFields, setHiddenFields] = useState({
    first: false,
    second: false,
    third: false,
  });

  const [hiddenFieldsStations, setHiddenFieldsStations] = useState([{ first: null, second: null, third: null }]);

  useEffect(() => {
    if (payload?.forAllLocations) {
      setHiddenFields({ 
        first: payload?.specialPrice ? true : false,
        second: payload?.initialFee ? true : false,
        third: payload?.parkingFee ? true : false })
    }
    else {
      if (!selectedLocations?.length) return
      let newHiddenFieldsStations = selectedLocations.map(el => {
        return { first: el.specialPrice ? true : false,
                 second: el.initialFee ? true : false,
                 third: el.parkingFee ? true : false }
      })
        setHiddenFieldsStations(newHiddenFieldsStations)
    }
  }, [payload, selectedLocations]) 

  return (
    <>
      <div className="right-stations-add-container">
        {/* {(!multipleLocations && selectedLocations?.length) ? */}
        {(!multipleLocations) ?
        <div className="selected-stations-container">
          {selectedLocations?.map((location, index) => {
          return (
            <div className="right-inner-stations-add-container padding">
              <div className="flex">
                <p>{location?.name}</p>
                <div 
                className="delete-icon"             
                onClick={() => {
                Swal.fire({
                  title: "Премахване на локация",
                  text: "Сигурни ли сте, че искате да премахнете тази локация?",
                  showDenyButton: true,
                  confirmButtonText: "Да, сигурен съм",
                  confirmButtonColor: "#F54280",
                  denyButtonColor: "#FFFFFF",
                  denyButtonText: `Назад`,
                  denyButtonTextColor: "black",
                  reverseButtons: true,
                  customClass: "mine",
                }).then((result) => {
                  if (result.isConfirmed) {
                    let newPayload = {...payload}
                    const newLocations = selectedLocations.slice(0);
                    const locationToDeleteIndex = newLocations.findIndex(el => el.location === location.location)
                    newLocations.splice(locationToDeleteIndex, 1);
                    newPayload.locations = newLocations
                    setPayload(newPayload)
                    setSelectedLocations(newLocations);
                    Swal.fire("Успешно изтриване.", "", "success");
                  }
                });
              }} />
              </div>
  
            <div className="flex-wrapper">
              <div className="flex-wrapper col">
                 <div className="second-add-section no-border padding">
                   <div className="upper-half">
                     <span className="title">
                       Специална цена зареждане
                     </span>
                     <div className="checkboxes-and-radios">
                       <label
                         className="switch"
                         onClick={() => {
                          if (document.getElementById(`${`checkbox1-${index}`}`).checked === true) {
                            let list = JSON.parse(JSON.stringify(hiddenFieldsStations))
                            let station = {...list[index]}
                            station.first = false
                            list.splice(index, 1, station)
                            setHiddenFieldsStations(list)
                          } else {
                           let list = JSON.parse(JSON.stringify(hiddenFieldsStations))
                           let station = {...list[index]}
                           station.first = true
                           list.splice(index, 1, station)
                           setHiddenFieldsStations(list)
                          }

                          const newLocations = payload.locations?.length ? payload.locations.slice(0) : [
                            {
                              location: "",
                              specialPrice: null,
                              initialFee: null,
                              parkingFee: null
                          }
                          ];
                          newLocations[index].specialPrice = null
                          setPayload({...payload, locations: newLocations })
                        }}
                       >
                         <input
                          type="checkbox"
                          id={`checkbox1-${index}`}
                          checked={hiddenFieldsStations[index]?.["first"]}
                          onClick={() => {
                            const newLocations = payload.locations?.length ? payload.locations.slice(0) : [
                              {
                                location: "",
                                specialPrice: null,
                                initialFee: null,
                                parkingFee: null
                            }
                            ];
                            newLocations[index].specialPrice = null
                            setPayload({ ...payload, locations: newLocations })
                          }}
                         />
                         <div className="slider round">
                           <span className="on" id="on">
                             <b>Да</b>
                           </span>
                           <span className="off" id="off">
                             <b>Не</b>
                           </span>
                         </div>
                       </label>
                     </div>
                   </div>
                 </div>
                 
                 <div className='first-input'>
                   <div className={`sum-container wide ${!hiddenFieldsStations[index]?.["first"] ? 'disabled': ''}`}>
                           <input
                               required
                               className='sum wide'
                               type="number"
                               disabled={!hiddenFieldsStations[index]?.["first"]}
                               value={payload?.locations?.[index]?.specialPrice}
                               id="partial"
                               onChange={(e) => {
                                const newLocations = payload.locations?.length ? payload.locations.slice(0) : [
                                  {
                                    location: "",
                                    specialPrice: null,
                                    initialFee: null,
                                    parkingFee: null
                                }
                                ];
                                newLocations[index].specialPrice = Number(e.target.value)
                                setPayload({ ...payload, locations: newLocations })
                              }}
                           />
                           <div className='suffix'>лв./kW</div>
                   </div>
                   
                 </div>
              </div>
  
              <div className="flex-wrapper col">
                 <div className="second-add-section no-border padding">
                   <div className="upper-half">
                     <span className="title">
                       Начална такса
                     </span>
                     <div className="checkboxes-and-radios">
                       <label
                         className="switch"
                         onClick={() => {
                          if (document.getElementById(`${`checkbox2-${index}`}`).checked === true) {
                            let list = JSON.parse(JSON.stringify(hiddenFieldsStations))
                            let station = {...list[index]}
                            station.second = false
                            list.splice(index, 1, station)
                            setHiddenFieldsStations(list)
                          } else {
                           let list = JSON.parse(JSON.stringify(hiddenFieldsStations))
                           let station = {...list[index]}
                           station.second = true
                           list.splice(index, 1, station)
                           setHiddenFieldsStations(list)
                          }

                          const newLocations = payload.locations?.length ? payload.locations.slice(0) : [
                            {
                              location: "",
                              specialPrice: null,
                              initialFee: null,
                              parkingFee: null
                          }
                          ];
                          newLocations[index].initialFee = null
                          setPayload({...payload, locations: newLocations })
                        }}
                       >
                         <input
                          type="checkbox"
                          id={`checkbox2-${index}`}
                          checked={hiddenFieldsStations[index]?.["second"]}
                          onClick={() => {
                            const newLocations = payload.locations?.length ? payload.locations.slice(0) : [
                              {
                                location: "",
                                specialPrice: null,
                                initialFee: null,
                                parkingFee: null
                            }
                            ];
                            newLocations[index].initialFee = null
                            setPayload({ ...payload, locations: newLocations })
                          }}
                         />
                         <div className="slider round">
                           <span className="on" id="on">
                             <b>Да</b>
                           </span>
                           <span className="off" id="off">
                             <b>Не</b>
                           </span>
                         </div>
                       </label>
                     </div>
                   </div>
                 </div>
                 
                 <div className='first-input'>
                   <div className={`sum-container wide ${!hiddenFieldsStations[index]?.["second"] ? 'disabled': ''}`}>
                           <input
                               required
                               className='sum'
                               type="number"
                               disabled={!hiddenFieldsStations[index]?.["second"]}
                               value={payload?.locations?.[index]?.initialFee}
                               id="partial"
                               onChange={(e) => {
                                const newLocations = payload.locations?.length ? payload.locations.slice(0) : [
                                  {
                                    location: "",
                                    specialPrice: null,
                                    initialFee: null,
                                    parkingFee: null
                                }
                                ];
                                newLocations[index].initialFee = Number(e.target.value)
                                setPayload({ ...payload, locations: newLocations })
                              }}
                           />
                           <div className='suffix'>лв.</div>
                   </div>
                   
                 </div>
              </div>
  
              <div className="flex-wrapper col">
                 <div className="second-add-section no-border padding small">
                   <div className="upper-half">
                     <span className="title">
                       Такса престой
                     </span>
                     <div className="checkboxes-and-radios">
                       <label
                         className="switch"
                         onClick={() => {
                          if (document.getElementById(`${`checkbox3-${index}`}`).checked === true) {
                            let list = JSON.parse(JSON.stringify(hiddenFieldsStations))
                            let station = {...list[index]}
                            station.third = false
                            list.splice(index, 1, station)
                            setHiddenFieldsStations(list)
                          } else {
                           let list = JSON.parse(JSON.stringify(hiddenFieldsStations))
                           let station = {...list[index]}
                           station.third = true
                           list.splice(index, 1, station)
                           setHiddenFieldsStations(list)
                          }

                          const newLocations = payload.locations?.length ? payload.locations.slice(0) : [
                            {
                              location: "",
                              specialPrice: null,
                              initialFee: null,
                              parkingFee: null
                          }
                          ];
                          newLocations[index].parkingFee = null
                          setPayload({...payload, locations: newLocations })
                        }}
                       >
                         <input
                          type="checkbox"
                          id={`checkbox3-${index}`}
                          checked={hiddenFieldsStations[index]?.["third"]}
                          onClick={() => {
                            const newLocations = payload.locations?.length ? payload.locations.slice(0) : [
                              {
                                location: "",
                                specialPrice: null,
                                initialFee: null,
                                parkingFee: null
                            }
                            ];
                            newLocations[index].parkingFee = null
                            setPayload({ ...payload, locations: newLocations })
                          }}
                         />
                         <div className="slider round">
                           <span className="on" id="on">
                             <b>Да</b>
                           </span>
                           <span className="off" id="off">
                             <b>Не</b>
                           </span>
                         </div>
                       </label>
                     </div>
                   </div>
                 </div>
                 
                 <div className='first-input'>
                   <div className={`sum-container wide ${!hiddenFieldsStations[index]?.["third"] ? 'disabled': ''}`}>
                           <input
                               required
                               className='sum wide'
                               type="number"
                               disabled={!hiddenFieldsStations[index]?.["third"]}
                               value={payload?.locations?.[index]?.parkingFee}
                               id="partial"
                               onChange={(e) => {
                                const newLocations = payload.locations?.length ? payload.locations.slice(0) : [
                                  {
                                    location: "",
                                    specialPrice: null,
                                    initialFee: null,
                                    parkingFee: null
                                }
                                ];
                                newLocations[index].parkingFee = Number(e.target.value)
                                setPayload({ ...payload, locations: newLocations })
                              }}
                           />
                           <div className='suffix'>лв./мин</div>
                   </div>
                   
                 </div>
              </div>
            </div>
          </div>
          )
        })}
        </div> :
        <div className="right-inner-stations-add-container">
          <p style={{ fontSize: "18px" }}>
            <b>{`Цени за всички станции`}</b>
          </p>
          <div className="flex-wrapper">
            <div className="flex-wrapper col">
               <div className="second-add-section no-border padding">
                 <div className="upper-half">
                   <span className="title">
                     Специална цена зареждане
                   </span>
                   <div className="checkboxes-and-radios">
                     <label
                       className="switch"
                       onClick={() => {
                         if (document.getElementById("checkbox1").checked === true) {
                           setHiddenFields({...hiddenFields, first: false });
                           const newLocations = {...payload};
                           newLocations.specialPrice = null
                           setPayload({ ...newLocations })
                         } else {
                          const newLocations = {...payload};
                          newLocations.specialPrice = null
                          setPayload({ ...newLocations })
                          setHiddenFields({...hiddenFields, first: true });
                         }

                         const newLocations = {...payload};
                         newLocations.specialPrice = null
                         setPayload({ ...newLocations })
                       }}
                     >
                       <input
                         type="checkbox"
                         id="checkbox1"
                        //  checked={ payload?.specialPrice === true }
                         checked={hiddenFields.first}
                         onClick={() => {
                           const newLocations = {...payload};
                           newLocations.specialPrice = null

                           setPayload({ ...newLocations })
                         }}
                       />
                       <div className="slider round">
                         <span className="on" id="on">
                           <b>Да</b>
                         </span>
                         <span className="off" id="off">
                           <b>Не</b>
                         </span>
                       </div>
                     </label>
                   </div>
                 </div>
               </div>
               
               <div className='first-input'>
                 <div className={`sum-container wide ${!hiddenFields["first"] ? 'disabled': ''}`}>
                         <input
                             required
                             className='sum wide'
                             type="number"
                             disabled={!hiddenFields["first"]}
                             value={payload?.specialPrice}
                             id="partial"
                             onChange={(e) => {
                              const newPayload = {...payload};
                              newPayload.specialPrice = Number(e.target.value);
                              setPayload({ ...newPayload })
                            }}
                         />
                         <div className='suffix'>лв./kW</div>
                 </div>
                 
               </div>
            </div>

            <div className="flex-wrapper col">
               <div className="second-add-section no-border padding">
                 <div className="upper-half">
                   <span className="title">
                     Начална такса
                   </span>
                   <div className="checkboxes-and-radios">
                     <label
                       className="switch"
                       onClick={() => {
                         if (document.getElementById("checkbox2").checked === true) {
                           setHiddenFields({ ...hiddenFields, second: false });
                           const newLocations = {...payload};
                           newLocations.initialFee = null
                           setPayload({ ...newLocations })
                         } 
                         else {
                          const newLocations = {...payload};
                          newLocations.initialFee = null
                          setPayload({ ...newLocations })
                          setHiddenFields({ ...hiddenFields, second: true });
                         }

                         const newLocations = {...payload};
                         newLocations.initialFee = null
                         setPayload({ ...newLocations })
                       }}
                     >
                       <input
                         type="checkbox"
                         id="checkbox2"
                         checked={hiddenFields.second}
                         onClick={() => {
                           const newLocations = {...payload};
                           newLocations.initialFee = null
                           setPayload({ ...newLocations })
                         }}
                       />
                       <div className="slider round">
                         <span className="on" id="on">
                           <b>Да</b>
                         </span>
                         <span className="off" id="off">
                           <b>Не</b>
                         </span>
                       </div>
                     </label>
                   </div>
                 </div>
               </div>
               
               <div className='first-input'>
                 <div className={`sum-container wide ${!hiddenFields["second"] ? 'disabled': ''}`}>
                         <input
                             required
                             className='sum'
                             type="number"
                             disabled={!hiddenFields["second"]}
                             value={payload?.initialFee}
                             id="partial"
                             onChange={(e) => {
                              const newPayload = {...payload};
                              newPayload.initialFee = Number(e.target.value);
                              setPayload({ ...newPayload })
                            }}
                         />
                         <div className='suffix'>лв.</div>
                 </div>
                 
               </div>
            </div>

            <div className="flex-wrapper col">
               <div className="second-add-section no-border padding small">
                 <div className="upper-half">
                   <span className="title">
                     Такса престой
                   </span>
                   <div className="checkboxes-and-radios">
                     <label
                       className="switch"
                       onClick={() => {
                         if (document.getElementById("checkbox3").checked === true) {
                           setHiddenFields({...hiddenFields, third: false });
                           const newLocations = {...payload};
                           newLocations.parkingFee = null
                           setPayload({ ...newLocations })
                         } 
                         else {
                          const newLocations = {...payload};
                          newLocations.parkingFee = null
                          setPayload({ ...newLocations })
                          setHiddenFields({...hiddenFields, third: true });
                         }

                         const newLocations = {...payload};
                         newLocations.parkingFee = null
                         setPayload({ ...newLocations })
                       }}
                     >
                       <input
                         type="checkbox"
                         id="checkbox3"
                         checked={hiddenFields.third}
                         onClick={() => {
                           const newLocations = {...payload};
                           newLocations.parkingFee = null
                           setPayload({ ...newLocations })
                         }}
                       />
                       <div className="slider round">
                         <span className="on" id="on">
                           <b>Да</b>
                         </span>
                         <span className="off" id="off">
                           <b>Не</b>
                         </span>
                       </div>
                     </label>
                   </div>
                 </div>
               </div>
               
               <div className='first-input'>
                 <div className={`sum-container wide ${!hiddenFields["third"] ? 'disabled': ''}`}>
                         <input
                             required
                             className='sum wide'
                             type="number"
                             disabled={!hiddenFields["third"]}
                             value={payload?.parkingFee}
                             id="partial"
                             onChange={(e) => {
                              const newPayload = {...payload};
                              newPayload.parkingFee = Number(e.target.value);
                              setPayload({ ...newPayload })
                            }}
                         />
                         <div className='suffix'>лв./мин</div>
                 </div>
                 
               </div>
            </div>
          </div>
        </div>}
      </div>
    </>
  );
}
