import React from "react"
import { Statistics } from "../../components"
import "./styles.scss"

const StatisticsScreen = () => {
    return <div className="screen-statistics-container row">
        <div className="col small">
            <Statistics.Active />
            <Statistics.Downloads />
        </div>
        <div className="col wide"><Statistics.Sessions /></div>
        <div className="col"><Statistics.Top /></div>
        <div className="col"><Statistics.Month /></div>
        <div className="col"><Statistics.Cars /></div>
        <div className="col"><Statistics.Notifications /></div>
    </div>
}

export default StatisticsScreen