import React, { useCallback, useMemo } from "react";
import "./styles.scss";
import { useState, useEffect, useRef } from "react";
import { ClientMainInfo, ClientHistory, Inputs } from "../../../components";
import Inputs2 from "../../../components/Inputs2";
import { getClientStatistics, getClientDeposits, setLocationDetails } from "../../../actions";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { API } from "../../../config/settings";
import { Headers } from "../../../utilities";
import Popup from "reactjs-popup";

export default function Client() {
  const tableRef = useRef();
  const dispatch = useDispatch();
  const { id } = useParams();
  const clientData = useSelector((state) => state.finances.clientData);
  const clientDeposits = useSelector((state) => state.finances.clientDeposits);

  const [sortBy, setSortBy] = useState("");
  const [sortValue, setSortValue] = useState(0);
  const [type, setType] = useState([]);
  const [payment, setPayment] = useState([]);
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [, setFilteredStations] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [innerLoading, setInnerLoading] = useState(false);

  const sortData = (sortKey, sortValue, filterType, payment, users, filteredLocations, pageNumber) => {
    setInnerLoading(true);
    let payload = {
      user: id,
      pageNumber: pageNumber || 1,
      pageSize: "10",
      sort: "",
      paymentMethod: [],
      type: [],
      noPagination: false,
    };

    if (sortKey && sortValue) payload.sort = { [sortKey]: sortValue };
    if (filteredLocations?.length) payload.location = [...filteredLocations];
    if (payment?.length) payload.paymentMethod = [...payment];
    if (filterType?.length) payload.type = [...filterType];

    dispatch(
      getClientDeposits({
        ...payload,
        onSuccess: (response) => {
          setCurrentPage(response.payload.page);
          setHasMore(response.payload.hasNextPage);
          setInnerLoading(false);
        },
        onError: () => alert("Имаше проблем с изпращането на съобщение,опитайте отново!"),
      })
    );
  };
  const fetchData = () => {
    if (hasMore) {
      sortData(sortBy, sortValue, type, payment, [], filteredLocations, currentPage + 1);
    } else return;
  };

  const loadOptionsLocations = useCallback((search, onSuccess) => new Promise((resolve, reject) => {
    return fetch(`${API}location/list`, {
      method: "POST",
      headers: Headers.get_auth(),
      body: JSON.stringify({ pageSize: 20, search }),
    })
      .then((res) => res.json())
      .then((res) => {
        const options = res.payload.docs.map(({ _id, name }) => ({ type: _id, label: name }));
        let stations = [];
        res.payload.docs.forEach((location) => { if (location._stations) location._stations.forEach((station) => { stations.push({ type: station._id, label: station.name }); }); });
        if (onSuccess) onSuccess([...options]);
        // setFilteredLocations([...options])
        dispatch(setLocationDetails({ hasNextPage: res?.payload?.hasNextPage, page: res?.payload?.page }));
        setLocations([...options]);
        setFilteredStations([...stations]);
        return resolve(options);
      })
      .catch((err) => {
        console.error(err)
        reject()
      });
  }), [dispatch]);

  useEffect(() => {
    dispatch(getClientStatistics({ _id: id }));
    dispatch(getClientDeposits({ user: id, pageNumber: 1, pageSize: 10 }));
    loadOptionsLocations("");
  }, [dispatch, id, loadOptionsLocations]);

  const [modal, setModal] = useState(null)
  const difference = useMemo(() => {
    if (!modal?.newBalance || !clientData?.currentBalance) return null
    if (Number(modal.newBalance) === Number(clientData.currentBalance)) return null
    return (Number(modal.newBalance) - Number(clientData.currentBalance)).toFixed(2)
  }, [modal, clientData])

  const handleUpdateBalance = async () => {
    try {
      await (await fetch(`${API}finance/manual`, {
        method: "PUT",
        headers: Headers.get_auth(),
        body: JSON.stringify({ user: id, newBalance: modal.newBalance, comment: modal.comment }),
      })).json()
      setModal(null)
      dispatch(getClientStatistics({ _id: id }))
      dispatch(getClientDeposits({ user: id, pageNumber: 1, pageSize: 10 }));
    } catch (error) { console.error(error) }
  }

  return (
    <div className="client-container">
      <div className="top-container">
        <h1 className="bold">Профил клиент</h1>
        <p>
          Общ брой зареждания: <span className="bold">{clientData?.totalCharges || 0}</span>
        </p>
        <p>
          Общо платени: <span className="bold">{clientData?.totalPaid?.toFixed(2) || 0} лв.</span>
        </p>
        <p>
          Общо заредени: <span className="bold">{clientData?.totalEnergy || 0} kW</span>
        </p>
        <p>
          Актуален баланс: <span className="bold green">{clientData?.currentBalance?.toFixed(2) || 0} лв.</span>
        </p>
        <button onClick={() => setModal({ newBalance: '', comment: '' })}>
          Редакция баланс
          <div className="icon-edit-balance" />
        </button>
      </div>

      <div className="main-container">
        <ClientMainInfo clientData={clientData} />
        <ClientHistory
          historyData={clientDeposits}
          fetchData={fetchData}
          setHasMore={setHasMore}
          sortBy={sortBy}
          setSortBy={(v) => setSortBy(v)}
          type={type}
          setType={(v) => setType(v)}
          payment={payment}
          setPayment={(v) => setPayment(v)}
          locations={locations}
          setLocations={(v) => setLocations(v)}
          filteredLocations={filteredLocations}
          setFilteredLocations={(v) => setFilteredLocations(v)}
          sortValue={sortValue}
          setSortValue={(v) => setSortValue(v)}
          sortData={(sortKey, sortValue, filterType, payment, filteredLocations, pageNumber) => sortData(sortKey, sortValue, filterType, payment, filteredLocations, pageNumber)}
          loadOptionsLocations={loadOptionsLocations}
          tableRef={tableRef}
          innerLoading={innerLoading}
        />
      </div>
      {<Popup
        modal
        open={!!modal}
        onClose={() => setModal(null)}
      >
        <div className="modal-edit-balance-container">
          <div className="modal-edit-balance-header row">
            <h2>Редакция баланс</h2>
            <div className="icon-close" onClick={() => setModal(null)} />
          </div>
          <div className="modal-edit-balance-content">
            <div className="row">
              <Inputs2.TextInput bold label='Актуален баланс' disabled value={`${clientData?.currentBalance?.toFixed(2) || 0} лв.`} />
              <Inputs2.TextInput
                label='Редактирай на'
                suffix='лв.'
                value={modal?.newBalance ?? ''}
                onChange={({ target: { value } }) => {
                  if (!/^\d+(\.||\.\d{0,2})?$/.test(value) && value !== '') return
                  setModal((modal) => ({ ...modal, newBalance: value }))
                }}
              />
              {difference && <div className={`row difference-container ${difference >= 0 ? 'positive' : 'negative'}`}>{difference >= 0 && '+'}{difference} лв.</div>}
            </div>
            <Inputs2.TextInput
              label='Коментар (опционално)'
              value={modal?.comment ?? ''}
              onChange={({ target: { value } }) => setModal((modal) => ({ ...modal, comment: value }))}
            />
          </div>
          <div className="modal-edit-balance-footer">
            <Inputs.Raised text='Запази' disabled={!difference} onClick={handleUpdateBalance} />
          </div>
        </div>
      </Popup>}
    </div>
  );
}
