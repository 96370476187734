import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import Popup from "reactjs-popup"
import { Statistics } from "../.."
import { getStatisticsSessions, getStatisticsSuccess } from "../../../actions"
import { periods } from "../../../config/constants"
import { getPeriod } from "../../../utilities/helperFunctions"
import "./styles.scss"

const Sessions = () => {
    const dispatch = useDispatch()
    const { loading, period, totalSessions, uniqueUsers, average, sessionsLength, sessionByCity, timesOpened, totalCharges, chargesCount } = useSelector(({ statistics }) => statistics.sessions)

    const dates = useMemo(() => getPeriod(period), [period])
    useEffect(() => {
        dispatch(getStatisticsSuccess({ type: "sessions", loading: true }))
        dispatch(getStatisticsSessions({ payload: { ...dates } }))
    }, [dispatch, dates])

    return <div className="statistics-sessions-container col">
        <div className="statistics-sessions-header row">
            <h2><span>{totalSessions}</span> сесии общо</h2>
            <div className="col">
                <div className="row">
                    <span>{periods.find(({ value }) => value === period).label}</span>
                    <Popup
                        className='popup-header-options-container'
                        trigger={<div className="icon icon-settings" />}
                        position="left"
                    >
                        {close => <Statistics.SelectPeriod value={period} onChange={(period) => {
                            dispatch(getStatisticsSuccess({ type: 'sessions', period }))
                            close()
                        }} />}
                    </Popup>
                </div>
                <span>1 сесия = 5 секунди в приложението</span>
            </div>
        </div>
        <div className="statistics-sessions-secondery-header row">
            <h3><span>{uniqueUsers}</span> уникални потребители</h3>
            <h3><span>{Math.round(average).toString()}</span> сесии средно</h3>
            <h3><span>{totalCharges}</span> зареждания</h3>
        </div>
        <div className="statistics-sessions-content">
            <div className="statistics-sessions-section col">
                <h4>Зареждания</h4>
                {loading
                    ? <Statistics.LoaderInline />
                    : <Statistics.Horizontal data={Object.entries(chargesCount || {}).map(([key, value]) => ({ value: key, label: value }))} />}
                <p>Зареждания въз основа на общия брой уникални потребители <span>{uniqueUsers}</span></p>
            </div>
            <div className="statistics-sessions-section col">
                <h4>Продължителност</h4>
                {loading
                    ? <Statistics.LoaderInline />
                    : <Statistics.Horizontal data={Object.entries(sessionsLength || {}).map(([key, value]) => ({ value: key, label: value }))} />}
                <p>Сесии на потребителска група въз основа на общия брой <span>{totalSessions}</span></p>
            </div>
            <div className="statistics-sessions-section col">
                <h4>Брой отваряния</h4>
                {loading
                    ? <Statistics.LoaderInline />
                    : <Statistics.Horizontal data={Object.entries(timesOpened || {}).map(([key, value]) => ({ value: key, label: value }))} />}
                <p>Сесии на потребителска група въз основа на общия брой <span>{totalSessions}</span></p>
            </div>
            <div className="statistics-sessions-section col">
                <h4>Локация</h4>
                {loading
                    ? <Statistics.LoaderInline />
                    : <Statistics.Horizontal data={sessionByCity?.map(({ role, count }) => ({ value: role || "Unknown", label: count }))} />}
                <p>Сесии на потребителска група въз основа на общия брой <span>{totalSessions}</span></p>
            </div>
        </div>
    </div>
}

export default Sessions