/* eslint-disable */
import React, { useEffect, useState } from "react";
import "./styles.scss";
import "moment/locale/bg";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import moment from "moment";
import { connect, useStore } from "react-redux";
import { API } from "../../../config/settings";
import { Headers } from "../../../utilities";
import { reject, set } from "lodash";
import { toast } from "react-toastify";
import { User } from "../../../utilities/user";

const TabSettings = ({leftSettings, middleSettings}) => {
    const [ selected, setSelected ] = useState('main')

  return (
    <div className="settings-cont col">
        <div className="tabs-container">
          <div className={`option ${selected === 'main' ? 'selected' : ''}`} onClick={() => setSelected('main')}>Основни</div>
          <div className={`option ${selected === 'outlets' ? 'selected' : ''}`} onClick={() => setSelected('outlets')}>Изходи</div>
        </div>
      {(leftSettings && selected === "main") && leftSettings}
      {(middleSettings && selected === "outlets") && middleSettings}
    </div>
  );
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps)(TabSettings);
