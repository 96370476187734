/* eslint-disable */
import React from 'react'
import { history } from "../../config/stores";
import { VouchersGrid } from '../../components';
import './styles.scss'


const Vouchers = () =>{
    return(
            <div className='vouchers-container'>
                <div className='inner-vouchers-container'>
                    <div className='upper-vouchers-container'>
                        <h3 className='vouchers'>Ваучери</h3>
                        <div className='right-inner-container'>
                            <div className='button-container smaller' onClick={() => {
                                history.push("/voucher/add");
                            }}>Добави</div>
                        </div>
                    </div>
                    <div className='vouchers-content'>
                        <VouchersGrid/>
                    </div>
                </div>
            </div>
);
}

export default Vouchers;