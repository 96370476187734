/* eslint-disable */
import React, { useState,useEffect } from "react";
import Swal from "sweetalert2";
import "../styles.scss";
import { getUsers } from "../../../actions";
import { connect } from "react-redux";

const BusinessUsers = ({payload,setPayload,allUsers,setSelectedTab,inputUsers,setInputUsers, findVisibleForUsers}) => {

    const [tabs, setTabs] = useState(1);

    return (
        <>
            <div className="right-users-container">
              <div className="right-inner-users-container">
                {/* <div className="button-width-container">
                    <div
                      className="button-container continue"
                      style={{width:"150px",height:"100%"}}
                      onClick={() => {
                        let foundUsers = Array();
                        let notFoundUsers = Array();
                        inputUsers.forEach(inputUser => {
                          let valid = false;
                          allUsers.forEach(user => {
                            if(user.phoneNumber.includes(inputUser.phoneNumber)){
                               valid = true;
                               foundUsers.push(user._id)
                            }
                          })
                          if(!valid) notFoundUsers.push("+359".concat(inputUser.phoneNumber))
                        });
                      if (!findVisibleForUsers()) return
                        setPayload({
                          ...payload,
                          ["users"]: foundUsers
                        })
                        if(notFoundUsers.length > 0) Swal.fire(`Не съществуват потребители с телефонни номера: ${notFoundUsers.map(number => number + " ")}.`,'','error')
                        else setSelectedTab("locations")
                      }}
                    >
                      Продължи
                    </div>
                </div> */}
                {inputUsers.map(function (x, i) {
                  return (
                    <div className="inner-users-container" key={i} style={{marginTop:window.innerWidth < 1600 && "-2%"}}>
                      <div
                        className="inner-span-exit"
                        style={{ display: "flex" }}
                      >
                        <p style={{ fontSize: "18px" }}>
                          <b>{`Потребител ${i + 1}`}</b>
                        </p>
                        {inputUsers.length > 1 ? (
                          <div
                            className="exit"
                            id={"exit"}
                            onClick={() => {
                              const newUsers = inputUsers.slice(0);
                              newUsers.splice(i, 1);
                              setInputUsers(newUsers);
                              //setCable("");
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="all-fields">
                        <div className="header-input">
                          <span className="input-title">Име и фамилия</span>
                          <input
                            required
                            className="input-field readOnly"
                            type="text"
                            id={`group-name${i}`}
                            value={x?.name}
                            readonly
                            // onChange={(event) => {
                            //   const newUsers = inputUsers.slice(0);
                            //   newUsers[i].name = event.target.value;
                            //   setInputUsers(newUsers);
                            // }}
                          />
                        </div>
                        <div className="header-input no-margin">
                          <span className="input-title" style={{marginBottom:window.innerWidth < 1600 && "3%"}}>Телефонен номер</span>
                          <div className="prefix-input">
                            <div className="prefixx">+359</div>
                            <input
                              required
                              className="inputt-field"
                              type="number"
                              id={`group-name${i}`}
                              value={x?.phoneNumber}
                              onChange={({target : {value}}) => {
                                if(!/^\d{0,9}$/.test(value)) return
                                const newUsers = inputUsers.slice(0);
                                let userName = allUsers.find(user => user.phoneNumber === "+359" + value)?.name || ''
                                newUsers[i].phoneNumber = value;
                                userName?.length ? newUsers[i].name = userName : newUsers[i].name = ''
                                setInputUsers(newUsers);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {i + 1 === inputUsers.length ? (

                        <div
                          className="button-container add-section" 
                          style={{width:"100px"}}
                          onClick={() => {
                            setTabs(tabs + 1);
                            setInputUsers((prevState) => [
                              ...prevState,
                              {
                                name: "",
                                phoneNumber: "+359",
                              },
                            ]);
                          }}
                        >
                          Добави
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </>
    )
}

// const mapStateToProps = (state) =>({
//     allUsers:state.login.allUsers
// })

// const mapDispatchToProps = (dispatch) => ({
//   getUsers: (payload) => dispatch(getUsers(payload)),
// })

// export default connect(mapStateToProps, mapDispatchToProps)(BusinessUsers);

export default BusinessUsers;